import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import { useFormContext, type UseControllerProps } from 'react-hook-form'
import { FormInput } from 'src/common/components/FormInput'
import type { DashboardAccountSettingsFields } from '../../../../useDashboardAccountSettingsForm'

const baseRules: UseControllerProps['rules'] = {
  required: 'This field is required',
  minLength: {
    value: 6,
    message: 'Password must be at least 6 characters in length.',
  },
}

const Input = FormInput<DashboardAccountSettingsFields>

export const DashboardAccountSettingsFormSecurityPassword = () => {
  const [showForm, setShowForm] = useState(false)
  const { getValues, setError } = useFormContext()

  const validate = () => {
    const { passwordCurrent, passwordNew, passwordNewConfirm } = getValues()

    if (passwordCurrent === passwordNew) {
      setError('passwordNew', {
        message: 'Add a password different from your current one',
      })

      return false
    }

    if (passwordNew !== passwordNewConfirm) {
      const message = "Passwords don't match"
      setError('passwordNew', { message })
      setError('passwordNewConfirm', { message })

      return false
    }

    return true
  }

  if (showForm)
    return (
      <div className='space-y-4'>
        <p className='type-body-1-desktop'>Password</p>
        <Input
          fullWidth={true}
          label='Current Password'
          name='passwordCurrent'
          placeholder='Current Password'
          rules={{
            ...baseRules,
            validate,
          }}
          type='password'
        />
        <Input
          fullWidth={true}
          label='New Password'
          name='passwordNew'
          placeholder='New Password'
          rules={{
            ...baseRules,
            validate,
          }}
          type='password'
        />
        <Input
          fullWidth={true}
          label='Confirm Password'
          name='passwordNewConfirm'
          placeholder='Confirm Password'
          rules={{
            ...baseRules,
            validate,
          }}
          type='password'
        />
        <Button
          label='cancel'
          variant='outlined'
          onClick={() => setShowForm(false)}
        />
      </div>
    )

  return (
    <div className='flex flex-row items-center justify-between gap-2'>
      <div className='space-y-4'>
        <p className='type-body-1-desktop'>Password</p>
        <p className='type-body-1-desktop tracking-0.5'>••••••••</p>
      </div>
      <Button
        label='update'
        variant='outlined'
        onClick={() => setShowForm(true)}
      />
    </div>
  )
}
