import { useQuery } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { constructImageLinks, logError } from 'src/utils'
import { currentUserHotelBookingsHotelImageGQL } from './hotelBookingsGql'

export const useHotelImage = (hotelId: string) => {
  const { data, error, loading } = useQuery(
    currentUserHotelBookingsHotelImageGQL,
    {
      variables: { hotelId },
      notifyOnNetworkStatusChange: true,
    }
  )

  const rawImageLinks = data?.lodging?.imageLinks ?? []
  const formattedImageLinks = constructImageLinks(rawImageLinks)
  const imageLink = formattedImageLinks?.[0] ?? ''

  const hasError = !!error || isEmpty(imageLink)
  if (error) logError(error)

  return {
    imageLink,
    hasError,
    loading,
  }
}
