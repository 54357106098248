import { useRef, useState } from 'react'
import {
  IconButton,
  MenuItem,
  Popover,
  StopPropagation,
  useSnackbar,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import type { Guide } from 'src/__generated__/graphql'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { defaultLocationImage } from 'src/constants'
import { useShareGuide } from 'src/pages/guides'
import { GuideMetricLike, GuideMetricView } from 'src/pages/guides/common'
import { useIncrementGuideShareCountMutation } from 'src/pages/guides/useIncrementGuideShareCountMutation'
import { getGuideAddress, getGuidePublishedDate } from 'src/utils'
import { AvatarRow } from '../common/AvatarRow'
import { FallbackImage } from '../common/FallbackImage'
import { FormattedLocation } from '../common/FormattedLocation'

const GuideImage = ({ imageUrl }) => (
  <FallbackImage
    alt='City'
    className='block h-full w-full object-cover'
    fallbackSrc={defaultLocationImage}
    src={imageUrl}
  />
)

export const ProfileGuideCard = ({
  guide,
  href,
}: {
  guide: Guide
  href: string
}) => {
  const shareGuide = useShareGuide()
  /**@todo figure out how to deduplicate this logic from GuideMetricShare.tsx */
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const [hasShared, setHasShared] = useState(false)
  // end todo

  const {
    id,
    images,
    imageUrl,
    description,
    addresses,
    ownerProfile,
    name,
    updatedAt,
    timeZone,
  } = guide ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const image = images?.[0]?.url ?? imageUrl
  const { displayName, profileImageUrl } = ownerProfile ?? {}
  const anchorRef = useRef<HTMLAnchorElement>(null)

  const handleDataLayerPush = () => {
    pushDataToDataLayer('guide_card_click', {
      guide_id: guide.id,
      guide_name: name,
      owner_id: ownerProfile?.id,
      trigger_url: window.location.href,
    })
  }

  /**@todo figure out how to deduplicate this logic from GuideMetricShare.tsx */
  const onShareGuide = async () => {
    await shareGuide({
      guideDraftTitle: name,
      isUserOwner: ownerProfile?.isUserOwner,
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({ timeout: 3000, title: 'Guide URL copied' })
      },
    })
    if (!hasShared) {
      incrementShareCount({ variables: { guideId: id } })
      setHasShared(true)
    }
  }

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      className={classNames(
        'rounded-3 border-grey-300 of-hidden relative border-2 border-solid hover:cursor-pointer',
        /**
         * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
         * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
         */
        'group'
      )}
      onClick={() => {
        handleDataLayerPush()
        anchorRef?.current?.click?.()
      }}
    >
      <div className='md:h-77 relative flex flex-col justify-between gap-x-8 px-6 py-4 md:flex-row'>
        {/* Top (Mobile) Picture */}
        <div className='h-60 w-full overflow-hidden rounded-lg sm:mb-4 sm:block md:mb-0 md:hidden'>
          <GuideImage imageUrl={image} />
        </div>
        <div className='flex min-w-0 flex-col gap-y-2 md:w-[60%]'>
          {/* important for SEO! Preserving referrer allows search engine bots to crawl internal links */}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <StopPropagation className='[&_svg]:c-grey800 flex flex-row items-center justify-between'>
            <Link
              className='outline-offset-5 focus-visible:outline-emerald type-h3 c-forest inline-block no-underline'
              ref={anchorRef}
              to={href}
              onClick={() => handleDataLayerPush()}
            >
              {name}
            </Link>
            <Popover
              className='b-grey-300 b-solid b-1 w-50 text-body-1 c-grey900 rd-3 children-[:nth-child(1)]:rd-t-3 children-[:nth-child(2)]:rd-b-3'
              placement='bottom-end'
              trigger={<IconButton icon='moreHoriz' />}
            >
              <MenuItem
                label='Add to'
                startIcon='addCircleOutline'
                value='Add to'
                onClick={() => {
                  dispatchToggleAddToCollectionsModal({
                    isModalOpen: true,
                    item: {
                      id: guide?.id,
                      name: guide?.name,
                      type: CollectionItemIdType.Guide,
                    },
                  })
                }}
              ></MenuItem>
              <MenuItem
                label='Share'
                startIcon='iosShare'
                value='Share'
                onClick={onShareGuide}
              ></MenuItem>
            </Popover>
          </StopPropagation>
          <FormattedLocation
            address={{
              fullAddress: getGuideAddress({
                city,
                country,
                state,
              }),
            }}
          />
          <AvatarRow
            dateTimeText={getGuidePublishedDate({
              insertedAt: updatedAt,
              timeZone,
            })}
            displayName={displayName}
            profileImageUrl={profileImageUrl}
          />
          <p className='text-body-1 text-ellipsis sm:line-clamp-2 md:line-clamp-3'>
            {description}
          </p>
          <div className='flex grow flex-row items-end'>
            <StopPropagation>
              <div className='[&>button]:p-x-0 flex flex-row items-center gap-3.5'>
                <GuideMetricLike guideData={guide} size='small' />
                <GuideMetricView guideData={guide} size='small' />
              </div>
            </StopPropagation>
          </div>
        </div>
        {/* Righthand (Desktop) Picture */}
        <div className='rounded-3 hidden h-full w-60 overflow-hidden md:block'>
          <GuideImage imageUrl={image} />
        </div>
      </div>
    </div>
  )
}
