import { useState } from 'react'
import { Icon, Tag, Button, useSnackbar } from '@travelpass/design-system'
import { isMobile } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'
import type { StandardHotelsQueryInBookingDetailsHeroQuery } from 'src/__generated__/graphql'
import { HotelPoliciesModal } from 'src/common/components'
import { isOwner, isPublic } from 'src/pages/trips/utils/permissionUtils'
import {
  constructImageLinks,
  copyToClipboard,
  generateHotelDetailsUrl,
} from 'src/utils'
import {
  bookingDetailsHeroActionsCss,
  bookingDetailsHeroContentCss,
  bookingDetailsHeroCopyButtonCss,
  bookingDetailsHeroCss,
  bookingDetailsHeroImageCss,
  bookingDetailsHeroTitleCss,
} from './bookingDetailsHeroStyles'
import {
  useBookingDetailsDatesQuery,
  useBookingDetailsIdsQuery,
  useBookingDetailsLocationQuery,
  useBookingDetailsPoliciesQuery,
} from '../../hooks'

interface BookingDetailsHeroProps {
  bookingDetailsData: StandardHotelsQueryInBookingDetailsHeroQuery['lodging']
}

export const BookingDetailsHero = ({
  bookingDetailsData,
}: BookingDetailsHeroProps) => {
  const navigate = useNavigate()
  const { bookingId } = useParams()
  const { addSuccessSnack } = useSnackbar()
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({
    bookingId,
  })
  const { hotelId, externalConfirmationId, trip } = bookingDetailsIdsData ?? {}
  const { bookingDetailsDatesData } = useBookingDetailsDatesQuery({
    bookingId,
  })
  const { bookingDetailsLocationData } = useBookingDetailsLocationQuery({
    hotelId,
  })
  const { bookingDetailsPoliciesData, bookingDetailsPoliciesHotelData } =
    useBookingDetailsPoliciesQuery({
      bookingId,
      hotelId,
    })
  const [isHotelPoliciesModalOpen, setIsHotelPoliciesModalOpen] =
    useState(false)
  const { imageLinks } = bookingDetailsData ?? {}
  const { status } = bookingDetailsDatesData ?? {}
  const { city, id, name, state, stateCode } = bookingDetailsLocationData ?? {}
  const { policies: ratePolicies } =
    bookingDetailsPoliciesData?.bookingDetails?.hotelDetails ?? {}
  const { checkinInstructions, checkinSpecialInstructions, fees, policies } =
    bookingDetailsPoliciesHotelData ?? {}
  const constructedImage = constructImageLinks(imageLinks, '1280x720')?.shift()
  const displayConfirmationNum =
    isOwner(trip?.currentUserRole) || isPublic(trip?.currentUserRole)
  const constructedStatus = () => {
    if (status === 'CANCELLED')
      return (
        <Tag
          color='red'
          startIcon='eventBusy'
          text='This hotel booking has been cancelled.'
        />
      )
    return
  }

  const onCopyButtonClick = () => {
    copyToClipboard(externalConfirmationId)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
  }

  const onProductButtonClick = () => {
    const constructedHotelRoute = generateHotelDetailsUrl({
      city,
      id,
      name,
      state,
      stateAbbreviation: stateCode,
    })

    navigate(constructedHotelRoute)
  }

  return (
    <>
      {isHotelPoliciesModalOpen && (
        <HotelPoliciesModal
          checkinInstructions={checkinInstructions}
          checkinSpecialInstructions={checkinSpecialInstructions}
          fees={fees}
          policies={policies}
          ratePolicies={ratePolicies}
          onClose={() => setIsHotelPoliciesModalOpen(false)}
        />
      )}
      <div className={bookingDetailsHeroCss} data-testid='BookingDetailsHero'>
        <div className={bookingDetailsHeroContentCss}>
          <h1 className={bookingDetailsHeroTitleCss}>{name}</h1>
          {!!externalConfirmationId && displayConfirmationNum && (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div
              className={bookingDetailsHeroCopyButtonCss}
              onClick={onCopyButtonClick}
            >
              <Icon name='contentCopy' />
              <span>Confirmation number: {externalConfirmationId}</span>
            </div>
          )}
          {constructedStatus()}
          {!!constructedImage && (
            <img
              alt={`${name} hero - Booking Details`}
              className={bookingDetailsHeroImageCss}
              data-testid='BookingDetailsHero-image'
              src={constructedImage}
            />
          )}
        </div>
        <div className='hide-on-print'>
          <div className={bookingDetailsHeroActionsCss}>
            <Button
              endIcon='arrowForward'
              label={isMobile ? 'Hotel Listing' : 'View Hotel Listing'}
              variant='text'
              onClick={onProductButtonClick}
            />
            <Button
              label='Hotel Policy'
              startIcon='listAlt'
              variant='text'
              onClick={() => setIsHotelPoliciesModalOpen(true)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
