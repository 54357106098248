import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { dashboardPath } from 'src/constants'
import { DashboardTab } from 'src/pages/dashboard/dashboardConstants'

export const FavoriteIdeas = () => {
  const { isAnonymous } = useFirebaseUser()
  const navigate = useNavigate()

  const onClick = () => {
    if (isAnonymous)
      dispatch('openSignin', {
        isCreateAccount: true,
        onClose: loggedIn => {
          if (loggedIn)
            navigate(`${dashboardPath}?tab=${DashboardTab.collections}`)
        },
      })
    else {
      navigate(`${dashboardPath}?tab=${DashboardTab.collections}`)
    }
  }

  return (
    <div className='px-8% py-80px flex flex-col gap-10 lg:flex-row'>
      <img
        alt='plan'
        className='max-w-90% lg:max-w-50% w-full'
        src='https://static.travelpass.com/assets/plan/FavoriteIdeas.webp'
      />
      <section className='max-w-90% lg:max-w-50% flex flex-col justify-center gap-5'>
        <h2 className='type-h2 font700 w-full text-center lg:text-start'>
          See Something You Love?
        </h2>
        <div className='flex justify-center lg:justify-start'>
          <div className='border-3px border-valley w-32 border-solid'></div>
        </div>
        <p className='type-body-1-desktop w-80%'>
          Keep your favorite ideas together with Collections, a way to save
          anything and everything for your next adventure.
        </p>
        <div className='hidden lg:block'>
          <Button onClick={onClick}>Create A Collection</Button>
        </div>
        <div className='lg:hidden'>
          <Button fullWidth onClick={onClick}>
            Create A Collection
          </Button>
        </div>
      </section>
    </div>
  )
}
