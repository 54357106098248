import { ExploreSearchBar } from './ExploreSearchBar'

export const ExploreHero = () => (
  <section className='py-34 space-y-10 bg-[url(https://static.travelpass.com/assets/guides/guides-search-header.webp)] bg-cover bg-[position:50%_70%] bg-no-repeat md:py-44 lg:bg-[position:50%_36%]'>
    <h1 className='type-h1 text-center max-md:px-2'>
      <span className='hidden lg:block'>Travel With Real Recommendations</span>
      <span className='block text-4xl lg:hidden'>
        Travel With Real Recommendations
      </span>
    </h1>
    <ExploreSearchBar
      hideSlotBefore
      className='max-w-150 mx-auto px-4 max-md:px-8'
    />
  </section>
)
