import type { ComponentPropsWithoutRef, ReactNode } from 'react'

type PolymorphicProps<T extends React.ElementType> = {
  as?: T
  children?: ReactNode
} & ComponentPropsWithoutRef<T>

function Polymorphic<T extends React.ElementType = 'div'>({
  as,
  children,
  ...props
}: PolymorphicProps<T>) {
  const Component = as || 'div'

  return <Component {...props}>{children}</Component>
}

export default Polymorphic
