import { Skeleton } from '@travelpass/design-system'

export const GuideHeroLoading = () => (
  <div className='space-y-6'>
    <div className='h-37.5 lg:h-75 m-a flex flex-row items-center gap-1'>
      <div className='of-hidden lg:w-194 lg:max-w-194 relative h-full grow'>
        <Skeleton />
      </div>
      <div className='of-hidden relative h-full grow'>
        <Skeleton />
      </div>
      <div className='of-hidden relative hidden h-full grow lg:block'>
        <Skeleton />
      </div>
    </div>
  </div>
)
