import { Avatar, Divider } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { ReadMoreContentSection } from 'src/common/components/ReadMoreContentSection'
import { getGuideOwnerUrl, getGuidePublishedDate } from 'src/utils'
import type { GuideData, GuideHeaderData } from '../../types'

interface GuideHeaderContentProps {
  guideHeaderData: Pick<
    GuideHeaderData,
    'description' | 'insertedAt' | 'ownerProfile' | 'timeZone' | 'updatedAt'
  >
}

export const GuideHeaderContent = ({
  guideHeaderData,
}: GuideHeaderContentProps) => {
  const { description, insertedAt, ownerProfile, timeZone, updatedAt } =
    guideHeaderData ?? {}
  const { accountHandle, displayName, profileImageUrl } = ownerProfile ?? {}
  const guidePublishedDate = getGuidePublishedDate({
    insertedAt,
    timeZone,
    updatedAt,
  })

  return (
    <div className='flex flex-col gap-5 md:flex-row md:gap-7'>
      <div className='flex min-w-fit flex-col gap-3 md:flex-row md:items-start md:gap-3.5'>
        <div className='flex flex-row items-center gap-3'>
          <a
            aria-label={`View profile of ${displayName}`}
            className='transition-opacity-200 ease-linear hover:opacity-50 active:opacity-50'
            href={getGuideOwnerUrl(accountHandle)}
            rel='noreferrer'
            target='_BLANK'
          >
            <Avatar border='none' size='sm' src={profileImageUrl} />
          </a>
          <div className='space-y-1'>
            <a
              aria-label={`View profile of ${displayName}`}
              className='transition-opacity-200 decoration-none type-body-2 block w-fit ease-linear hover:opacity-50 active:opacity-50'
              href={getGuideOwnerUrl(accountHandle)}
              rel='noreferrer'
              target='_BLANK'
            >
              {`By ${displayName}`}
            </a>
            <time className='type-small-text'>{guidePublishedDate}</time>
          </div>
        </div>
        {description && (
          <Divider className='w-1px m-0 hidden min-h-12 md:block' />
        )}
      </div>
      <div className='hidden md:block'>
        <ReadMoreContentSection maxHeightClass='max-h-16'>
          <p className='type-body-1'>{description}</p>
        </ReadMoreContentSection>
      </div>
      <div className='md:hidden'>
        <ReadMoreContentSection maxHeightClass='max-h-18.5'>
          <p className='type-body-1'>{description}</p>
        </ReadMoreContentSection>
      </div>
    </div>
  )
}
