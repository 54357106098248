import { useCallback, useRef, useState } from 'react'
import { Divider } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type {
  BookingHotelDetailsQuery,
  BookingValidateRateQuery,
} from 'src/__generated__/graphql'
import {
  HotelCancellationCard,
  HotelCancellationCardLoading,
  HotelCancellationModal,
  HotelPriceSummary,
  HotelPriceSummaryLoading,
  DateSummary,
} from 'src/common/components'
import {
  constructDueLater,
  constructDueNow,
  constructFees,
  constructTotal,
} from 'src/common/components/HotelPriceSummary/hotelPriceSummaryUtils'
import { useFlag, useIsElementOnScreen } from 'src/common/hooks'
import { initialRooms } from 'src/constants/user'
import { BookingSidebarAdditionalInfo } from './BookingSidebarAdditionalInfo'
import { BookingSidebarRoom } from './BookingSidebarRoom'
import { StickyNav } from '../StickyNav'

interface BookingTermsProps {
  bookingHotelData: BookingHotelDetailsQuery
  bookingHotelLoading: boolean
  bookingRateData: BookingValidateRateQuery
  bookingRateLoading: boolean
}

export const BookingSidebar = ({
  bookingHotelData,
  bookingHotelLoading,
  bookingRateData,
  bookingRateLoading,
}: BookingTermsProps) => {
  const isInternationalGeocoderEnabled = useFlag(
    'isInternationalGeocoderEnabled'
  )
  const [isHotelCancellationModalOpen, setIsHotelCancellationModalOpen] =
    useState(false)
  const [searchParams] = useSearchParams()
  const { address, checkin, checkout, country, city, fees, name, state } =
    bookingHotelData?.lodging ?? {}
  const { provider, room } = bookingRateData?.validatedRate ?? {}
  const { cancelPolicy, price } = room?.rates?.[0] ?? {}
  const {
    constructedCancellationPolicies,
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    refundType,
  } = cancelPolicy ?? {}
  const { policyText, shortText } = constructedCancellationPolicies ?? {}
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const {
    allInTotal,
    additionalFees,
    dueLater,
    dueNow,
    subtotal,
    surchargeTotal,
    surcharges,
  } = price ?? {}
  const showInternationalDisclaimer =
    !!isInternationalGeocoderEnabled && country !== 'United States of America'

  const constructedFees = constructFees(additionalFees)
  const constructedDueLater = constructDueLater(
    constructedFees,
    dueLater?.amount
  )
  const constructedDueNow = constructDueNow(dueNow?.amount)
  const constructedTotalPrice = constructTotal(
    constructedDueLater,
    constructedDueNow
  )
  const priceSummaryRef = useRef(null)
  const [, hasMounted] = useState(false)
  const setPriceSummaryRef = useCallback(node => {
    priceSummaryRef.current = node
    hasMounted(!!priceSummaryRef.current)
  }, [])
  const isPriceSummaryOnScreen = useIsElementOnScreen(priceSummaryRef)
  const showStickyNav =
    !isPriceSummaryOnScreen && !bookingHotelLoading && !bookingRateLoading

  const onClickStickyNav = e => {
    e.preventDefault()
    priceSummaryRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  return (
    <>
      {isHotelCancellationModalOpen && (
        <HotelCancellationModal
          fullyRefundableUntil={fullyRefundableUntil}
          nonRefundableAfter={nonRefundableAfter}
          partiallyRefundableUntil={partiallyRefundableUntil}
          policies={policyText}
          onClose={() => setIsHotelCancellationModalOpen(false)}
        />
      )}
      <div className='space-y-6'>
        <section className='bg-warm-grey rounded-4 border-1 border-grey-300 space-y-6 border-solid p-6'>
          <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-2 space-y-4'>
              <h1 className='type-h4 c-newForest font-600 line-clamp-2'>
                {name}
              </h1>
              <p className='type-body-1 c-black'>
                {address}, {city}, {state}
              </p>
            </div>
          </div>
          <Divider className='mb-6' />
          <DateSummary
            arrival={arrival}
            checkin={checkin}
            checkout={checkout}
            departure={departure}
          />
          <BookingSidebarRoom
            bookingRateData={bookingRateData}
            bookingRateLoading={bookingRateLoading}
            city={city}
            name={name}
            state={state}
          />
          {bookingHotelLoading || bookingRateLoading ? (
            <HotelCancellationCardLoading />
          ) : (
            <HotelCancellationCard
              fullyRefundableUntil={fullyRefundableUntil}
              nonRefundableAfter={nonRefundableAfter}
              partiallyRefundableUntil={partiallyRefundableUntil}
              policies={policyText}
              refundType={refundType}
              subtitle={shortText}
            />
          )}
        </section>
        {bookingHotelLoading || bookingRateLoading ? (
          <HotelPriceSummaryLoading />
        ) : (
          <section
            className='bg-warm-grey rounded-4 border-1 border-grey-300 scroll-mt-2 space-y-6 border-solid p-6'
            ref={setPriceSummaryRef}
          >
            <HotelPriceSummary
              showLegal
              additionalFees={additionalFees}
              allInTotal={allInTotal?.amount}
              arrival={arrival}
              departure={departure}
              dueLater={dueLater?.amount}
              dueNow={dueNow?.amount}
              hotelFees={fees}
              provider={provider}
              rooms={initialRooms}
              showInternationalDisclaimer={showInternationalDisclaimer}
              subtotal={subtotal?.amount}
              surchargeTotal={surchargeTotal?.amount}
              surcharges={surcharges}
            />
          </section>
        )}
        {showStickyNav && (
          <StickyNav
            totalPrice={constructedTotalPrice}
            onClick={onClickStickyNav}
          />
        )}
        <BookingSidebarAdditionalInfo
          bookingHotelData={bookingHotelData}
          bookingHotelLoading={bookingHotelLoading}
          bookingRateData={bookingRateData}
        />
      </div>
    </>
  )
}
