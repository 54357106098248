import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { isbot } from 'isbot'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useIsElementOnScreen } from 'src/common/hooks'
import {
  decodeGuideId,
  encodeGuideId,
  getGuideDetailsUrl,
  stringToBoolean,
} from 'src/utils'
import {
  GuideCreateGuideAction,
  GuideDrawer,
  GuideEmpty,
  GuideHeader,
  GuideHero,
  GuideLoading,
  GuideMap,
  GuideSections,
  GuideStickyScrollUp,
  GuideStickyActionButtons,
  GuideStickyTopNav,
  GuideMapWrapper,
  GuideSectionsWrapper,
} from './common'
import { GuideSearchParam } from './guideConstants'
import { useGetGuideQuery } from './useGetGuideQuery'
import { useGuideSessionStorageIds } from './useGuideSessionStorageIds'

export const Guide = () => {
  const { selectedId } = useGuideSessionStorageIds()
  const navigate = useNavigate()
  const { guideId } = useParams()
  const guideHeaderRef = useRef(null)
  const isGuideHeaderOnScreen = useIsElementOnScreen(guideHeaderRef)
  const [searchParams] = useSearchParams()
  const [points, setPoints] = useState<PublishedEvent[]>()
  const guideIdDecoded = decodeGuideId(guideId)
  const guideIdEncoded = encodeGuideId({
    guideId: guideIdDecoded,
    isGuideDraft: false,
  })
  const { guideData, guideMetaData, hasError, isLoading, location, owner } =
    useGetGuideQuery(guideIdEncoded)
  const mapExpanded = stringToBoolean(
    searchParams.get(GuideSearchParam.mapExpanded)
  )
  const { isUserOwner } = owner ?? {}
  const {
    addresses,
    collectedCount,
    description,
    eventCounts,
    guideDraft,
    id,
    insertedAt,
    isCollected,
    name,
    numberOfLikes,
    ownerProfile,
    shareCount,
    tags,
    timeZone,
    updatedAt,
    viewCount,
  } = guideData ?? {}

  useEffect(() => {
    if (guideId !== guideIdDecoded) {
      navigate(
        getGuideDetailsUrl({
          id: guideIdDecoded,
          name,
        }),
        {
          replace: true,
        }
      )
    }
  }, [guideId, guideIdDecoded, name])

  const onPointsChange = (updatedPoints: PublishedEvent[]) =>
    setPoints(updatedPoints)

  if (isLoading) return <GuideLoading />

  if (hasError || isEmpty(guideData)) return <GuideEmpty />

  /** @todo use only 1 PageLayoutContainer instead of 2 if possible (bg color discrepancy caused this initially) */
  return (
    <>
      {!isEmpty(guideData) && (
        <Helmet
          canonicalUrl={`${window.location.origin}${getGuideDetailsUrl({
            id: guideIdDecoded,
          })}`}
          metaDescription={guideMetaData?.metaDescription}
          metaImage={guideMetaData?.metaImage}
          pageName={guideMetaData?.pageName}
        />
      )}
      <article>
        <div
          className={classNames({
            'invisible absolute lg:visible lg:static': mapExpanded,
          })}
        >
          <GuideHero guideData={guideData} isEdit={false} />
        </div>
        <div className='lg:flex lg:flex-row'>
          <GuideSectionsWrapper
            mapExpanded={mapExpanded}
            selectedId={selectedId}
          >
            <div className='bg-white' ref={guideHeaderRef}>
              <PageLayoutContainer size='none'>
                <GuideHeader
                  guideHeaderData={{
                    addresses,
                    collectedCount,
                    description,
                    eventCounts,
                    guideDraftId: guideDraft?.id,
                    id,
                    insertedAt,
                    isCollected,
                    name,
                    numberOfLikes,
                    ownerProfile,
                    shareCount,
                    tags,
                    timeZone,
                    updatedAt,
                    viewCount,
                  }}
                  isEdit={false}
                />
              </PageLayoutContainer>
            </div>
            <GuideStickyTopNav
              guideData={guideData}
              isVisible={!isGuideHeaderOnScreen && guideHeaderRef.current}
              selectedId={selectedId}
            />
            <PageLayoutContainer size='none'>
              <GuideSections
                eventCount={eventCounts?.numberEvents}
                guideId={guideIdEncoded}
                guideName={name}
                owner={owner}
                onPointsChange={onPointsChange}
              />
              {owner?.isUserOwner && <div className='p-b-5 lg:p-bv-4' />}
            </PageLayoutContainer>
            <GuideStickyScrollUp
              className='w-full lg:hidden'
              defaultVisibility={true}
            >
              <GuideStickyActionButtons
                guideData={guideData}
                isUserOwner={isUserOwner}
              />
            </GuideStickyScrollUp>
          </GuideSectionsWrapper>
          <GuideDrawer
            className='h-100dvh lg:top-0'
            guideDraftId={guideDraft?.id}
            isEdit={false}
            owner={owner}
            points={points}
            selectedId={selectedId}
          />
          {!isbot(navigator.userAgent) && (
            <GuideMapWrapper
              className='lg:h-100svh lg:top-0'
              mapExpanded={mapExpanded}
              selectedId={selectedId}
            >
              <GuideMap
                isLoading={isLoading}
                location={location}
                owner={owner}
                points={points}
              />
            </GuideMapWrapper>
          )}
        </div>
        {!owner?.isUserOwner && (
          <div
            className={classNames({
              'invisible absolute lg:visible lg:static': mapExpanded,
            })}
          >
            <GuideCreateGuideAction />
          </div>
        )}
      </article>
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </>
  )
}
