import { useMutation } from '@apollo/client'
import { Button, useSnackbar } from '@travelpass/design-system'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { dispatch } from 'src/common/hooks/useListen'
import { tripTimelinePath } from 'src/constants'
import { HotelSearchParams } from 'src/pages/hotels/details/constants'

const claimAnonymousBookingMutation = gql(`
  mutation ClaimAnonymousBooking($input: ClaimAnonymousBookingInput!) {
    claimAnonymousBooking(input: $input) {
      updatedUser {
        id
        bookings {
          id
        }
      }
    }
  }
`)

interface BookingConfirmationTripsSignInProps {
  onClaimedAnonymousUser?: () => void
}

export const BookingConfirmationTripsSignIn = ({
  onClaimedAnonymousUser,
}: BookingConfirmationTripsSignInProps): JSX.Element => {
  const navigate = useNavigate()
  const { bookingId } = useParams()
  const [searchParams] = useSearchParams()
  const tripId = searchParams.get(HotelSearchParams.tripId)

  const { addErrorSnack, addSuccessSnack } = useSnackbar()

  const [claimAnonymousBooking] = useMutation(claimAnonymousBookingMutation)

  const onCloseSigninModal = async (shouldMergeAccount: boolean) => {
    if (shouldMergeAccount && bookingId) {
      const variables = { input: { bookingId } }
      try {
        await claimAnonymousBooking({ variables })

        navigate(`${tripTimelinePath}/${tripId}?${searchParams}`)

        addSuccessSnack({ title: 'Successfully added booking to your account' })
      } catch {
        addErrorSnack({ title: 'Unable to add booking to your account' })
      }
    }

    if (shouldMergeAccount) onClaimedAnonymousUser?.()
  }

  const onOpenSigninModal = (updatedShowSignInCreateAccount = false) => {
    dispatch('openSignin', {
      isCreateAccount: updatedShowSignInCreateAccount,
      onClose: onCloseSigninModal,
    })
  }

  return (
    <div className={bookingConfirmationTripsSignInCss}>
      <Button fullWidth label='Sign In' onClick={() => onOpenSigninModal()} />
      <Button
        fullWidth
        label='Create An Account'
        variant='outlined'
        onClick={() => onOpenSigninModal(true)}
      />
    </div>
  )
}

const bookingConfirmationTripsSignInCss = 'flex flex-col gap-4 md:flex-row'
