import { useState, useEffect } from 'react'

export const GetTheApp = () => {
  const [showText, setShowText] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      setShowText(false)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleMouseEnter = () => {
    setShowText(true)
  }

  const handleMouseLeave = () => {
    setShowText(false)
  }

  return (
    <div
      className='bg-forest rd-2.5 hidden items-center gap-4 px-3 py-1 lg:flex'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showText && (
        <div>
          <img
            alt='Travelpass App QR Code'
            className='w-5.75 h-5.75 transform-rotate-9.15deg absolute left-24'
            src='https://static.travelpass.com/assets/brands/travelpass/logo-symbol-valley.webp'
          />
          <p className='type-h4 c-white w-37'>Get the Travelpass App</p>
        </div>
      )}
      <img
        alt='Travelpass App QR Code'
        className='w-25 h-25'
        src='https://static.travelpass.com/assets/travelpass-app-qr-code.webp'
      />
    </div>
  )
}
