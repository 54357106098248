import { useEffect, useState } from 'react'
import { EmptyState, Carousel } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { defaultLocationImage } from 'src/constants'
import { HeroImage } from './HeroImage'

export const Hero = ({
  images = [],
  name,
  hideArrows = true,
  hideDots = false,
  showDefaultImage = false,
}: {
  images?: string[]
  name?: string
  hideArrows?: boolean
  hideDots?: boolean
  showDefaultImage?: boolean
}) => {
  const [hasFirstImageLoaded, setHasFirstImageLoaded] = useState(false)
  const [imageLinks, setImageLinks] = useState<string[]>([])
  const isCarouselDisabled = !hasFirstImageLoaded || imageLinks?.length <= 1

  const onError = (index: number) => {
    const newImageLinks = imageLinks?.filter((_, i) => i !== index)

    setImageLinks(newImageLinks)
  }

  const onLoad = () => {
    if (!hasFirstImageLoaded) setHasFirstImageLoaded(true)
  }

  useEffect(() => {
    if (images?.length > 0) {
      setImageLinks(images)
    } else if (showDefaultImage) {
      setImageLinks([defaultLocationImage])
    }
  }, [images])

  return (
    <div className='h-55 w-full px-8'>
      <div className='rd-3 h-full w-full overflow-hidden'>
        <span className='sr-only'>Photo gallery for {name}</span>
        {isEmpty(imageLinks) ? (
          <div className='flex h-full w-full flex-col items-center justify-center'>
            <EmptyState name='noImages' />
          </div>
        ) : (
          <Carousel
            isLight
            hideArrows={hideArrows || isCarouselDisabled}
            hideDots={hideDots || imageLinks?.length <= 1}
            isDisabled={isCarouselDisabled}
            size='small'
          >
            {imageLinks?.map((imageLink, index) => (
              <HeroImage
                key={imageLink}
                hasFirstImageLoaded={hasFirstImageLoaded}
                imageLink={imageLink}
                index={index}
                name={name}
                onError={() => onError(index)}
                onLoad={onLoad}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  )
}
