import { Skeleton } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'

export const DashboardNavLoading = () => {
  const isTripsHideEnabled = useFlag('tripsHide')

  return (
    <div className='md:h-14.5 flex h-12 flex-row items-center'>
      <div className='px-2 py-3 md:px-4 md:py-3'>
        <div className='w-18 h-7.5 md:h-6 md:w-20'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <div className='px-2 py-3 md:px-4 md:py-3'>
        <div className='md:w-15.5 w-16.5 h-7.5 md:h-6'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      {!isTripsHideEnabled && (
        <div className='px-2 py-3 md:px-4 md:py-3'>
          <div className='h-7.5 w-15 md:w-17 md:h-6'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      )}
      <div className='px-2 py-3 md:px-4 md:py-3'>
        <div className='md:w-24.5 w-22 h-7.5 md:h-6'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <div className='px-2 py-3 md:px-4 md:py-3'>
        <div className='md:w-27 h-7.5 w-24 md:h-6'>
          <Skeleton variant='rounded' />
        </div>
      </div>
    </div>
  )
}
