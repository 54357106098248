import { Divider, Skeleton } from '@travelpass/design-system'

export const DashboardFeedCardHeaderGuideLoading = () => (
  <>
    <div className='flex flex-row gap-2'>
      <div className='h-12 w-12'>
        <Skeleton variant='circular' />
      </div>
      <div className='grow space-y-1'>
        <div className='h-5 w-20'>
          <Skeleton />
        </div>
        <div className='w-35 lg:h-4.5 h-4 lg:w-40'>
          <Skeleton />
        </div>
        <div className='lg:w-22.5 h-3 w-20'>
          <Skeleton />
        </div>
      </div>
    </div>
    <Divider className='m-b-4 m-t-3' />
  </>
)
