type featureFlagNames =
  | 'alwaysRefundable'
  | 'dashboardV3AccountSettings'
  | 'dashboardV3FeedNewEvent'
  | 'dashboardV3FeedNewNote'
  | 'dashboardV3FeedNewPhotos'
  | 'dashboardV3FeedRepublishGuideGrouped'
  | 'dashboardV3FeedSuggestedGuides'
  | 'dashboardV3FeedTravelpassGuides'
  | 'enableAddGuideOnGuideSearch'
  | 'enableApplySearchPrefFilters'
  | 'enableCompetition2024'
  | 'enableSMSCompetition2024'
  | 'enableExplore'
  | 'enableProfileFavoriteHotels'
  | 'enableQuickResponsePrototype'
  | 'enablePlanPage'
  | 'guideDraftOnboardingUserRoles'
  | 'guideDraftUiUpdates'
  | 'guideEventImageUploading'
  | 'guidePrivatePublic'
  | 'guideUiUpdates1'
  | 'isBookingSendSmsEnabled'
  | 'isDestinationCarouselEnabled'
  | 'isHotelRateProviderNameEnabled'
  | 'isInternationalGeocoderEnabled'
  | 'searchInTopNav'
  | 'searchPreferencesQuizEnabled'
  | 'tripExploreV2'
  | 'tripsHide'
  | 'newSignUpFlow'
  | 'appCtaEnabled'

const featureFlags: Record<featureFlagNames, boolean> = {
  alwaysRefundable: false,
  dashboardV3AccountSettings: false,
  dashboardV3FeedNewEvent: false,
  dashboardV3FeedNewNote: false,
  dashboardV3FeedNewPhotos: false,
  dashboardV3FeedRepublishGuideGrouped: false,
  dashboardV3FeedSuggestedGuides: false,
  dashboardV3FeedTravelpassGuides: false,
  enableAddGuideOnGuideSearch: false,
  enableApplySearchPrefFilters: false,
  enableCompetition2024: false,
  enableSMSCompetition2024: false,
  enableExplore: false,
  enableProfileFavoriteHotels: false,
  enableQuickResponsePrototype: false,
  enablePlanPage: false,
  guideDraftOnboardingUserRoles: false,
  guideDraftUiUpdates: false,
  guideEventImageUploading: false,
  guidePrivatePublic: false,
  guideUiUpdates1: false,
  isBookingSendSmsEnabled: false,
  isDestinationCarouselEnabled: false,
  isHotelRateProviderNameEnabled: false,
  isInternationalGeocoderEnabled: false,
  searchInTopNav: false,
  searchPreferencesQuizEnabled: false,
  tripExploreV2: false,
  tripsHide: false,
  newSignUpFlow: false,
  appCtaEnabled: false,
}

export type { featureFlagNames }
export { featureFlags }
