import { useState, useEffect } from 'react'
import type { featureFlagNames } from 'src/config/feature-flags/featureFlags'
import { useLocalStorageFlags } from './useLocalStorageFlags'

export const useFlag = (flagKey: featureFlagNames | string): boolean => {
  const [isFlagTrulyEnabled, setIsFlagTrulyEnabled] = useState(false)
  const [localFlags] = useLocalStorageFlags()

  useEffect(() => {
    // Do we have this key in the local feature flags? (even if 'turned off', it's still present!)
    if (flagKey in localFlags) {
      const { [flagKey]: localStorageFlag } = localFlags

      // allows the local override to always win out, even if the env has the toggle on
      setIsFlagTrulyEnabled(String(localStorageFlag).toLowerCase() == 'true')
    }
  }, [flagKey, localFlags])

  return isFlagTrulyEnabled
}
