import { PageLayoutContainer } from 'src/common/components'

const bgImg =
  'https://static.travelpass.com/assets/homev2-page/travelers-bg-desktop.webp'

export const AwayGameSignUp = () => {
  return (
    <section
      className='p-t-80 p-b-12 relative bg-cover bg-center bg-no-repeat'
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className='absolute top-0 h-full w-full bg-gradient-to-b from-white'>
        <PageLayoutContainer>
          <div className='m-x-5 md:m-x-0 flex w-full flex-col items-center gap-6 md:flex-row'></div>
        </PageLayoutContainer>
      </div>
    </section>
  )
}
