import { useEffect, useState } from 'react'
import { Button, UnderlineTab, useScreenQuery } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { UserProfile } from 'src/__generated__/graphql'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { useFlag } from 'src/common/hooks'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'
import { getGuideDetailsUrl } from 'src/utils'
import { BragMetrics } from './BragMetrics'
import { CompetitionVoteCta } from './CompetitionVoteCta'
import { ProfileStatistics } from './ProfileStatistics'
import { VideoPreview } from './guides/VideoPreview'
import { RecommendedHotels } from './hotels/RecommendedHotels'
import { ProfileGuideCard } from './new-guides/ProfileGuideCard'
import { ProfileGuidesZeroState } from './new-guides/ProfileGuidesZeroState'
import { useCheckEditingMode } from '../components/hooks/useCheckEditingMode'
import { BioSection } from '../information-section/BioSection'
import { ProfileSearchParamTab, ProfileTab } from '../profileConstants'
import type { FollowersModalType } from '../types'

interface ProfileContentSectionProps {
  profile: UserProfile
  toggleModal: (viewType: FollowersModalType) => void
}

const headerContainerStyles = {
  tabsAlone: 'flex sm:justify-center md:justify-start mb-4',
  tabsWithButton: 'flex justify-between mb-4',
}

const getActiveTabIndex = (tab: string) => {
  if (tab === ProfileTab.topHotels) return 1
  return 0
}

export const ProfileContentSection = ({
  profile,
  toggleModal,
}: ProfileContentSectionProps) => {
  const enableProfileFavoriteHotels = useFlag('enableProfileFavoriteHotels')
  const { isMobileScreen, isDesktopScreen } = useScreenQuery()
  const { handleOpenEditMode } = useCheckEditingMode()

  // Tab State
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get(ProfileSearchParamTab) ?? 'guides'

  useEffect(() => {
    if ((!isMobileScreen && tab === ProfileTab.guides) || !tab)
      setSearchParams(sp => {
        sp.set('tab', ProfileTab.guides)
        return sp
      })
  }, [searchParams, tab])

  let tabs = [
    {
      label: 'Guides',
      onClick: () => setSearchParams({ tab: ProfileTab.guides }),
    },
  ]

  const [isGuideDraftCreateModalOpen, setIsGuideDraftCreateModalOpen] =
    useState(false)

  if (
    enableProfileFavoriteHotels &&
    (profile?.isUserOwner || profile?.hotelRecommendations?.length > 0)
  ) {
    tabs.push({
      label: 'Top Hotels',
      onClick: () => setSearchParams({ tab: ProfileTab.topHotels }),
    })
  }

  const buttonText = isMobileScreen ? 'Hotel' : 'Add Hotel Recommendation'

  const enableCompetition2024 = useFlag('enableCompetition2024')

  const showVoteForCta = enableCompetition2024

  return (
    <div className='grow-3 flex w-full flex-col gap-2 self-start md:pl-24'>
      <div className='hidden lg:grid'>
        <BragMetrics
          metrics={[
            {
              label: profile?.bragContinents === 1 ? 'Continent' : 'Continents',
              value: profile?.bragContinents || 0,
            },
            {
              label: profile?.bragCountries === 1 ? 'Country' : 'Countries',
              value: profile?.bragCountries || 0,
            },
            {
              label: profile?.bragStates === 1 ? 'State' : 'States',
              value: profile?.bragStates || 0,
            },
          ]}
        />
        <ProfileStatistics profile={profile} toggleModal={toggleModal} />
        {showVoteForCta && <CompetitionVoteCta />}
        <div className='flex gap-10 py-6'>
          <VideoPreview
            embedVideoLink={profile?.introVideoEmbed}
            fullWidth={!profile?.bio || profile?.bio?.length === 0}
          />
          {isDesktopScreen && <BioSection bio={profile?.bio} />}
        </div>
      </div>
      <div className='flex flex-col'>
        <div
          className={
            profile?.isUserOwner
              ? headerContainerStyles.tabsWithButton
              : headerContainerStyles.tabsAlone
          }
        >
          <UnderlineTab
            activeIndex={getActiveTabIndex(tab)}
            tabs={tabs}
            willScroll={false}
          />
          {profile?.isUserOwner && tab === ProfileTab.topHotels && (
            <div className='flex flex-col justify-center'>
              <Button
                size='small'
                startIcon='add'
                onClick={() => handleOpenEditMode()}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
        {tab === ProfileTab.guides ? (
          <div className='flex flex-col gap-y-4'>
            {profile?.guides?.length > 0 ? (
              profile?.guides?.map(guide => (
                <ProfileGuideCard
                  key={`guide-card-${guide.displayId}`}
                  guide={guide}
                  href={getGuideDetailsUrl({
                    id: guide?.id,
                    name: guide?.name,
                  })}
                />
              ))
            ) : (
              <ProfileGuidesZeroState
                displayName={profile?.displayName}
                isUserOwner={profile?.isUserOwner}
                onClick={() => setIsGuideDraftCreateModalOpen(true)}
              />
            )}
          </div>
        ) : (
          <RecommendedHotels profile={profile} />
        )}
      </div>
      {isGuideDraftCreateModalOpen && (
        <GuideDraftCreateModal
          onDismiss={() => setIsGuideDraftCreateModalOpen(false)}
        />
      )}
      <AddToCollectionsModalListener />
    </div>
  )
}
