import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftPublishedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQueryOld'

const updatePublishedEventMutation = gql(`
  mutation UpdatePublishedEventMutationInGuideDraftOld($first: Int = 100, $publishedEventInput: UpdatePublishedEventInput!) {
    updatePublishedEvent(updatePublishedEventInput: $publishedEventInput) {
      guideDraft {
        id
        guide {
          id
          status
        }
        hasUnpublishedChanges
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFieldsOld
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFieldsOld
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

export const useUpdateGuideDraftEventMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updatePublishedEventMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updatePublishedEvent?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
