import { Icon } from '@travelpass/design-system'
import { GuideImage } from 'src/pages/guides/details/common'
import { getGuideAddress, getGuideDetailsUrl } from 'src/utils'
import { DashboardFeedCard } from './DashboardFeedCard'
import { DashboardFeedCardHeaderGuide } from './DashboardFeedCardHeaderGuide'
import { getDashboardFeedGuideImages } from '../../../dashboardUtils'
import type { DashboardFeedItem } from '../../../types'

interface DashboardFeedCardRepublishedGuideGroupedProps {
  dashboardFeedItem: DashboardFeedItem
}

export const DashboardFeedCardRepublishedGuideGrouped = ({
  dashboardFeedItem,
}: DashboardFeedCardRepublishedGuideGroupedProps) => {
  const { addresses, description, eventCounts, id, latestUpdates, name } =
    dashboardFeedItem?.guide ?? {}
  const { numberEvents } = eventCounts ?? {}
  const address = getGuideAddress({
    city: addresses?.[0]?.city,
    country: addresses?.[0]?.country,
    state: addresses?.[0]?.state,
  })
  /** @todo when BE is ready, get images from guide.latestUpdate.images */
  const images = getDashboardFeedGuideImages({
    images: dashboardFeedItem?.guide?.images,
    imageUrl: dashboardFeedItem?.guide?.imageUrl,
  })
  const url = getGuideDetailsUrl({
    id,
    name,
  })
  const eventCount = latestUpdates?.newEvents?.eventsCount ?? 0
  const imageCount = latestUpdates?.newImages?.imagesCount ?? 0
  /** @todo temporary solution until BE adds an image count. */
  const noteCount = 0

  return (
    <DashboardFeedCard href={url}>
      <article>
        <DashboardFeedCardHeaderGuide dashboardFeedItem={dashboardFeedItem}>
          <p className='type-body-2 flex flex-row items-center gap-1'>
            <Icon name='checkCircleOutline' size='small' />
            <span className='min-w-fit'>Updated this Guide</span>
          </p>
        </DashboardFeedCardHeaderGuide>
        <div className='space-y-3'>
          <div className='h-27 lg:h-50 pointer-events-none flex flex-row items-center gap-1'>
            {images.map(({ url }, index) => (
              <GuideImage key={index} src={url} />
            ))}
          </div>
          <div className='space-y-4'>
            <div className='space-y-2'>
              <h2 className='type-body-1-medium-mobile type-subtitle-1-desktop line-clamp-2'>
                {name}
              </h2>
              <div className='flex flex-row items-center gap-3'>
                {address && (
                  <div className='flex flex-row items-center gap-2'>
                    <Icon name='placeOutline' size='small' />
                    <p className='type-body-2 c-black'>{address}</p>
                  </div>
                )}
                {address && !!numberEvents && (
                  <div className='c-grey-700'>|</div>
                )}
                {!!numberEvents && (
                  <p className='type-body-2 c-black'>
                    {numberEvents} {numberEvents > 1 ? 'events' : 'event'}
                  </p>
                )}
              </div>
              {description && (
                <p className='type-body-2 c-black md:hidden lg:line-clamp-3'>
                  {description}
                </p>
              )}
            </div>
            <div className='[&>p>span>i]:c-canyon flex flex-row flex-wrap items-center gap-6'>
              {!!noteCount && (
                <p className='m-0 flex flex-row items-center gap-1'>
                  <span className='hidden lg:flex'>
                    <Icon name='stickyNote2' />
                  </span>
                  <span className='flex lg:hidden'>
                    <Icon name='stickyNote2' size='small' />
                  </span>
                  <span className='type-body-1-mobile lg:type-subtitle-2-desktop c-forest-dark'>
                    {noteCount} <span className='hidden md:inline'>new</span>{' '}
                    {noteCount > 1 ? 'notes' : 'note'}
                  </span>
                </p>
              )}
              {!!imageCount && (
                <p className='m-0 flex flex-row items-center gap-1'>
                  <span className='hidden lg:flex'>
                    <Icon name='camera' />
                  </span>
                  <span className='flex lg:hidden'>
                    <Icon name='camera' size='small' />
                  </span>
                  <span className='type-body-1-mobile lg:type-subtitle-2-desktop c-forest-dark'>
                    {imageCount} <span className='hidden md:inline'>new</span>{' '}
                    {imageCount > 1 ? 'photos' : 'photo'}
                  </span>
                </p>
              )}
              {!!eventCount && (
                <p className='m-0 flex flex-row items-center gap-1'>
                  <span className='hidden lg:flex'>
                    <Icon name='placeOutline' />
                  </span>
                  <span className='flex lg:hidden'>
                    <Icon name='placeOutline' size='small' />
                  </span>
                  <span className='type-body-1-mobile lg:type-subtitle-2-desktop c-forest-dark'>
                    {eventCount} <span className='hidden md:inline'>new</span>{' '}
                    {eventCount > 1 ? 'events' : 'event'}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      </article>
    </DashboardFeedCard>
  )
}
