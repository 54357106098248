import type { ButtonVariant, IconButtonProps } from '@travelpass/design-system'
import { Button, IconButton, useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { useIncrementGuideEventLikeCountMutation } from '../../useIncrementGuideEventLikeCountMutation'

interface GuideEventMetricLikeProps {
  color?: IconButtonProps['color']
  guideEventData: Pick<
    PublishedEvent,
    'id' | 'likedByCurrentUser' | 'publishedEventLikedByUsers'
  >
  hideLabel?: boolean
  isDisabled?: boolean
  isIconButton?: boolean
  size?: IconButtonProps['size']
  variant?: ButtonVariant
}

export const GuideEventMetricLike = ({
  color = 'forestLight',
  guideEventData,
  hideLabel = false,
  isDisabled = false,
  isIconButton = false,
  size = 'small',
  variant = 'text',
}: GuideEventMetricLikeProps) => {
  const { isAnonymous } = useFirebaseUser()
  const [incrementGuideEventLikeCount] =
    useIncrementGuideEventLikeCountMutation()
  const { addErrorSnack } = useSnackbar()
  const { id, likedByCurrentUser, publishedEventLikedByUsers } =
    guideEventData ?? {}
  const { totalCount } = publishedEventLikedByUsers ?? {}

  const onLikeGuideEvent = async () => {
    if (isAnonymous) {
      dispatch('openSignin', {
        isCreateAccount: false,
      })
    } else {
      try {
        incrementGuideEventLikeCount({
          variables: {
            publishedEventId: id,
          },
        })
      } catch (errror) {
        addErrorSnack({ timeout: 1000, title: 'Already liked!' })
      }
    }
  }

  return (
    <>
      {isIconButton ? (
        <IconButton
          aria-label='Like this Guide event'
          aria-pressed={likedByCurrentUser}
          color={color}
          icon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
          isDisabled={isDisabled}
          outline='round'
          size={size}
          onClick={debounce(onLikeGuideEvent, 100)}
        />
      ) : (
        <Button
          aria-label='Like this Guide event'
          aria-pressed={likedByCurrentUser}
          isDisabled={isDisabled}
          size={size}
          startIcon={likedByCurrentUser ? 'favorite' : 'favoriteBlank'}
          variant={variant}
          onClick={debounce(onLikeGuideEvent, 100)}
        >
          {!hideLabel && totalCount?.toString()}
        </Button>
      )}
    </>
  )
}
