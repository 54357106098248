import { Divider, Skeleton } from '@travelpass/design-system'
import { GuideEventCardDescriptionLoading } from './GuideEventCardDescriptionLoading'

export const GuideEventCardLoading = () => {
  return (
    <div>
      <div className='b-2px b-grey-300 b-solid rounded-3 flex w-full flex-col gap-2 md:flex-row'>
        <div className='min-h-35 md:min-w-45.5 md:w-45.5 pointer-events-none relative w-full md:min-h-0'>
          <div className='rounded-3 absolute inset-0 h-full w-full object-cover'>
            <Skeleton />
          </div>
        </div>
        <div className='p-x-4 p-y-3 pointer-events-none relative grow space-y-3 md:max-w-[calc(100%-198px)]'>
          <div className='space-y-1'>
            <div className='flex flex-row items-center justify-between'>
              <div className='w-30 h-5 lg:w-40'>
                <Skeleton />
              </div>
              <div className='h-4 w-4'>
                <Skeleton variant='rounded' />
              </div>
            </div>
            <div className='h-3 w-40'>
              <Skeleton />
            </div>
          </div>
          <GuideEventCardDescriptionLoading />
          <div className='md:hidden'>
            <Divider />
          </div>
          <div className='z-4 pointer-events-auto flex gap-2 self-start md:gap-3'>
            <div className='w-25 h-6.5'>
              <Skeleton variant='rounded' />
            </div>
            <div className='w-23 h-6.5'>
              <Skeleton variant='rounded' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
