import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { stringToBoolean } from 'src/utils'
import { GuideCreateGuideAction } from './GuideCreateGuideAction'
import { GuideHeaderLoading } from './GuideHeader'
import { GuideHeroLoading } from './GuideHero'
import { GuideMapLoading, GuideMapWrapper } from './GuideMap'
import { GuideSectionsLoading, GuideSectionsWrapper } from './GuideSection'
import { GuideSearchParam } from '../guideConstants'
import { useGuideSessionStorageIds } from '../useGuideSessionStorageIds'

export const GuideLoading = () => {
  const { selectedId } = useGuideSessionStorageIds()
  const [searchParams] = useSearchParams()
  const mapExpanded = stringToBoolean(
    searchParams.get(GuideSearchParam.mapExpanded)
  )

  return (
    <>
      <div
        className={classNames({
          'invisible absolute lg:visible lg:static': mapExpanded,
        })}
      >
        <GuideHeroLoading />
      </div>
      <div className='lg:flex lg:flex-row'>
        <GuideSectionsWrapper mapExpanded={mapExpanded} selectedId={selectedId}>
          <div className='bg-white'>
            <PageLayoutContainer size='none'>
              <GuideHeaderLoading />
            </PageLayoutContainer>
          </div>
          <PageLayoutContainer size='none'>
            <GuideSectionsLoading />
            <div className='p-b-5 lg:p-bv-4' />
          </PageLayoutContainer>
        </GuideSectionsWrapper>
        <GuideMapWrapper
          className='lg:h-100svh lg:top-0'
          mapExpanded={mapExpanded}
          selectedId={selectedId}
        >
          <GuideMapLoading />
        </GuideMapWrapper>
      </div>
    </>
  )
}
