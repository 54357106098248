import type { MutableRefObject } from 'react'
import { Button, Icon } from '@travelpass/design-system'
import type { HotelHeroHeaderAndImagesQuery } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'

interface HotelHeroHeaderProps {
  hotelData: HotelHeroHeaderAndImagesQuery['lodging']
  hotelRoomsRef: MutableRefObject<HTMLDivElement>
}

const constructStarRating = (rating: number) => {
  const stars = []
  for (let i = 1; i <= Math.ceil(rating); i++) {
    stars.push(
      <Icon
        key={`star-${i}`}
        name={i === Math.ceil(rating) && rating !== i ? 'starHalf' : 'star'}
      />
    )
  }
  return stars
}

export const HotelHeroHeader = ({
  hotelData,
  hotelRoomsRef,
}: HotelHeroHeaderProps) => {
  const { name, rating, address, city, state } = { ...hotelData }

  const scrollToRates = () => {
    hotelRoomsRef?.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <PageLayoutContainer>
      <div className='flex w-full flex-col gap-4 py-[40px] text-center'>
        <div>{constructStarRating(parseFloat(rating))}</div>
        <h1 className='type-h1'>{name}</h1>
        <div className='type-body-1'>
          {[address, city, state].filter(value => value).join(', ')}
        </div>
        <div className='flex flex-col items-center justify-center'>
          <Button
            label='Reserve Now'
            variant='outlined'
            onClick={scrollToRates}
          />
        </div>
      </div>
    </PageLayoutContainer>
  )
}
