import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { stringToBoolean } from 'src/utils'
import { GuideDraftDrawer } from './GuideDraftDrawer'
import { GuideDraftEventsLoading } from './GuideDraftEvents'
import { GuideDraftHeaderLoading } from './GuideDraftHeader'
import { GuideDraftTopNavLoading } from './GuideDraftTopNav'
import { GuideDraftTopNavLoadingOld } from './GuideDraftTopNavOld'
import { GuideGeocoderLoading } from '../../common'
import { GuideSearchParam, useGuideSessionStorageIds } from '../../details'
import { GuideMapLoading } from '../../details/common'

export const GuideDraftLoading = () => {
  const isGuidePrivatePublicEnabled = useFlag('guidePrivatePublic')
  const { selectedId } = useGuideSessionStorageIds()
  const [searchParams] = useSearchParams()
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView) ?? 'false'
  )

  return (
    <>
      {isGuidePrivatePublicEnabled ? (
        <GuideDraftTopNavLoading />
      ) : (
        <GuideDraftTopNavLoadingOld />
      )}
      <div className='lg:flex lg:flex-row'>
        <div
          className={classNames(
            'p-b-5 lg:w-100vw lg:b-r-grey-400 lg:b-r-solid lg:b-r-1 lg:p-b-30 lg:w-202.5 lg:z-3 bg-white',
            {
              'hidden lg:block': mapFullView,
              'p-t-0': false,
              'lg:p-t-3 p-t-5': true,
            }
          )}
        >
          <PageLayoutContainer size='none'>
            <div className='space-y-4 lg:space-y-8'>
              <GuideDraftHeaderLoading />
              <div className='space-y-4'>
                <div className='w-30 h-5'>
                  <Skeleton />
                </div>
                <GuideGeocoderLoading />
              </div>
              <GuideDraftEventsLoading />
            </div>
          </PageLayoutContainer>
        </div>
        <GuideDraftDrawer points={[]} />
        <div
          className={classNames(
            'bg-grey-100 lg:top-77px lg:transition-margin-175 lg:z-4 relative h-[calc(100svh-89px)] grow lg:sticky lg:h-[calc(100svh-77px)] lg:ease-[cubic-bezier(0,0,0.2,1)]',
            {
              'hidden lg:block': !mapFullView,
              'lg:-m-l-119': !selectedId,
            }
          )}
        >
          <GuideMapLoading />
        </div>
      </div>
    </>
  )
}
