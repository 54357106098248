import { useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import { Button, Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Link, useSearchParams } from 'react-router-dom'
import { TravelpassLogo } from 'src/common/components'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { HowToEnterModal } from './modals/HowToEnterModal'
import { useShareProfileModal } from './modals/useShareProfileModal'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'
import { DashboardPlaneSVG } from '../DashboardHeader/DashboardPlaneSVG'
interface ParticipantReadHeaderProps {
  firstName: string
  currentUser: UseGetDashboardQuery['dashboardData']['currentUser']
}

export const ParticipantReadyHeader = ({
  firstName,
  currentUser,
}: ParticipantReadHeaderProps) => {
  const { guideLikesCount, guideViewsCount, userProfile } = { ...currentUser }
  const [searchParams] = useSearchParams()
  const collapsed = searchParams.get('collapsed')
  const [shouldExpand, setShouldExpand] = useState(!(collapsed == 'false'))
  const [showModal, setShowModal] = useState(false)
  const onHowToEnter = () => {
    setShowModal(true)
    pushDataToDataLayer('view_comp_video', {
      timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
    })
  }

  const { Modal: ShareProfileModal, open: openShareProfileModal } =
    useShareProfileModal(userProfile?.accountHandle)

  return (
    <>
      {/**DESKTOP */}
      <div className='min-w-35% flex gap-x-10 space-y-5'>
        <div className='w-42 max-w-42 lg:left--26 absolute hidden lg:top--8 lg:block'>
          <DashboardPlaneSVG />
        </div>
        <div>
          <h1 className='type-h1 c-white block'>
            Hello, <br className='hidden lg:block' /> {firstName}
          </h1>
          <div className='mt-5 hidden space-y-3 lg:block'>
            <ButtonsContainer onShareProfile={openShareProfileModal} />
          </div>
        </div>

        <div className='hidden lg:block'>
          <div className='lg:pt0 w-full space-y-5'>
            <div className='w-30px'>
              <TravelpassLogo variant='symbol-valley' />
            </div>
            <OfficiallyEntered />
            <ParticipantStats
              guideLikesCount={guideLikesCount}
              guideViewsCount={guideViewsCount}
              leaderboardRanking={
                userProfile?.competitionInfo?.leaderboardRanking
              }
              voteCount={userProfile?.competitionInfo?.voteCount}
            />
            <div className='flex justify-between'>
              <SeeLeaderboard />
              <button
                className='c-valleyLight type-button b-none flex cursor-pointer flex-row items-center bg-transparent'
                type='button'
                onClick={onHowToEnter}
              >
                Contest Details <Icon name='arrowForward' />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/**MOBILE */}
      <div className='lg:hidden'>
        <div className='flex flex-row items-center justify-between'>
          <div>
            <OfficiallyEntered />
          </div>
        </div>
        <div className='pt5 flex flex-row justify-around gap-x-5'>
          <ButtonsContainer onShareProfile={openShareProfileModal} />
        </div>
        <Accordion
          className='mb-5 mt-10'
          index={Number(shouldExpand)}
          onChange={() =>
            setShouldExpand(previousIsExpanded => !previousIsExpanded)
          }
        >
          <AccordionItem className='space-y-3 md:space-y-4'>
            <AccordionButton className='b-none w-full cursor-pointer space-y-3 bg-transparent p-0 text-left'>
              <p className='c-white type-button flex flex-row items-center justify-center gap-x-1'>
                {shouldExpand ? 'View My Stats' : 'Hide My Stats'}
                <Icon
                  name={shouldExpand ? 'arrowDownIos' : 'arrowUpIos'}
                  size='small'
                />
              </p>
            </AccordionButton>
            <AccordionPanel>
              <div className='relative space-y-5'>
                <div className='my-8 flex w-full flex-row justify-center'>
                  <SeeLeaderboard />
                </div>
                <ParticipantStats
                  guideLikesCount={guideLikesCount}
                  guideViewsCount={guideViewsCount}
                  leaderboardRanking={
                    userProfile?.competitionInfo?.leaderboardRanking
                  }
                  voteCount={userProfile?.competitionInfo?.voteCount}
                />
                <button
                  className='c-valleyLight type-button b-none mx-auto flex cursor-pointer flex-row items-center bg-transparent'
                  type='button'
                  onClick={onHowToEnter}
                >
                  Contest Details <Icon name='arrowForward' />
                </button>
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <ShareProfileModal />
      {showModal && <HowToEnterModal onClose={() => setShowModal(false)} />}
    </>
  )
}

const statCss =
  'type-title-3 c-white lg:py-0 py-3 lg:border-r-solid border-width-1px bg-white/10 lg:bg-transparent lg:rounded-0px rounded-8px lg:px-5 text-center lg:block flex flex-col'

const ParticipantStats = ({
  voteCount,
  guideLikesCount,
  guideViewsCount,
  leaderboardRanking,
}: {
  voteCount: number
  leaderboardRanking: number
  guideViewsCount: number
  guideLikesCount: number
}) => (
  <div className='grid grid-cols-2 gap-5 lg:grid-cols-4'>
    <p className={statCss}>
      {leaderboardRanking || '0'} <br />{' '}
      <span className='type-h5 c-white'>Rank</span>
    </p>
    <p className={statCss}>
      {voteCount} <br /> <span className='type-h5 c-white'>Votes</span>
    </p>
    <p className={statCss}>
      {guideViewsCount} <br />{' '}
      <span className='type-h5 c-white'>Guide Views</span>
    </p>
    <p className={classNames(statCss, 'border-width-0')}>
      {guideLikesCount} <br />{' '}
      <span className='type-h5 c-white'>Guide Likes</span>
    </p>
  </div>
)

const OfficiallyEntered = () => (
  <>
    <p className='type-h5 c-white my-3 lg:my-0'>
      You&apos;ve officially entered the contest!
    </p>
    <p className='type-body-1 c-white'>
      Boost your chances of winning by sharing your profile for more votes.
    </p>
  </>
)

const ButtonsContainer = ({ onShareProfile }) => (
  <>
    <Button
      label='Share Profile'
      size='small'
      startIcon='linkDiagonal'
      variant='transparent'
      onClick={onShareProfile}
    />
  </>
)

const SeeLeaderboard = () => (
  <Link
    className='type-button c-white text-center underline'
    to='/competition/leaderboard#competition-nav'
    onClick={() =>
      pushDataToDataLayer('dashboard_leaderboard_cta_clicked', {
        timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
      })
    }
  >
    Leaderboard
  </Link>
)
