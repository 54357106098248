import { useMutation } from '@apollo/client'
import {
  Button,
  Link,
  Modal,
  ModalActions,
  useSnackbar,
} from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import {
  useLucencyNumber,
  onPhoneNumberClick,
} from 'src/config/analytics/useLucencyNumber'
import { getArrivalDate, getDepartureDate } from 'src/utils'
import { BookingDetailsLoading } from './BookingDetailsLoading'
import { useBookingDetailsDatesQuery } from '../hooks'

const cancelBookingMutation = gql(`
  mutation CancelBookingInBookingDetailsCancelModal(
    $input: CancelBookingInput!
  ) {
    cancelBooking(input: $input) {
      cancellation {
        booking {
          id
          status
        }
        id
      }
    }
  }
`)

interface BookingDetailsCancelModalProps {
  onClose(): void
}

export const BookingDetailsCancelModal = ({
  onClose,
}: BookingDetailsCancelModalProps) => {
  const [cancelBooking, { error: hasError, loading: isLoading }] = useMutation(
    cancelBookingMutation,
    {
      onError: error => {
        console.error(error)
      },
      onCompleted: () => {
        addSuccessSnack({ title: 'Your booking is successfully cancelled' })
        onClose()
      },
    }
  )

  const { bookingId } = useParams()
  const { addSuccessSnack } = useSnackbar()
  const { bookingDetailsDatesData } = useBookingDetailsDatesQuery({
    bookingId,
  })
  const { arrival, departure } = bookingDetailsDatesData ?? {}
  const formatToken = 'ddd, MMMM DD, YYYY'
  const constructedArrival = getArrivalDate({
    date: arrival,
    format: formatToken,
  })
  const constructedDeparture = getDepartureDate({
    date: departure,
    format: formatToken,
  })
  const { lucencyNumber } = useLucencyNumber()

  const showConfirmButton = !isLoading && !hasError

  const onConfirm = async () => {
    if (!isLoading) {
      await cancelBooking({
        variables: {
          input: {
            id: bookingId,
          },
        },
      })
    }
  }

  return (
    <Modal size='medium' title='Cancel Booking' onDismiss={onClose}>
      <div className='type-subtitle-2 c-grey-800'>
        {isLoading ? <BookingDetailsLoading /> : null}
        {hasError ? (
          <>
            Due to an error, we were unable to cancel your booking at this time.
            Please contact Travelpass Support at{' '}
            <Link
              label={lucencyNumber}
              onClick={() => {
                window.location.href = `tel:${lucencyNumber}`
                onPhoneNumberClick(lucencyNumber)
              }}
            />{' '}
            for help completing your cancellation.
          </>
        ) : null}
        {!isLoading && !hasError ? (
          <>
            Are you sure you want to cancel your booking from{' '}
            {constructedArrival} to {constructedDeparture}?
          </>
        ) : null}
      </div>
      <ModalActions>
        {showConfirmButton && (
          <Button label='Cancel Booking' onClick={onConfirm} />
        )}
        <Button label='Dismiss' variant='outlined' onClick={onClose} />
      </ModalActions>
    </Modal>
  )
}
