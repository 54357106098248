import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { stringToBoolean } from 'src/utils'
import { GuideCreateGuideAction } from './GuideCreateGuideAction'
import { GuideHeaderLoading } from './GuideHeader'
import { GuideMapLoading } from './GuideMap'
import { GuideSectionsLoading } from './GuideSection'
import { GuideSearchParam } from '../guideConstants'

export const GuideLoading = () => {
  const [searchParams] = useSearchParams()
  const mapExpanded = stringToBoolean(
    searchParams.get(GuideSearchParam.mapExpanded)
  )

  return (
    <div>
      <div
        className={classNames({
          'invisible absolute lg:visible lg:static': mapExpanded,
        })}
      >
        <GuideHeaderLoading />
      </div>
      <div className='lg:flex'>
        <div
          className={classNames(
            'p-x-4 p-y-6 lg:max-w-195 lg:p-x-9 lg:p-y-8 lg:w-70vw lg:z-3 max-w-full bg-white',
            {
              'invisible absolute lg:visible lg:static': mapExpanded,
            }
          )}
        >
          <GuideSectionsLoading />
        </div>
        <div
          className={classNames(
            'lg:h-100svh h-[calc(100svh-76px)] lg:sticky lg:top-0 lg:grow',
            {
              relative: mapExpanded,
              'lg:w-initial invisible absolute w-full lg:visible': !mapExpanded,
            }
          )}
        >
          <GuideMapLoading />
        </div>
      </div>
      <div
        className={classNames({
          'invisible absolute lg:visible lg:static': mapExpanded,
        })}
      >
        <GuideCreateGuideAction />
      </div>
    </div>
  )
}
