import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  StandardHotelsQueryInBookingDetailsLocationQuery,
  StandardHotelsQueryInBookingDetailsLocationQueryVariables,
} from 'src/__generated__/graphql'
import { LodgingIdType } from 'src/__generated__/graphql'

const { Standard } = LodgingIdType

const standardHotelsQuery = gql(`
  query StandardHotelsQueryInBookingDetailsLocation(
    $hotelId: String!
    $hotelIdType: LodgingIdType!
  ) {
    lodging(id: $hotelId, idType: $hotelIdType) {
      id
      address
      city
      googlePlaceIds
      latitude
      longitude
      name
      postalCode
      state
      stateCode
    }
  }
`)

export const useBookingDetailsLocationQuery = ({
  hotelId,
}: {
  hotelId: StandardHotelsQueryInBookingDetailsLocationQueryVariables['hotelId']
}) => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(standardHotelsQuery, {
    skip: !hotelId,
    variables: {
      hotelId,
      hotelIdType: Standard,
    },
  })

  const { lodging: standardHotel } = data ?? {}
  const bookingDetailsLocationData =
    standardHotel as StandardHotelsQueryInBookingDetailsLocationQuery['lodging']

  return {
    hasError,
    bookingDetailsLocationData,
    isLoading,
  }
}
