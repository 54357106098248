import { GuideStatus } from 'src/__generated__/graphql'
import type {
  GuideDraftPublishFormValues,
  GuideDraftPublishInvalidFormValues,
} from './types'

const initialGuideDraftPublishFormValues: GuideDraftPublishFormValues = {
  address: {},
  description: '',
  images: [],
  name: '',
  status: GuideStatus.Published,
  tagIds: [],
}

const initialGuideDraftPublishInvalidFormValues: GuideDraftPublishInvalidFormValues =
  {
    address: false,
    description: false,
    images: false,
    imagesSize: false,
    imagesType: false,
    imagesUploaded: false,
    name: false,
    tagIds: false,
  }

export {
  initialGuideDraftPublishFormValues,
  initialGuideDraftPublishInvalidFormValues,
}
