import { useLayoutEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Link, NavLink, matchPath, useLocation } from 'react-router-dom'
import { TravelpassLogo } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { useListen } from 'src/common/hooks/useListen'
import {
  experiencesResultsPath,
  guidesPath,
  hotelResultsPath,
  guideDraftPath,
  tripsPath,
  dashboardPath,
} from 'src/constants'
import { SignInModal } from 'src/pages/signin'
import { ProfileMenu } from './ProfileMenu'
import { Menu } from './components/Menu'
import { SearchBar } from './components/SearchBar'

const ROOT_ROUTE = '/'

const useMatchRoutes = (paths: string[]) => {
  const location = useLocation()
  const result = useMemo(() => {
    const matchedPaths = paths.map(route =>
      Boolean(matchPath(route, location.pathname))
    )
    return matchedPaths.some(Boolean)
  }, [paths, location.pathname])
  return result
}

const TopNavLink = props => (
  <NavLink
    {...props}
    className={classNames(
      'text-h6 underline-offset-5 hidden text-lg no-underline hover:underline lg:block',
      // focus styles
      'focus:outline-valley [&.active]:decoration-underline [&.active]:underline-valley',
      // clear theme-specific styles
      'group-[.clear]:c-white group-[&.clear]:[&.active]:underline-current'
    )}
  />
)

export const TopNav = ({ isAnonymous }) => {
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<{
    open: boolean
    isCreateAccount: boolean
    onClose?: (arg0) => void
  }>({
    open: false,
    isCreateAccount: true,
    onClose: () => {},
  })

  const isTripsHideEnabled = useFlag('tripsHide')
  const isSearchInTopNavEnabled = useFlag('searchInTopNav')
  const enableHidden = useMatchRoutes([
    tripsPath + '/:tripId/*',
    guideDraftPath + '/:guideId',
    guideDraftPath + '/:guideId/share',
    guideDraftPath + '/:guideId/:guideName',
    guideDraftPath + '/:guideId/:guideName/share',
  ])
  /** @todo find a way to make floating and sticky mutually exclusive */
  const enableFloating = useMatchRoutes([ROOT_ROUTE])
  const enableSticky = useMatchRoutes([
    experiencesResultsPath,
    hotelResultsPath,
  ])
  const enableClearTheme = useMatchRoutes([ROOT_ROUTE])

  const enableContainer = !useMatchRoutes([
    experiencesResultsPath,
    hotelResultsPath,
    guidesPath + '/:guideId',
    guidesPath + '/:guideId/:guideName',
  ])

  const enablePlanPage = useFlag('enablePlanPage')
  const enableExplore = useFlag('enableExplore')

  /**
   * in order to support as many layouts as possible, the current size of the topnav is surfaced up using css.
   *
   * @example
   * ```tsx
   * <div className='sticky top-[calc(var(--top-nav-height-offset-px))]'> ... </div>
   * ```
   */
  /** const useTopNavSizeObserver = () => { */
  useLayoutEffect(() => {
    const ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const size = entry.borderBoxSize?.[0]?.blockSize || 0
        document.documentElement.style.setProperty(
          '--top-nav-height-offset-px',
          `${size}px`
        )
      }
    })

    ro.observe(document.getElementById('top-nav'), {
      box: 'border-box',
    })
    return () => ro.disconnect()
  }, [])
  /* } */

  useListen('openSignin', e => {
    if (e?.detail) {
      const { isCreateAccount, onClose } = e?.detail ?? {}
      setIsSignInModalOpen({
        open: true,
        isCreateAccount,
        onClose,
      })
    }
  })

  const defaultNavLinks = [
    {
      label: 'Search',
      path: '/search',
    },
    {
      label: 'Guides',
      path: '/guides/all?sort=RECOMMENDED',
    },
    ...[
      !isTripsHideEnabled && {
        label: 'Trip Planning',
        path: '/trips',
      },
    ],
  ]
  const enablePlanPageNavLinks = [
    {
      label: 'Explore',
      path: enableExplore ? '/explore' : '/guides/all?sort=RECOMMENDED',
    },
    {
      label: 'Plan',
      path: '/plan',
    },
    {
      label: 'Book',
      path: '/book',
    },
    ...[
      !isTripsHideEnabled && {
        label: 'Trip Planning',
        path: '/trips',
      },
    ],
  ]

  return (
    <nav
      className={classNames(
        'border-b-1 border-b-solid z-4 group',
        enableHidden && 'hidden',
        enableFloating && 'absolute left-0 right-0 top-0',
        enableSticky && 'sticky top-0',
        enableClearTheme
          ? 'clear border-b-transparent bg-transparent'
          : 'border-b-grey-300 bg-white'
      )}
      id='top-nav'
    >
      <div
        className={classNames(
          'flex w-full items-center justify-between gap-4 px-4 py-4 md:px-8',
          enableContainer && 'max-w-340 mx-auto'
        )}
      >
        <Link
          className='decoration-none block w-36 cursor-pointer border-none'
          to={isAnonymous ? '/' : dashboardPath}
        >
          <TravelpassLogo variant={enableClearTheme ? 'beach' : 'primary'} />
        </Link>
        <div className='flex w-full items-center justify-end gap-16'>
          {isSearchInTopNavEnabled && <SearchBar />}
          {(enablePlanPage ? enablePlanPageNavLinks : defaultNavLinks)?.map(
            ({ label, path }) =>
              !!path && (
                <TopNavLink key={path} to={path}>
                  {label}
                </TopNavLink>
              )
          )}
          {!isSearchInTopNavEnabled && (
            <ProfileMenu isAnonymous={isAnonymous} />
          )}
          {isSearchInTopNavEnabled && <Menu />}
        </div>
      </div>
      {isSignInModalOpen.open && (
        <SignInModal
          initiallyShowCreateAccount={isSignInModalOpen.isCreateAccount}
          onClose={e => {
            isSignInModalOpen?.onClose?.(e)
            setIsSignInModalOpen({ ...isSignInModalOpen, open: false })
          }}
        />
      )}
    </nav>
  )
}
