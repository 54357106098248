import { useQuery } from '@apollo/client'
import type {
  GuideFilters,
  GuideSearchArgs,
  GuideSorts,
} from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getTracker } from 'src/utils'
import { GUIDE_SEARCH_QUERY } from './useGuideSearchQuery'

const RESULTS_LIMIT = 14

type UseAllGuideSearchQueryProps = {
  country?: string
  filterBy?: GuideFilters
  latitude?: number
  longitude?: number
  location?: string
  similarName?: string
  sortBy: GuideSorts
  state?: string
}

export const useAllGuideSearchQuery = ({
  country,
  latitude,
  longitude,
  location,
  similarName,
  sortBy,
  state,
  filterBy,
}: UseAllGuideSearchQueryProps) => {
  const tracker = getTracker(similarName || location || 'all_guides_search')
  const searchBy: GuideSearchArgs['searchBy'] = {}

  // we need to search by either similarName (fuzzy search), lat/long, or state/country
  if (similarName) {
    searchBy.similarName = similarName
  } else if (latitude && longitude) {
    searchBy.nearbyLocations = {
      radiusRange: {
        min: null,
        max: 80,
      },
      latLong: {
        latitude,
        longitude,
      },
    }
  } else if (state) {
    searchBy.country = country
    searchBy.state = state
  } else if (country) {
    searchBy.country = country
  }

  return useQuery(GUIDE_SEARCH_QUERY, {
    variables: {
      first: RESULTS_LIMIT,
      guideSearchArgs: {
        searchBy,
        sortBy,
        tracker,
        filterBy,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const firstFive = data.guideSearch?.edges.slice(0, 5)
      pushDataToDataLayer('guide_search_results', {
        firstResults: firstFive,
        location,
        latitude,
        longitude,
        sortBy,
      })
      pushDataToDataLayer('guides_results_sort_by', {
        value: sortBy,
      })
    },
  })
}
