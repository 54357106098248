import {
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
} from 'src/utils'
import { guideMock } from './guideMock'
import type { GuideOwner } from '../../types'

const { ownerProfile } = guideMock
const { accountHandle, displayName, profileImageUrl } = ownerProfile

export const guideOwnerMock: GuideOwner = {
  isUserOwner: ownerProfile.isUserOwner,
  image: getGuideOwnerImage(profileImageUrl),
  name: getGuideOwnerName({
    accountHandle,
    displayName,
  }),
  url: getGuideOwnerUrl(accountHandle),
}
