import { Skeleton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { stringToBoolean } from 'src/utils'
import { GuideDraftEventsLoading } from './GuideDraftEvents'
import { GuideDraftTopNavLoading } from './GuideDraftTopNav'
import { GuideGeocoderLoading } from '../../common'
import { GuideSearchParam, useGuideSessionStorageIds } from '../../details'
import {
  GuideMapLoading,
  GuideMapWrapper,
  GuideSectionsWrapper,
} from '../../details/common'
import { GuideHeaderLoading } from '../../details/common/GuideHeader'
import { GuideHeroLoading } from '../../details/common/GuideHero'

export const GuideDraftLoading = () => {
  const { selectedId } = useGuideSessionStorageIds()
  const [searchParams] = useSearchParams()
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView) ?? 'false'
  )

  return (
    <>
      <GuideDraftTopNavLoading />
      <GuideHeroLoading />
      <div className='lg:flex lg:flex-row'>
        <GuideSectionsWrapper mapExpanded={mapFullView} selectedId={selectedId}>
          <div className='bg-white'>
            <PageLayoutContainer size='none'>
              <GuideHeaderLoading />
            </PageLayoutContainer>
          </div>
          <PageLayoutContainer size='none'>
            <div className='p-t-6 p-b-5 lg:p-b-30 space-y-4 lg:space-y-8'>
              <div className='w-30 h-5'>
                <Skeleton />
              </div>
              <GuideGeocoderLoading />
              <GuideDraftEventsLoading />
            </div>
          </PageLayoutContainer>
        </GuideSectionsWrapper>
        <GuideMapWrapper
          className='lg:top-78px'
          mapExpanded={mapFullView}
          selectedId={selectedId}
        >
          <GuideMapLoading />
        </GuideMapWrapper>
      </div>
    </>
  )
}
