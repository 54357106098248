import type { ReactNode } from 'react'
import { Divider, Skeleton } from '@travelpass/design-system'

interface GuideSectionLoadingProps {
  children: ReactNode
}

export const GuideSectionLoading = ({ children }: GuideSectionLoadingProps) => (
  <div>
    <div className='space-y-3 md:space-y-4'>
      <div className='b-none w-full cursor-pointer space-y-3 bg-transparent p-0 text-left'>
        <div className='relative flex min-h-6 flex-col justify-center'>
          <div className='h-5 w-40 lg:h-10'>
            <Skeleton />
          </div>
          <div className='h-4.5 m-t-3 hidden md:block'>
            <Skeleton />
          </div>
        </div>
        <Divider />
      </div>
      <div>
        <div className='relative space-y-3 md:space-y-5'>{children}</div>
      </div>
    </div>
  </div>
)
