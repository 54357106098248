import {
  Button,
  IconButton,
  MenuItem,
  Popover,
  type ButtonVariant,
  type IconButtonProps,
} from '@travelpass/design-system'
import {
  CollectionItemIdType,
  type PublishedEvent,
} from 'src/__generated__/graphql'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { dispatchToggleAddToGuideModal } from 'src/common/components/Guides/dispatchToggleAddToGuideModal'

interface GuideEventMetricSaveProps {
  color?: IconButtonProps['color']
  guideEventData: Pick<PublishedEvent, 'id' | 'name'>
  isDisabled?: boolean
  isIconButton?: boolean
  size?: IconButtonProps['size']
  variant?: ButtonVariant
}

/** @todo extract business logic into a custom hook, leave the styling up to the component using the hook */
export const GuideEventMetricSave = ({
  color = 'forestLight',
  guideEventData,
  isDisabled = false,
  isIconButton = false,
  size = 'small',
  variant = 'text',
}: GuideEventMetricSaveProps) => {
  const { id, name } = guideEventData ?? {}

  return (
    <Popover
      placement='bottom'
      trigger={
        isIconButton ? (
          <IconButton
            aria-label='Save this Guide event'
            color={color}
            icon='add'
            isDisabled={isDisabled}
            outline='round'
            size={size}
          />
        ) : (
          <Button
            aria-label='Save this Guide event'
            isDisabled={isDisabled}
            size='small'
            startIcon='addCircleOutline'
            variant={variant}
          >
            Add To
          </Button>
        )
      }
    >
      <MenuItem
        label='Add to Guide'
        startIcon='listAlt'
        value='addToGuide'
        onClick={() =>
          dispatchToggleAddToGuideModal({
            isModalOpen: true,
            item: {
              id,
              name,
              type: CollectionItemIdType.Event,
            },
          })
        }
      />
      <MenuItem
        label='Add to Collection'
        startIcon='bookmarkBorder'
        value='addToCollection'
        onClick={() =>
          dispatchToggleAddToCollectionsModal({
            isModalOpen: true,
            item: {
              id,
              name,
              type: CollectionItemIdType.Event,
            },
          })
        }
      />
    </Popover>
  )
}
