import { RefundType } from 'src/__generated__/graphql'
import {
  getAdultGuests,
  getArrivalDate,
  getKidGuests,
  stringToNumber,
} from 'src/utils'
import { constructBookingUrl } from '../../../utils'
import type { HotelRoom as HotelRoomType } from '../hotelRoomsTypes'

interface GetBookingPageUrlParams {
  hotelId: string
  rateToken: string
  searchParams: URLSearchParams
}

interface PaymentTypeOptions {
  isDisabled: boolean
  rateToken: string
  grandtotal: string
}

type PaymentTypes = {
  payNow: PaymentTypeOptions
  payLater: PaymentTypeOptions
}

const { Full, None, Partial, PartialWithFullPeriod } = RefundType

const getBookingPageUrl = ({
  hotelId,
  rateToken,
  searchParams,
}: GetBookingPageUrlParams) => {
  const { arrival, adults, departure, eventId, kids, tripId } =
    Object.fromEntries([...searchParams])

  return constructBookingUrl({
    adults: getAdultGuests(adults),
    arrival: getArrivalDate({ date: arrival }),
    departure: getArrivalDate({ date: departure }),
    eventId,
    hotelId,
    kids: getKidGuests(kids),
    rateToken,
    tripId,
  })
}

const getCancelationPolicyTitle = (refundType: RefundType) => {
  if (refundType === Full) return 'Free Cancellation'

  if (refundType === Partial || refundType === PartialWithFullPeriod)
    return 'Partial Cancellation'

  return 'Non Refundable'
}

const getPaymentTypeOptionsAvailable = (rates: HotelRoomType['rates']) => {
  const paymentTypeOptions = {
    payNow: <PaymentTypeOptions>{
      isDisabled: true,
      rateToken: null,
      grandtotal: null,
    },
    payLater: <PaymentTypeOptions>{
      isDisabled: true,
      rateToken: null,
      grandtotal: null,
    },
  }

  rates.forEach(item => {
    const dueLater = item.dueLater

    if (dueLater === null || dueLater === '0.00') {
      paymentTypeOptions.payNow.isDisabled = false
      paymentTypeOptions.payNow.rateToken = item.rateToken
      paymentTypeOptions.payNow.grandtotal = item.allInTotal.amount
    }

    if (dueLater !== null && dueLater !== '0.00') {
      paymentTypeOptions.payLater.isDisabled = false
      paymentTypeOptions.payLater.rateToken = item.rateToken
      paymentTypeOptions.payLater.grandtotal = item.allInTotal.amount
    }
  })

  return paymentTypeOptions
}

const getLowestGrandTotalFromPaymentTypeOptions = (
  paymentTypeOptions: PaymentTypes
) => {
  const { payLater, payNow } = paymentTypeOptions ?? {}

  if (payNow?.isDisabled) return payLater?.grandtotal

  if (payLater?.isDisabled) return payNow?.grandtotal

  const payLaterGrandTotalAsNumber = stringToNumber(payLater?.grandtotal ?? '0')
  const payNowGrandTotalAsNumber = stringToNumber(payNow?.grandtotal ?? '0')

  if (payNowGrandTotalAsNumber >= payLaterGrandTotalAsNumber)
    return payLaterGrandTotalAsNumber

  return payNowGrandTotalAsNumber
}

export {
  getBookingPageUrl,
  getCancelationPolicyTitle,
  getLowestGrandTotalFromPaymentTypeOptions,
  getPaymentTypeOptionsAvailable,
}

export type { PaymentTypeOptions, PaymentTypes }
