import {
  Button,
  Icon,
  IconButton,
  Input,
  Toggle,
  useSnackbar,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideStatus } from 'src/__generated__/graphql'
import { copyToClipboard, getGuideDetailsUrl } from 'src/utils'
import type {
  GuideDraftPublishFormValues,
  UseGuideDraftPublishForm,
} from './types'
import type { GuideDraftData } from '../../types'
import { GuideDraftGroup, GuideDraftGroupHeader } from '../GuideDraftGroup'

interface GuideDraftRepublishSettingsProps {
  formValues: GuideDraftPublishFormValues
  guideDraftData: GuideDraftData
  onChange: UseGuideDraftPublishForm['onFormValuesChange']
  onStepChange: UseGuideDraftPublishForm['onStepChange']
}

/** @todo remove when guidePrivatePublic is released. */
export const GuideDraftRepublishSettings = ({
  formValues,
  guideDraftData,
  onChange,
}: GuideDraftRepublishSettingsProps) => {
  const { addSuccessSnack } = useSnackbar()
  const { guide } = guideDraftData ?? {}
  const { id, name } = guide ?? {}
  const isGuidePublished = formValues.status === GuideStatus.Published
  const guideUrl = `${window.location.origin}${getGuideDetailsUrl({ id, name })}`

  const onCopy = () => {
    copyToClipboard(guideUrl)
    addSuccessSnack({
      title: 'Copied to clipboard!',
    })
  }

  const onToggleChange = async () => {
    onChange({
      ...formValues,
      status: isGuidePublished
        ? GuideStatus.ViewableWithLink
        : GuideStatus.Published,
    })
  }

  return (
    <div className='space-y-6'>
      <GuideDraftGroup>
        <GuideDraftGroupHeader
          subtitle='When published, your guide will automatically be public within our travel community. Toggle the switch to make your guide hidden.'
          title='Guide Privacy Settings'
        />
        <div className='p-l-4 flex flex-row items-start gap-2'>
          <Icon name={isGuidePublished ? 'visibility' : 'visibilityOff'} />
          <div className='flex w-full flex-row items-center justify-between'>
            <div className='space-y-1'>
              <div className='flex flex-row items-center gap-2'>
                <h6 className='color-grey-900 font-700 type-body-2-mobile lg:type-body-1-desktop'>
                  Your Guide is {isGuidePublished ? 'Live' : 'Private'}
                </h6>
                <span
                  className={classNames('rounded-1/2 h-3 w-3', {
                    'bg-valley': isGuidePublished,
                    'bg-red': !isGuidePublished,
                  })}
                />
              </div>
              <p className='color-grey-800 type-body-2'>
                {isGuidePublished
                  ? 'Anyone on Travelpass can view'
                  : 'No one can view'}
              </p>
            </div>
            <Toggle
              checked={isGuidePublished}
              onChange={() => onToggleChange()}
            />
          </div>
        </div>
        {isGuidePublished && (
          <div className='flex flex-row items-center gap-1'>
            <div className='hidden w-full md:block'>
              <Input
                fullWidth={true}
                readOnly={true}
                slotBefore={
                  <IconButton
                    aria-label='Copy guide URL'
                    color='transparent'
                    icon='contentCopy'
                    onClick={onCopy}
                  />
                }
                value={guideUrl}
              />
            </div>
            <div className='block w-full space-y-2 md:hidden'>
              <Input
                fullWidth={true}
                readOnly={true}
                slotAfter={
                  <IconButton
                    aria-label='Copy guide URL'
                    color='valley'
                    icon='contentCopy'
                    onClick={onCopy}
                  />
                }
                value={guideUrl}
              />
              <div className='[&>button>div]:text-color-new-forest'>
                <Button
                  aria-label='View Guide'
                  color='inherit'
                  endIcon='arrowForward'
                  fullWidth={true}
                  size='large'
                  variant='text'
                  onClick={() => window.open(guideUrl, '_blank')}
                >
                  View Guide
                </Button>
              </div>
            </div>
            <div className='md:min-w-45 hidden md:block'>
              <Button
                aria-label='View Guide'
                fullWidth={true}
                size='large'
                onClick={() => window.open(guideUrl, '_blank')}
              >
                View Guide
              </Button>
            </div>
          </div>
        )}
      </GuideDraftGroup>
    </div>
  )
}
