import { useEffect, useMemo, useRef, useState } from 'react'
import type { LazyQueryExecFunction } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import {
  PublishedEventImageUrlSource,
  type UserImage,
  type GetPresignedUrlsLazyQuery,
  type GetPresignedUrlsLazyQueryVariables,
  PresignedUrlType,
} from 'src/__generated__/graphql'
import { useGetPresignedUrlsLazyQuery } from 'src/common/hooks'
import { encodeGuideId } from 'src/utils'
import {
  guideDraftEventImagesLimit,
  guideDraftImageMaxSize,
} from './guideDraftConstants'
import { checkGuideDraftFileTypeIsValid } from './guideDraftUtils'
import type { GuideDraftPublishedEvent } from './types'
import { useAddGuideDraftEventImagesMutation } from './useAddGuideDraftEventImagesMutation'
import { useRemoveGuideDraftEventImagesMutation } from './useRemoveGuideDraftEventImagesMutation'
import { useUpdateGuideDraftEventMutation } from './useUpdateGuideDraftEventMutation'

const guideDraftEventImagesUploadErrorMessages = {
  size: "One or more of your images failed to upload because it's greater than 5MB.",
  sizeAndType:
    "One or more of your images failed to upload because it's greater than 5MB and/or not in JPG or PNG format.",
  type: "One or more of your images failed to upload because it's not in JPG or PNG format.",
  uploaded:
    'One or more of your images failed to upload because it did not meet our community guidelines.',
}

const getGuideDraftEventImagesUploadBlob = (
  imageFile: File
): Promise<FileReader['result']> =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = event => {
      resolve(event?.target?.result)
    }
    reader.readAsArrayBuffer(imageFile)
  })

const getGuideDraftEventImagesUploadCategories = ({
  existingImages,
  selectedImages,
}: {
  existingImages: UserImage[]
  selectedImages: string[]
}) => {
  const createdUrls = selectedImages.filter(
    selectedImage => !existingImages.some(image => image?.url === selectedImage)
  )
  const deletedIds = existingImages.reduce((total, current) => {
    if (!selectedImages.includes(current?.url)) total.push(current?.id)

    return total
  }, [] as string[])

  return {
    createdUrls,
    deletedIds,
  }
}

const getGuideDraftEventImagesUploadErrorMessage = ({
  isSizeValid,
  isTypeValid,
}: {
  isSizeValid: boolean
  isTypeValid: boolean
}): string => {
  if (!isSizeValid && !isTypeValid)
    return guideDraftEventImagesUploadErrorMessages.sizeAndType

  if (!isSizeValid) return guideDraftEventImagesUploadErrorMessages.size

  if (!isTypeValid) return guideDraftEventImagesUploadErrorMessages.type

  return ''
}

/** @todo write tests for this function */
const getGuideDraftEventImagesUploadedUrls = async ({
  createdUrls,
  getPresignedUrls,
}: {
  createdUrls: string[]
  getPresignedUrls: LazyQueryExecFunction<
    GetPresignedUrlsLazyQuery,
    GetPresignedUrlsLazyQueryVariables
  >
}): Promise<string[]> => {
  if (isEmpty(createdUrls)) return []

  const presignedUrlsResponse = await getPresignedUrls({
    fetchPolicy: 'network-only',
    variables: {
      count: createdUrls.length,
      presignedUrlType: PresignedUrlType.GuideImages,
    },
  })
  const presignedUrls = presignedUrlsResponse?.data?.getPresignedUrls ?? []

  if (presignedUrls.length !== createdUrls.length) {
    console.error('Mismatch between number of images and presigned URLs')
    return []
  }

  const uploadedUrls = await Promise.all(
    createdUrls.map(async (image, index) => {
      try {
        const presignedUrl = presignedUrls[index]
        if (!presignedUrl) {
          console.error(
            `Failed to get presigned URL for image at index ${index}`
          )
          return null
        }

        const presignedUrlWithoutQuery = presignedUrl.split('?')[0]
        const blob = await fetch(image).then(response => response.blob())

        const uploadResponse = await fetch(presignedUrl, {
          method: 'PUT',
          body: blob,
        })

        if (!uploadResponse.ok) {
          console.error(`Failed to upload image at index ${index}`)
          return null
        }

        return presignedUrlWithoutQuery
      } catch (error) {
        console.error(error)
        return null
      }
    })
  )

  return uploadedUrls.filter(url => url !== null)
}

type UseGuideDraftEventImagesUpload = {
  imageError: string
  images: string[]
  isLoading: boolean
  onSelected: (updatedImage: string) => void
  onSubmit: VoidFunction
  onUpload: (updatedImagesFiles: FileList) => void
  selectedImages: string[]
  selectedImagesText: string
}

const useGuideDraftEventImagesUpload = ({
  guideDraftId,
  onDismiss,
  selectedEvent,
}: {
  guideDraftId: string
  onDismiss: VoidFunction
  selectedEvent: GuideDraftPublishedEvent
}): UseGuideDraftEventImagesUpload => {
  const [addGuideDraftEventImagesUploadImages] =
    useAddGuideDraftEventImagesMutation()
  const [getPresignedUrls] = useGetPresignedUrlsLazyQuery()
  const [imageError, setImageError] = useState('')
  const [images, setImages] = useState<string[]>([])
  const [selectedImages, setSelectedImages] = useState<string[]>([])
  const selectedImagesText = useMemo(() => {
    if (selectedImages?.length === 1) return '1 Photo Selected*'

    return `${selectedImages?.length} Photos Selected*`
  }, [selectedImages])
  const [isLoading, setIsLoading] = useState(false)
  const objectUrls = useRef<string[]>([])
  const [removeGuideDraftEventImagesUploadImages] =
    useRemoveGuideDraftEventImagesMutation()
  const [updatedPublishedEvent] = useUpdateGuideDraftEventMutation()

  useEffect(() => {
    return () => {
      objectUrls?.current?.forEach(url => URL.revokeObjectURL(url))
    }
  }, [])

  useEffect(() => {
    if (!selectedEvent?.images?.length) return

    const currentImages = selectedEvent?.images?.map(image => image?.url) ?? []
    setImages(currentImages)
    setSelectedImages(currentImages)
  }, [selectedEvent?.images])

  const onSelected = (updatedImage: string) => {
    const updatedSelectedImages = selectedImages.includes(updatedImage)
      ? selectedImages.filter(image => image !== updatedImage)
      : [...selectedImages, updatedImage]

    if (updatedSelectedImages.length > guideDraftEventImagesLimit) return

    setSelectedImages(updatedSelectedImages)
  }

  const onSubmit = async () => {
    if (isLoading) return

    setIsLoading(true)
    const { createdUrls, deletedIds } =
      getGuideDraftEventImagesUploadCategories({
        existingImages: selectedEvent?.images ?? [],
        selectedImages: selectedImages,
      })
    const uploadedUrls = await getGuideDraftEventImagesUploadedUrls({
      createdUrls,
      getPresignedUrls,
    })

    if (createdUrls.length !== uploadedUrls.length)
      setImageError(guideDraftEventImagesUploadErrorMessages.uploaded)

    try {
      if (deletedIds.length)
        await removeGuideDraftEventImagesUploadImages({
          variables: {
            input: {
              publishedEventId: selectedEvent?.id,
              imageIds: deletedIds,
            },
          },
        })

      if (uploadedUrls.length)
        await addGuideDraftEventImagesUploadImages({
          variables: {
            input: {
              publishedEventId: selectedEvent?.id,
              source: PublishedEventImageUrlSource.UserUploadedImage,
              urls: uploadedUrls,
            },
          },
        })

      if (!createdUrls?.length && !uploadedUrls.length) {
        const updatedSortOrder = selectedImages.reduce((total, current) => {
          const { id } =
            selectedEvent?.images?.find(image => image?.url === current) ?? {}

          if (id) total.push(id)

          return total
        }, [])

        await updatedPublishedEvent({
          variables: {
            publishedEventInput: {
              guideDraftId: encodeGuideId({
                guideId: guideDraftId,
                isGuideDraft: true,
              }),
              publishedEventId: selectedEvent?.id,
              imageSortOrder: updatedSortOrder,
            },
          },
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      onDismiss()
    }
  }

  const onUpload = async (updatedImagesFiles: FileList) => {
    if (!updatedImagesFiles.length) return

    let updatedIsSizeValid = true
    let updatedIsTypeValid = true
    const validImages: string[] = []

    for (const imageFile of Array.from(updatedImagesFiles)) {
      const result = await getGuideDraftEventImagesUploadBlob(imageFile)

      if (result) {
        const isSizeValid = imageFile.size <= guideDraftImageMaxSize
        const isTypeValid = checkGuideDraftFileTypeIsValid({
          result,
          type: imageFile.type,
        })

        if (!isSizeValid) updatedIsSizeValid = false
        if (!isTypeValid) updatedIsTypeValid = false

        if (isSizeValid && isTypeValid) {
          const updatedObjectUrl = URL.createObjectURL(imageFile)
          validImages.push(updatedObjectUrl)
          objectUrls.current.push(updatedObjectUrl)
        }
      }
    }

    setImageError(
      getGuideDraftEventImagesUploadErrorMessage({
        isSizeValid: updatedIsSizeValid,
        isTypeValid: updatedIsTypeValid,
      })
    )
    setImages(previousImages => [...previousImages, ...validImages])
    setSelectedImages(previousSelectedImages => {
      const updatedSelectedImages = [...previousSelectedImages, ...validImages]

      return updatedSelectedImages.slice(0, guideDraftEventImagesLimit)
    })
  }

  return {
    imageError,
    images,
    isLoading,
    onSelected,
    onSubmit,
    onUpload,
    selectedImages,
    selectedImagesText,
  }
}

export { useGuideDraftEventImagesUpload }
