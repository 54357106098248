import { useEffect, useRef, useState } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Divider,
  Dropdown,
  DropdownOption,
  Icon,
  IconButton,
  KeyCode,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getGeocode, getLatLng, type Suggestion } from 'use-places-autocomplete'
import { SortBy } from 'src/__generated__/graphql'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import { explorePath } from 'src/constants'
import { BOUNDING_BOX_PARAMS } from 'src/pages/explore/bounding-box/constants/boundingBoxParams'
import { extractViewport } from 'src/pages/explore/bounding-box/utils/extractViewport'
import { COUNTRY_STATE_PARAMS } from 'src/pages/explore/country-state/constants/COUNTRY_STATE_PARAMS'
import { KEYWORD_SEARCH_PHRASE } from 'src/pages/explore/keyword-search/constants'
import {
  generateExperiencesResultsUrl,
  generateHotelResultsUrl,
  getGeocoderPlaceCountryFromAddressComponents,
  getGeocoderPlaceTypeFromAddressComponents,
} from 'src/utils'
import { SEARCH_BAR_OPTIONS, SEARCH_BAR_STATES } from './constants'

const assetOptions = Object.keys(SEARCH_BAR_OPTIONS).map(key => ({
  label: key,
  value: SEARCH_BAR_OPTIONS[key] as string,
}))

export const SearchBar = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  const [state, setState] = useState<SEARCH_BAR_STATES>(
    SEARCH_BAR_STATES.closed
  )

  const openSearchBar = () => setState(SEARCH_BAR_STATES.open)
  const closeSearchBar = () => setState(SEARCH_BAR_STATES.closed)
  const isSearchBarOpen = state === SEARCH_BAR_STATES.open
  const isSearchBarClosed = state === SEARCH_BAR_STATES.closed

  const [asset, setAsset] = useState<SEARCH_BAR_OPTIONS>(
    SEARCH_BAR_OPTIONS.guides
  )

  const autocompleteRef = useRef<HTMLInputElement>(null)
  const autocompleteParentRef = useRef<HTMLDivElement>(null)

  const { isMobileScreen } = useScreenQuery()

  const [showSearchTerm, setShowSearchTerm] = useState(false)

  const isGuideSearch = asset === SEARCH_BAR_OPTIONS.guides
  const isHotelSearch = asset === SEARCH_BAR_OPTIONS.hotels
  const isExperienceSearch = asset === SEARCH_BAR_OPTIONS.experiences
  const isDestinationSearch = asset === SEARCH_BAR_OPTIONS.destinations

  const geocoderOptions = isGuideSearch
    ? {
        requestOptions: { componentRestrictions: { country: [] } },
        cacheKey: CACHE_KEYS.withoutRestriction,
      }
    : undefined

  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch(geocoderOptions)

  useEffect(() => {
    setValue(value)
  }, [asset])

  const searchTermEnabled =
    isSearchBarOpen && isGuideSearch && showSearchTerm && value?.trim()

  const options = suggestions?.data || []
  const showOptions = isSearchBarOpen && Boolean(options?.length)
  const searchTerm = value?.trim()

  const handleClearAndFocus = ({
    newValue,
    focusesInput,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput: boolean
    shouldFetchData?: boolean
  }) => {
    newValue !== null && setValue(newValue, shouldFetchData)
    clearSuggestions()
    setShowSearchTerm(false)
    // hacky way to force the functionality to work with ESC key
    autocompleteRef?.current?.blur()
    focusesInput && autocompleteRef?.current?.focus()
    closeSearchBar()
  }

  const ClearButton = () => {
    return (
      <span className='mr--3'>
        <IconButton icon='clear' onClick={onClear} />
      </span>
    )
  }

  const onClear = () =>
    handleClearAndFocus({
      newValue: '',
      focusesInput: true,
    })

  const handleOptionSelection = async (selection: Suggestion | string) => {
    if (isGuideSearch) {
      if (typeof selection === 'string') {
        if (selection.trim().length) handleKeywordSearch(selection)
      } else if (await checkCountryStateSearch(selection))
        handleCountryStateSearch(selection)
      else handleLocationSearch(selection)
    }

    if (typeof selection !== 'string') {
      if (isHotelSearch) {
        handleHotelSearch(selection)
      }

      if (isExperienceSearch) {
        handleExperienceSearch(selection)
      }

      if (isDestinationSearch) {
        handleDestinationSearch(selection)
      }
    }

    onClear()
  }

  const handleHotelSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({
      placeId: selection.place_id,
    })
    const { lat, lng } = getLatLng(result)
    const placeCountry =
      getGeocoderPlaceCountryFromAddressComponents(result.address_components) ??
      ''

    const placeFilteredType = getGeocoderPlaceTypeFromAddressComponents(
      result.address_components
    )

    // per logic in src/common/components/SearchHotels/searchHotelsUtils.ts#L47-L48
    // 'lodging' means hotel/"specific place". if hotel, omit place_id to trigger specificPlace search on Hotels page
    const placeId = (result.types?.includes('lodging') && result.place_id) || ''

    /** @todo how to preserve existing URL search params when using this util? eg. utm */
    const url = generateHotelResultsUrl({
      adults: 2,
      kids: 0,
      // arrival,
      // departure,
      location: selection.description,
      latitude: lat,
      longitude: lng,
      placeCountry,
      placeId,
      placeShortName: placeFilteredType?.shortName ?? '',
      placeType: placeFilteredType?.type ?? '',
      sort: !placeId ? SortBy.Recommended : SortBy.Distance,
      generateDefaultDates: false,
    })
    navigate(url)
  }

  const handleExperienceSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({
      placeId: selection.place_id,
    })
    const { lat, lng } = getLatLng(result)
    const url = generateExperiencesResultsUrl({
      location: result.formatted_address,
      latitude: lat,
      longitude: lng,
      generateDefaultDates: false,
    })
    navigate(url)
  }

  const handleDestinationSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({
      placeId: selection.place_id,
    })
    const { lat, lng } = getLatLng(result)
    searchParams.set('displayName', result.formatted_address)
    searchParams.set('location', result.formatted_address)
    searchParams.set('latitude', lat.toString())
    searchParams.set('longitude', lng.toString())
    const url = `/destinations/results/?${searchParams.toString()}`
    navigate(url)
  }

  const handleCountryStateSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({ placeId: selection?.place_id })
    const country = result.address_components?.find(
      addressComponent => addressComponent.types[0] === 'country'
    )?.long_name

    const state = result.address_components?.find(
      addressComponent =>
        addressComponent.types[0] === 'administrative_area_level_1'
    )?.long_name

    setSearchParams(sp => {
      sp.set('title', result?.formatted_address)
      sp.set(
        'location',
        result?.address_components?.[0]?.long_name ||
          result?.address_components?.[0]?.short_name ||
          result?.formatted_address
      )
      country ? sp.set('country', country) : sp.delete('country')
      state ? sp.set('state', state) : sp.delete('state')

      sp.delete('miles')
      sp.delete('lat')
      sp.delete('lng')
      sp.delete('locationTitle')
      sp.delete(KEYWORD_SEARCH_PHRASE)
      sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
      sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
      sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
      sp.delete(BOUNDING_BOX_PARAMS.sw.lng)

      return sp
    })

    if (!pathname.includes('/country-state'))
      navigate({
        pathname: `${explorePath}/country-state`,
        search: searchParams.toString(),
      })

    handleClearAndFocus({
      newValue: result.formatted_address,
      focusesInput: !isMobileScreen,
    })
  }

  const checkCountryStateSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({ placeId: selection?.place_id })

    const country = result.address_components?.[0]?.types.find(
      type => type === 'country'
    )

    const state = result.address_components?.[0]?.types.find(
      type => type === 'administrative_area_level_1'
    )

    return !!country || !!state
  }

  const handleKeywordSearch = (selection: string) => {
    // do not set a new value for the input since we want the user's search term to remain
    handleClearAndFocus({ newValue: null, focusesInput: !isMobileScreen })
    setSearchParams(sp => {
      sp.set('title', selection)
      sp.set(KEYWORD_SEARCH_PHRASE, selection)

      sp.delete(COUNTRY_STATE_PARAMS.country)
      sp.delete(COUNTRY_STATE_PARAMS.state)
      sp.delete('location')
      sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
      sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
      sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
      sp.delete(BOUNDING_BOX_PARAMS.sw.lng)

      return sp
    })
    if (!pathname.includes('/keyword'))
      navigate({
        pathname: `${explorePath}/keyword`,
        search: searchParams.toString(),
      })
  }

  const handleLocationSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({ placeId: selection?.place_id })
    const viewport = extractViewport(result?.geometry?.viewport)

    setSearchParams(sp => {
      sp.set(BOUNDING_BOX_PARAMS.ne.lat, String(viewport.ne.lat))
      sp.set(BOUNDING_BOX_PARAMS.ne.lng, String(viewport.ne.lng))
      sp.set(BOUNDING_BOX_PARAMS.sw.lat, String(viewport.sw.lat))
      sp.set(BOUNDING_BOX_PARAMS.sw.lng, String(viewport.sw.lng))
      sp.set('title', result?.formatted_address)
      sp.set(
        'location',
        result?.address_components?.[0]?.long_name ||
          result?.address_components?.[0]?.short_name ||
          result?.formatted_address
      )

      sp.delete('miles')
      sp.delete('lat')
      sp.delete('lng')
      sp.delete('locationTitle')
      sp.delete(KEYWORD_SEARCH_PHRASE)
      sp.delete(COUNTRY_STATE_PARAMS.country)
      sp.delete(COUNTRY_STATE_PARAMS.state)

      return sp
    })

    if (!pathname.includes('/bounding-box'))
      navigate({
        pathname: `${explorePath}/bounding-box`,
        search: searchParams.toString(),
      })

    handleClearAndFocus({
      newValue: result.formatted_address,
      focusesInput: !isMobileScreen,
    })
  }

  const handleSearchTermEnter = e => {
    if (e.key === KeyCode.ENTER) {
      if (isGuideSearch) handleOptionSelection(searchTerm)
      else if (options?.length) handleOptionSelection(options[0])
    }
    if (e.key === KeyCode.ESC) onClear()
  }

  const handleSearchByButtonClick = () => {
    if (showSearchTerm) handleOptionSelection(searchTerm)
    else if (showOptions) handleOptionSelection(options[0])
    else document.getElementById('guides-autocomplete-input')?.focus()
  }

  const handleButtonClick = () => {
    if (isSearchBarOpen) handleSearchByButtonClick()
    if (isSearchBarClosed) openSearchBar()
  }

  if (isMobileScreen) return null

  return (
    <div
      className={classNames(
        'flex max-h-12 w-full max-w-sm items-stretch justify-end lg:max-w-lg',
        'before:duration-350 before:z--1 relative before:absolute before:inset-y-0 before:right-0 before:rounded-lg before:transition-all before:ease-in before:content-[""]',
        isSearchBarOpen && 'before:w-full before:rounded-l-lg before:bg-white',
        isSearchBarClosed && 'before:w-0 before:rounded-lg'
      )}
      ref={autocompleteParentRef}
    >
      <div
        className={classNames(
          'b-grey-300 b-r-0 b-r-transparent rounded-l-lg border border-solid',
          isHomePage && 'border-transparent',
          'duration-350 transition-all ease-in',
          'flex',
          isSearchBarOpen && 'w-full! -mr-2',
          isSearchBarClosed && 'pointer-events-none w-0 rounded-lg opacity-0'
        )}
      >
        <div
          className={classNames(
            '[&>div_span]:b-0! [&>div_span]:text-grey-800 [&>div>span]:rounded-0! [&>div>span]:bg-transparent! [&>div_span]:focus:border-r-grey-300! capitalize',
            'duration-350 transition-all ease-in',
            isSearchBarClosed && 'pointer-events-none w-0 opacity-0'
          )}
        >
          <Dropdown
            value={asset}
            onChange={e => setAsset(e as SEARCH_BAR_OPTIONS)}
          >
            {isSearchBarOpen &&
              assetOptions?.map(({ label, value }) => (
                <DropdownOption key={value} value={value}>
                  <span className='capitalize'>{label}</span>
                </DropdownOption>
              ))}
          </Dropdown>
        </div>
        <div
          className={classNames(
            'w-full',
            '[&>div>div>div]:rounded-0! [&>div>div>div]:b-0! [&>div>div>div]:bg-transparent! [&>div>div>div]:b-l [&>div>div>div]:b-l-grey-300 [&>div>div>div]:b-l-solid [&>div>div>div]:my-0 [&>div>div>div]:h-full',
            'duration-350 transition-all ease-in',
            isSearchBarClosed && 'pointer-events-none w-0 opacity-0'
          )}
        >
          <Autocomplete
            autoExpand
            className={classNames(
              isSearchBarOpen && 'b-l b-l-solid b-l-grey-300'
            )}
            isDisabled={!ready}
            ref={autocompleteRef}
            slotAfter={<ClearButton />}
            tabIndex={state !== SEARCH_BAR_STATES.open && -1}
            value={value}
            onChange={e => {
              setShowSearchTerm(true)
              setValue(e.target.value)
            }}
            onFocus={
              isMobileScreen
                ? () => {
                    autocompleteParentRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }
                : null
            }
            onKeyDown={handleSearchTermEnter}
            onOptionSelect={handleOptionSelection}
          >
            {showOptions &&
              options.map(option => (
                <GeocoderOption
                  key={option.place_id}
                  option={option}
                  searchTerm={searchTerm}
                />
              ))}

            {showOptions && searchTermEnabled && <Divider />}

            {searchTermEnabled && (
              <AutocompleteOption
                className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey mt- mt-2 flex w-full cursor-pointer flex-row items-center gap-2 px-4 text-left outline-none'
                value={searchTerm}
              >
                <span className='c-valleyDark mt1'>
                  <Icon name='search' />
                </span>
                Search for Keyword
                <span className='font500'>&quot;{searchTerm}&quot;</span>
              </AutocompleteOption>
            )}
          </Autocomplete>
        </div>
      </div>
      <div
        className={classNames(
          'w-min',
          isHomePage &&
            !isSearchBarOpen &&
            '[&>button]:text-white [&>button]:hover:bg-white/20 [&>button]:hover:text-white'
        )}
      >
        <IconButton
          color={isSearchBarOpen ? 'valley' : 'transparent'}
          icon='search'
          onClick={handleButtonClick}
        />
      </div>
    </div>
  )
}
