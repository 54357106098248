import { useState } from 'react'
import { Button, useSnackbar } from '@travelpass/design-system'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { GuideDraftEventDeleteModal } from 'src/pages/guides/lists/common'
import { useArchiveGuideDraftEventMutation } from 'src/pages/guides/lists/useArchiveGuideDraftEventMutation'
import { useUpdateGuideDraftEventMutation } from 'src/pages/guides/lists/useUpdateGuideDraftEventMutation'
import { encodeGuideId } from 'src/utils'
import { onGuideSessionStorageSelectedIdChange } from '../../useGuideSessionStorageIds'
import {
  GuideEventMetricLike,
  GuideEventMetricSave,
  GuideEventMetricShare,
} from '../GuideEventMetrics'

interface GuideDrawerFooterProps {
  guideDraftId: string
  guideName: string
  isEdit: boolean
  isUserOwner: boolean
  notes: string
  selectedEvent: PublishedEvent
}

export const GuideDrawerContentFooter = ({
  guideDraftId,
  guideName,
  isEdit,
  isUserOwner,
  notes,
  selectedEvent,
}: GuideDrawerFooterProps) => {
  const [archivePublishedEvent] = useArchiveGuideDraftEventMutation()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [updatePublishedEvent] = useUpdateGuideDraftEventMutation()

  const onDelete = async () => {
    try {
      if (!!notes && !isModalDeleteOpen) {
        setIsModalDeleteOpen(true)
        return
      }
      await archivePublishedEvent({
        variables: {
          archivePublishedEventInput: {
            publishedEventId: selectedEvent?.id,
          },
        },
      })
      setIsModalDeleteOpen(false)
      onGuideSessionStorageSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event deleted',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  const onSubmit = async () => {
    try {
      await updatePublishedEvent({
        variables: {
          publishedEventInput: {
            guideDraftId: guideDraftId,
            notes,
            publishedEventId: selectedEvent?.id,
          },
        },
      })
      onGuideSessionStorageSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event updated',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  return (
    <>
      <div className='b-grey-400 b-t-1 b-b-1 h-20.5 shadow-1 p-x-9 p-y-5 flex flex-row items-center justify-end gap-3 border-0 border-solid bg-white'>
        {isEdit && (
          <>
            <Button
              fullWidth
              aria-label='Delete event from guide'
              startIcon='deleteOutline'
              variant='outlined'
              onClick={onDelete}
            >
              Delete
            </Button>
            <Button fullWidth aria-label='Save event note' onClick={onSubmit}>
              Save
            </Button>
          </>
        )}
        {!isEdit && (
          <>
            <GuideEventMetricSave
              color='white'
              guideEventData={selectedEvent}
              isIconButton={true}
              size='medium'
            />
            <GuideEventMetricShare
              color='white'
              guideEventId={selectedEvent?.id}
              guideName={guideName}
              isIconButton={true}
              isUserOwner={isUserOwner}
              size='medium'
            />
            <GuideEventMetricLike
              color='white'
              guideEventData={selectedEvent}
              isIconButton={true}
              size='medium'
            />
          </>
        )}
      </div>
      {isModalDeleteOpen && (
        <GuideDraftEventDeleteModal
          onClose={() => setIsModalDeleteOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  )
}
