import { useScreenQuery } from '@travelpass/design-system'
import { copyToClipboard } from 'src/utils'

export const useShareGuide = () => {
  const { isMobileScreen } = useScreenQuery()

  const shareGuide = async ({
    guideDraftTitle,
    isUserOwner,
    shareUrl,
    onSuccessfulShare,
  }) => {
    if (navigator?.share && isMobileScreen) {
      navigator.share({
        title: guideDraftTitle,
        text: isUserOwner
          ? `Check out my "${guideDraftTitle}" guide on Travelpass!`
          : `Check out this "${guideDraftTitle}" guide I found on Travelpass!`,
        url: shareUrl,
      })
    } else {
      copyToClipboard(shareUrl)
      onSuccessfulShare()
    }
  }

  return shareGuide
}
