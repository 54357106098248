import { useState } from 'react'
import { Icon } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { CustomContentProgressbar } from './CustomContentProgressbar'
import { HowToEnterModal } from './modals/HowToEnterModal'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface StepsCompletedProps {
  name: string
  competitionCheck: UseGetDashboardQuery['dashboardData']['currentUser']['userProfile']['competitionInfo']['competitionCheck']
}

export const StepsCompleted = ({
  name,
  competitionCheck,
}: StepsCompletedProps) => {
  const stepsCompleted = Object.keys(competitionCheck).filter(key =>
    key !== '__typename' &&
    key !== 'isCompetitionReady' &&
    competitionCheck[key]
      ? key
      : false
  )
  const progress = stepsCompleted.length * 25
  const [showModal, setShowModal] = useState(false)

  const onHowToEnter = () => {
    setShowModal(true)
    pushDataToDataLayer('view_comp_video', {
      timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
    })
  }

  return (
    <>
      <section className='hidden pl-20 lg:block'>
        <h1 className='type-h1 c-white hidden lg:block'>
          Hello, <br /> {name}
        </h1>
        <div className='relative mt-5 w-40'>
          <CustomContentProgressbar value={progress}>
            <div className='mt-10 flex flex-col gap-y-2 text-center'>
              <p className='c-white type-h3'>{stepsCompleted.length}/4</p>
              <p className='type-body-2 c-white'>
                Steps <br /> Completed
              </p>
            </div>
          </CustomContentProgressbar>
        </div>
        <button
          className='c-valleyLight type-button b-none text-4 font-500 mt-20 flex cursor-pointer flex-row items-center bg-transparent'
          type='button'
          onClick={onHowToEnter}
        >
          Contest Details <Icon name='arrowForward' />
        </button>
      </section>

      {/**MOBILE */}
      <section className='flex w-full justify-around gap-x-8 lg:hidden'>
        <div className='w-40 w-full'>
          <CustomContentProgressbar value={progress}>
            <div className='md:mt-9.5 mt-9 flex flex-col gap-y-2 text-center'>
              <p className='c-white type-h3'>{stepsCompleted.length}/4</p>
              <p className='type-body-2 c-white'>
                Steps <br /> Completed
              </p>
            </div>
          </CustomContentProgressbar>
        </div>
        <div className='max-w-50% mt-7 flex w-full flex-col gap-y-3'>
          <h1 className='type-h1 c-white'>Hello, {name}</h1>
          <button
            className='c-valleyLight type-button b-none text-4 font-500 flex cursor-pointer flex-row items-center bg-transparent'
            type='button'
            onClick={onHowToEnter}
          >
            Contest Details <Icon name='arrowForward' />
          </button>
        </div>
      </section>
      {showModal && <HowToEnterModal onClose={() => setShowModal(false)} />}
    </>
  )
}
