import { useEffect, useState } from 'react'
import { Button, Icon, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { GuideDraftEventImagesUploadModal } from 'src/pages/guides/lists/common'
import { Hero } from 'src/pages/trips/components/TripDrawerContent/components'
import { getGuideAddress } from 'src/utils'
import { GuideDrawerContentFooter } from './GuideDrawerContentFooter'
import { GuideDrawerContentLoading } from './GuideDrawerContentLoading'
import { GuideDrawerContentNotes } from './GuideDrawerContentNotes'
import { getGuideDrawerImages } from '../../guideUtils'
import type { GuideOwner } from '../../types'
import { useGetGuideDrawerQuery } from '../../useGetGuideDrawerQuery'

interface GuideDrawerContentProps {
  guideDraftId: string
  isEdit: boolean
  owner: GuideOwner
  selectedEvent: PublishedEvent
}

/** @todo tests (separate tests for GuideDraft/Guide drawer contents) */
export const GuideDrawerContent = ({
  guideDraftId,
  isEdit,
  owner,
  selectedEvent,
}: GuideDrawerContentProps) => {
  const isGuideEventImageUploadingEnabled = useFlag('guideEventImageUploading')
  const {
    guideDrawerData,
    guideDrawerNumberOfGuidesCount,
    guideDrawerWebsite,
    isLoading,
  } = useGetGuideDrawerQuery(selectedEvent)
  const [isHoursExpanded, setIsHoursExpanded] = useState(false)
  const [isModalImageUploadOpen, setIsModalImageUploadOpen] = useState(false)
  const [notes, setNotes] = useState('')
  const {
    address: addressFirstLine,
    city,
    country,
    imageLinks,
    openingHours,
    phoneNumber,
    state,
  } = guideDrawerData ?? {}
  const { weekdayText } = openingHours ?? {}
  const { name } = selectedEvent ?? {}
  const address = getGuideAddress({
    addressLine1: addressFirstLine,
    city,
    country,
    state,
  })
  const images = getGuideDrawerImages({
    internalImages: selectedEvent?.images,
    externalImages: imageLinks,
  })

  useEffect(() => {
    setNotes(selectedEvent?.notes ?? '')
  }, [selectedEvent])

  if (isLoading || isEmpty(guideDrawerData))
    return <GuideDrawerContentLoading isEdit={isEdit} />

  return (
    <>
      <div className='p-b-8 grow space-y-4 overflow-y-auto'>
        <Hero
          hideArrows={false}
          hideDots={isGuideEventImageUploadingEnabled}
          images={images}
          showDefaultImage={true}
        />
        {isGuideEventImageUploadingEnabled && isEdit && (
          <div className='flex flex-col items-center'>
            <Button
              size='small'
              startIcon='camera'
              variant='outlined'
              onClick={() => setIsModalImageUploadOpen(true)}
            >
              {selectedEvent?.images?.length ? 'Edit photos' : 'Add photos'}
            </Button>
          </div>
        )}
        <div className='p-x-9 space-y-4'>
          <div className='space-y-3'>
            <h2 className='type-subtitle-1 line-clamp-1'>{name}</h2>
            {address && (
              <p className='c-grey-800 type-body-2 flex flex-row gap-1'>
                <Icon name='placeOutline' size='small' />
                <span>{address}</span>
              </p>
            )}
            {phoneNumber && (
              <p className='c-grey-800 type-body-2 flex flex-row gap-1'>
                <Icon name='phone' size='small' />
                <span>{phoneNumber}</span>
              </p>
            )}
            {weekdayText && (
              <div className='c-grey-800 type-body-2 flex flex-row items-start gap-1'>
                <Icon name='accessTime' size='small' />
                <div
                  className={classNames('space-y-1', {
                    'max-h-4.125 md:max-h-4.375 overflow-hidden':
                      !isHoursExpanded,
                  })}
                >
                  {weekdayText.map((availableString, index) => (
                    <p key={index} className='type-body-2 c-grey-800 m-0'>
                      {availableString}
                    </p>
                  ))}
                </div>
                <div className='[&>button]:m-b--2 [&>button]:justify-start [&>button]:p-0'>
                  <IconButton
                    aria-expanded={isHoursExpanded}
                    aria-label='See more hours'
                    icon={isHoursExpanded ? 'expandLess' : 'expandMore'}
                    size='small'
                    onClick={() => setIsHoursExpanded(!isHoursExpanded)}
                  />
                </div>
              </div>
            )}
            {guideDrawerWebsite && (
              <div className='[&>a>span]:type-body-2 flex cursor-pointer flex-row gap-1 [&>a>span]:line-clamp-1'>
                <Icon name='language' size='small' />
                <a
                  className='type-body-2 c-grey-800 decoration-none line-clamp-1'
                  href={guideDrawerWebsite}
                  target='blank'
                >
                  {guideDrawerWebsite}
                </a>
              </div>
            )}
          </div>
          <GuideDrawerContentNotes
            isEdit={isEdit}
            notes={notes}
            owner={owner}
            onNotesChange={setNotes}
          />
          {!isEdit && guideDrawerNumberOfGuidesCount > 1 && (
            <div className='m-x-a w-fit'>
              <div className='rounded-2 bg-mint p-x-2 p-y-0.5 [&>*]:c-new-forest flex flex-row items-center gap-1'>
                <Icon name='listAlt' size='small' />
                <span className='type-body-1-medium'>{`Appears in ${guideDrawerNumberOfGuidesCount} Guides`}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <GuideDrawerContentFooter
        guideDraftId={guideDraftId}
        guideName={selectedEvent?.guide?.name}
        isEdit={isEdit}
        isUserOwner={owner?.isUserOwner}
        notes={notes}
        selectedEvent={selectedEvent}
      />
      {isModalImageUploadOpen && (
        <GuideDraftEventImagesUploadModal
          guideDraftId={guideDraftId}
          selectedEvent={selectedEvent}
          onDismiss={() => setIsModalImageUploadOpen(false)}
        />
      )}
    </>
  )
}
