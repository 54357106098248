import { Avatar } from '@travelpass/design-system'

interface AvatarContainerProps {
  avatarURL?: string
  bannerImage?: string
  showBannerImage?: boolean
}

export const AvatarContainer = ({
  bannerImage,
  avatarURL,
  showBannerImage,
}: AvatarContainerProps) => {
  return (
    <>
      {showBannerImage ? (
        <div
          className='h-2xl rounded-t-5 max-h-180px mb-25 flex w-full items-center justify-center pt-40'
          style={{
            backgroundImage: `url(${bannerImage || 'https://static.travelpass.com/assets/profile-banner-images/default.webp'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Avatar size='xl' src={avatarURL} />
        </div>
      ) : (
        <Avatar size='xl' src={avatarURL} />
      )}
    </>
  )
}
