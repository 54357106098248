import { useMemo } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { FooterLink } from 'src/common/components/Footer/FooterLink'
import {
  onPhoneNumberClick,
  useLucencyNumber,
} from 'src/config/analytics/useLucencyNumber'

export const FooterContact = ({ className }: { className?: string }) => {
  const { pathname } = useLocation()
  const { lucencyNumber } = useLucencyNumber()
  const phoneNumber = useMemo(() => lucencyNumber, [pathname])
  return phoneNumber ? (
    <div className={classNames('flex flex-col gap-4', className)}>
      <h3 className='type-button-large c-warmGrey uppercase'>Need Help?</h3>
      <p className='type-body-1 c-warmGrey md:max-w-100 max-w-full'>
        Contact us to book or get support
      </p>
      <div className='text-center md:text-left'>
        <FooterLink
          hideUnderline
          label={phoneNumber}
          onClick={() => {
            onPhoneNumberClick(phoneNumber)
            window.location.href = `tel:${phoneNumber}`
          }}
        />
      </div>
    </div>
  ) : null
}
