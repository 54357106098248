import { Chip } from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import { explorePath } from 'src/constants'

export const TagsSection = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { tags, loading } = useGetPredefinedGuideTagsQuery(true)

  if (loading) return null

  const onChipClick = (tagId: string) => {
    setSearchParams(sp => {
      sp.set('tags', tagId)
      return sp
    })

    navigate({
      pathname: `${explorePath}/tag`,
      search: searchParams.toString(),
    })
  }

  return (
    <section className='max-w-300 mx-auto space-y-8'>
      <header className='space-y-2 text-balance text-center text-white max-md:px-4'>
        <h2 className='type-h2 c-current capitalize'>
          Search Travel Guides By Tag
        </h2>
        <p>Use tags to quickly find the Guides you&apos;re looking for.</p>
      </header>
      <div className='flex flex-wrap justify-center gap-2 max-md:px-4'>
        {tags?.map(({ id, name, icon }) => (
          <Chip
            key={id}
            icon={icon}
            isSelected={false}
            label={name}
            onClick={() => onChipClick(id)}
          />
        ))}
      </div>
    </section>
  )
}
