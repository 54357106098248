import { useState } from 'react'
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  useScreenQuery,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { dashboardPath } from 'src/constants'
import { getGuideDetailsUrl } from 'src/utils'
import type { GuideDraftData } from '../../types'
import { GuideDraftDeleteModal } from '../GuideDraftDeleteModal'
import { GuideDraftEditModal } from '../GuideDraftEditModal'
import { GuideDraftShare } from '../GuideDraftShare'

interface GuideDraftTopNavMenuProps {
  guideDraftData: GuideDraftData
}

export const GuideDraftTopNavMenu = ({
  guideDraftData,
}: GuideDraftTopNavMenuProps) => {
  const { isMobileScreen } = useScreenQuery()
  const navigate = useNavigate()
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalShareOpen, setIsModalShareOpen] = useState(false)
  const { guide } = guideDraftData ?? {}

  const onViewGuide = () =>
    navigate(
      getGuideDetailsUrl({
        id: guide?.id,
        name: guide?.name,
      })
    )

  return (
    <>
      <div className='flex flex-row items-center gap-6'>
        <div className='hidden flex-row items-center gap-6 lg:flex'>
          <Button
            aria-label='View Guide'
            size='small'
            variant='outlined'
            onClick={onViewGuide}
          >
            View Guide
          </Button>
          <Divider className='w-1px m-0 min-h-8' />
          <Button
            aria-label='Share Guide'
            size='small'
            onClick={() => setIsModalShareOpen(true)}
          >
            Share
          </Button>
        </div>
      </div>
      <Popover
        placement='bottom-end'
        trigger={
          <IconButton
            aria-label='Guide Menu'
            color='transparent'
            icon={isMobileScreen ? 'menuFilled' : 'moreVert'}
          />
        }
      >
        <div className='w-50 p-2'>
          <MenuItem
            label='Edit Details'
            startIcon='modeEdit'
            value='Edit Details'
            onClick={() => setIsModalEditOpen(true)}
          />
          <MenuItem
            label='Delete Guide'
            startIcon='delete'
            value='Delete Guide'
            onClick={() => setIsModalDeleteOpen(true)}
          />
          <div className='lg:hidden'>
            <MenuItem
              aria-label='View Guide'
              label='View Guide'
              startIcon='campaign'
              value='View Guide'
              onClick={() => onViewGuide()}
            />
            <MenuItem
              aria-label='Share Guide'
              label='Share Guide'
              startIcon='share'
              value='Share Guide'
              onClick={() => setIsModalShareOpen(true)}
            />
          </div>
        </div>
      </Popover>
      {isModalDeleteOpen && (
        <GuideDraftDeleteModal
          guideDraftData={guideDraftData}
          onDelete={() => navigate(dashboardPath)}
          onDismiss={() => {
            setIsModalDeleteOpen(false)
          }}
        />
      )}
      {isModalEditOpen && (
        <GuideDraftEditModal
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalEditOpen(false)}
        />
      )}
      {isModalShareOpen && (
        <GuideDraftShare
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalShareOpen(false)}
        />
      )}
    </>
  )
}
