import { Divider, Skeleton } from '@travelpass/design-system'

export const GuideHeaderContentLoading = () => (
  <div className='flex flex-col gap-5 md:flex-row md:gap-7'>
    <div className='flex min-w-fit flex-col gap-3 md:flex-row md:items-start md:gap-3.5'>
      <div className='flex flex-row items-center gap-3'>
        <div className='h-12 w-12'>
          <Skeleton variant='circular' />
        </div>
        <div className='space-y-1'>
          <div className='w-25 h-3 md:h-3.5'>
            <Skeleton />
          </div>
          <div className='w-25 h-2.5 md:h-4'>
            <Skeleton />
          </div>
        </div>
      </div>
      <Divider className='w-1px m-0 hidden min-h-12 md:block' />
    </div>
    <div className='hidden grow md:block'>
      <div className='h-26'>
        <Skeleton />
      </div>
    </div>
    <div className='grow md:hidden'>
      <Divider className='min-h-1px m-t--2 m-b-2 m-0 w-full md:hidden' />
      <div className='h-28 w-full'>
        <Skeleton />
      </div>
    </div>
  </div>
)
