import type { QueryHookOptions } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  PaginatedCuratedGuidesQuery,
  PaginatedCuratedGuidesQueryVariables,
} from 'src/__generated__/graphql'

const GET_PAGINATED_CURATED_GUIDES = gql(`
    query PaginatedCuratedGuides($category: String, $first: Int) {
        paginatedCuratedGuides(
            first: $first
            category: $category
        ) {
            totalCount
            edges {
                cursor
                node {
                    id
                    addresses {
                        id
                        city
                        country
                        state
                    }
                    isCollected
                    endDate
                    guideType
                    images {
                        id
                        source
                        type
                        url
                    }
                    imageUrl
                    insertedAt
                    name
                    ownerProfile {
                        id
                        accountHandle
                        displayName
                        profileImageUrl
                    }
                    startDate
                    updatedAt
                    timeZone
                }
            }
        }
    }
`)

export const useGetPaginatedCuratedGuides = (
  options?: QueryHookOptions<
    PaginatedCuratedGuidesQuery,
    PaginatedCuratedGuidesQueryVariables
  >
) => useQuery(GET_PAGINATED_CURATED_GUIDES, options)
