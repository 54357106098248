import {
  Divider,
  Icon,
  StopPropagation,
  Tag,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionItemGroup,
  AccordionPanel,
} from 'src/common/components/Accordion'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { firebaseSignOut } from 'src/config/firebase/firebaseUtils'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from 'src/pages/profile/components/hooks/useGetCurrentUserProfileAccountHandleAndAvatar'
import { getProfilePageUrl } from 'src/pages/profile/components/profileUtils'
import Polymorphic from '../../Polymorphic'
import { ProfileAvatar } from '../../ProfileAvatar'
import type { LINK_ITEM, TITLE_LINK } from '../constants'
import {
  BOOK_LINKS,
  COMPETITION_LINKS,
  EXPLORE_LINKS,
  FIND_MY_BOOKING_LINK,
  MY_HOME_LINKS,
  PLAN_LINK,
} from '../constants'

const ProfileImage = ({ profileImageUrl = undefined }) => (
  <>
    {!profileImageUrl && (
      <div className='p-1 md:px-1.5'>
        <Icon name='person' size='large' />
      </div>
    )}
    {!!profileImageUrl && (
      <ProfileAvatar className='h-12 w-12' src={profileImageUrl} />
    )}
  </>
)

const UserSection = () => {
  const { isAnonymous } = useFirebaseUser()
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const profile = data?.currentUser?.userProfile
  const openSignInModal = () =>
    dispatch('openSignin', {
      isCreateAccount: true,
    })
  const profileURL = getProfilePageUrl(profile?.accountHandle)

  const Trigger = ({ isAnonymous, children }) => {
    const className =
      'b-none hover:bg-zinc-1 flex w-full cursor-pointer items-center gap-3 rounded-lg bg-transparent md:px-1.5 py-1.5 decoration-none px-0'

    if (isAnonymous)
      return (
        <button className={className} onClick={openSignInModal}>
          {children}
        </button>
      )

    return (
      <Link className={className} to={profileURL}>
        {children}
      </Link>
    )
  }

  return (
    <section className={classNames(isAnonymous && 'max-md:hidden')}>
      <Trigger isAnonymous={isAnonymous}>
        {!isAnonymous && (
          <div className='bg-zinc-2 aspect-square h-fit w-fit rounded-full'>
            <ProfileImage profileImageUrl={profile?.profileImageUrl} />
          </div>
        )}
        {!isAnonymous && (
          <div>
            <span className='type-title-1 block text-lg'>
              {profile?.displayName}
            </span>
            <span className='type-subtitle-2 block text-sm'>
              @{profile?.accountHandle}
            </span>
          </div>
        )}
        {isAnonymous && (
          <div className='flex items-center gap-2'>
            <span className='c-grey800 contents'>
              <Icon name='login' />
            </span>
            <span className='type-h5 text-xl'>Sign Up / Sign In</span>
          </div>
        )}
      </Trigger>
    </section>
  )
}

const AccordionSection = ({ title, children }) => {
  return (
    <AccordionItemGroup className='[&>.accordion-panel]:ml-7'>
      <AccordionButton className='text-forest cursor-pointer appearance-none px-0 py-2 text-xl transition-colors'>
        <StopPropagation>{title}</StopPropagation>
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItemGroup>
  )
}

const LinkTitle = ({ href = '', title, icon, tag }: TITLE_LINK) => (
  <Link
    className='type-title-3 decoration-none hover:bg-zinc-1 flex items-center gap-2 rounded-lg py-1 text-xl md:px-1.5'
    to={href}
  >
    <div className='text-gray block flex items-center'>
      <Icon name={icon} />
    </div>
    <span className='vertical-bottom block'>{title}</span>
    {!!tag && <Tag color='grey200' text={tag} />}
  </Link>
)

const LinkItem = ({ href = '', title, tag, disabled }: LINK_ITEM) => (
  <Polymorphic
    aria-disabled={disabled}
    as={disabled ? 'div' : Link}
    className='hover:bg-zinc-1 decoration-none text-zinc-5 ml-1 block flex w-fit items-center gap-2 rounded-lg py-1 font-normal aria-disabled:cursor-not-allowed aria-disabled:hover:bg-transparent md:px-2'
    to={href}
  >
    <span>{title}</span>
    {!!tag && <Tag color='valley' text={tag} />}
  </Polymorphic>
)

export const MenuContent = () => {
  const { isAnonymous } = useFirebaseUser()
  const { isMobileOrTablet, isMobileScreen } = useScreenQuery()
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const openSignInModal = () => {
    dispatch('openSignin', {
      isCreateAccount: true,
    })
  }

  return (
    <div className='space-y-4 p-5'>
      <UserSection />

      {!isAnonymous && (
        <>
          <Divider />
          <section>
            <Accordion multiple={true}>
              <AccordionSection
                title={
                  <LinkTitle
                    href={MY_HOME_LINKS.main.href}
                    icon={MY_HOME_LINKS.main.icon}
                    title={MY_HOME_LINKS.main.title}
                  />
                }
              >
                {MY_HOME_LINKS.links.map(({ href, title, tag, disabled }) => (
                  <LinkItem
                    key={title}
                    disabled={disabled}
                    href={href}
                    tag={tag}
                    title={title}
                  />
                ))}
              </AccordionSection>
            </Accordion>
          </section>
        </>
      )}

      {enableCompetition2024 && isAnonymous && !isMobileScreen && <Divider />}
      {enableCompetition2024 && !isAnonymous && <Divider />}

      {enableCompetition2024 && (
        <section>
          <Accordion multiple={true}>
            <AccordionSection
              title={
                <LinkTitle
                  href={COMPETITION_LINKS.main.href}
                  icon={COMPETITION_LINKS.main.icon}
                  title={COMPETITION_LINKS.main.title}
                />
              }
            >
              {COMPETITION_LINKS.links.map(({ href, title, tag, disabled }) => (
                <LinkItem
                  key={title}
                  disabled={disabled}
                  href={href}
                  tag={tag}
                  title={title}
                />
              ))}
            </AccordionSection>
          </Accordion>
        </section>
      )}

      {isMobileOrTablet && (
        <>
          <Divider />

          <section>
            <Accordion multiple={true}>
              <AccordionSection
                title={
                  <LinkTitle
                    href={EXPLORE_LINKS.main.href}
                    icon={EXPLORE_LINKS.main.icon}
                    title={EXPLORE_LINKS.main.title}
                  />
                }
              >
                {EXPLORE_LINKS.links.map(({ href, title, tag, disabled }) => (
                  <LinkItem
                    key={title}
                    disabled={disabled}
                    href={href}
                    tag={tag}
                    title={title}
                  />
                ))}
              </AccordionSection>
            </Accordion>
          </section>

          <Divider />

          <section>
            <LinkTitle
              href={PLAN_LINK.href}
              icon={PLAN_LINK.icon}
              title={PLAN_LINK.title}
            />
          </section>

          <Divider />

          <section>
            <Accordion multiple={true}>
              <AccordionSection
                title={
                  <LinkTitle
                    href={BOOK_LINKS.main.href}
                    icon={BOOK_LINKS.main.icon}
                    title={BOOK_LINKS.main.title}
                  />
                }
              >
                {BOOK_LINKS.links.map(({ href, title, tag, disabled }) => (
                  <LinkItem
                    key={title}
                    disabled={disabled}
                    href={href}
                    tag={tag}
                    title={title}
                  />
                ))}
              </AccordionSection>
            </Accordion>
          </section>
        </>
      )}

      {isAnonymous && (
        <>
          <Divider />
          <section>
            <LinkTitle
              href={FIND_MY_BOOKING_LINK.href}
              icon={FIND_MY_BOOKING_LINK.icon}
              title={FIND_MY_BOOKING_LINK.title}
            />
          </section>
        </>
      )}

      {!isAnonymous && (
        <>
          <Divider />
          <button
            className='type-title-3 decoration-none hover:bg-zinc-1 b-none flex w-full cursor-pointer items-center gap-2 rounded-lg bg-transparent px-0 py-1 text-xl md:px-1.5'
            onClick={firebaseSignOut}
          >
            <div className='text-gray block flex items-center'>
              <Icon name='logout' />
            </div>
            <span className='vertical-bottom block'>Log out</span>
          </button>
        </>
      )}

      {isAnonymous && isMobileScreen && (
        <>
          <Divider />
          <button
            className='type-title-3 decoration-none hover:bg-zinc-1 b-none flex w-full cursor-pointer items-center gap-2 rounded-lg bg-transparent px-0 py-1 text-xl md:px-1.5'
            onClick={openSignInModal}
          >
            <span className='c-grey800 contents'>
              <Icon name='login' />
            </span>
            <span className='type-h5 text-xl'>Sign Up / Sign In</span>
          </button>
        </>
      )}
    </div>
  )
}
