import { Button, Illustration } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'

export const DashboardBookingsEmpty = () => {
  const navigate = useNavigate()

  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <section className='space-y-10'>
      <div className='bg-warm-grey p-x-11 p-t-18.5 p-b-11 rounded-3 b-1 b-solid b-canyon-light space-y-4'>
        <div className='m-a block max-w-64 md:hidden'>
          <Illustration name='suitcase' />
        </div>
        <div className='flex flex-row items-center'>
          <div className='space-y-5'>
            <h3 className='c-forest-light type-h3'>Make A Booking</h3>
            <p className='type-body-1'>
              Book your next hotel or experience with Travelpass and keep all
              the details in one place.
            </p>
            <div className='m-a flex flex-row justify-center md:block'>
              <Button
                aria-label='Find a booking'
                startIcon='add'
                variant='text'
                onClick={() => navigate(`/${pathSegment}`)}
              >
                Book Now
              </Button>
            </div>
          </div>
          <div className='hidden min-w-64 md:block'>
            <Illustration name='suitcase' />
          </div>
        </div>
      </div>
    </section>
  )
}
