import { useEffect } from 'react'
import {
  Button,
  Icon,
  Illustration,
  Input,
  Modal,
  ModalActions,
  useSnackbar,
} from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useForm } from 'react-hook-form'
import type { GeocodeResult } from 'use-places-autocomplete'
import { Geocoder } from 'src/common/components'
import { CACHE_KEYS } from 'src/common/components/Geocoder/useGeocoderSearch'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { initialGeocoder, type GeocoderType } from 'src/constants/user'
import { accountHandleValidationRegex } from 'src/constants/validation'
import { useUserProfileMutation } from '../account-settings/hooks/useUserProfileMutation'
import { useGetUserOnboardingMutation } from '../profile/components/hooks/useGetUserOnboardingMutation'
import { useGetUserOnboardingQuery } from '../profile/components/hooks/useGetUserOnboardingQuery'
import { constructAddressInput } from '../trips/utils'

export const OnboardingModal = ({ onClose }: { onClose: VoidFunction }) => {
  const { data } = useGetUserOnboardingQuery()
  const [updateUserProfileMutation, { loading: isProfileLoading }] =
    useGetUserOnboardingMutation()
  const [updateUserAddressMutation, { loading: isAddressLoading }] =
    useUserProfileMutation()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()

  const { currentUser } = data ?? {}
  const { accountHandle: userAccountHandle } = currentUser?.userProfile ?? {}
  const { formState, handleSubmit, register, watch, getValues, setValue } =
    useForm({
      defaultValues: {
        accountHandle: userAccountHandle || '',
        geocode: initialGeocoder,
      },
    })
  const { errors } = formState
  const { accountHandle: accountHandleError } = errors
  const accountHandle = watch('accountHandle', '')

  const handleError = () => {
    if (accountHandleError?.type === 'required') {
      return 'Account handle is required.'
    } else if (accountHandleError?.type === 'validate') {
      return `${accountHandle.length}/25. Use only letters, numbers, ".", and "_". No spaces.`
    }
  }

  useEffect(() => {
    if (userAccountHandle && !isProfileLoading && !isAddressLoading) {
      setValue('accountHandle', userAccountHandle)
    }
  }, [currentUser])

  const onGeocoderResult = ({
    center,
    placeName,
    addressComponents,
  }: GeocoderType) => {
    setValue('geocode', {
      center: [center[0], center[1]],
      placeName: placeName,
      addressComponents,
    })
  }

  const onSubmit = async ({ accountHandle }) => {
    try {
      if (!isEmpty(accountHandle) && accountHandle !== userAccountHandle) {
        await updateUserProfileMutation({
          variables: {
            input: { userProfileRequest: { accountHandle } },
          },
        })
      }

      if (!isEmpty(getValues('geocode')?.addressComponents)) {
        const { addressComponents, center, placeId } = getValues('geocode') as {
          addressComponents?: GeocodeResult['address_components']
          center: number[]
          placeId?: string
        }
        const { addressLine1, addressLine2, city, state, country, zipcode } =
          constructAddressInput({ addressComponents, center, placeId })

        await updateUserAddressMutation({
          variables: {
            input: {
              id: currentUser?.id,
              addressFirstLine: addressLine1,
              addressSecondLine: addressLine2,
              city,
              country,
              state,
              zip: zipcode,
            },
          },
        })

        pushDataToDataLayer('account_update', {
          accountHandle,
          addressFirstLine: addressLine1,
          addressSecondLine: addressLine2,
          city,
          country,
          state,
          zip: zipcode,
        })
      }

      addSuccessSnack({ title: 'Personal Information updated!' })
      onClose()
    } catch (error) {
      if (
        error?.message ===
        '%{account_handle: ["account_handle already exists"]}'
      ) {
        addErrorSnack({ title: 'Account handle already exists.' })
      } else {
        addErrorSnack({ title: 'An unexpected error was found.' })
      }
    }
  }

  return (
    <Modal size='medium' title='Personalize Your Profile' onDismiss={onClose}>
      <form noValidate className='mt-8' onSubmit={handleSubmit(onSubmit)}>
        <div className='max-w-254px max-h-182px md:max-w-216px md:max-h-155px mx-auto hidden md:block'>
          <Illustration name='world' />
        </div>
        <div className='mb-8 space-y-6 md:mb-0 md:mt-5 md:space-y-3'>
          <Input
            fullWidth
            errorText={handleError()}
            helperText={`${accountHandle.length}/25. Use only letters, numbers, ".", and "_". No spaces.`}
            label='Claim your account handle'
            {...register('accountHandle', {
              required: true,
              validate: (value: string) => {
                const regex = new RegExp(accountHandleValidationRegex)
                return regex.test(value)
              },
            })}
          />
          <Geocoder
            config={{
              requestOptions: { componentRestrictions: { country: [] } },
              cacheKey: CACHE_KEYS.withoutRestriction,
            }}
            geocoder={getValues('geocode')}
            label='Where’s home or your most central location?'
            placeholder='Search'
            slotBefore={
              <span className='c-new-forest'>
                <Icon name='placeOutline' />
              </span>
            }
            onResult={onGeocoderResult}
          />
        </div>
        <div className='max-w-254px max-h-182px md:max-w-216px md:max-h-155px mx-auto block md:hidden'>
          <Illustration name='world' />
        </div>
      </form>
      <ModalActions>
        <Button label='Done' type='submit' onClick={handleSubmit(onSubmit)} />
      </ModalActions>
    </Modal>
  )
}
