import { GuideDraftShareReviewButton } from './GuideDraftShareReviewButton'
import { getGuideDraftInvalidFields } from '../../../guideDraftUtils'
import type { GuideDraftData } from '../../../types'

interface GuideDraftShareReviewProps {
  guideDraftData: GuideDraftData
  onModalEditOpen: (updatedScrollId: string) => void
  onModalImagesEditOpen: VoidFunction
}

export const GuideDraftShareReview = ({
  guideDraftData,
  onModalEditOpen,
  onModalImagesEditOpen,
}: GuideDraftShareReviewProps) => {
  const invalidFields = getGuideDraftInvalidFields(guideDraftData)

  return (
    <section className='space-y-6'>
      <p className='type-subtitle-2 c-black'>
        {invalidFields?.length > 1
          ? "There are a couple of things to do before you can publicly share your Guide on Travelpass.com. Peek below to see what's missing."
          : 'Finish up the last step to share your Guide on Travelpass.com.'}
      </p>
      <div className='max-w-98 m-auto space-y-6'>
        {invalidFields.includes('addresses') && (
          <GuideDraftShareReviewButton
            icon='placeOutline'
            subtitle='Help others discover your recommendations.'
            title='Location'
            onClick={() => onModalEditOpen('guide-draft-edit-modal-location')}
          />
        )}
        {invalidFields.includes('images') && (
          <GuideDraftShareReviewButton
            icon='cameraAlt'
            subtitle='Personalize your Guide with up to three header images.'
            title='Header Image'
            onClick={onModalImagesEditOpen}
          />
        )}
        {invalidFields.includes('description') && (
          <GuideDraftShareReviewButton
            icon='formatListBulleted'
            subtitle='Highlight your travel tips or explain what this Guide is about.'
            title='Description'
            onClick={() =>
              onModalEditOpen('guide-draft-edit-modal-description')
            }
          />
        )}
        {invalidFields.includes('tags') && (
          <GuideDraftShareReviewButton
            icon='priceTag'
            subtitle='Choose up to five tags to describe your Guide.'
            title='Tags'
            onClick={() => onModalEditOpen('guide-draft-edit-modal-tags')}
          />
        )}
      </div>
    </section>
  )
}
