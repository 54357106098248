import { useEffect, useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useFlag } from 'src/common/hooks'
import { useIncrementGuideViewCountMutation } from 'src/pages/guides'
import { GuideDraftImagesEditModal } from 'src/pages/guides/lists/common'
import type { GuideData } from '../../types'
import { GuideImage } from '../GuideImage'
import { GuideImageGalleryModal } from '../GuideImageGallery'

interface GuideHeroProps {
  guideData: Pick<
    GuideData,
    'addresses' | 'id' | 'galleryImages' | 'images' | 'name'
  >
  isEdit: boolean
}

export const GuideHero = ({ guideData, isEdit }: GuideHeroProps) => {
  const isGuideDraftUiUpdatesEnabled = useFlag('guideDraftUiUpdates')
  const isGuideEventImageUploadingEnabled = useFlag('guideEventImageUploading')
  const [incrementGuideViewCount] = useIncrementGuideViewCountMutation()
  const [isModalImagesEditOpen, setIsModalImagesEditOpen] = useState(false)
  const [isModalImageGalleryOpen, setIsModalImageGalleryOpen] = useState(false)
  const { addresses, id, galleryImages, images, name } = guideData ?? {}

  /** @todo this incrementGuideViewCount should probably live in Guide and not in GuideHero */
  useEffect(() => {
    if (!isEdit) {
      incrementGuideViewCount({
        variables: {
          guideId: id,
        },
      })
    }
  }, [isEdit])

  const onImageClick = () => {
    if (isEdit) return setIsModalImagesEditOpen(true)

    if (isGuideDraftUiUpdatesEnabled) return setIsModalImageGalleryOpen(true)
  }

  if (isEmpty(images)) return

  return (
    <>
      <div className='space-y-6'>
        <div className='h-37.5 lg:h-75 m-a relative flex flex-row items-center gap-1'>
          <div
            className={classNames('of-hidden relative h-full grow', {
              'lg:w-194 lg:max-w-194': images?.[1],
            })}
          >
            <button
              className='transition-transform-300 hover:scale-120 b-none m-none p-none relative left-0 top-0 h-full w-full grow cursor-pointer bg-transparent p-0 outline-none'
              onClick={onImageClick}
            >
              <GuideImage
                alt=''
                className='absolute inset-0'
                src={images?.[0]?.url}
              />
              <div className='of-hidden from-black/3 absolute inset-0 hidden bg-gradient-to-b via-black/20 to-black/20 lg:block' />
            </button>
            {isEdit && (
              <div className='[&>button]:bg-forest-dark absolute right-2 top-4 [&>button]:bg-opacity-50'>
                <IconButton
                  color='forestLight'
                  icon='modeEdit'
                  outline='round'
                  size='medium'
                  onClick={onImageClick}
                />
              </div>
            )}
          </div>
          {images?.[1] && (
            <div className='of-hidden relative h-full grow'>
              <button
                className='transition-transform-300 hover:scale-120 b-none m-none p-none relative left-0 top-0 h-full w-full grow cursor-pointer bg-transparent p-0 outline-none'
                onClick={onImageClick}
              >
                <GuideImage
                  alt=''
                  className='absolute inset-0'
                  src={images[1]?.url}
                />
              </button>
              {isEdit && (
                <div className='[&>button]:bg-forest-dark absolute right-2 top-4 [&>button]:bg-opacity-50'>
                  <IconButton
                    color='forestLight'
                    icon='modeEdit'
                    outline='round'
                    size='medium'
                    onClick={onImageClick}
                  />
                </div>
              )}
            </div>
          )}
          {images?.[2] && (
            <div className='of-hidden relative hidden h-full grow lg:block'>
              <button
                className='transition-transform-300 hover:scale-120 b-none m-none p-none relative left-0 top-0 h-full w-full grow cursor-pointer bg-transparent p-0 outline-none'
                onClick={onImageClick}
              >
                <GuideImage
                  alt=''
                  className='absolute inset-0'
                  src={images[2]?.url}
                />
              </button>
              {isEdit && (
                <div className='[&>button]:bg-forest-dark absolute right-2 top-4 [&>button]:bg-opacity-50'>
                  <IconButton
                    color='forestLight'
                    icon='modeEdit'
                    outline='round'
                    size='medium'
                    onClick={onImageClick}
                  />
                </div>
              )}
            </div>
          )}
          {!isEdit && isGuideEventImageUploadingEnabled && (
            <div className='absolute bottom-4 right-4'>
              <Button
                color='forestLight'
                size='small'
                startIcon='photoLibrary'
                variant='outlined'
                onClick={onImageClick}
              >
                {images?.length.toString()}
              </Button>
            </div>
          )}
        </div>
      </div>
      {isModalImagesEditOpen && (
        <GuideDraftImagesEditModal
          guideDraftImagesEditData={{
            addresses,
            id,
            images,
          }}
          onDismiss={() => setIsModalImagesEditOpen(false)}
        />
      )}
      {isModalImageGalleryOpen && (
        <GuideImageGalleryModal
          images={galleryImages}
          name={name}
          onDismiss={() => setIsModalImageGalleryOpen(false)}
        />
      )}
    </>
  )
}
