import { Modal, ModalScrollContents } from '@travelpass/design-system'
import type { UserImage } from 'src/__generated__/graphql'
import { GuideImageGallery } from './GuideImageGallery'

interface GuideImageGalleryModalProps {
  images: Partial<UserImage>[]
  name: string
  onDismiss: () => void
}

export const GuideImageGalleryModal = ({
  images,
  name,
  onDismiss,
}: GuideImageGalleryModalProps) => {
  return (
    <Modal size='large' title={name} onDismiss={onDismiss}>
      <div className='hidden md:block'>
        <GuideImageGallery images={images} />
      </div>
      <div className='md:hidden'>
        <ModalScrollContents>
          <GuideImageGallery images={images} />
        </ModalScrollContents>
      </div>
    </Modal>
  )
}
