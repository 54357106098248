import { Illustration } from '@travelpass/design-system'

interface GuideSectionsEmptyProps {
  isUserOwner: Boolean
}

export const GuideSectionsEmpty = ({
  isUserOwner,
}: GuideSectionsEmptyProps) => {
  return (
    <div className='p-t-4'>
      <div className='b-2 b-canyon-light b-solid bg-warm-grey m-t-5 rounded-3 lg:m-t-10 flex flex-col items-center justify-center gap-4 p-6 text-center lg:flex-row-reverse lg:gap-6 lg:p-9 lg:text-left'>
        <div className='w-34'>
          <Illustration name='guide' />
        </div>
        <div className='space-y-1 lg:space-y-5'>
          <h3 className='c-grey-800 type-h4'>
            {isUserOwner
              ? 'Are you still working on this Guide?'
              : 'Good Things Are Coming'}
          </h3>
          <p className='type-body-1'>
            {isUserOwner
              ? "This Guide either doesn't have any recommendations (yet) or needs to be updated to show what you've added."
              : "There aren't any recommendations in this Guide quite yet. Check back later!"}
          </p>
        </div>
      </div>
    </div>
  )
}
