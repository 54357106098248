import { useSearchParams } from 'react-router-dom'
import type {
  CompetitionCategoryEnum as CompetitionCategoryEnumType,
  CompetitionSortEnum as CompetitionSortEnumType,
  CompetitionLeaderboardRequest,
} from 'src/__generated__/graphql'
import {
  CompetitionCategoryEnum,
  CompetitionSortEnum,
} from 'src/__generated__/graphql'

enum LeaderboardSearchParams {
  category = 'category',
  sortBy = 'sort',
  userProfile = 'userProfile',
}

export const useLeaderboardSearchParams = () => {
  const [searchParams] = useSearchParams()

  return {
    category: searchParams.get(
      LeaderboardSearchParams.category || CompetitionCategoryEnum.General
    ) as CompetitionCategoryEnumType,
    sortBy: searchParams.get(
      LeaderboardSearchParams.sortBy || CompetitionSortEnum.Popular
    ) as CompetitionSortEnumType,
    userProfile: searchParams.get(
      LeaderboardSearchParams.userProfile || ''
    ) as CompetitionLeaderboardRequest['userProfile'],
  }
}
