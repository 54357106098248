import { Divider, Icon } from '@travelpass/design-system'
import { Link } from 'react-router-dom'
import { GuideStatus } from 'src/__generated__/graphql'
import { PageLayoutContainer, TravelpassLogo } from 'src/common/components'
import { dashboardPath } from 'src/constants'
import { GuideBadge } from 'src/pages/guides/details/common/GuideBadge'
import { GuideDraftTopNavMenu } from './GuideDraftTopNavMenu'
import type { GuideDraftData } from '../../types'

interface GuideDraftTopNavProps {
  guideDraftData: GuideDraftData
}

export const GuideDraftTopNav = ({ guideDraftData }: GuideDraftTopNavProps) => {
  const { guide, name } = guideDraftData ?? {}
  const { status } = guide ?? {}

  return (
    <div className='lg:z-4 bg-white lg:sticky lg:top-0'>
      <PageLayoutContainer size='none'>
        <div className='p-y-3.5 h-19.25 flex flex-row items-center gap-6'>
          <div className='flex grow flex-row items-center gap-2 md:gap-8'>
            <div className='flex min-w-0 flex-row items-center gap-4 lg:gap-3.5'>
              <Link to={dashboardPath}>
                <TravelpassLogo
                  className='h-7 w-7 lg:h-8 lg:w-8'
                  variant='symbol-valley'
                />
              </Link>
              <span className='type-h4 c-forest line-clamp-1 hidden md:block'>
                Now Editing {name}
              </span>
              <span className='type-h4 c-forest line-clamp-1 md:hidden'>
                Editing Guide
              </span>
            </div>
            <GuideBadge>
              <Icon
                name={
                  status === GuideStatus.Published
                    ? 'visibility'
                    : 'visibilityOff'
                }
                size='small'
              />
              <span>
                {status === GuideStatus.Published ? 'Public' : 'Private'}
              </span>
            </GuideBadge>
          </div>
          <GuideDraftTopNavMenu guideDraftData={guideDraftData} />
        </div>
      </PageLayoutContainer>
      <Divider className='bg-grey-200 h-1px w-full' />
    </div>
  )
}
