import { useRef, useState } from 'react'
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { dashboardPath } from 'src/constants'
import { getGuideDetailsUrl } from 'src/utils'
import type { GuideDraftData } from '../../types'
import { GuideDraftDeleteModal } from '../GuideDraftDeleteModal'
import { GuideDraftEditModalOld } from '../GuideDraftEditModalOld'
import { GuideDraftPublish, GuideDraftRepublish } from '../GuideDraftPublish'

interface GuideDraftTopNavMenuProps {
  guideDraftData: GuideDraftData
  isUserOwner: boolean
}

export const GuideDraftTopNavMenu = ({
  guideDraftData,
  isUserOwner,
}: GuideDraftTopNavMenuProps) => {
  const { guide } = guideDraftData ?? {}
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isManagePublishModalOpen, setIsManagePublishModalOpen] =
    useState(false)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const hasGuide = !!guide?.id

  const onPublish = () => {
    if (guide?.id) return setIsManagePublishModalOpen(true)

    setIsPublishModalOpen(true)
  }

  const onViewGuide = () =>
    navigate(
      getGuideDetailsUrl({
        id: guide?.id,
        name: guide?.name,
      })
    )

  return (
    <>
      <div className='flex flex-row items-center gap-8'>
        <div className='hidden gap-8 lg:flex'>
          <div className='flex flex-row gap-8'>
            {isUserOwner && (
              <Button
                aria-label={hasGuide ? 'Publish Update' : 'Publish'}
                size='small'
                startIcon='campaign'
                onClick={onPublish}
              >
                {hasGuide ? 'Publish Update' : 'Publish'}
              </Button>
            )}
            <Divider className='w-1px m-0 min-h-8' />
          </div>
          {hasGuide && (
            <Button
              aria-label='View Guide'
              size='small'
              startIcon='campaign'
              onClick={onViewGuide}
            >
              View Guide
            </Button>
          )}
        </div>
      </div>
      <Popover
        containerClassName='z-5'
        content={
          <div className='shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
            <div>
              {isUserOwner && (
                <div>
                  <MenuItem
                    label='Edit Details'
                    startIcon='modeEdit'
                    value='Edit Details'
                    onClick={() => {
                      setIsModalEditOpen(true)
                      setIsPopoverOpen(false)
                    }}
                  />
                  <MenuItem
                    label='Delete Guide'
                    startIcon='delete'
                    value='Delete Guide'
                    onClick={() => {
                      setIsModalDeleteOpen(true)
                      setIsPopoverOpen(false)
                    }}
                  />
                </div>
              )}
              <div className='block lg:hidden'>
                {isUserOwner && (
                  <MenuItem
                    label={hasGuide ? 'Publish Update' : 'Publish'}
                    startIcon='listAlt'
                    value={hasGuide ? 'Publish Update' : 'Publish'}
                    onClick={onPublish}
                  />
                )}
              </div>
            </div>
          </div>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div ref={ref}>
          <div className='block lg:hidden'>
            <IconButton
              aria-label='Guide Top Nav Menu'
              color='transparent'
              icon='menuFilled'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
          {isUserOwner && (
            <div className='hidden lg:block'>
              <IconButton
                aria-label='Guide Top Nav Menu'
                icon='moreVert'
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            </div>
          )}
        </div>
      </Popover>
      {isModalDeleteOpen && (
        <GuideDraftDeleteModal
          guideDraftData={guideDraftData}
          onDelete={() => navigate(dashboardPath)}
          onDismiss={() => {
            setIsModalDeleteOpen(false)
          }}
        />
      )}
      {isModalEditOpen && (
        <GuideDraftEditModalOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalEditOpen(false)}
        />
      )}
      {isManagePublishModalOpen && (
        <GuideDraftRepublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsManagePublishModalOpen(false)}
        />
      )}
      {isPublishModalOpen && (
        <GuideDraftPublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsPublishModalOpen(false)}
        />
      )}
    </>
  )
}
