import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  NodeQueryInBookingDetailsPoliciesQuery,
  NodeQueryInBookingDetailsPoliciesQueryVariables,
} from 'src/__generated__/graphql'
import { LodgingIdType } from 'src/__generated__/graphql'

const { Standard } = LodgingIdType

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsPolicies(
    $nodeId: ID!
    $hotelId: String!
    $hotelIdType: LodgingIdType!
  ) {
    node(id: $nodeId) {
      ... on Booking {
        bookingDetails {
          hotelDetails {
            policies {
              type
              description
            }
          }
        }
      }
    }
    lodging(id: $hotelId, idType: $hotelIdType) {
      id
      checkin
      checkinInstructions
      checkinSpecialInstructions
      checkout
      fees {
        type
        text
      }
      policies {
        title
        text
      }
    }
  }
`)

export const useBookingDetailsPoliciesQuery = ({
  bookingId,
  hotelId,
}: {
  bookingId: NodeQueryInBookingDetailsPoliciesQueryVariables['nodeId']
  hotelId: NodeQueryInBookingDetailsPoliciesQueryVariables['hotelId']
}) => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(nodeQuery, {
    skip: !hotelId,
    variables: {
      nodeId: bookingId,
      hotelId,
      hotelIdType: Standard,
    },
  })

  const { node, lodging } = data ?? {}

  const bookingDetailsPoliciesHotelData =
    lodging as NodeQueryInBookingDetailsPoliciesQuery['lodging']
  const bookingDetailsPoliciesData = node as Extract<
    NodeQueryInBookingDetailsPoliciesQuery['node'],
    { __typename?: 'Booking' }
  >

  return {
    hasError,
    bookingDetailsPoliciesData,
    bookingDetailsPoliciesHotelData,
    isLoading,
  }
}
