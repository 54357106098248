import { Button, Divider, Link } from '@travelpass/design-system'
import { useLocation } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'

export const SaltLakeBees = () => {
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  const imagePath =
    'https://static.travelpass.com/assets/landing-pages/salt-lake-bees/'
  const onGetItineraryClick = e => {
    e.preventDefault()
    const node = document.getElementById('free-itinerary')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='h-400px lg:h-600px bg-bottom-center relative w-full bg-[url(https://static.travelpass.com/assets/landing-pages/salt-lake-bees/bees-hero-bg.webp)] bg-cover'>
        <div className='z-2 relative flex h-full w-full flex-col items-center justify-center'>
          <img
            alt='Salt Lake Bees + Travelpass logo lockup'
            className='w-190px lg:w-320px mx-a mb-7 mt--7 block lg:mb-10 lg:mt--10'
            src={`${imagePath}sl-bees-travelpass-logo-lockup.webp`}
          />
          <h1 className='type-h1 c-white text-shadow-lg leading-12 text-10 lg:leading-26 lg:text-24 mb-4 text-center lg:mb-14'>
            Welcome, Bees Fans!
          </h1>
          <h2 className='type-h1 text-shadow-lg text-5.5 lg:text-16 c-white mb-14 text-center leading-6 lg:mb-20'>
            Your Next <span className='c-valley'>Vacation</span> Is Already{' '}
            <span className='c-valley'>Planned</span>
          </h2>
          <Button
            label='Claim Your Free Trip Itinerary'
            size='large'
            onClick={onGetItineraryClick}
          />
        </div>
        <div className='bg-forestDark/60 z-1 absolute bottom-0 left-0 right-0 top-0 h-full w-full'></div>
      </div>

      <div className='bg-warmGrey py-10' id='free-itinerary'>
        <PageLayoutContainer>
          <div className='flex w-full flex-col gap-4 lg:flex-row lg:items-center'>
            <div className='flex basis-1/2 flex-col gap-2'>
              <p className='type-overline c-valley uppercase'>
                Proud Partner of the Salt Lake Bees
              </p>
              <h3 className='type-h1 lg:w-400px mb-2'>
                Come Truly Explore Utah (And More)
              </h3>
              <p className='type-body-1'>
                <b>Travelpass</b> is here to take your trip to the next level.
                Our Utah-based team is excited to share top travel destinations
                and under-the-radar recommendations whether you&apos;re staying
                close to home or venturing far.
              </p>
              <p className='type-body-1'>
                Easily find the perfect hotel, incredible experience, and inside
                scoop for any adventure with Travelpass. Try it out with a free
                trip itinerary!
              </p>
            </div>
            <div className='basis-1/2'>
              <div className='w-98% mx-a bg-forest rounded-tr-8 rounded-bl-8 outline-offset-6 outline-valley mt-4 px-8 py-6 outline-dashed outline-1 lg:mt-0'>
                <p className='type-overline c-valley uppercase'>
                  We&apos;ve Planned Your Next Trip
                </p>
                <h2 className='type-h2 c-white mt-2'>
                  Claim Your Free Trip Itinerary
                </h2>
                <div className='weblayer--form mt-2'>
                  {/* Bloomreach Form - bannerId: 6606ee31d3153c3f9eafca36 */}
                </div>
              </div>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <Divider />

      <div className='bg-warmGrey py-10'>
        <PageLayoutContainer>
          <div className='flex w-full flex-col gap-4 lg:flex-row lg:items-center'>
            <div className='basis-1/2'>
              <img
                alt='Travelpass My Trips tool'
                className='lg:w-90% mx-a block w-full'
                src='https://static.travelpass.com/assets/my-trips/my_trips_info.webp'
              />
            </div>
            <div className='flex basis-1/2 flex-col gap-2'>
              <p className='type-overline c-valley uppercase'>
                Your Next Trip Should
              </p>
              <h3 className='type-h1 lg:w-400px mb-2'>
                Minimize Stress And Maximize Fun
              </h3>
              <p className='type-body-1'>
                Travelpass lets every traveler easily explore, plan, book, and
                share their adventures. Discover exciting experiences before
                choosing between top-rated hotels. Keep all of your details
                together with My Trips (our free trip planning tool) and explore
                similar itineraries created by other travelers.
              </p>
              <p className='type-body-1'>
                Share your own recommendations and inside tips to truly create a
                network of destinations full of hidden gems and must-dos. With
                Travelpass, you&apos;ll always know where to stay, what to do,
                and where to eat, no matter where you’re traveling.
              </p>
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <Divider />

      <div className='bg-warmGrey py-10'>
        <PageLayoutContainer>
          <div className='flex w-full flex-col flex-col-reverse gap-4 lg:flex-row lg:items-center'>
            <div className='flex basis-1/2 flex-col gap-2'>
              <p className='type-overline c-valley uppercase'>
                Plan A Pre-Game Adventure
              </p>
              <h3 className='type-h1 lg:w-400px mb-2'>
                Get More Out Of Every Home Game
              </h3>
              <p className='type-body-1'>
                We feel immensely proud to be partners with the SL Bees. As a
                local company eager to explore our own backyard as much as our
                favorite cities in the U.S., we aim to share a better travel
                experience with everyone, especially with those who also cheer
                on our favorite baseball team.
              </p>
              <p className='type-body-1'>
                Travelpass makes every home game an opportunity for adventure.
                <Link
                  href='/inside-scoop/salt-lake-city#salt-lake-bees'
                  label='Get the inside scoop'
                  target='_blank'
                />{' '}
                about a new restaurant to try before the game, or head to the
                city early to truly explore Salt Lake before (loudly) cheering
                on the Bees.
              </p>
              <p className='type-body-1'>
                Travelpass is your key to traveling like an insider, knowing
                you&apos;ll experience the best of the best.
              </p>
            </div>
            <div className='basis-1/2'>
              <img
                alt='Smith’s Ballpark in Salt Lake City, Utah'
                className='w-98% lg:w-90% mx-a rounded-tr-8 rounded-bl-8 outline-offset-6 outline-valley mb-4 block shadow-lg outline-dashed outline-1 lg:mb-0'
                src={`${imagePath}sl-bees-01.webp`}
              />
            </div>
          </div>
        </PageLayoutContainer>
      </div>

      <Divider />

      <div className='bg-warmGrey py-10'>
        <PageLayoutContainer>
          <div className='flex w-full flex-col gap-4 lg:flex-row lg:items-center'>
            <div className='basis-1/2'>
              <img
                alt='Salt Lake Bees baseball'
                className='w-98% lg:w-90% mx-a rounded-tr-8 rounded-bl-8 outline-offset-6 outline-valley mb-4 block shadow-lg outline-dashed outline-1 lg:mb-0'
                src={`${imagePath}sl-bees-02.webp`}
              />
            </div>
            <div className='flex basis-1/2 flex-col items-center gap-2 lg:items-start'>
              <p className='type-overline c-valley self-start uppercase'>
                Adventure Awaits
              </p>
              <h3 className='type-h1 lg:w-400px mb-2 self-start'>
                Eager For More?
              </h3>
              <p className='type-body-1 mb-8 self-start'>
                There’s really no excuse not to book a quick getaway, an epic
                road trip, or finally make that family vacation happen now that
                we’ve done the trip planning for you! Easily find top-rated
                hotels and incredible activities in all of your favorite cities
                with Travelpass itineraries.
              </p>
              <Button
                label='Free Itineraries Here'
                size='large'
                onClick={onGetItineraryClick}
              />
            </div>
          </div>
        </PageLayoutContainer>
      </div>
    </>
  )
}

const metaDescription =
  'Welcome, Salt Lake Bees fans! Discover, organize and share your favorite places, activities, and restaurants with Travelpass!'
const pageName = 'Welcome, Bees Fans!'
