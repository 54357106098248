import { useLayoutEffect, useState } from 'react'
import { Button, Divider } from '@travelpass/design-system'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import type { MappedRoom, StandardHotel } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  constructSupplier,
  getPrice,
  stringToNumber,
  getPriceWithDigits,
} from 'src/utils'
import { RoomRatesCancelationPolicyRadio } from './RoomRatesCancelationPolicyRadio'
import { RoomRatesPaymentOptionsRadio } from './RoomRatesPaymentOptionsRadio'
import {
  getBookingPageUrl,
  getPaymentTypeOptionsAvailable,
} from './hotelRoomRateUtils'
import type { HotelRoom as HotelRoomType } from '../hotelRoomsTypes'

interface HotelRoomRatesProps {
  hotelName: StandardHotel['name']
  rates: HotelRoomType['rates']
  roomName: MappedRoom['name']
}

export const HotelRoomRates = ({
  hotelName,
  rates,
  roomName,
}: HotelRoomRatesProps) => {
  const navigate = useNavigate()
  const { hotelId } = useParams()
  const [searchParams] = useSearchParams()
  const isHotelRateProviderNameEnabled = useFlag(
    'isHotelRateProviderNameEnabled'
  )
  const cancellationPolicyOptions = [
    ...new Set(rates.map(rate => rate.cancelationPolicy.refundType)),
  ]

  const [selectedRateToken, setSelectedRateToken] = useState(null)
  const [selectedCancelationPolicy, setSelectedCancelationPolicy] = useState(
    cancellationPolicyOptions[0]
  )
  const rateProviderName = constructSupplier(selectedRateToken)
  const ratesPaymentTypeOptions = rates.filter(
    rate => rate.cancelationPolicy.refundType === selectedCancelationPolicy
  )

  const selectedRate = rates.find(rate => rate.rateToken === selectedRateToken)

  useLayoutEffect(() => {
    setSelectedCancelationPolicy(cancellationPolicyOptions[0])
    setSelectedRateToken(getSelectedRateTokenByLowestNightlyAverage())
  }, [rates])

  const paymentTypeOptions = getPaymentTypeOptionsAvailable(
    ratesPaymentTypeOptions
  )
  const { payNow, payLater } = paymentTypeOptions

  const grandTotal = selectedRate?.allInTotal?.amount

  const onClickReserve = () => {
    pushDataToDataLayer('select_room', {
      currency: selectedRate?.currencyCode,
      item_category: 'Hotel',
      item_id: hotelId,
      item_name: hotelName,
      item_variant: roomName,
      price: selectedRate?.nightlyAverage,
      value: grandTotal,
    })
    navigate(
      getBookingPageUrl({
        hotelId,
        rateToken: selectedRateToken,
        searchParams,
      })
    )
  }

  useLayoutEffect(() => {
    setSelectedRateToken(getSelectedRateTokenByLowestNightlyAverage())
  }, [selectedCancelationPolicy])

  const handlePaymentTypeClick = (rateToken: string) =>
    setSelectedRateToken(rateToken)

  const getSelectedRateTokenByLowestNightlyAverage = () => {
    const payNowGrandTotal = stringToNumber(payNow?.grandtotal ?? '0')
    const payLaterGrandTotal = stringToNumber(payLater?.grandtotal ?? '0')

    if (!payLaterGrandTotal) return payNow?.rateToken

    if (!payNowGrandTotal) return payLater?.rateToken

    if (payLaterGrandTotal >= payNowGrandTotal) return payNow?.rateToken

    return payLater?.rateToken ?? ratesPaymentTypeOptions[0]?.rateToken
  }

  return (
    <div className='p-4 lg:p-0'>
      <div className='sm:pr-0 lg:pr-10'>
        <h6 className='type-h6 my-3'>Cancellation Policy</h6>
        <div className='grid grid-cols-1 gap-3'>
          {cancellationPolicyOptions.map((option, index) => (
            <RoomRatesCancelationPolicyRadio
              key={index}
              rates={rates.filter(
                rate => rate.cancelationPolicy.refundType === option
              )}
              refundType={option}
              selectedCancelationPolicy={selectedCancelationPolicy}
              selectedRateGrandTotal={Number(grandTotal)}
              onClick={() => setSelectedCancelationPolicy(option)}
            />
          ))}
        </div>
      </div>
      <div className='sm:pr-0 lg:pr-10'>
        <h6 className='type-h6 my-3'>Payment Options</h6>
        <div className='grid grid-cols-1 gap-3 lg:grid-cols-2'>
          <RoomRatesPaymentOptionsRadio
            label='Pay Now'
            paymentTypeOptions={payNow}
            selectedOptionGrandTotal={payLater.grandtotal}
            selectedRateToken={selectedRateToken}
            onClick={handlePaymentTypeClick}
          />
          <RoomRatesPaymentOptionsRadio
            label='Pay Later'
            paymentTypeOptions={payLater}
            selectedOptionGrandTotal={payNow.grandtotal}
            selectedRateToken={selectedRateToken}
            onClick={handlePaymentTypeClick}
          />
        </div>
      </div>
      <div className='flex flex-col gap-4 py-4 text-left'>
        <div className='block lg:hidden'>
          <Divider />
        </div>
        <div className='type-h2'>
          {getPrice(selectedRate?.nightlyAverage?.toString())}
          <span className='c-grey-700 type-body-1'>&nbsp;per night</span>
        </div>
        <div className='lg:flex-justify-between lg:grid-items-center flex sm:flex-col sm:gap-2 lg:flex-row'>
          <div>
            Total: {getPriceWithDigits(grandTotal)}
            &nbsp;(includes taxes and fees)
          </div>
          {isHotelRateProviderNameEnabled && (
            <div className='type-subtitle-2 font-600'>{rateProviderName}</div>
          )}
        </div>
        <div>
          <Button
            fullWidth
            isDisabled={!selectedRateToken}
            label='Reserve'
            variant='filled'
            onClick={onClickReserve}
          />
        </div>
      </div>
    </div>
  )
}
