import { Helmet } from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { MobileBottomNav } from 'src/common/components/MobileBottomNav'
import { ExploreHero } from './components/ExploreHero'
import { Foods } from './components/Foods'
import { Outdoors } from './components/Outdoors'
import { Park } from './components/Park'
import { TagsSection } from './components/Tags'
import { Trending } from './components/Trending'
import { ProfileModalListener } from '../guides/results/ProfileModalListener'

export const Explore = () => {
  return (
    <div className='space-y-10 md:space-y-20'>
      <Helmet pageName='Explore' />
      <ExploreHero />
      <Trending />
      <Foods />
      <Outdoors />
      <div className='bg-top-center md:space-y-30 space-y-16 bg-[url(https://static.travelpass.com/assets/explore/footer-bg.webp)] bg-cover bg-no-repeat py-12 text-center md:bg-[url(https://static.travelpass.com/assets/explore/footer-bg.webp)] md:py-20'>
        <Park />
        <TagsSection />
      </div>
      <MobileBottomNav />
      <AddToCollectionsModalListener />
      <ProfileModalListener />
    </div>
  )
}
