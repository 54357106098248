import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { constructAddress } from 'src/utils'

const createGuideDraftMutation = gql(`
  mutation CreateGuideDraftMutationInGuideDraft($createGuideDraftInput: CreateGuideDraftInput!, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    createGuideDraft(input: $createGuideDraftInput) {
      guideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          state
          zipcode
        }
        description
        guide {
          id
        }
        insertedAt
        images {
          id
          source
          url
        }
        imageUrl
        name
        owner {
          id
          userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
            totalCount
          }
        }
        ownerProfile {
          id
        }
      }
    }  
  }
`)

export const useCreateGuideDraftMutation = () =>
  useMutation(createGuideDraftMutation, {
    onCompleted: data => {
      const { addresses, description, guide, id, insertedAt, name, owner } =
        data?.createGuideDraft?.guideDraft ?? {}
      const address = constructAddress({
        addressFirstLine: addresses?.[0]?.addressLine1,
        city: addresses?.[0]?.city,
        state: addresses?.[0]?.state,
      })

      if (!id) return

      pushDataToDataLayer('guide_create', {
        guide_draft_id: id,
        guide_description: description,
        guide_id: guide?.id,
        guide_location: address,
        guide_name: name,
        inserted_at: insertedAt,
        trigger_url: window.location.href,
        user_id: owner?.id,
      })
    },
  })
