import { useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  Tile,
  useSnackbar,
} from '@travelpass/design-system'
import type { ProfileByAccountHandleQuery } from 'src/__generated__/graphql'
import { useUpsertCurrentUserProfile } from 'src/pages/profile/components/hooks/useUpsertCurrentUserProfile'
import { useGetDashboardBannerImagesQuery } from '../../useGetDashboardBannerImagesQuery'

interface DashboardProfileBannerModalProps {
  onDismiss: VoidFunction
  profile: ProfileByAccountHandleQuery['viewUserProfile']
}

export const DashboardProfileBannerModal = ({
  onDismiss,
  profile,
}: DashboardProfileBannerModalProps) => {
  const { addSuccessSnack } = useSnackbar()
  const [selectedId, setSelectedId] = useState(profile?.activeBannerImage?.id)
  const [updateProfileInfo] = useUpsertCurrentUserProfile()
  const { bannerImagesData, isLoading, hasError } =
    useGetDashboardBannerImagesQuery()
  const { profileImageSource, profileImageUrl, tags } = profile ?? {}

  const onSubmit = async () => {
    if (selectedId) {
      try {
        await updateProfileInfo({
          variables: {
            input: {
              userProfileRequest: {
                bannerImageId: selectedId,
                profileImageSource,
                profileImageUrl,
                profileTagIds: tags?.map(tag => tag?.id) || [],
              },
            },
          },
        })
        addSuccessSnack({
          title: 'Profile updated!',
        })
        onDismiss()
      } catch (error) {
        console.error(error)
      }
    }
  }

  if (!bannerImagesData?.length || hasError || isLoading || !selectedId) return

  return (
    <Modal title='Edit Banner Image' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-x-2 md:p-x-0 flex flex-col space-y-6 lg:space-y-10'>
          {bannerImagesData?.map(bannerImage => (
            <Tile
              key={bannerImage?.id}
              pressed={selectedId === bannerImage?.id}
              onClick={() =>
                selectedId !== bannerImage?.id && setSelectedId(bannerImage?.id)
              }
            >
              <img
                alt=''
                className='rounded-2 max-h-48 w-full object-cover'
                draggable={false}
                src={bannerImage?.extraSmallUrl}
              />
            </Tile>
          ))}
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button autoFocus={true} onClick={onSubmit}>
          Save Image
        </Button>
      </ModalActions>
    </Modal>
  )
}
