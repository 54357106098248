import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { hasUserEnteredCompetition } from 'src/pages/competition/utils'

const CHECK_PROFILE_IN_COMPETITION = gql(`
 query GetIsProfileInCompetition($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      competitionInfo {
        id
        status
      }
    }
  }     
`)

export const useIsProfileInCompetition = (
  accountHandle: string,
  skip: boolean = false
) => {
  const { data, loading, error } = useQuery(CHECK_PROFILE_IN_COMPETITION, {
    variables: {
      accountHandle,
    },
    skip,
  })

  const enteredCompetition =
    data &&
    !!hasUserEnteredCompetition(data?.viewUserProfile?.competitionInfo?.status)

  return {
    data: enteredCompetition,
    loading,
    error,
  }
}
