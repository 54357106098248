import type { ReactNode } from 'react'
import classNames from 'classnames'

interface GuideDraftGroupProps {
  children: ReactNode
  className?: string
}

export const GuideDraftGroup = ({
  children,
  className = '',
}: GuideDraftGroupProps) => (
  <section
    className={classNames(
      'md:bg-warm-grey md:p-x-6 md:p-y-4 md:rounded-3 space-y-3',
      className
    )}
  >
    {children}
  </section>
)
