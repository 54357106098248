import { Icon, Tile } from '@travelpass/design-system'
import { GuideDraftPublishHero } from './GuideDraftPublishHero'
import type {
  GuideDraftPublishFormValues,
  UseGuideDraftPublishForm,
} from './types'
import { GuideDraftGroup, GuideDraftGroupHeader } from '../GuideDraftGroup'

interface GuideDraftPublishReviewProps {
  formValues: GuideDraftPublishFormValues
  onStepChange: UseGuideDraftPublishForm['onStepChange']
}

export const GuideDraftPublishReview = ({
  formValues,
  onStepChange,
}: GuideDraftPublishReviewProps) => {
  return (
    <GuideDraftGroup>
      <div className='space-y-3'>
        <GuideDraftGroupHeader
          subtitle='Confirm your updates below to publish your Guide so that it will be viewable on your profile and searchable on travelpass.com'
          title="You're almost there!"
        />
        <div className='flex flex-col gap-8 md:flex-row'>
          <div className='h-40 md:h-auto md:w-72'>
            <GuideDraftPublishHero formValues={formValues} />
          </div>
          <div className='flex grow flex-col space-y-3'>
            <Tile pressed={false} size='small' onClick={() => onStepChange(1)}>
              <div className='flex w-full flex-row items-center gap-4'>
                <div className='color-valley'>
                  <Icon name='checkCircleOutline' />
                </div>
                <span className='type-body-1-desktop color-grey-800'>
                  Title
                </span>
              </div>
            </Tile>
            <Tile pressed={false} size='small' onClick={() => onStepChange(1)}>
              <div className='flex flex-row items-center gap-4'>
                <div className='color-valley'>
                  <Icon name='checkCircleOutline' />
                </div>
                <span className='type-body-1-desktop color-grey-800'>
                  Description
                </span>
              </div>
            </Tile>
            <Tile pressed={false} size='small' onClick={() => onStepChange(2)}>
              <div className='flex flex-row items-center gap-4'>
                <div className='color-valley'>
                  <Icon name='checkCircleOutline' />
                </div>
                <span className='type-body-1-desktop color-grey-800'>
                  Location
                </span>
              </div>
            </Tile>
            <Tile pressed={false} size='small' onClick={() => onStepChange(2)}>
              <div className='flex flex-row items-center gap-4'>
                <div className='color-valley'>
                  <Icon name='checkCircleOutline' />
                </div>
                <span className='type-body-1-desktop color-grey-800'>Tags</span>
              </div>
            </Tile>
            <Tile pressed={false} size='small' onClick={() => onStepChange(3)}>
              <div className='flex flex-row items-center gap-4'>
                <div className='color-valley'>
                  <Icon name='checkCircleOutline' />
                </div>
                <span className='type-body-1-desktop color-grey-800'>
                  Images
                </span>
              </div>
            </Tile>
          </div>
        </div>
      </div>
    </GuideDraftGroup>
  )
}
