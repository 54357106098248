import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { LodgingIdType } from 'src/__generated__/graphql'
import { BookingDetailsRoom } from './BookingDetailsRoom'
import { BookingDetailsRoomApolloLoading } from './BookingDetailsRoomApolloLoading'
import type {
  BookingDetailsRoomHotel,
  BookingDetailsRoomNode,
} from './bookingDetailsRoomTypes'
import { useBookingDetailsIdsQuery } from '../../hooks'

const { Standard } = LodgingIdType

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsRoom(
    $hotelId: String!
    $hotelIdType: LodgingIdType!
    $nodeId: ID!
  ) {
    node(id: $nodeId) {
      ... on Booking {
        id
        bookingDetails {
          hotelDetails {
            room {
              name
              images {
                caption
                href
              }
            }
          }
        }
      }
    }
    lodging(id: $hotelId, idType: $hotelIdType) {
      id
      amenities
    }
  }
`)

export const BookingDetailsRoomApollo = () => {
  const { bookingId } = useParams()
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({
    bookingId,
  })
  const { hotelId } = bookingDetailsIdsData ?? {}
  const willSkip = !bookingId || !hotelId

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(nodeQuery, {
    skip: willSkip,
    variables: {
      hotelId,
      hotelIdType: Standard,
      nodeId: bookingId,
    },
  })

  const { node, lodging } = data ?? {}
  const bookingDetailsData = node as BookingDetailsRoomNode
  const bookingDetailsHotelData = lodging as BookingDetailsRoomHotel

  if (isLoading || willSkip) return <BookingDetailsRoomApolloLoading />

  if (hasError) return

  return (
    <BookingDetailsRoom
      bookingDetailsData={bookingDetailsData}
      bookingDetailsHotelData={bookingDetailsHotelData}
    />
  )
}
