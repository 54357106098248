import { Avatar } from '@travelpass/design-system'
import type { UseGetDashboardFeedQuery } from 'src/pages/dashboard/useGetDashboardFeedQuery'
import { FollowButton } from 'src/pages/profile/components/FollowButton/FollowButton'
import { getProfilePageUrl } from 'src/pages/profile/components/profileUtils'

interface DashboardFeedSuggestedFollowerProps {
  follower: UseGetDashboardFeedQuery['dashboardFeedSuggestedFollowersData'][0]
}

export const DashboardFeedSuggestedFollower = ({
  follower,
}: DashboardFeedSuggestedFollowerProps) => {
  const {
    accountHandle,
    displayName,
    followerFollowingCount,
    guideCount,
    isFollowed,
    profileImageUrl,
    userId,
  } = follower ?? {}
  const ownerUrl = `${window.location.origin}${getProfilePageUrl(follower?.accountHandle)}`

  if (!accountHandle) return

  return (
    <div
      className='min-w-35 flex select-none flex-col items-center gap-3'
      data-testid='DashboardFeedSuggestedFollower'
    >
      <a
        className='h-35.875 no-underline'
        href={ownerUrl}
        rel='noreferrer'
        target='_blank'
      >
        <div className='hidden md:block'>
          <Avatar border='sm' size='lg' src={profileImageUrl} />
        </div>
        <div className='md:hidden'>
          <Avatar border='sm' size='md' src={profileImageUrl} />
        </div>
      </a>
      <div className='flex flex-col items-center space-y-1'>
        <h5 className='type-h6-mobile md:type-h5-desktop text-center'>
          <a
            className='c-forest-light no-underline'
            href={ownerUrl}
            rel='noreferrer'
            target='_blank'
          >
            {accountHandle}
          </a>
        </h5>
        <div className='flex flex-row items-center gap-2'>
          <p className='c-forest m-0 flex flex-col text-center'>
            <span className='type-body-1-medium-mobile md:type-button-desktop'>
              {followerFollowingCount?.countOfFollowers}
            </span>
            <span className='type-subtext md:type-small-text-desktop'>
              Followers
            </span>
          </p>
          <p className='c-forest m-0 flex flex-col text-center'>
            <span className='type-body-1-medium-mobile md:type-button-desktop'>
              {guideCount}
            </span>
            <span className='type-subtext md:type-small-text-desktop'>
              Guides
            </span>
          </p>
        </div>
      </div>
      <FollowButton
        displayName={displayName}
        fullWidth={true}
        isFollowed={isFollowed}
        label={isFollowed ? 'Following' : 'Follow'}
        userId={userId}
        variant={isFollowed ? 'outlined' : 'filled'}
      />
    </div>
  )
}
