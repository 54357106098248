import { useLayoutEffect } from 'react'
import { Button, MenuItem, Popover } from '@travelpass/design-system'
import classNames from 'classnames'
import { useLocation, useSearchParams } from 'react-router-dom'
import { GuideSorts, KeywordSorts } from 'src/__generated__/graphql'
import { EXPLORE_SORT_SP } from '../constants'
import { enumToArray } from '../utils/enumToArray'

const VALID_SORT_OPTIONS_KEYWORD_SEARCH = [
  KeywordSorts.Relevance,
  KeywordSorts.LastUpdated,
  KeywordSorts.Oldest,
]
const VALID_SORT_OPTIONS_DEFAULT_SEARCH = [
  GuideSorts.Recommended,
  GuideSorts.LastUpdated,
  GuideSorts.Oldest,
  GuideSorts.TrendingPastDay,
  GuideSorts.TrendingPastWeek,
  GuideSorts.TrendingPastMonth,
]

const DEFAULT_SORT_OPTION_KEYWORD_SEARCH = KeywordSorts.Relevance
const DEFAULT_SORT_OPTION_DEFAULT_SEARCH = GuideSorts.Recommended

const SORT_OPTIONS_CUSTOM_LABEL_KEYWORD_SEARCH = {
  [KeywordSorts.LastUpdated]: 'Newest',
}

const SORT_OPTIONS_CUSTOM_LABEL_DEFAULT_SEARCH = {
  [GuideSorts.LastUpdated]: 'Newest',
}

export const SortBy = () => {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const sortBy = searchParams.get(EXPLORE_SORT_SP)

  const isKeywordSearch = pathname.includes('/keyword')

  const keywordSortOptions = enumToArray(KeywordSorts).filter(option =>
    VALID_SORT_OPTIONS_KEYWORD_SEARCH.some(
      validOption => validOption === option.value
    )
  )

  const defaultSortOptions = enumToArray(GuideSorts).filter(option =>
    VALID_SORT_OPTIONS_DEFAULT_SEARCH.some(
      validOption => validOption === option.value
    )
  )

  const sortOptions = isKeywordSearch ? keywordSortOptions : defaultSortOptions
  const sortOptionsCustomLabels = isKeywordSearch
    ? SORT_OPTIONS_CUSTOM_LABEL_KEYWORD_SEARCH
    : SORT_OPTIONS_CUSTOM_LABEL_DEFAULT_SEARCH

  const selectedSort = (
    isKeywordSearch ? keywordSortOptions : defaultSortOptions
  ).find(option => option.value === sortBy)

  const selectedSortLabel =
    'Sort by' +
    (selectedSort
      ? `: ${sortOptionsCustomLabels[selectedSort.value] || selectedSort.label}`
      : '...')

  const resetSortSP = () =>
    setSearchParams(
      sp => {
        sp.set(
          EXPLORE_SORT_SP,
          isKeywordSearch
            ? DEFAULT_SORT_OPTION_KEYWORD_SEARCH
            : DEFAULT_SORT_OPTION_DEFAULT_SEARCH
        )
        return sp
      },
      {
        replace: true,
      }
    )

  const emptySortSP = !sortBy
  const invalidSortKeywordSearch =
    isKeywordSearch &&
    !keywordSortOptions.some(option => option.value === sortBy)
  const invalidSortDefaultSearch =
    !isKeywordSearch &&
    !defaultSortOptions.some(option => option.value === sortBy)

  useLayoutEffect(() => {
    if (emptySortSP || invalidSortKeywordSearch || invalidSortDefaultSearch)
      resetSortSP()
  }, [emptySortSP, invalidSortKeywordSearch, invalidSortDefaultSearch])

  return (
    <Popover
      className='children:capitalize'
      placement='bottom-start'
      trigger={
        <Button
          aria-label='Sort guides'
          endIcon='expandMore'
          size='small'
          startIcon='sort'
          variant='outlined'
        >
          {selectedSortLabel}
        </Button>
      }
    >
      <div className='shadow-1 rounded-2'>
        {sortOptions.map(({ label, value }, index) => (
          <div
            key={value}
            className={classNames('overflow-y-auto bg-white', {
              'rd-t-2': index == 0,
              'rd-b-2': index == sortOptions.length - 1,
            })}
          >
            <MenuItem
              isHighlighted={value === sortBy}
              label={sortOptionsCustomLabels[value] || label}
              value={value}
              onClick={() => {
                setSearchParams(sp => {
                  sp.set(EXPLORE_SORT_SP, value)
                  return sp
                })
              }}
            />
          </div>
        ))}
      </div>
    </Popover>
  )
}
