import { Button, Illustration } from '@travelpass/design-system'
import type { UserProfile, Participant } from 'src/__generated__/graphql'
import type { ToggleVoteModalProps } from './dispatchToggleVoteModal'
interface ContestantCardProps {
  canVoteLeaderboard: Participant['canVoteLeaderboard']
  onVoteClick: VoidFunction
  voteCount: Participant['voteCount']
  userProfile: ToggleVoteModalProps &
    Pick<UserProfile, 'id' | 'introVideoEmbed'>
}

export const ContestantCard = ({
  canVoteLeaderboard,
  onVoteClick,
  voteCount,
  userProfile,
}: ContestantCardProps) => {
  const { accountHandle, displayName, profileImageUrl } = userProfile ?? {}
  const profileURL = `/profile/${accountHandle}`

  return (
    <div className='b-none b-rounded-md max-w-70.5 mx-auto h-full w-full bg-white'>
      <button
        aria-label={`Click to view ${displayName}'s video`}
        className='m-none b-none p-none of-hidden pointer-events-none relative w-full rounded-t-md bg-white'
        onClick={() => window.open(profileURL, '_blank')}
      >
        {profileImageUrl ? (
          <img
            alt={`${displayName ?? "Contestant's"} profile`}
            className='pointer-events-auto m-auto block aspect-square w-full cursor-pointer object-cover'
            src={profileImageUrl}
          />
        ) : (
          <div className='aspect-square'>
            <Illustration name='publicProfile' />
          </div>
        )}
      </button>
      <section className='space-y-5 p-4'>
        <div>
          <a
            className='decoration-none type-h5 transition-opacity-200 block w-fit truncate hover:opacity-50 active:opacity-50'
            href={profileURL}
            rel='noreferrer'
            target='_blank'
          >
            {displayName}
          </a>
          <a
            className='decoration-none c-forestLight type-body-1 transition-opacity-200 block w-fit truncate hover:opacity-50 active:opacity-50'
            href={profileURL}
            rel='noreferrer'
            target='_blank'
          >
            @{accountHandle}
          </a>
        </div>
        <div className='flex items-center justify-between'>
          <Button
            isDisabled={!canVoteLeaderboard}
            size='small'
            startIcon='thumbUpOffAlt'
            onClick={onVoteClick}
          >
            {canVoteLeaderboard ? 'Vote' : 'Voted'}
          </Button>
          <p className='type-button truncate break-words'>{voteCount} Votes</p>
        </div>
      </section>
    </div>
  )
}
