import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import type { ExperienceBooking } from 'src/__generated__/graphql'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { findMyBookingPath, tripsPath } from 'src/constants'
import { ExperiencesBookingDetailsApolloLoading } from './ExperiencesBookingDetailsApolloLoading'
import { useGetExperienceBookingDetailsQuery } from '../../common/hooks'
import { ExperiencesBookingDetails } from '../ExperiencesBookingDetails.client'

export const ExperiencesBookingDetailsApollo = () => {
  const navigate = useNavigate()
  const { isAnonymous } = useFirebaseUser()
  const { bookingId } = useParams()
  const { data, loading, error } =
    useGetExperienceBookingDetailsQuery(bookingId)

  const experienceBooking =
    data?.findExperienceBookingByExternalConfirmationId as ExperienceBooking
  const experienceProduct =
    data?.findExperienceBookingByExternalConfirmationId?.product

  useEffect(() => {
    if (error) navigate(isAnonymous ? findMyBookingPath : tripsPath)
  }, [error])

  if (error) return null
  if (loading) return <ExperiencesBookingDetailsApolloLoading />

  return (
    <ExperiencesBookingDetails
      experiencesBookingDetailsData={experienceBooking}
      experiencesBookingDetailsProductData={experienceProduct}
    />
  )
}
