import { Icon } from '@travelpass/design-system'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from 'src/pages/profile/components/hooks/useGetCurrentUserProfileAccountHandleAndAvatar'
import { ProfileAvatar } from '../../ProfileAvatar'

export const MenuTrigger = ({ onClick = undefined }) => {
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const profile = data?.currentUser?.userProfile
  return (
    <button
      aria-label='Open site menu'
      className='border-1 border-grey-300 c-forest group-[.clear]:c-white hover:shadow-1 inline-flex cursor-pointer items-center rounded-full border-solid bg-transparent p-1.5 transition-colors group-[.clear]:hover:bg-black/15'
      onClick={onClick}
    >
      {!profile?.profileImageUrl ? (
        <Icon name='person' />
      ) : (
        <ProfileAvatar className='h-8 w-8' src={profile.profileImageUrl} />
      )}
      <Icon name='expandMore' />
    </button>
  )
}
