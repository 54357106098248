import { Button, ModalNext } from '@travelpass/design-system'

interface AddABioModalProps {
  onClose: () => void
  onClick: () => void
}

export const AddABioModal = ({ onClose, onClick }: AddABioModalProps) => (
  <ModalNext
    actions={
      <div className='flex w-full justify-center'>
        <Button label='Add A Bio' size='medium' onClick={onClick} />
      </div>
    }
    size='medium'
    title='Add A Bio'
    onClose={onClose}
  >
    <section className='space-y-5'>
      <p className='type-h6'>What to include in your bio?</p>
      <p className='type-body-1'>
        Your bio should be genuine, personable, well-written, and clearly
        communicate who you are as a traveler in 375 characters (or less).
      </p>
    </section>
  </ModalNext>
)
