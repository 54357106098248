import { Skeleton } from '@travelpass/design-system'

export const DashboardHeaderLoading = () => (
  <div className='lg:h-87.5 h-35.5 lg:pl-34.5 space-y-3 bg-[radial-gradient(61.11%_100%_at_50.03%_100%,_#125E5C_26%,_#042E30_100%)] pb-8 pl-9 pr-5 pt-9 lg:pt-12'>
    <div className='mx-a max-w-452 relative'>
      <div className='hidden space-y-3 lg:block'>
        <div className='w-68 h-12'>
          <Skeleton />
        </div>
        <div className='w-71.5 h-5.5'>
          <Skeleton />
        </div>
      </div>
      {/** Mobile Header */}
      <div className='flex flex-row items-center gap-4 lg:hidden'>
        <div className='h-12 w-12'>
          <Skeleton variant='circular' />
        </div>
        <div className='space-y-3'>
          <div className='w-62.5 max-w-full space-y-1'>
            <div className='flex w-full flex-row items-center justify-between gap-3'>
              <div className='w-17 h-5'>
                <Skeleton />
              </div>
              <div className='h-4 w-4'>
                <Skeleton variant='rounded' />
              </div>
            </div>
            <div className='flex flex-row items-center gap-4'>
              <div className='h-4 w-20'>
                <Skeleton />
              </div>
              <div className='[&>button]:p-x-0 [&>button>div]:color-white [&>button>div]:type-small-text flex flex-row items-center gap-1'>
                <div className='h-4 w-20'>
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
