import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { GuideDraftImagesEditSearch } from './GuideDraftImagesEditSearch'
import { GuideDraftImagesEditSelected } from './GuideDraftImagesEditSelected'
import {
  useGuideDraftImagesEdit,
  type GuideDraftImagesEditData,
} from '../../useGuideDraftImagesEdit'
import { GuideDraftGroup, GuideDraftGroupHeader } from '../GuideDraftGroup'

interface GuideDraftImagesEditModalProps {
  guideDraftImagesEditData: GuideDraftImagesEditData
  onDismiss: VoidFunction
}

export const GuideDraftImagesEditModal = ({
  guideDraftImagesEditData,
  onDismiss,
}: GuideDraftImagesEditModalProps) => {
  const { imageError, images, isLoading, onSelected, onSubmit, onUpload } =
    useGuideDraftImagesEdit({
      guideDraftImagesEditData,
      onDismiss,
    })

  return (
    <Modal title='Add Photos' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
          <div className='space-y-3'>
            <section className='md:p-x-6 md:p-y-4 md:rounded-3 space-y-3 md:space-y-6'>
              <p className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop md:p-x-3.5'>
                Select up to three images from our stock photos or upload your
                own!
              </p>
              <div className='space-y-2'>
                <GuideDraftImagesEditSelected
                  images={images}
                  onSelected={onSelected}
                  onUpload={onUpload}
                />
                {!!imageError && (
                  <p className='c-red font-2.5 type-subtext pl-3.5'>
                    {imageError}
                  </p>
                )}
              </div>
            </section>
            <GuideDraftGroup>
              <GuideDraftGroupHeader title='Stock Photos' />
              <GuideDraftImagesEditSearch
                guideDraftImagesEditData={guideDraftImagesEditData}
                images={images}
                onSelected={onSelected}
              />
            </GuideDraftGroup>
          </div>
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button autoFocus={true} isDisabled={isLoading} onClick={onSubmit}>
          Save
        </Button>
        <Button variant='outlined' onClick={onDismiss}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
