import type { ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { SkeletonDots } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import type { Supercluster } from 'supercluster'
import { googleMapsConfig, googleMapIdsDefaultConfig } from 'src/config/maps'
import { useCreateSupercluster } from './clusters/useCreateSupercluster'
import type { MapBounds } from './constants'
import type { LatLng } from './types'

const libraries = ['marker']

interface MapProps<T extends LatLng> {
  defaultCenter: LatLng
  defaultZoom: number
  onClick?: (event: google.maps.MapMouseEvent) => void
  gestureHandling?: 'auto' | 'cooperative' | 'greedy'
  googleMapConfigId?: string
  enableScrollWheel?: boolean
  enableZoomControl?: boolean
  children?: ReactNode
  data?: T[]
  maxZoom?: number
  minZoom?: number
  // TODO - great place to center the map
  onDataChanged?: (map: google.maps.Map) => void
  onCenterChanged?: () => void
  // This is a terrible pattern
  // Please do not do this in the future
  // unless absolutely necessary
  render?: ({
    clusters,
    supercluster,
    bounds,
    zoom,
  }: {
    clusters: Supercluster.PointFeature<T>[]
    supercluster: Supercluster
    bounds: MapBounds
    zoom: number
  }) => ReactNode
  superclusterRadius?: Supercluster.Options['radius']
}

export const Map = <T extends LatLng>({
  children,
  defaultCenter,
  data,
  maxZoom = 19,
  minZoom = 0,
  onDataChanged,
  onCenterChanged,
  // TODO - I just used the default from the previous map
  defaultZoom = 13,
  gestureHandling = 'auto',
  googleMapConfigId = googleMapIdsDefaultConfig,
  onClick,
  enableScrollWheel = true,
  // TODO - This is not working properly so it is disabled
  // Zoom controls are not going to show for now
  enableZoomControl = true,
  render,
  superclusterRadius,
}: MapProps<T>) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsConfig,
    // @ts-ignore
    libraries,
  })
  // I don't understand, but if we don't hold the map center in React state
  // we get strange re-renders from the map.
  const [center, setCenter] = useState(defaultCenter)
  // TODO - trying to load the mapApi key here did not work.
  // I had to add the key in the actual map
  // mapId is required for the use of AdvancedMarkers
  const mapRef = useRef<google.maps.Map & { zoom?: number }>()
  const [startedInitialRender, setStartedInitialRender] = useState(false)
  const [zoom, setZoom] = useState<number>(defaultZoom)
  const [bounds, setBounds] = useState<MapBounds>()
  const [isMapLoaded, setIsMapLoaded] = useState(false)

  useEffect(() => {
    if (data && mapRef.current) {
      onDataChanged?.(mapRef.current)
    }
    // Terrible pattern, but nothing else worked
  }, [
    JSON.stringify(
      data?.map(d => ({
        lat: d.lat,
        lng: d.lng,
      }))
    ),
    isMapLoaded,
  ])

  const { clusters, supercluster } = useCreateSupercluster({
    bounds,
    zoom,
    radius: superclusterRadius,
    data,
  })

  const onMapBoundsChanged = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds()
      setBounds(bounds)
    }
  }

  const onZoomChanged = () => {
    if (mapRef.current && mapRef.current.zoom) {
      setZoom(mapRef.current.zoom)
    }
  }

  const debounceOnBoundsChanged = debounce(onMapBoundsChanged, 200)
  const debounceOnZoomChanged = debounce(onZoomChanged, 200)

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
    // We can't use the map until it is loaded (you can't watch a ref in an useEffect dependency array)
    // So we have to wait until a tick has passed to know we can access the map in the above useEffect
    // So don't judge me... asshole
    setTimeout(() => {
      setIsMapLoaded(true)
    })
  }, [])

  const onMapUnmount = useCallback((_map: google.maps.Map) => {
    // TODO - Not sure if we need to do anything when map unmounts
  }, [])

  const containerStyle = {
    width: '100%',
    height: '100%',
  }

  return isLoaded ? (
    <GoogleMap
      center={center}
      mapContainerStyle={containerStyle}
      options={{
        mapId: googleMapConfigId,
        // TODO - I can only get the zoom controls to work if I enable the default UI
        // Zoom currently is not working with the default UI disabled and zoomControl enable - 🤷‍♂️
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        scrollwheel: enableScrollWheel,
        zoomControl: enableZoomControl,
        maxZoom,
        minZoom,
      }}
      zoom={zoom}
      onBoundsChanged={debounceOnBoundsChanged}
      onCenterChanged={() => {
        // Not sure if this is the correct logic, but I need
        // something to inidicate the initial render happened
        if (startedInitialRender) {
          onCenterChanged?.()
        }
        setStartedInitialRender(true)
      }}
      onClick={onClick}
      onLoad={onMapLoad}
      onUnmount={onMapUnmount}
      onZoomChanged={debounceOnZoomChanged}
    >
      {render?.({ clusters, supercluster, bounds, zoom })}
      {children}
    </GoogleMap>
  ) : (
    <div className={spinnerWrapper}>
      <SkeletonDots />
    </div>
  )
}

const spinnerWrapper = 'flex items-center justify-center h-full w-full'
