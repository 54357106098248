import { useState } from 'react'
import { Button, Chip, IconButton } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import { Scroller } from 'src/common/components'
import { GuideDraftEditModal } from 'src/pages/guides/lists/common'
import { getGuideDraftDetailsUrl } from 'src/utils'
import { GuideHeaderContent } from './GuideHeaderContent'
import { GuideHeaderSummary } from './GuideHeaderSummary'
import type { GuideHeaderData } from '../../types'

interface GuideHeaderProps {
  guideHeaderData: GuideHeaderData
  isEdit: boolean
}

export const GuideHeader = ({ guideHeaderData, isEdit }: GuideHeaderProps) => {
  const navigate = useNavigate()
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const { guideDraftId, name, ownerProfile, tags } = guideHeaderData ?? {}
  const { isUserOwner } = ownerProfile ?? {}

  return (
    <>
      <header className='p-y-4 space-y-6'>
        <div className='space-y-5 md:space-y-6'>
          <div className='flex flex-row justify-between gap-2'>
            <div className='flex flex-row gap-2'>
              <h1 className='type-h3'>{name}</h1>
              {isUserOwner && isEdit && (
                <div className='m-t--1 md:m-t-0 md:self-center'>
                  <IconButton
                    aria-label='Edit Guide details'
                    color='transparent'
                    icon='modeEdit'
                    outline='round'
                    size='medium'
                    onClick={() => setIsModalEditOpen(true)}
                  />
                </div>
              )}
            </div>
            {isUserOwner && !isEdit && (
              <>
                <div className='min-w-39 hidden md:block'>
                  <Button
                    aria-label='Edit this Guide'
                    color='transparent'
                    size='small'
                    startIcon='modeEdit'
                    onClick={() =>
                      navigate(
                        getGuideDraftDetailsUrl({
                          id: guideDraftId,
                          isUserOwner,
                          name,
                        })
                      )
                    }
                  >
                    Edit Guide
                  </Button>
                </div>
                <div className='md:hidden'>
                  <IconButton
                    aria-label='Edit this Guide'
                    color='forestLight'
                    icon='modeEdit'
                    outline='round'
                    size='small'
                    onClick={() =>
                      navigate(
                        getGuideDraftDetailsUrl({
                          id: guideDraftId,
                          isUserOwner,
                          name,
                        })
                      )
                    }
                  />
                </div>
              </>
            )}
          </div>
          <GuideHeaderContent
            guideHeaderData={{
              ...guideHeaderData,
            }}
          />
          {!isEmpty(tags) && (
            <Scroller
              allowTouch={true}
              hideScrollBar={true}
              scrollContainerClassName='flex hidden flex-row items-center gap-1'
            >
              {tags?.map(({ id, name }) => (
                <div key={id} className='pointer-events-none'>
                  <Chip label={name} onClick={() => {}} />
                </div>
              ))}
            </Scroller>
          )}
          <GuideHeaderSummary
            guideHeaderData={{ ...guideHeaderData }}
            isEdit={isEdit}
          />
        </div>
      </header>
      {isModalEditOpen && (
        <GuideDraftEditModal
          guideDraftData={{ ...guideHeaderData, id: guideDraftId }}
          onDismiss={() => setIsModalEditOpen(false)}
        />
      )}
    </>
  )
}
