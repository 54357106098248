import { useState } from 'react'
import {
  Button,
  Illustration,
  Input,
  Modal,
  ModalActions,
  ModalScrollContents,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import type { GuideDraft } from 'src/__generated__/graphql'
import { Geocoder } from 'src/common/components'
import { initialGeocoder, type GeocoderType } from 'src/constants/user'
import { constructGeocoderFromAddress } from 'src/pages/trips/utils'
import { getUpdateGuideDraftVariablesOld } from '../../lists-old/guideDraftUtils'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from '../guideDraftConstants'
import { useUpdateGuideDraftMutation } from '../useUpdateGuideDraftMutation'

interface GuideDraftEditModalOldProps {
  guideDraftData: Pick<
    GuideDraft,
    'addresses' | 'description' | 'guide' | 'id' | 'name'
  >
  onDismiss?(): void
}

export const GuideDraftEditModalOld = ({
  guideDraftData,
  onDismiss,
}: GuideDraftEditModalOldProps) => {
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)
  const [isNameInvalid, setIsNameInvalid] = useState(false)
  const [isGeocoderInvalid, setIsGeocoderInvalid] = useState(false)
  const [name, setName] = useState(guideDraftData?.name ?? '')
  const [geocoder, setGeocoder] = useState(
    constructGeocoderFromAddress(guideDraftData?.addresses?.[0]) ??
      initialGeocoder
  )
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [description, setDescription] = useState(
    guideDraftData?.description ?? ''
  )
  const [updateGuideDraft] = useUpdateGuideDraftMutation()
  const { guide, id } = guideDraftData ?? {}

  const onGeocoderChange = (updatedGeocoder: GeocoderType) => {
    setGeocoder(updatedGeocoder)
  }

  const onSubmit = async () => {
    const validatedDescription = description?.trim() ?? null
    const validatedName = name?.trim() ?? null
    const updatedIsDescriptionInvalid =
      validatedDescription?.indexOf('http') !== -1 ||
      (guide?.id ? !validatedDescription : false)
    const updatedIsGeocoderInvalid =
      !!guide?.id && geocoder?.center?.length === 0
    const updatedIsNameInvalid = !validatedName
    setIsDescriptionInvalid(updatedIsDescriptionInvalid)
    setIsGeocoderInvalid(updatedIsGeocoderInvalid)
    setIsNameInvalid(updatedIsNameInvalid)

    if (
      !updatedIsDescriptionInvalid &&
      !updatedIsGeocoderInvalid &&
      !updatedIsNameInvalid
    ) {
      try {
        await updateGuideDraft({
          variables: getUpdateGuideDraftVariablesOld({
            description: validatedDescription,
            geocoder,
            guideDraftId: id,
            name: validatedName,
          }),
        })
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide has been updated',
        })
        onDismiss()
      } catch (error) {
        console.error(error)
        addErrorSnack({
          timeout: 1000,
          title: 'Server error',
        })
      }
    }
  }

  return (
    <Modal size='medium' title='Edit Guide Details' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='space-y-3 p-8 lg:flex lg:flex-row'>
          <div className='lg:min-w-50 lg:p-t-17 lg:w-50'>
            <div className='m-a w-30'>
              <Illustration name='guide' />
            </div>
          </div>
          <div className='w-full space-y-4'>
            <Input
              aria-label='Add a name for your Guide'
              errorText={isNameInvalid && 'Guide Name is required'}
              fullWidth={true}
              helperText={`${name.length}/${guideDraftNameMaxLength} characters`}
              isInvalid={isNameInvalid}
              label='Guide Name'
              maxLength={guideDraftNameMaxLength}
              placeholder='e.g. Best Restaurants in Seattle'
              required={true}
              value={name}
              onChange={event => setName(event.target.value)}
            />
            <Geocoder
              aria-label='Add a location for your Guide'
              config={{
                requestOptions: {
                  componentRestrictions: {
                    country: null,
                  },
                },
              }}
              errorText={isGeocoderInvalid && 'Guide Location is required'}
              focusOnInput={false}
              fullWidth={true}
              geocoder={geocoder}
              label='Location'
              placeholder='e.g., Salt Lake City, Utah'
              required={!!guide?.id}
              onResult={onGeocoderChange}
            />
            <p className='type-small-text-desktop'>
              Write a quick description about your recommendation guide and
              express your excitement to others. Let them know why it&apos;s got
              you excited!
            </p>
            <TextArea
              aria-label='Add a description for your guide'
              errorText={
                isDescriptionInvalid &&
                (guide?.id
                  ? 'Guide Description is required and cannot contain URLs'
                  : 'Guide Description cannot contain URLs')
              }
              helperText={`${description.length}/${guideDraftDescriptionMaxLength} characters`}
              maxLength={guideDraftDescriptionMaxLength}
              required={!!guide?.id}
              value={description ?? ''}
              onInput={event => setDescription(event.currentTarget.value)}
            />
          </div>
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button
          aria-label='Save Guide changes'
          autoFocus={true}
          size='large'
          onClick={onSubmit}
        >
          Save
        </Button>
        <Button
          aria-label='Cancel Guide changes'
          size='large'
          variant='outlined'
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
