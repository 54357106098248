import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const incrementGuideEventLikeCountMutation = gql(`
  mutation incrementGuideEventLikeCountMutation($publishedEventId: ID!) {
    likePublishedEvent(publishedEventId: $publishedEventId) {
      id
      likedByCurrentUser
      publishedEventLikedByUsers(first: 1) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  }
`)

export const useIncrementGuideEventLikeCountMutation = () =>
  useMutation(incrementGuideEventLikeCountMutation)
