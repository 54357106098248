import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import classNames from 'classnames'

interface GuideDraftGroupProps extends ComponentPropsWithoutRef<'section'> {
  children: ReactNode
  className?: string
}

export const GuideDraftGroup = ({
  children,
  className = '',
  ...props
}: GuideDraftGroupProps) => (
  <section
    className={classNames(
      'md:bg-warm-grey md:p-x-6 md:p-y-4 md:rounded-3 space-y-3',
      className
    )}
    {...props}
  >
    {children}
  </section>
)
