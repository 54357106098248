import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

export const USER_PROFILE_INPUT_FIELDS_FRAGMENT = gql(`
  fragment UserProfileInputFields on UserProfile {
    id
    accountHandle
    activeBannerImage {
      id
    }
    bio
    bragCities
    bragContinents
    bragCountries
    bragStates
    displayName
    introVideoEmbed
    links {
      title
      url
    }
    profileImageSource
    profileImageUrl
    socialLinks {
      identifier
      network
    }
    tags {
      id
    }
    visibilityLevel
    competitionInfo {
      id
      status
    }
  }
`)

const GET_CURRENT_USER_COMPETITION_INFO = gql(`
  query CurrentUserCompetitionInfo {
    currentUser {
      id
      userProfile {
        ...UserProfileInputFields
      }
    }
  }
`)

export const useGetCurrentUserCompetitionInfo = () =>
  useQuery(GET_CURRENT_USER_COMPETITION_INFO)
