export const extractViewport = (viewport: google.maps.LatLngBounds) => ({
  ne: {
    lat: viewport.getNorthEast().lat(),
    lng: viewport.getNorthEast().lng(),
  },
  sw: {
    lat: viewport.getSouthWest().lat(),
    lng: viewport.getSouthWest().lng(),
  },
})
