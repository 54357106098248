import { useState } from 'react'
import { Button, IconButton, useSnackbar } from '@travelpass/design-system'
import type {
  ButtonSize,
  ButtonVariant,
  IconButtonProps,
} from '@travelpass/design-system'
import { getGuideMetricString } from './'
import type { GuideData } from '../../details/types'
import { useIncrementGuideShareCountMutation } from '../../useIncrementGuideShareCountMutation'
import { useShareGuide } from '../../useShareGuide'

interface GuideMetricShareProps {
  color?: IconButtonProps['color']
  guideData: Pick<GuideData, 'id' | 'name' | 'shareCount' | 'ownerProfile'>
  hideLabel?: boolean
  isDisabled?: boolean
  isIconButton?: boolean
  size?: ButtonSize | IconButtonProps['size']
  variant?: ButtonVariant
}

/** @todo extract business logic into a custom hook, leave the styling up to the component using the hook */
export const GuideMetricShare = ({
  color = 'forestLight',
  guideData,
  hideLabel = false,
  isDisabled = false,
  isIconButton = false,
  size = 'medium',
  variant = 'text',
}: GuideMetricShareProps) => {
  const shareGuide = useShareGuide()
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const [hasShared, setHasShared] = useState(false)
  const { id, name, shareCount, ownerProfile } = guideData ?? {}
  const { isUserOwner } = ownerProfile ?? {}

  const onShareGuide = async () => {
    await shareGuide({
      guideDraftTitle: name,
      isUserOwner,
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({ timeout: 1000, title: 'Guide URL copied' })
      },
    })
    if (!hasShared) {
      incrementShareCount({ variables: { guideId: id } })
      setHasShared(true)
    }
  }

  return isIconButton ? (
    <IconButton
      aria-label='Share this guide'
      color={color}
      data-testid='GuideMetricShare-icon-button'
      icon='iosShare'
      isDisabled={isDisabled}
      outline='round'
      size={size as IconButtonProps['size']}
      onClick={onShareGuide}
    />
  ) : (
    <Button
      aria-label='Share this guide'
      data-testid='GuideMetricShare-button'
      isDisabled={isDisabled}
      size={size}
      startIcon='iosShare'
      variant={variant}
      onClick={onShareGuide}
    >
      {getGuideMetricString(shareCount, hideLabel ? '' : 'share')}
    </Button>
  )
}
