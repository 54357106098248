import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const removeGuideDraftImagesMutation = gql(`
  mutation RemoveGuideDraftImagesOld($input: RemoveGuideDraftImagesInput!) {
    removeGuideDraftImages(input: $input) {
      guideDraft {
        id
        guide {
          id
          images {
            id
          }
        }
      }
    }
  }
`)

export const useRemoveGuideDraftImagesMutation = () =>
  useMutation(removeGuideDraftImagesMutation)
