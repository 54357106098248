import {
  Avatar,
  Button,
  Icon,
  IconButton,
  ProgressBar,
} from '@travelpass/design-system'
import { getProfilePageUrl } from 'src/pages/profile/components/profileUtils'
import type { FollowersModalType } from 'src/pages/profile/types'
import { getDashboardHeaderGreeting } from '../../dashboardUtils'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardMobileHeaderProps {
  currentUser: UseGetDashboardQuery['dashboardData']['currentUser']
  onScrollIdChange: (updatedScrollId: string) => void
  onModalInfoOpen: VoidFunction
  toggleFollowersModal: (modalType: FollowersModalType) => void
}

export const DashboardMobileHeader = ({
  currentUser,
  onScrollIdChange,
  onModalInfoOpen,
  toggleFollowersModal,
}: DashboardMobileHeaderProps) => {
  const { userProfile, firstName } = { ...currentUser }
  const { profileCompletion } = userProfile ?? {}
  const { completionPercentage } = profileCompletion ?? {}
  const ownerUrl = `${window.location.origin}${getProfilePageUrl(userProfile?.accountHandle)}`
  const isProfileComplete = completionPercentage === 1
  const formattedCompletionPercentage = completionPercentage * 100

  return (
    <div className='flex flex-row items-center gap-4 lg:hidden'>
      <a
        className='[&>img]:border-1 self-start'
        href={ownerUrl}
        rel='noreferrer'
        target='_blank'
      >
        <Avatar border='sm' size='sm' src={userProfile?.profileImageUrl} />
      </a>
      <div className='w-62.5 max-w-full space-y-3'>
        <div className='space-y-1'>
          <div className='flex w-full flex-row items-center justify-between gap-3'>
            <span className='type-h4-mobile c-white'>
              {getDashboardHeaderGreeting({
                firstName,
                isMobile: true,
                isProfileComplete,
              })}
            </span>
            <div className='lg:hidden [&>button]:bg-transparent [&>button]:shadow-none'>
              <IconButton
                color='forestLight'
                icon='modeEdit'
                size='small'
                onClick={() => {
                  onScrollIdChange('')
                  onModalInfoOpen()
                }}
              />
            </div>
          </div>
          {!isProfileComplete && (
            <p className='type-body-2 color-white'>
              Your profile is almost complete!
            </p>
          )}
          {isProfileComplete && (
            <div className='flex flex-row items-center gap-4'>
              <div className='[&>button]:p-x-0 [&>button>div]:color-white [&>button>div]:type-small-text flex flex-row items-center gap-1'>
                <p className='type-body-1-medium color-white'>
                  {userProfile?.followCount?.countOfFollowing ?? 0}
                </p>
                <Button
                  size='small'
                  variant='text'
                  onClick={() => toggleFollowersModal('followers')}
                >
                  Followers
                </Button>
              </div>
              <div className='[&>button]:p-x-0 [&>button>div]:color-white [&>button>div]:type-small-text flex flex-row items-center gap-1'>
                <p className='type-body-1-medium color-white'>
                  {userProfile?.followCount?.countOfFollowed ?? 0}
                </p>
                <Button
                  size='small'
                  variant='text'
                  onClick={() => toggleFollowersModal('following')}
                >
                  Following
                </Button>
              </div>
            </div>
          )}
        </div>
        {!isProfileComplete && (
          <button
            className='w-full space-y-3 border-none bg-transparent p-0 outline-none hover:cursor-pointer'
            onClick={() => {
              onScrollIdChange('')
              onModalInfoOpen()
            }}
          >
            <div className='[&>i]:color-white flex flex-row items-center gap-3'>
              <p className='type-body-2-desktop color-white text-left'>
                Complete your Profile
              </p>
              <Icon name='arrowForward' size='small' />
            </div>
            <div className='w-full space-y-1.5'>
              <ProgressBar
                percentage={formattedCompletionPercentage}
                variant='dark'
              />
              <div className='type-subtext color-white text-left'>
                {formattedCompletionPercentage}% Completed
              </div>
            </div>
          </button>
        )}
      </div>
    </div>
  )
}
