import { Fragment, useState } from 'react'
import { Divider } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type {
  BookingHotelDetailsQuery,
  BookingValidateRateQuery,
} from 'src/__generated__/graphql'
import {
  HotelAmenitiesModal,
  HotelCheckInInstructionsModal,
  HotelPoliciesModal,
  LucencySupportModal,
  MenuItemWithIcon,
} from 'src/common/components'
import type { MenuItemWithIconProps } from 'src/common/components/MenuItemWithIcon'
import { isFirstIndex } from 'src/utils'

interface BookingSidebarAdditionalInfoProps {
  bookingHotelData: BookingHotelDetailsQuery
  bookingHotelLoading: boolean
  bookingRateData: BookingValidateRateQuery
}

export const BookingSidebarAdditionalInfo = ({
  bookingHotelData,
  bookingHotelLoading,
  bookingRateData,
}: BookingSidebarAdditionalInfoProps) => {
  const [isHotelAmenitiesModalOpen, setIsHotelAmenitiesModalOpen] =
    useState(false)
  const [
    isHotelCheckInInstructionsModalOpen,
    setIsHotelCheckInInstructionsModalOpen,
  ] = useState(false)
  const [isHotelPoliciesModalOpen, setIsHotelPoliciesModalOpen] =
    useState(false)
  const [isLucencySupportModalOpen, setIsLucencySupportModalOpen] =
    useState(false)
  const {
    amenities,
    checkin,
    checkinInstructions,
    checkinSpecialInstructions,
    fees,
    policies,
  } = bookingHotelData?.lodging ?? {}
  const { policies: ratePolicies } =
    bookingRateData?.validatedRate?.room?.rates?.[0] ?? {}

  const constructedAmenities = () => {
    if (isEmpty(amenities)) return ['Amenities not specified']

    return amenities
  }

  const constructedItems: MenuItemWithIconProps[] = [
    {
      endIcon: 'chevronRight',
      label: 'Hotel Amenities',
      startIcon: 'hotel',
      onClick: () => setIsHotelAmenitiesModalOpen(true),
    },
    {
      endIcon: 'chevronRight',
      label: 'Check-in Instructions',
      startIcon: 'roomService',
      onClick: () => setIsHotelCheckInInstructionsModalOpen(true),
    },
    {
      endIcon: 'chevronRight',
      label: 'Hotel Policy',
      startIcon: 'listAlt',
      onClick: () => setIsHotelPoliciesModalOpen(true),
    },
    {
      endIcon: 'chevronRight',
      label: 'Support',
      startIcon: 'phone',
      onClick: () => setIsLucencySupportModalOpen(true),
    },
  ]

  return (
    <div>
      {isHotelCheckInInstructionsModalOpen && (
        <HotelCheckInInstructionsModal
          checkin={checkin}
          checkinInstructions={checkinInstructions}
          onClose={() => setIsHotelCheckInInstructionsModalOpen(false)}
        />
      )}
      {isHotelAmenitiesModalOpen && (
        <HotelAmenitiesModal
          amenities={constructedAmenities()}
          onClose={() => setIsHotelAmenitiesModalOpen(false)}
        />
      )}
      {isHotelPoliciesModalOpen && (
        <HotelPoliciesModal
          checkinInstructions={checkinInstructions}
          checkinSpecialInstructions={checkinSpecialInstructions}
          fees={fees}
          policies={policies}
          ratePolicies={ratePolicies}
          onClose={() => setIsHotelPoliciesModalOpen(false)}
        />
      )}
      {isLucencySupportModalOpen && (
        <LucencySupportModal
          onClose={() => setIsLucencySupportModalOpen(false)}
        />
      )}
      {constructedItems.map((item, index) => (
        <Fragment key={item?.label}>
          {isFirstIndex(index) && <Divider />}
          <MenuItemWithIcon {...item} />
          <Divider />
        </Fragment>
      ))}
    </div>
  )
}
