import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import type { GuideDraft } from 'src/__generated__/graphql'
import { GuideDraftImagesEditModal } from './GuideDraftImagesEdit'

interface GudieDraftHeroEmpty {
  guideDraftData: Pick<GuideDraft, 'addresses' | 'id' | 'images'>
}

export const GuideDraftHeroEmpty = ({
  guideDraftData,
}: GudieDraftHeroEmpty) => {
  const [isModalImagesEditOpen, setIsModalImagesEditOpen] = useState(false)
  const { addresses, id, images } = guideDraftData ?? {}

  return (
    <>
      <div className='bg-mint b-grey-400 b-dashed b-1 b-rounded-2 h-29 relative w-full'>
        <button
          className='b-none m-none p-x-9 p-t-4 absolute flex h-full w-full cursor-pointer flex-col gap-1 bg-transparent outline-none'
          onClick={() => setIsModalImagesEditOpen(true)}
        >
          <span className='type-h6-desktop c-grey-900'>Add Header Photos</span>
          <p className='type-small-text-desktop c-grey-900'>
            Upload or select three photos for your Guide Header.
          </p>
        </button>
        <div className='b-none m-none p-none [&>button]:c-new-forest absolute bottom-4 left-9 cursor-pointer bg-transparent outline-none [&>button]:normal-case'>
          <Button
            startIcon='camera'
            variant='text'
            onClick={() => setIsModalImagesEditOpen(true)}
          >
            Add Photos
          </Button>
        </div>
      </div>
      {isModalImagesEditOpen && (
        <GuideDraftImagesEditModal
          guideDraftImagesEditData={{
            addresses,
            id,
            images,
          }}
          onDismiss={() => setIsModalImagesEditOpen(false)}
        />
      )}
    </>
  )
}
