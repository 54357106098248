import { useState } from 'react'
import type { AnimateLayoutChanges } from '@dnd-kit/sortable'
import {
  defaultAnimateLayoutChanges,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Accordion, AccordionItem, AccordionPanel } from '@reach/accordion'
import { Divider } from '@travelpass/design-system'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { GuideDraftSectionHeader } from './GuideDraftSectionHeader'
import type { GuideDraftData, GuideDraftDragItem } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQueryOld'
import { GuideDraftEvents } from '../GuideDraftEvents'

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({
    ...args,
    wasDragging: true,
  })

interface GuideDraftSectionProps {
  isOverlay?: boolean
  isSortingContainer: boolean
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  owner: GuideOwner
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  paginatedEventCategory: GuideDraftDragItem
  selectedId: string
}

export const GuideDraftSection = ({
  isOverlay = false,
  isSortingContainer,
  onMapMarkerCenterChange,
  owner,
  paginatedEventCategories,
  paginatedEventCategory,
  selectedId,
}: GuideDraftSectionProps) => {
  const { id, publishedEvents } = paginatedEventCategory ?? {}
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    animateLayoutChanges,
    data: {
      children: publishedEvents ?? [],
    },
    id,
  })
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const isDraggingOrOverlay = isDragging || isOverlay

  if (!id)
    return (
      <SortableContext
        items={publishedEvents ?? []}
        strategy={verticalListSortingStrategy}
      >
        <GuideDraftEvents
          isSortingContainer={isSortingContainer}
          owner={owner}
          paginatedEventCategories={paginatedEventCategories}
          paginatedEventCategory={paginatedEventCategory}
          selectedId={selectedId}
          onMapMarkerCenterChange={onMapMarkerCenterChange}
        />
      </SortableContext>
    )

  return (
    <SortableContext
      items={publishedEvents ?? []}
      strategy={verticalListSortingStrategy}
    >
      <Accordion
        className='p-b-4 lg:p-b-6'
        collapsible={false}
        defaultIndex={[0]}
        ref={isOverlay ? undefined : setNodeRef}
        style={
          {
            transition,
            transform: CSS.Translate.toString(transform),
            opacity: isDragging ? 0.5 : undefined,
            '--columns': 1,
          } as React.CSSProperties
        }
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionItem>
          <GuideDraftSectionHeader
            attributes={attributes}
            isDraggingOrOverlay={isDraggingOrOverlay}
            isExpanded={!isDraggingOrOverlay && isExpanded}
            listeners={listeners}
            paginatedEventCategory={paginatedEventCategory}
          />
          <Divider className='m-b-6 m-y-3 pointer-events-none' />
          <AccordionPanel>
            {!isDraggingOrOverlay && isExpanded && (
              <GuideDraftEvents
                isSortingContainer={isSortingContainer}
                owner={owner}
                paginatedEventCategories={paginatedEventCategories}
                paginatedEventCategory={paginatedEventCategory}
                selectedId={selectedId}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
              />
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </SortableContext>
  )
}
