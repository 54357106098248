import { useSnackbar } from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { useGetTripPlaceDetailsLazyQuery } from 'src/pages/trips/hooks'
import { encodeGuideId } from 'src/utils'
import { GuideGeocoder } from '../../common'
import {
  onGuideSessionStorageHoverIdChange,
  useGuideSessionStorageGeocoder,
} from '../../details'
import { getGuideDraftCreatePublishedVariables } from '../guideDraftUtils'
import { useCreateGuideDraftEventMutation } from '../useCreateGuideDraftEventMutation'
import type { UseGetGuideDraftQuery } from '../useGetGuideDraftQuery'

interface GuideDraftGeocoderProps {
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  onPlacePointsChange: (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => void
  onSearchValueChange: (updatedSearchValue: string) => void
  searchValue: string
  slotAfter?: JSX.Element
  willAddEvent?: boolean
}

export const GuideDraftGeocoder = ({
  onMapMarkerCenterChange,
  onPlacePointsChange,
  onSearchValueChange,
  searchValue,
  slotAfter,
  willAddEvent = true,
}: GuideDraftGeocoderProps) => {
  const { bounds, location } = useGuideSessionStorageGeocoder()
  const { guideDraftId } = useParams()
  const [getPlaceDetails] = useGetTripPlaceDetailsLazyQuery()
  const [createPublishedEvent] = useCreateGuideDraftEventMutation()
  const { addSuccessSnack, addMailSnack, addErrorSnack } = useSnackbar()

  const onGeocoderChange = async (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      try {
        const response = await getPlaceDetails({
          variables: {
            includeImageLinks: true,
            placeDetailsRequest: {
              placeId: updatedGeocoder?.placeId,
            },
          },
        })
        addMailSnack({
          title: 'Adding to guide...',
        })
        await createPublishedEvent({
          variables: getGuideDraftCreatePublishedVariables({
            googlePlaceId: updatedGeocoder?.placeId,
            guideDraftId: encodeGuideId({
              guideId: guideDraftId,
              isGuideDraft: true,
            }),
            placeDetailsData: response.data,
          }),
        })
        addSuccessSnack({
          timeout: 1000,
          title: 'Added to Guide!',
        })
      } catch (error) {
        console.error(error)
        addErrorSnack({
          timeout: 1000,
          title: 'Server error',
        })
      }

      onPlacePointsChange([])
    }
  }

  const onResult = (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      if (!willAddEvent) {
        onGuideSessionStorageHoverIdChange(updatedGeocoder?.placeId)
        onMapMarkerCenterChange({
          lat: updatedGeocoder?.center?.[0],
          lng: updatedGeocoder?.center?.[1],
        })
      } else {
        onGeocoderChange(updatedGeocoder)
      }
    }
  }

  return (
    <GuideGeocoder
      aria-label='Search and add a google event for this guide'
      config={{
        bounds,
        location,
      }}
      count={20}
      placeholder='Search here for hotels, restaurants, etc.'
      searchValue={searchValue}
      slotAfter={slotAfter}
      slotBefore={<></>}
      willAddEvent={willAddEvent}
      onClear={() => onPlacePointsChange([])}
      onResult={onResult}
      onResults={onPlacePointsChange}
      onSearchValueChange={onSearchValueChange}
    />
  )
}
