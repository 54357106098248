import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Button,
  Icon,
  Input,
  Modal,
  ModalActions,
  ModalScrollContents,
  Radio,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import { Controller, useForm } from 'react-hook-form'
import { useUserProfileQuery } from 'src/common/hooks/useUserProfileQuery'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { emailValidationRegex } from 'src/constants/validation'
import { onFeedbackFormSubmit } from './onFeedbackFormSubmit'

interface FormValues {
  name: string
  email: string
  feedbackOption: string
  url: string
  feedbackDetails: string
  canWeReachYou: 'Yes' | 'No'
  receiveMarketingEmail: 'Yes' | 'No'
}
interface FeedbackModalProps {
  onClose(): void
}

export const FeedbackModal = ({ onClose }: FeedbackModalProps) => {
  const { data } = useUserProfileQuery()
  const { addSuccessSnack } = useSnackbar()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      email: data?.currentUser?.email ?? '',
      feedbackOption: '',
      url: window.location.href,
      feedbackDetails: '',
      canWeReachYou: 'Yes',
      receiveMarketingEmail: 'Yes',
    },
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const options = ['Suggest an improvement', 'Report a bug', 'Give feedback']

  const onSubmit = (formData: FormValues) => {
    if (isSubmitting) return // prevent double-clicking
    setIsSubmitting(true)
    if (formData?.receiveMarketingEmail == 'Yes') {
      pushDataToDataLayer('marketing_email_opt_in', {
        name: formData?.name,
        email: formData?.email,
      })
    }

    onFeedbackFormSubmit(formData, () => {
      addSuccessSnack({ title: 'Feedback sent' })
      setIsSubmitting(false)
      onClose()
    })
  }

  return (
    <Modal size='medium' title='Feedback' onDismiss={onClose}>
      <form className='overflow-y-auto' onSubmit={handleSubmit(onSubmit)}>
        <ModalScrollContents>
          <div className='mt-4 flex flex-col gap-4 after:pl-0.5'>
            <div className='gap-sm flex sm:flex-col md:flex-row'>
              <Input
                fullWidth
                required
                label='Name'
                placeholder='i.e. John Smith'
                {...register('name', {
                  required: 'This field is required',
                })}
                errorText={errors.name?.message}
              />
              <Input
                fullWidth
                required
                label='Email'
                placeholder='example@travelpass.com'
                {...register('email', {
                  required: 'This field is required',
                  pattern: {
                    value: emailValidationRegex,
                    message: 'Please enter a valid email',
                  },
                })}
                errorText={errors.email?.message}
              />
            </div>
            <Controller
              control={control}
              name='feedbackOption'
              render={({ field }) => (
                <Autocomplete
                  autoExpand
                  required
                  aria-label='I would like to…'
                  autoComplete='on'
                  errorText={errors?.feedbackOption?.message ?? ''}
                  label='I would like to…'
                  placeholder='I would like to…'
                  slotAfter={<Icon name='arrowDownIos' size='small' />}
                  value={field.value}
                  onChange={event => field.onChange(event?.target?.value)}
                  onOptionSelect={field.onChange}
                >
                  {options?.map(item => (
                    <AutocompleteOption
                      key={item}
                      className='color-black rounded-0 transition-background-color-200 type-body-1 focus:bg-grey-100 hover:bg-grey-100 aria-selected:bg-grey-100 w-full cursor-pointer bg-white px-4 py-4 ease-linear'
                      value={item}
                    >
                      {item}
                    </AutocompleteOption>
                  ))}
                </Autocomplete>
              )}
              rules={{ required: 'Please select a feedback option' }}
            />
            <TextArea
              required
              aria-label='feedback details'
              autogrow={false}
              placeholder='Please provide as many relevant details as possible'
              rows={9}
              {...register('feedbackDetails', {
                required: 'This field is required',
              })}
              errorText={errors.feedbackDetails?.message}
              onDrop={e => e.preventDefault()}
              onPaste={e => e.preventDefault()}
            />
            <div className='flex items-center gap-4'>
              <p className='type-body-1 c-black after:c-red after:content-["*"]'>
                If we have further questions, can we reach out to you?{' '}
              </p>
              <Controller
                control={control}
                name='canWeReachYou'
                render={({ field }) => (
                  <>
                    {['Yes', 'No'].map(option => (
                      <Radio
                        key={option}
                        id={option}
                        isChecked={field.value === option}
                        label={option}
                        value={option}
                        onChange={() => field.onChange(option)}
                      />
                    ))}
                  </>
                )}
              />
            </div>
            <div className='flex items-center gap-4'>
              <p className='type-body-1 c-black after:c-red after:content-["*"]'>
                Would you like to receive marketing email messages from
                Travelpass?
              </p>
              <Controller
                control={control}
                name='receiveMarketingEmail'
                render={({ field }) => (
                  <>
                    {['Yes', 'No'].map(option => (
                      <Radio
                        key={option}
                        id={`${option}-receiveMarketingEmail`}
                        isChecked={field.value === option}
                        label={option}
                        value={option}
                        onChange={() => field.onChange(option)}
                      />
                    ))}
                  </>
                )}
              />
            </div>
          </div>
        </ModalScrollContents>
        <ModalActions>
          <Button
            isDisabled={isSubmitting}
            label='Submit Feedback'
            type='submit'
          />
        </ModalActions>
      </form>
    </Modal>
  )
}
