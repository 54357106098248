import { useMemo } from 'react'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { bookPath, dashboardPath, explorePath, planPath } from 'src/constants'

export const MobileBottomNav = () => {
  const location = useLocation()
  const { isAnonymous } = useFirebaseUser()
  const enableExplore = useFlag('enableExplore')
  const enablePlanPage = useFlag('enablePlanPage')
  const homePaths = isAnonymous ? '/' : dashboardPath
  const explorePaths = enableExplore ? explorePath : '/guides/all'
  const pathGroups = [homePaths, explorePaths, planPath, bookPath]
  const activeIndex = useMemo(() => {
    return pathGroups.findIndex(route => matchPath(route, location.pathname))
  }, [pathGroups, location.pathname])

  if (!enablePlanPage) return

  return (
    <nav className='h-17 b-1 b-t-solid b-grey-200 fixed bottom-0 flex w-full flex-row items-center bg-white px-1 py-3 md:hidden'>
      <Link
        aria-label='Navigate to Home'
        className='decoration-none w-full'
        to={homePaths}
      >
        <div className='m-x-a w-fit space-y-1'>
          <div className='color-grey-800 flex flex-col items-center gap-1 px-4'>
            <Icon name='home' />
            <span className='type-subtext color-black'>Home</span>
          </div>
          <div
            className={classNames('b-none h-2px m-0 w-full', {
              'bg-valley': activeIndex === 0,
              'bg-transparent': activeIndex !== 0,
            })}
          />
        </div>
      </Link>
      <Link
        aria-label='Navigate to Explore'
        className='decoration-none w-full'
        to={enableExplore ? explorePath : '/guides/all?sort=RECOMMENDED'}
      >
        <div className='m-x-a w-fit space-y-1'>
          <div className='color-grey-800 flex flex-col items-center gap-1 px-4'>
            <Icon name='search' />
            <span className='type-subtext color-black'>Explore</span>
          </div>
          <div
            className={classNames('b-none h-2px m-0 w-full', {
              'bg-valley': activeIndex === 1,
              'bg-transparent': activeIndex !== 1,
            })}
          />
        </div>
      </Link>
      <Link
        aria-label='Navigate to Plan'
        className='decoration-none w-full'
        to={planPath}
      >
        <div className='m-x-a w-fit space-y-1'>
          <div className='color-grey-800 flex flex-col items-center gap-1 px-4'>
            <Icon name='mapOutlined' />
            <span className='type-subtext color-black'>Plan</span>
          </div>
          <div
            className={classNames('b-none h-2px m-0 w-full', {
              'bg-valley': activeIndex === 2,
              'bg-transparent': activeIndex !== 2,
            })}
          />
        </div>
      </Link>
      <Link
        aria-label='Navigate to Book'
        className='decoration-none w-full'
        to={bookPath}
      >
        <div className='m-x-a w-fit space-y-1'>
          <div className='color-grey-800 flex flex-col items-center gap-1 px-4'>
            <Icon name='luggage' />
            <span className='type-subtext color-black'>Book</span>
          </div>
          <div
            className={classNames('b-none h-2px m-0 w-full', {
              'bg-valley': activeIndex === 3,
              'bg-transparent': activeIndex !== 3,
            })}
          />
        </div>
      </Link>
    </nav>
  )
}
