import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const UPDATE_USER_ONBOARDING = gql(`
  mutation UpdateUserOnboarding($input: UpsertProfileInput!) {
    upsertProfile(input: $input) {
      userProfile {
        accountHandle
      }
    }
  }
`)

export const useGetUserOnboardingMutation = () =>
  useMutation(UPDATE_USER_ONBOARDING)
