import { Skeleton } from '@travelpass/design-system'

export const ContestantCardLoading = ({
  className,
}: {
  className?: string
}) => {
  return (
    <div
      className={`b-none b-rounded-md max-w-70.5 h-414px mx-auto h-full w-full space-y-5 bg-white ${className || ''}`}
    >
      <div className='h-68% w-full'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-11 w-3/5 px-4'>
        <Skeleton variant='rounded' />
      </div>
      <div className='flex h-8 w-full gap-16 px-4'>
        <Skeleton variant='rounded' />
        <Skeleton variant='rounded' />
      </div>
    </div>
  )
}
