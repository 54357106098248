import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import {
  Button,
  InlineDateRangePicker,
  Modal,
  ModalActions,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { EventStatus, EventType } from 'src/__generated__/graphql'
import { useLucencyNumber } from 'src/config/analytics/useLucencyNumber'
import type { DatesType } from 'src/constants/user'
import {
  getTripTimelineActiveDates,
  pushTripEventCreateToDataLayer,
} from 'src/pages/trips/utils'
import {
  formatDate,
  generateHotelDetailsUrl,
  getArrivalDate,
  getDateDiff,
  getDateFromUTC,
  getDepartureDate,
  stringToNumber,
} from 'src/utils'
import type { RecommendedHotel } from './hotelRecommendedConstants'
import { TripSearchParams } from '../../../constants'
import { useTripsDetailsCreateEvent } from '../../../hooks/useTripsDetailsCreateEvent'
import { TripDetailsDrawerFooter } from '../../TripDetailsDrawerFooter'

const dateTemplate = 'YYYY-MM-DD'
const timeTemplate = 'THH:mm:ssZ[Z]'

interface HotelRecommendedContentFooterProps {
  recommendedHotelData: RecommendedHotel
  setScrollToEventId?: Dispatch<SetStateAction<string>>
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
  onEventAdded?: () => void
}

export const HotelRecommendedContentFooter = ({
  recommendedHotelData,
  setScrollToEventId,
  tripDetailsData,
  onEventAdded,
}: HotelRecommendedContentFooterProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    endDate: initialEndDate,
    id: tripId,
    name: tripName,
    startDate: initialStartDate,
    timeZone,
  } = tripDetailsData ?? {}
  const endDate = dayjs(getDateFromUTC(initialEndDate, timeZone))
  const endDateTime = endDate.format(timeTemplate)
  const startDate = dayjs(getDateFromUTC(initialStartDate, timeZone))
  const startDateTime = startDate.format(timeTemplate)
  const formattedEndDate = dayjs(formatDate(endDate))
  const formattedStartDate = dayjs(formatDate(startDate))
  const [dates, setDates] = useState<DatesType>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { addSuccessSnack } = useSnackbar()
  const [createEvent, { loading: isLoading }] = useTripsDetailsCreateEvent()
  const {
    city,
    descriptions,
    id,
    googlePlaceId,
    latitude,
    longitude,
    name,
    state,
    stateCode,
  } = recommendedHotelData ?? {}
  const { updateLucency } = useLucencyNumber()

  useEffect(() => {
    const activeTabIndex = stringToNumber(
      searchParams.get(TripSearchParams.dateTab) ?? '0'
    )
    const activeDates = getTripTimelineActiveDates({
      activeTabIndex,
      endDate: initialEndDate,
      startDate: initialStartDate,
      timeZone,
    })
    const activeEndDate = dayjs(formatDate(dayjs(activeDates.endDate)))
    const activeStartDate = dayjs(formatDate(dayjs(activeDates.startDate)))

    if (activeEndDate && activeStartDate && timeZone)
      setDates([activeStartDate, activeEndDate])
  }, [initialEndDate, initialStartDate, timeZone])

  const onAddToTripClick = async () => {
    try {
      setIsModalOpen(false)
      const updatedEndDate = dayjs(
        `${formatDate(dates?.[1])}${endDateTime}`,
        `${dateTemplate}${timeTemplate}`
      )
        .utc()
        .format()
      const updatedStartDate = dayjs(
        `${formatDate(dates?.[0])}${startDateTime}`,
        `${dateTemplate}${timeTemplate}`
      )
        .utc()
        .format()
      const newEvent = await createEvent({
        variables: {
          eventInput: {
            addresses: [
              {
                lat: latitude,
                long: longitude,
                googlePlaceId,
              },
            ],
            description: descriptions?.[0]?.text,
            endDate: updatedEndDate,
            productId: id,
            name,
            startDate: updatedStartDate,
            tripId,
            type: EventType.Stay,
            useEventTime: false,
            status: EventStatus.NotBooked,
          },
        },
      })
      const {
        endDate,
        insertedAt,
        name: itemName,
        productId: itemId,
        startDate,
        status,
        trip,
        type,
      } = newEvent?.data?.createEvent ?? {}
      const tripName = trip?.name ?? ''
      pushTripEventCreateToDataLayer({
        itemCategory: type,
        itemId,
        itemInsertedAt: insertedAt,
        itemEndDate: endDate,
        itemName,
        itemStartDate: startDate,
        itemStatus: status,
        triggerVariant: 'HotelRecommendedContentFooter AddToTripButton',
        tripId,
        tripName,
      })
      const updatedActiveTabIndex =
        getDateDiff([
          formatDate(getDateFromUTC(updatedStartDate, timeZone)),
          formatDate(formattedStartDate),
        ]) ?? 0
      searchParams.delete(TripSearchParams.activeExploreId)
      searchParams.delete(TripSearchParams.eventId)
      searchParams.delete(TripSearchParams.eventType)
      searchParams.set(
        TripSearchParams.dateTab,
        updatedActiveTabIndex.toString()
      )
      setSearchParams(searchParams, {
        replace: true,
      })
      addSuccessSnack({ title: 'Event created' })

      if (setScrollToEventId && newEvent?.data?.createEvent?.id) {
        setScrollToEventId(newEvent.data.createEvent.id)
      }

      onEventAdded?.()
    } catch (error) {
      console.error(error)
    }
  }

  // TODO: timeZone should probably be passed to getArrivalDate and getDepartureDate
  const onBookClick = () =>
    window.open(
      generateHotelDetailsUrl({
        arrival: getArrivalDate({ date: startDate }),
        city,
        departure: getDepartureDate({ date: endDate }),
        id,
        name,
        state,
        stateAbbreviation: stateCode,
        tripId,
      })
    )

  const onSelect = (range: { from?: Dayjs; to?: Dayjs }) => {
    // track check-in and check-out dates to lucency
    updateLucency({
      checkin: range.from,
      checkout: range?.to,
    })
    const updatedDates: DatesType = [range?.from, range?.to]
    setDates(updatedDates)
  }

  return (
    <>
      {isModalOpen && (
        <Modal size='medium' onDismiss={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-4'>
            <InlineDateRangePicker
              fromDate={formattedStartDate.toDate()}
              label='Start Date – End Date'
              numberOfMonths={1}
              selected={{
                from: dates[0],
                to: dates[1],
              }}
              toDate={formattedEndDate.toDate()}
              onSelect={onSelect}
            />
          </div>
          <ModalActions>
            <Button
              aria-label='Add hotel to trip'
              isDisabled={!(dates[0] && dates[1])}
              onClick={onAddToTripClick}
            >
              Add to Trip
            </Button>
          </ModalActions>
        </Modal>
      )}
      <TripDetailsDrawerFooter>
        {isLoading ? (
          <div className='flex h-12 flex-col items-center justify-center'>
            <SkeletonDots />
          </div>
        ) : (
          <Button
            fullWidth
            aria-label='Add hotel to trip'
            size='large'
            variant='outlined'
            onClick={() => setIsModalOpen(true)}
          >
            Add to Trip
          </Button>
        )}
        <Button
          fullWidth
          aria-label='Book hotel now'
          size='large'
          onClick={onBookClick}
        >
          Book Now
        </Button>
      </TripDetailsDrawerFooter>
    </>
  )
}
