import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'

const HOTEL_DETAILS = gql(`
  query HotelDetailsStandardHotel($hotelId: String!, $width: Int, $height: Int) {
    lodging(id: $hotelId, idType: STANDARD) {
      id
      amenities
      descriptions {
        text
      }
      longitude
      latitude
      rating
      checkinInstructions
      checkinSpecialInstructions
      fees {
        type
        text
      }
      policies {
        title
        text
      }
      staticMap(width: $width, height: $height)
      # datalayer fields
      name
      imageLinks
      address
      city
      stateCode
      postalCode
    }
  }
`)

export const useHotelDetailsStandardHotel = () => {
  const { hotelId } = useParams()

  const { data, error, loading } = useQuery(HOTEL_DETAILS, {
    skip: !hotelId,
    variables: { hotelId, height: 600, width: 600 },
  })

  return {
    error,
    data: data?.lodging,
    loading,
  }
}
