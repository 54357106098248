import { useRef, useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { getGuideDetailsUrl } from 'src/utils'
import { GuideDraftShareIntro } from './GuideDraftShareIntro'
import { GuideDraftShareReview } from './GuideDraftShareReview/GuideDraftShareReview'
import { GuideDraftShareSuccess } from './GuideDraftShareSuccess'
import type { GuideDraftData } from '../../types'
import { GuideDraftEditModal } from '../GuideDraftEditModal'
import { GuideDraftImagesEditModal } from '../GuideDraftImagesEdit'

const getGuideDraftShareTitle = (step: number) => {
  if (step === 1) return 'Share Your Guide'

  if (step === 2) return "You're Almost There"

  return 'Success!'
}

interface GuideDraftShareProps {
  guideDraftData: GuideDraftData
  onDismiss: VoidFunction
}

export const GuideDraftShare = ({
  guideDraftData,
  onDismiss,
}: GuideDraftShareProps) => {
  const navigate = useNavigate()
  const scrollId = useRef<string>('')
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalImagesEditOpen, setIsModalImagesEditOpen] = useState(false)
  const [step, setStep] = useState(1)
  const { guide } = guideDraftData ?? {}
  const modalTitle = getGuideDraftShareTitle(step)

  const onModalEditOpen = (updatedScrollId: string) => {
    scrollId.current = updatedScrollId
    setIsModalEditOpen(true)
  }

  const onStepChange = (updatedStep: number) => setStep(updatedStep)

  const onView = () =>
    navigate(
      getGuideDetailsUrl({
        id: guide?.id,
        name: guide?.name,
      })
    )

  if (!guide?.id) return

  return (
    <>
      <Modal title={modalTitle} onDismiss={onDismiss}>
        <ModalScrollContents>
          <div className='p-x-2 md:p-x-0 md:p-b-3'>
            {step === 1 && (
              <GuideDraftShareIntro
                guideDraftData={guideDraftData}
                onStepChange={onStepChange}
              />
            )}
            {step === 2 && (
              <GuideDraftShareReview
                guideDraftData={guideDraftData}
                onModalEditOpen={onModalEditOpen}
                onModalImagesEditOpen={() => setIsModalImagesEditOpen(true)}
              />
            )}
            {step === 3 && (
              <GuideDraftShareSuccess guideDraftData={guideDraftData} />
            )}
          </div>
        </ModalScrollContents>
        {step === 3 && (
          <ModalActions>
            <Button autoFocus={true} onClick={onView}>
              View Guide
            </Button>
            <Button variant='outlined' onClick={onDismiss}>
              Done
            </Button>
          </ModalActions>
        )}
      </Modal>
      {isModalEditOpen && (
        <GuideDraftEditModal
          guideDraftData={guideDraftData}
          scrollId={scrollId?.current}
          onDismiss={onDismiss}
        />
      )}
      {isModalImagesEditOpen && (
        <GuideDraftImagesEditModal
          guideDraftImagesEditData={{
            addresses: guideDraftData?.addresses,
            images: guideDraftData?.images,
            id: guideDraftData?.id,
          }}
          onDismiss={onDismiss}
        />
      )}
    </>
  )
}
