import { Trending } from './Trending'

export const ExploreZeroState = () => {
  return (
    <section>
      <div className='gap-8px mb-10 flex flex-col'>
        <h2 className='type-h1 text-center'>No Results Found</h2>
        <p className='type-subtitle-2-desktop c-grey-800 text-center'>
          Try adjusting your filters to find guides
        </p>
      </div>
      <Trending onlyMainTitle title='Check Out These Trending Guides!' />
    </section>
  )
}
