import { ProfileSearchBar } from './ProfileSearchBar'

export const ProfileSearchBarContainer = () => {
  return (
    <div className='max-w-330 mxa flex w-full justify-end px-5 pt-5'>
      <div className='md:w-33% lg:w-25% w-full'>
        <ProfileSearchBar />
      </div>
    </div>
  )
}
