import { useRef, useState } from 'react'
import {
  IconButton,
  MenuItem,
  StopPropagation,
  useSnackbar,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import type { GuideDraft } from 'src/__generated__/graphql'
import { GuideStatus } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import {
  useIncrementGuideShareCountMutation,
  useShareGuide,
} from 'src/pages/guides'
import {
  GuideDraftDeleteModal,
  GuideDraftEditModal,
  GuideDraftEditModalOld,
} from 'src/pages/guides/lists/common'
import { getGuideDetailsUrl, getGuideDraftDetailsUrl } from 'src/utils'

/** @todo replace GuideDraft with GuideDraftData (when pagination is implemented) */
interface DashboardGuideDraftCardPopoverMenuProps {
  guideDraftData: Pick<GuideDraft, 'guide' | 'id' | 'name'>
}

export const DashboardGuideDraftCardPopoverMenu = ({
  guideDraftData,
}: DashboardGuideDraftCardPopoverMenuProps) => {
  const isGuidePrivatePublicEnabled = useFlag('guidePrivatePublic')
  const navigate = useNavigate()
  const shareGuide = useShareGuide()
  const [hasShared, setHasShared] = useState(false)
  const [isGuideDraftEditModalOpen, setIsGuideDraftEditModalOpen] =
    useState(false)
  const [isGuideDraftDeleteModalOpen, setIsGuideDraftDeleteModalOpen] =
    useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { id, name, guide } = guideDraftData ?? {}
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const ref = useRef<HTMLDivElement>(null)

  const onDeleteGuide = () => {
    setIsGuideDraftDeleteModalOpen(true)
    setIsPopoverOpen(false)
  }

  const onEditGuide = () => {
    if (isGuidePrivatePublicEnabled)
      return navigate(
        getGuideDraftDetailsUrl({
          id,
          isUserOwner: true,
          name,
        })
      )

    setIsPopoverOpen(false)
    setIsGuideDraftEditModalOpen(true)
  }

  const onShareGuide = async () => {
    await shareGuide({
      guideDraftTitle: name,
      isUserOwner: true,
      shareUrl: `${window.location.origin}${getGuideDetailsUrl({ id: guide?.id, name })}`,
      onSuccessfulShare: () => {
        setIsPopoverOpen(false)
        addSuccessSnack({ timeout: 1000, title: 'Guide URL copied' })
      },
    })
    if (!hasShared) {
      incrementShareCount({ variables: { guideId: id } })
      setHasShared(true)
    }
  }

  return (
    <>
      <Popover
        containerClassName='z-5'
        content={
          <StopPropagation>
            <div className='shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
              {guideDraftData?.guide?.status === GuideStatus.Published && (
                <MenuItem
                  aria-label='Share Guide'
                  label='Share'
                  startIcon='iosShare'
                  value='Share'
                  onClick={onShareGuide}
                />
              )}
              <MenuItem
                aria-label='Edit Guide name/description'
                label='Edit'
                startIcon='modeEditOutline'
                value='Edit'
                onClick={onEditGuide}
              />
              <MenuItem
                aria-label='Delete Guide'
                label='Delete'
                startIcon='deleteOutline'
                value='Delete'
                onClick={onDeleteGuide}
              />
            </div>
          </StopPropagation>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div ref={ref}>
          <StopPropagation>
            <div className='[&>button]:color-grey-800'>
              <IconButton
                aria-label='Guide Settings'
                icon='moreHoriz'
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            </div>
          </StopPropagation>
        </div>
      </Popover>
      {/** @todo move modals to DashboardGuideDrafts */}
      {isGuideDraftEditModalOpen && isGuidePrivatePublicEnabled && (
        <GuideDraftEditModal
          guideDraftData={guideDraftData}
          onDismiss={() => setIsGuideDraftEditModalOpen(false)}
        />
      )}
      {/** @todo remove GuideDraftEditModalOld and GuideDraftEditModal when guidePrivatePublic is released */}
      {isGuideDraftEditModalOpen && !isGuidePrivatePublicEnabled && (
        <GuideDraftEditModalOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsGuideDraftEditModalOpen(false)}
        />
      )}
      {isGuideDraftDeleteModalOpen && (
        <GuideDraftDeleteModal
          guideDraftData={guideDraftData}
          onDismiss={() => setIsGuideDraftDeleteModalOpen(false)}
        />
      )}
    </>
  )
}
