import type { ComponentPropsWithoutRef, SyntheticEvent } from 'react'
import { useState } from 'react'
import isEmpty from 'lodash.isempty'
import { CollectionItemIdType, EventType } from 'src/__generated__/graphql'
import {
  ResultsCard,
  ResultsCardImage,
  ResultsCardReviews,
} from 'src/common/components'
import { AddToMenu } from 'src/common/components/Collections/AddToMenu'
import { getPrice } from 'src/utils'
import { HotelResultsCardFeaturedContentContainer } from './HotelResultsCardFeaturedContentContainer'
import type { HotelResultsItemData } from '../../hotelResultsTypes'

interface HotelResultsCardFeaturedProps
  extends ComponentPropsWithoutRef<'button'> {
  hotelResultsCardData: HotelResultsItemData
}

export const HotelResultsCardFeatured = ({
  hotelResultsCardData,
  ...props
}: HotelResultsCardFeaturedProps) => {
  const {
    id,
    customerReviewScore,
    images: initialImages,
    name,
    nightlyAverage: initialNightlyAverage,
    totalCustomerReviews,
  } = hotelResultsCardData ?? {}
  const [images, setImages] = useState(() => initialImages)
  const nightlyAverage =
    initialNightlyAverage && getPrice(initialNightlyAverage)
  const showCard = !isEmpty(hotelResultsCardData) && !!name

  const onError = (event: SyntheticEvent<HTMLImageElement, Event>) =>
    setImages(updatedImages =>
      updatedImages?.filter(image => event.currentTarget?.src !== image)
    )

  return (
    showCard && (
      <>
        <ResultsCard {...props}>
          <ResultsCardImage
            alt={`${name} featured`}
            src={images?.[0]}
            onError={onError}
          />
          {!!nightlyAverage && (
            <div className='absolute left-0 top-0 flex'>
              <p className='bg-orange color-white rounded-bl-1 type-body-1 truncate px-6 py-2'>
                <span>from</span> {nightlyAverage} per night
              </p>
            </div>
          )}
          <HotelResultsCardFeaturedContentContainer>
            <h2 className='color-white type-h6'>{name}</h2>
            <div className='flex flex-row justify-between'>
              <ResultsCardReviews
                average={customerReviewScore}
                total={totalCustomerReviews}
                type={EventType.Stay}
                variant='featured'
              />
              <div className='z-0'>
                <AddToMenu
                  item={{ id, name, type: CollectionItemIdType.Hotel }}
                />
              </div>
            </div>
          </HotelResultsCardFeaturedContentContainer>
        </ResultsCard>
      </>
    )
  )
}
