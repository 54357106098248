import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { NearbyGuidesQueryVariables } from 'src/__generated__/graphql'
import { getGeocoderPlaceName, getTracker } from 'src/utils'

const NEARBY_GUIDES_QUERY = gql(`
  query NearbyGuides($guideSearchArgs: GuideSearchArgs!, $first: Int) {
    guideSearch(guideSearchArgs: $guideSearchArgs, first: $first) {
      edges {
        node {
          id
          addresses {
            id
            city
            state
          }
          endDate
          guideType
          imageUrl
          insertedAt
          name
          ownerProfile {
            accountHandle
            displayName
            id
            profileImageUrl
          }
          startDate
          updatedAt
          timeZone
        }
      }
    }
  }
`)

interface NearbyGuidesQueryProps {
  location: string
  variables: NearbyGuidesQueryVariables
}

const useNearbyGuidesQuery = ({
  location,
  variables,
}: NearbyGuidesQueryProps) => {
  const geocoderPlaceName = getGeocoderPlaceName(location)
  const durationDays = variables?.guideSearchArgs?.filterBy?.durationDays
  const guideType = variables?.guideSearchArgs?.filterBy?.guideType

  /** @todo uncomment when filter categories become available */
  // const { data: allAvailableTags, error: tagsError } = useGuideTagsQuery({
  //   tagType: TaggableType.Guide,
  // })

  /* This block is meant to handle an array of IDs (strings) passed in by the consumer
  in order to control which tags to filter by */
  // const filters = variables?.guideSearchArgs?.filterBy?.tagIds
  // const allAvailableTagIds = allAvailableTags?.tagsByTaggableType?.map(
  //   tag => tag.id
  // )
  // let tagsToFilterBy = []
  // filters?.forEach(filter => {
  //   if (allAvailableTagIds.includes(filter)) tagsToFilterBy.push(filter)
  //   else
  //     console.warn(
  //       `Warning: ${filter} is not a valid filter and will be excluded`
  //     )
  // })

  const nearbyLocations = variables?.guideSearchArgs?.searchBy?.nearbyLocations
  const { latLong, radiusRange } = nearbyLocations ?? {}
  const latitude = latLong.latitude
  const longitude = latLong.longitude
  const maxRadius = radiusRange?.max ?? 80
  const minRadius = radiusRange?.min ?? null
  const tracker =
    variables?.guideSearchArgs?.tracker ?? getTracker(geocoderPlaceName)

  const constructedResultsArguments = useMemo(() => {
    return {
      ...variables,
      guideSearchArgs: {
        filterBy: {
          // tagIds: !tagsError && tagsToFilterBy.length ? tagsToFilterBy : null,
          durationDays,
          guideType,
        },
        searchBy: {
          nearbyLocations: {
            latLong: {
              latitude,
              longitude,
            },
            radiusRange: {
              max: maxRadius,
              min: minRadius,
            },
          },
        },
        tracker,
      },
      first: variables.first,
    }
  }, [latitude, longitude, variables])

  const { data, loading, error } = useQuery(NEARBY_GUIDES_QUERY, {
    variables: constructedResultsArguments,
  })

  const { edges } = data?.guideSearch ?? {}
  const resultsData = edges ?? []

  return { resultsData, loading, error }
}

export { useNearbyGuidesQuery, NEARBY_GUIDES_QUERY }
