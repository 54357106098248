import { useState } from 'react'
import { Button, Carousel } from '@travelpass/design-system'
import type { HotelHeroHeaderAndImagesQuery } from 'src/__generated__/graphql'
import { constructImageLinks, getImageSrcSet } from 'src/utils'
import { HeroImage } from './HeroImage'
import { HotelModal } from '../../HotelModal'

interface HotelHeroImagesProps {
  hotelData: HotelHeroHeaderAndImagesQuery['lodging']
}

export const HotelHeroImages = ({ hotelData }: HotelHeroImagesProps) => {
  const [galleryIndex, setGalleryIndex] = useState(-1)

  const imageLinks = constructImageLinks(hotelData.imageLinks, '1280x720')
  const mobileImageLinks = constructImageLinks(hotelData.imageLinks, '640x400')
  const galleryButtonLabel =
    hotelData?.imageLinks.length > 25
      ? '25+'
      : String(hotelData?.imageLinks.length)

  const openGallery = (index = 0) => {
    setGalleryIndex(index)
  }

  if (imageLinks.length === 0) {
    return null
  }

  return (
    <>
      {galleryIndex >= 0 && (
        <HotelModal
          galleryIndex={galleryIndex}
          onCloseModal={() => openGallery(-1)}
        />
      )}
      <div className='flex w-full items-center justify-center'>
        <div className='max-w-100vw grid w-full grid-cols-1 gap-2 lg:w-[1440px] lg:grid-cols-5'>
          <div className='col-span-1 h-auto overflow-hidden lg:col-span-3 lg:h-[564px]'>
            <div className='block h-auto lg:hidden'>
              <Carousel hideDots isLight>
                {imageLinks.map((image, index) => (
                  <HeroImage
                    key={`mobile-carousel-${index}`}
                    alt={`${hotelData.name} photo ${index + 1}`}
                    src={image}
                    srcSet={getImageSrcSet({
                      desktop: image,
                      mobile: mobileImageLinks?.[index],
                    })}
                    onClick={() => openGallery(index)}
                  />
                ))}
              </Carousel>
            </div>
            <div className='hidden h-full lg:block'>
              <HeroImage
                alt={hotelData.name + ' photo 1'}
                src={imageLinks[0]}
                srcSet={getImageSrcSet({
                  desktop: imageLinks[0],
                  mobile: mobileImageLinks[0],
                })}
                onClick={() => openGallery(0)}
              />
            </div>
          </div>
          <div className='col-span-1 lg:col-span-2'>
            <div className='grid h-[70px] grid-cols-4 grid-rows-1 gap-2 lg:h-[564px] lg:grid-cols-2 lg:grid-rows-2'>
              <div className='overflow-hidden'>
                <HeroImage
                  alt={hotelData.name + ' photo 2'}
                  src={imageLinks[1]}
                  srcSet={getImageSrcSet({
                    desktop: imageLinks[1],
                    mobile: mobileImageLinks[1],
                  })}
                  onClick={() => openGallery(1)}
                />
              </div>
              <div className='overflow-hidden'>
                <HeroImage
                  alt={hotelData.name + ' photo 3'}
                  src={imageLinks[2]}
                  srcSet={getImageSrcSet({
                    desktop: imageLinks[2],
                    mobile: mobileImageLinks[2],
                  })}
                  onClick={() => openGallery(2)}
                />
              </div>
              <div className='overflow-hidden'>
                <HeroImage
                  alt={hotelData.name + ' photo 4'}
                  src={imageLinks[3]}
                  srcSet={getImageSrcSet({
                    desktop: imageLinks[3],
                    mobile: mobileImageLinks[3],
                  })}
                  onClick={() => openGallery(3)}
                />
              </div>
              <div className='relative overflow-hidden'>
                <div className='z-1 absolute bottom-3 right-3 hidden lg:block'>
                  <Button
                    label={galleryButtonLabel}
                    size='small'
                    startIcon='photoLibrary'
                    variant='outlined'
                    onClick={() => openGallery(0)}
                  />
                </div>
                <HeroImage
                  alt={hotelData.name + ' photo 5'}
                  src={imageLinks[4]}
                  srcSet={getImageSrcSet({
                    desktop: imageLinks[4],
                    mobile: mobileImageLinks[4],
                  })}
                  onClick={() => openGallery(4)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
