import { Icon, type AvailableIcons } from '@travelpass/design-system'
import classNames from 'classnames'

interface CompetitionStepsCheckerProps {
  icon: AvailableIcons
  title: string
  isCompleted: boolean
  helperText: string
  onClick: () => void
  onHelperTextClick: () => void
}

export const CompetitionStepsChecker = ({
  helperText,
  icon,
  isCompleted,
  title,
  onHelperTextClick,
  onClick,
}: CompetitionStepsCheckerProps) => (
  <div>
    <div
      className={classNames(
        'type-button c-white border-2px rounded-4px mb-2 cursor-pointer justify-between border-solid px-5 py-2',
        alignedCss,
        {
          'bg-#125E5C80 border-#125E5C80 c-op-70': isCompleted,
          'border-forestLight': !isCompleted,
        }
      )}
      role='button'
      tabIndex={0}
      onClick={onClick}
    >
      <span className={classNames('gap-x-3', alignedCss)}>
        <Icon name={icon} /> {title}
      </span>
      <span
        className={classNames({
          'c-valley': isCompleted,
          'c-forestLight': !isCompleted,
        })}
      >
        <Icon name='checkCircleOutline' />
      </span>
    </div>
    <button
      className='cursor-pointer border-none bg-transparent'
      onClick={() => onHelperTextClick()}
    >
      <p
        className={classNames(
          'type-button c-white c-op-70 gap-x-3',
          alignedCss
        )}
      >
        <Icon name='info' />
        {helperText}
      </p>
    </button>
  </div>
)

const alignedCss = 'flex flex-row items-center'
