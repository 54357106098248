import { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { PageNotFound, ScrollToTop } from 'src/common/components'
import { FeedbackModal } from 'src/common/components/FeedbackModal'
import { useFlag } from 'src/common/hooks'
import {
  accountSettingsSegment,
  bookSegment,
  bookingsSegment,
  collectionsPath,
  destinationsSegment,
  experiencesSegment,
  exploreSegment,
  faqSegment,
  findMyBookingSegment,
  guidesSegment,
  guidesResultsPath,
  hotelsSegment,
  infoSegment,
  insideScoopSegment,
  myAccountPath,
  nativeAppRedirectSegment,
  notificationsPath,
  overviewSegment,
  privacyNoticeSegment,
  privacySegment,
  profileSegment,
  resultsSegment,
  supportSegment,
  termsSegment,
  timelineSegment,
  tripsSegment,
  utahJazzSegment,
  guideDraftPath,
  guideDraftShareSegment,
  dashboardSegment,
  guidesEventsMapPath,
  planPath,
  explorePath,
} from 'src/constants'
import { AccountSettings } from './account-settings/AccountSettings'
import { FindMyBooking, MyBookings } from './bookings'
import { CollectionDetails } from './collections/CollectionDetails'
import { About } from './competition/About'
import { Apply } from './competition/Apply'
import { Competition } from './competition/Competition'
import { Faq as CompetitionFaq } from './competition/Faq'
import { Leaderboard } from './competition/Leaderboard'
import { Dashboard } from './dashboard/Dashboard'
import { DashboardTab } from './dashboard/dashboardConstants'
import { DestinationPage } from './destinations'
import { Destination } from './destinations-old'
import {
  ExperiencesBooking,
  ExperiencesConfirmation,
  ExperiencesDetails,
} from './experiences'
import { ExperiencesBookingDetailsApollo } from './experiences/booking-details'
import { ExperienceSearch } from './experiences/search'
import { Explore } from './explore'
import { BoundingBox } from './explore/bounding-box/BoundingBox'
import { CountryState } from './explore/country-state/CountryState'
import { KeywordSearch } from './explore/keyword-search/KeywordSearch'
import { TagSearch } from './explore/tag/TagSearch'
import {
  Guide,
  GuideOld,
  GuideResults,
  GuideDraft,
  GuideDraftCreate,
  GuideDraftCreateOld,
  GuideDraftOld,
  GuideDraftShare,
  GuideDraftShareOld,
} from './guides'
import { AllGuides } from './guides/AllGuides'
import { GuidesEventsMap } from './guides/events-map'
import { Home } from './home'
import { Booking } from './hotels/book'
import { BookingDetails } from './hotels/booking-details/BookingDetails.client'
import { BookingConfirmation } from './hotels/confirmation'
import { Hotel } from './hotels/details'
import { HotelResults } from './hotels/results'
import { Faq, Privacy, PrivacyNotice, Support, Terms } from './info'
import { NativeAppRedirect } from './info/NativeAppRedirect.tsx'
import {
  AwayGame,
  BlackFriday,
  BookAnExperience,
  FastFriends,
  FavoritePlacesUtah,
  FoodTours,
  GhostTour,
  GuidesLandingPage,
  IntroducingTravelpass,
  IntroducingTravelpassB,
  MardiGras,
  OutdoorAdventures,
  SaltLakeBees,
  SaltLakeCity,
  Schedule,
  SouthernUtah,
  SpringBreak,
  UtahCounty,
  UtahJazzSignUp,
  ValentinesDayCouples,
  ValentinesDaySolo,
} from './landing-pages'
import { MyAccount } from './my-account/MyAccount'
import { Notifications } from './notifications/Notifications'
import { Plan } from './plan/Plan'
import { Profile } from './profile/Profile'
import { Search } from './search'
import {
  MyTrips,
  TripExplore,
  TripExploreOld,
  TripTimeline,
  TripOverview,
} from './trips'

// With any services we can book we should be consistent with the confirmation route vs booking details route
// Confirmation route should be ${service}/book/confirmation/:someSortOfId i.e. hotels/book/confirmation/12637427
// Booking details route should be ${service}/confirmation/:someSortOfId i.e. hotels/confirmation/12637427

export const PageRoutes = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const isEnableProfileFavoriteHotelsEnabled = useFlag(
    'enableProfileFavoriteHotels'
  )
  const isGuideDraftUiUpdatesEnabled = useFlag('guideDraftUiUpdates')
  const isGuideUiUpdates1Enabled = useFlag('guideUiUpdates1')
  const isTripExploreV2Enabled = useFlag('tripExploreV2')
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const enablePlanPage = useFlag('enablePlanPage')
  const enableExplore = useFlag('enableExplore')

  return (
    <ScrollToTop>
      {isFeedbackModalOpen && (
        <FeedbackModal onClose={() => setIsFeedbackModalOpen(false)} />
      )}
      {enableCompetition2024 && (
        <button
          className='b-none top-70% type-button bg-orangeMedium c-white rd-t-1 z-4 fixed absolute left--8 m-0 rotate-90 cursor-pointer p-3'
          onClick={() => setIsFeedbackModalOpen(true)}
        >
          Feedback
        </button>
      )}
      <Routes>
        {/* MAIN */}
        <Route element={<Home />} path='/' />
        {enablePlanPage ? (
          <>
            <Route element={<Search />} path='/book/:type?' />
            <Route element={<Navigate to='/book' />} path='/search/:type?' />
          </>
        ) : (
          <Route element={<Search />} path='/search/:type?' />
        )}
        {/* LANDING PAGES */}
        <Route element={<Navigate replace to='/' />} path='/bees' />
        <Route element={<Navigate replace to='/utah-jazz' />} path='/jazz' />
        <Route element={<BlackFriday />} path='/deals/black-friday-travel' />
        <Route
          element={<GuidesLandingPage />}
          path='/15-travel-guides-for-the-best-fall-destinations'
        />
        <Route path={insideScoopSegment}>
          <Route
            element={<FavoritePlacesUtah />}
            path='an-insiders-guide-to-utah'
          />
          <Route element={<SaltLakeCity />} path='salt-lake-city' />
          <Route element={<SouthernUtah />} path='southern-utah' />
          <Route element={<UtahCounty />} path='utah-county' />
        </Route>
        <Route
          element={<BookAnExperience />}
          path='/book-an-experience-with-travelpass'
        />
        <Route element={<FastFriends />} path='/fast-friends' />
        <Route element={<FoodTours />} path='/food-tours' />
        <Route element={<GhostTour />} path='/ghost-tours' />
        {/* A/B Tests */}
        <Route
          element={<IntroducingTravelpass />}
          path='/my-trips-with-travelpass'
        />
        <Route
          element={<IntroducingTravelpassB />}
          path='/b/my-trips-with-travelpass'
        />
        <Route
          element={<MardiGras />}
          path='/the-ultimate-new-orleans-mardi-gras-itinerary'
        />
        <Route element={<OutdoorAdventures />} path='/outdoor-adventures' />
        <Route element={<SaltLakeBees />} path='/salt-lake-bees' />
        <Route
          element={<SpringBreak />}
          path='/the-best-spring-break-destinations'
        />
        <Route path={utahJazzSegment}>
          <Route index element={<UtahJazzSignUp />} />
          <Route
            element={<Navigate replace to='/utah-jazz' />}
            path='/utah-jazz/away-game-itineraries'
          />
          <Route element={<Schedule />} path='/utah-jazz/away-game-schedule' />
          <Route
            element={<Navigate replace to='/utah-jazz/away-game-schedule' />}
            path='/utah-jazz/vs'
          />
          <Route element={<AwayGame />} path='/utah-jazz/vs/:teamName' />
        </Route>
        <Route
          element={<ValentinesDayCouples />}
          path='/best-romantic-valentines-day-getaways-for-couples'
        />
        <Route
          element={<ValentinesDaySolo />}
          path='/best-unique-valentines-day-getaways-for-solo-travelers'
        />
        {/* BOOKINGS */}
        <Route element={<MyBookings />} path={bookingsSegment} />
        {/* COMPETITION */}
        {enableCompetition2024 && (
          <Route path='/competition'>
            <Route element={<Apply />} path='apply' />
            <Route element={<Competition />} path='*'>
              <Route element={<About />} path='about' />
              <Route element={<Leaderboard />} path='leaderboard' />
              <Route element={<CompetitionFaq />} path='faq' />
            </Route>
            <Route index element={<Navigate to='/competition/about' />} />
          </Route>
        )}
        {/* DESTINATIONS / PRODUCT DETAILS */}
        <Route path={destinationsSegment}>
          <Route
            element={<Hotel />}
            path={`:state/:city/${hotelsSegment}/:hotelId/:hotelName`}
          />
          <Route
            element={<ExperiencesDetails />}
            path={`:state/:city/${experiencesSegment}/:experienceId/:experienceName`}
          />
          <Route element={<DestinationPage />} path={resultsSegment} />
          <Route element={<Destination />} path=':stateCode/:city' />
        </Route>
        {/* EXPERIENCES */}
        <Route path={experiencesSegment}>
          <Route index element={<Navigate to='/' />} />
          <Route
            element={<ExperiencesBooking />}
            path={`${bookSegment}/:bookingFlowId`}
          />
          <Route
            element={<ExperiencesBookingDetailsApollo />}
            path='confirmation/:bookingId'
          />
          <Route
            element={<ExperiencesConfirmation />}
            path={`${bookSegment}/confirmation/:externalConfirmationId/:bookingId`}
          />
          <Route element={<ExperienceSearch />} path={resultsSegment} />
        </Route>
        {/* FIND MY BOOKING */}
        <Route element={<FindMyBooking />} path={findMyBookingSegment} />
        <Route
          element={
            isGuideDraftUiUpdatesEnabled ? (
              <GuideDraftCreate />
            ) : (
              <GuideDraftCreateOld />
            )
          }
          path='/list/create'
        />
        {/* GUIDES */}
        <Route path={guidesSegment}>
          <Route element={<AllGuides />} path='all' />
          <Route
            element={isGuideUiUpdates1Enabled ? <Guide /> : <GuideOld />}
            path=':guideId/:guideName'
          />
          <Route
            element={isGuideUiUpdates1Enabled ? <Guide /> : <GuideOld />}
            path=':guideId'
          />
          <Route element={<GuideResults />} path={guidesResultsPath} />
          <Route path={guideDraftPath}>
            <Route
              element={
                isGuideDraftUiUpdatesEnabled ? (
                  <GuideDraftShare />
                ) : (
                  <GuideDraftShareOld />
                )
              }
              path={`:guideDraftId/:guideDraftName/${guideDraftShareSegment}`}
            />
            <Route
              element={
                isGuideDraftUiUpdatesEnabled ? (
                  <GuideDraft />
                ) : (
                  <GuideDraftOld />
                )
              }
              path=':guideDraftId/:guideDraftName'
            />
            <Route
              element={
                isGuideDraftUiUpdatesEnabled ? (
                  <GuideDraft />
                ) : (
                  <GuideDraftOld />
                )
              }
              path=':guideDraftId'
            />
          </Route>
          <Route index element={<Navigate replace to='all' />} />
        </Route>
        {/* MAP */}
        <Route element={<GuidesEventsMap />} path={guidesEventsMapPath} />
        {/* HOTELS */}
        <Route path={hotelsSegment}>
          <Route element={<HotelResults />} path={resultsSegment} />
          <Route element={<Hotel />} path=':hotelId' />
          <Route element={<BookingDetails />} path='confirmation/:bookingId' />
          <Route element={<Booking />} path={`${bookSegment}/:rateToken`} />
          <Route
            element={<BookingConfirmation />}
            // TODO: remove :bookingId
            path={`${bookSegment}/confirmation/:externalConfirmationId/:bookingId`}
          />
        </Route>
        {/* INFO */}
        <Route path={infoSegment}>
          <Route element={<Faq />} path={faqSegment} />
          <Route
            element={<NativeAppRedirect />}
            path={nativeAppRedirectSegment}
          />
          <Route element={<Privacy />} path={privacySegment} />
          <Route element={<PrivacyNotice />} path={privacyNoticeSegment} />
          <Route element={<Support />} path={supportSegment} />
          <Route element={<Terms />} path={termsSegment} />
        </Route>
        <Route element={<Dashboard />} path={dashboardSegment} />
        {/* PROFILE */}
        {isEnableProfileFavoriteHotelsEnabled ? (
          <Route path={profileSegment}>
            <Route index element={<Profile />} />
            <Route element={<Profile />} path=':accountHandle' />
          </Route>
        ) : (
          <Route
            element={<Profile />}
            path={`${profileSegment}/:accountHandle`}
          />
        )}
        {/* NOTIFICATIONS */}
        <Route element={<Notifications />} path={notificationsPath} />
        {/* MY ACCOUNT */}
        <Route element={<MyAccount />} path={myAccountPath} />
        {/* ACCOUNT SETTINGS */}
        <Route element={<AccountSettings />} path={accountSettingsSegment} />
        {/* TRIPS */}
        <Route path={tripsSegment}>
          <Route index element={<MyTrips />} />
          <Route
            element={
              isTripExploreV2Enabled ? <TripExplore /> : <TripExploreOld />
            }
            path={`${exploreSegment}/:tripId/*`}
          />
          <Route
            element={<TripOverview />}
            path={`${overviewSegment}/:tripId/*`}
          />
          <Route
            element={<TripTimeline />}
            path={`${timelineSegment}/:tripId/*`}
          />
        </Route>
        {/** PLAN */}
        {enablePlanPage && <Route index element={<Plan />} path={planPath} />}
        {/* COLLECTIONS */}
        <Route path={collectionsPath}>
          <Route
            index
            element={
              <Navigate
                to={`${dashboardSegment}?tab=${DashboardTab.collections}`}
              />
            }
          />
          <Route element={<CollectionDetails />} path=':collectionId/*' />
        </Route>
        {/** EXPLORE */}
        {enableExplore && (
          <Route path={explorePath}>
            <Route index element={<Explore />} />
            <Route element={<KeywordSearch />} path='keyword' />
            <Route element={<BoundingBox />} path='bounding-box' />
            <Route element={<CountryState />} path='country-state' />
            <Route element={<TagSearch />} path='tag' />
          </Route>
        )}
        {/* MISC */}
        <Route path='/nativeapp.html' />
        <Route path='/.well-known' />
        <Route element={<PageNotFound />} path='*' />
      </Routes>
    </ScrollToTop>
  )
}
