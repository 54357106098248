import { useEffect } from 'react'

type EventListener = (event: CustomEvent) => void

const listen = (eventName: string, listener: EventListener) => {
  document.addEventListener(eventName, listener)
}

const unListen = (eventName: string, listener: EventListener) => {
  document.removeEventListener(eventName, listener)
}

const dispatch = (eventName: string, data?) => {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}

const useListen = (eventName: string, listener: EventListener) => {
  useEffect(() => {
    listen(eventName, listener)
    return () => unListen(eventName, listener)
  }, [])
}

export { useListen, dispatch }
