import { Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideHeaderContentLoading } from './GuideHeaderContentLoading'
import { GuideHeaderSummaryLoading } from './GuideHeaderSummaryLoading'

export const GuideHeaderLoading = () => (
  <div className={classNames('p-y-4 space-y-6', {})}>
    <div className='space-y-5 md:space-y-6'>
      <div className='flex flex-row items-start'>
        <div className='md:w-100 h-5 w-60 md:h-7 lg:h-8'>
          <Skeleton />
        </div>
      </div>
      <GuideHeaderContentLoading />
      <div className='flex flex-row items-center gap-2'>
        <div className='rounded-7.5 h-8 w-20'>
          <Skeleton variant='rounded' />
        </div>
        <div className='rounded-7.5 h-8 w-20'>
          <Skeleton variant='rounded' />
        </div>
        <div className='rounded-7.5 h-8 w-20'>
          <Skeleton variant='rounded' />
        </div>
        <div className='rounded-7.5 hidden h-8 w-20 md:block'>
          <Skeleton variant='rounded' />
        </div>
        <div className='rounded-7.5 hidden h-8 w-20 md:block'>
          <Skeleton variant='rounded' />
        </div>
      </div>
      <GuideHeaderSummaryLoading />
    </div>
  </div>
)
