import { useState } from 'react'
import { Link } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { GuideSorts } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  PageLayoutContainer,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { guidesSegment } from 'src/constants'
import { ProfileModal } from '../guides/results/ProfileModal'
import { ProfileModalListener } from '../guides/results/ProfileModalListener'
import { useGuideSearchQuery } from '../guides/results/useGuideSearchQuery'

export const Guides = () => {
  const [searchParams] = useSearchParams()
  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const location = searchParams.get('location')
  const lat = latitude ? parseFloat(latitude) : undefined
  const lon = longitude ? parseFloat(longitude) : undefined
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [profileAccountHandle] = useState('')
  const onClickOwnerProfile = useOnClickOwnerProfile()
  const viewMoreUrl = `/${guidesSegment}/all/?sort=RECOMMENDED&${searchParams.toString()}`

  const locationString = location.split(',')
  const locationTrimmed = locationString[0]

  const { loading, data, error } = useGuideSearchQuery({
    latitude: lat,
    longitude: lon,
    queryCount: 4,
    location,
    sortBy: GuideSorts.DistanceNearToFar,
  })

  const guides = data?.guideSearch?.edges.map(edge => edge.node)
  const isEmpty = !guides?.length
  const hasNoResults = !guides?.length && !loading && !error

  if (hasNoResults) return null

  return (
    <div>
      {isProfileModalOpen && (
        <ProfileModal
          accountHandle={profileAccountHandle}
          onClose={() => setIsProfileModalOpen(false)}
        />
      )}
      <PageLayoutContainer>
        <section className='space-y-6'>
          {loading && (
            <div className='grid gap-5 md:grid-cols-2 lg:grid-cols-4'>
              <GuideRecommendedCardLoading />
              <GuideRecommendedCardLoading />
              <div className='max-lg:hidden'>
                <GuideRecommendedCardLoading />
              </div>
              <div className='max-lg:hidden'>
                <GuideRecommendedCardLoading />
              </div>
            </div>
          )}
          {error && (
            <>
              <p>Oops! There was a problem.</p>
              <pre>{error.message}</pre>
            </>
          )}
          {guides && !isEmpty && (
            <>
              <header
                className={`flex flex-col items-center space-y-6 text-center ${location ? 'block' : 'hidden'}`}
              >
                <h6 className='c-grey-800 type-h6'>Travel Guides</h6>
                <div className='border-3px border-valley w-26 border-solid'></div>
                <h2 className='type-h1'>
                  Ultimate Insider Guides to {locationTrimmed}
                </h2>
              </header>

              <div className='space-y-6'>
                <div className='group flex flex-col items-center gap-4 md:flex-row md:justify-center'>
                  {guides.map(guide => (
                    <div
                      key={guide.id}
                      className='w-2/3 md:w-1/2 lg:w-1/3 lg:group-has-[>:nth-child(4)]:w-1/4 [&:nth-of-type(n+3)]:hidden lg:[&:nth-of-type(n+3)]:block'
                    >
                      <GuideRecommendedCard
                        guideRecommendedCardData={guide}
                        onClickOwnerProfile={onClickOwnerProfile}
                      />
                    </div>
                  ))}
                </div>
                <div className='flex flex-row justify-center'>
                  <Link href={viewMoreUrl} label='View More' target='_blank' />
                </div>
              </div>
            </>
          )}
        </section>
      </PageLayoutContainer>
      <ProfileModalListener />
      <AddToCollectionsModalListener />
    </div>
  )
}
