import { useState } from 'react'
import { Button, Icon, Illustration, Toggle } from '@travelpass/design-system'
import { GuideStatus } from 'src/__generated__/graphql'
import { useUpdateGuideMutation } from 'src/pages/guides/useUpdateGuideMutation'
import type { GuideDraftData } from '../../types'
import { GuideDraftGroup } from '../GuideDraftGroup'
import { GuideDraftPublish, GuideDraftRepublish } from '../GuideDraftPublish'

interface GuideDraftShareSettingsProps {
  guideDraftData: GuideDraftData
  isPublished: boolean
}

export const GuideDraftShareSettings = ({
  guideDraftData,
  isPublished,
}: GuideDraftShareSettingsProps) => {
  const [isModalPublishOpen, setIsModalPublishOpen] = useState(false)
  const [isModalRepublishOpen, setIsModalRepublishOpen] = useState(false)
  const [updateGuide, { loading: isLoading }] = useUpdateGuideMutation()
  const { id } = guideDraftData?.guide ?? {}

  const onChange = async () => {
    await updateGuide({
      variables: {
        updateGuideInput: {
          guideId: id,
          status: isPublished
            ? GuideStatus.ViewableWithLink
            : GuideStatus.Published,
        },
      },
    })
  }

  const onPublish = () => {
    if (id) return setIsModalRepublishOpen(true)

    setIsModalPublishOpen(true)
  }

  if (!id) return

  return (
    <>
      {isPublished && (
        <section className='md:p-t-3 md:p-x-6 md:p-b-4 md:bg-warm-grey md:rounded-3 space-y-5'>
          <header className='space-y-3'>
            <h4 className='c-black type-h5-mobile lg:type-h6-desktop'>
              Privacy Settings
            </h4>
            <p className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop'>
              When published, your guide will automatically be public within our
              travel community. Toggle the switch to make your guide public.
            </p>
          </header>
          <div className='flex flex-row items-center gap-3'>
            <div className='grow space-y-1'>
              <h5 className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop [&>i]:c-new-forest flex flex-row items-center gap-1'>
                <Icon name='visibility' size='small' />
                <span className='font-700'>Your Guide is Public</span>
                <span className='bg-valleyrounded-1/2 h-3 w-3' />
              </h5>
              <p className='color-grey-800 type-body-2'>
                Anyone on Travelpass can view
              </p>
            </div>
            <Toggle
              checked={isPublished}
              disabled={isLoading}
              name='checking-checking'
              onChange={onChange}
            />
          </div>
        </section>
      )}
      {!isPublished && (
        <GuideDraftGroup className='flex flex-col-reverse items-center justify-center gap-6 text-center md:flex-row md:text-left'>
          <header className='grow space-y-3'>
            <h4 className='c-black type-h4'>
              Share your Guide with the Travelpass community
            </h4>
            <p className='type-body-2 c-grey-900'>
              Make your Guide public to share travel tips and experiences with
              friends, family, and fellow explorers.
            </p>
            <div className='m-a w-fit md:w-auto'>
              <Button onClick={onPublish}>Publish your guide</Button>
            </div>
          </header>
          <div className='w-56 min-w-56'>
            <Illustration name='scooter' />
          </div>
        </GuideDraftGroup>
      )}
      {isModalPublishOpen && (
        <GuideDraftPublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalPublishOpen(false)}
        />
      )}
      {isModalRepublishOpen && (
        <GuideDraftRepublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalRepublishOpen(false)}
        />
      )}
    </>
  )
}
