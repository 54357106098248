import { useCallback, useEffect, useRef } from 'react'
import { Illustration, Skeleton } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash.isempty'
import { useGetImageSearchQuery } from 'src/common/hooks'
import { GuideImage } from 'src/pages/guides/details/common'
import type { UseGuideDraftImagesEdit } from '../../../useGuideDraftImagesEdit'

interface GuideDraftImagesEditSearchContentProps {
  images: UseGuideDraftImagesEdit['images']
  onSelected: UseGuideDraftImagesEdit['onSelected']
  searchString: string
}

export const GuideDraftImagesEditSearchContent = ({
  images,
  onSelected,
  searchString,
}: GuideDraftImagesEditSearchContentProps) => {
  const [getImageSearchQuery, { data, loading: isLoading, error: hasError }] =
    useGetImageSearchQuery()
  const isInitialRender = useRef(true)
  const debouncedSearchImages = useCallback(
    debounce(searchString => {
      getImageSearchQuery({
        variables: {
          limit: 12,
          searchString,
        },
      })
    }, 250),
    [getImageSearchQuery]
  )

  useEffect(() => {
    if (isInitialRender.current) {
      getImageSearchQuery({
        variables: {
          limit: 12,
          searchString,
        },
      })
      isInitialRender.current = false
    } else {
      debouncedSearchImages(searchString)
    }
  }, [searchString, debouncedSearchImages])

  if (isLoading)
    return (
      <div className='b-1 b-grey-300 b-solid rounded-2 grid grid-cols-2 gap-1 bg-white md:grid-cols-3 md:gap-2'>
        <div className='b-4 b-solid b-transparent h-40 p-2'>
          <Skeleton variant='rounded' />
        </div>
        <div className='b-4 b-solid b-transparent h-40 p-2'>
          <Skeleton variant='rounded' />
        </div>
        <div className='b-4 b-solid b-transparent h-40 p-2'>
          <Skeleton variant='rounded' />
        </div>
        <div className='b-4 b-solid b-transparent h-40 p-2'>
          <Skeleton variant='rounded' />
        </div>
        <div className='b-4 b-solid b-transparent h-40 p-2'>
          <Skeleton variant='rounded' />
        </div>
        <div className='b-4 b-solid b-transparent h-40 p-2'>
          <Skeleton variant='rounded' />
        </div>
      </div>
    )

  if (hasError || isEmpty(data?.imageSearch) || !searchString.trim())
    return (
      <div className='b-1 b-grey-300 b-solid p-b-10 p-t-8 p-x-20 rounded-2 md:p-x-40 flex h-full flex-col items-center justify-center gap-4 bg-white'>
        <div className='w-31'>
          <Illustration name='world' />
        </div>
        <span className='color-grey-700 type-body-1'>
          Search destinations above and choose photos for your guide.
        </span>
      </div>
    )

  return (
    <div className='md:p-x-3 grid grid-cols-2 gap-1 md:grid-cols-3 md:gap-2'>
      {data?.imageSearch?.map((imageSearchItem, index) => (
        <button
          key={index}
          aria-pressed={images?.includes(imageSearchItem)}
          className='b-1 b-grey-200 b-solid transition-opacity-200 rounded-2 aria-pressed:b-forest-light aria-pressed:b-2 aria-pressed:op-50 p-1.25 relative h-24 bg-white ease-linear hover:cursor-pointer md:h-40'
          onClick={() => onSelected(imageSearchItem)}
        >
          <GuideImage
            className='rounded-1.5'
            draggable={false}
            src={imageSearchItem}
          />
        </button>
      ))}
    </div>
  )
}
