import { Button, Divider } from '@travelpass/design-system'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'

export const UtahJazzSignUp = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  const imagePath =
    'https://static.travelpass.com/assets/landing-pages/utah-jazz/'

  const onViewItinerariesClick = () => {
    const url = '/utah-jazz/away-game-itineraries'
    navigate(url)
  }

  const onFormClick = e => {
    const href = '#travel-info'
    e.preventDefault()
    const node = document.getElementById('travel-info')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='wfull bg-canyonDark py4'>
        <a className='w250px ma block' href='/'>
          <img
            alt='Utah Jazz + Travelpass Logo Lockup'
            className='wfull block'
            src={`${imagePath}jazz-travelpass-logo-lockup.png`}
          />
        </a>
      </div>

      <div className='hero wfull py6 md:py15 relative bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/cd6b2548-b0ae-11ee-a39b-0a6b8d789641)] bg-cover bg-center'>
        <h1 className={heroTitleText}>Welcome, Jazz Fans!</h1>
        <div className='w100% h100% z-1 absolute left-0 top-0 bg-[rgba(0,0,0,0.35)]'></div>
      </div>

      <PageLayoutContainer>
        <div className='wfull py14 bg-white' id='travel-info'>
          <div className='weblayer--email-form-section relative'>
            {/* Bloomreach Weblayer - bannerId: 65a188cab1d21c8c8bb7df32 */}
          </div>
        </div>
      </PageLayoutContainer>

      <Divider />

      {/*
        * Temporarily removing this content - Will be added back next season when the new schedule/itineraries are released.
        *
      <PageLayoutContainer>
        <div className={flexContainer}>
          <div className='basis-1/2'>
            <img
              alt='Hassle-free trip planning'
              className='w350px mxa mb8 md:mb0 md:w80% block'
              src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/43fbbe68-b0b7-11ee-9fb0-060bb23e1103'
            />
          </div>
          <div className='px4 basis-1/2'>
            <p className={overlineText}>Hassle Free Trip Planning</p>
            <h2 className={titleText}>
              Your Next (Epic) Trip Is Already Planned
            </h2>
            <p className={bodyText}>
              Travelpass has created custom, easy-to-follow away game
              itineraries, with all the details planned out by our team of
              travel experts. All you need to do is book your hotels and
              experiences, then cheer on the Jazz!
            </p>
            <div className={buttonContainer}>
              <Button
                fullWidth
                label='View Itineraries'
                size='large'
                onClick={onViewItinerariesClick}
              />
            </div>
          </div>
        </div>
      </PageLayoutContainer>

      <Divider /> */}

      <PageLayoutContainer>
        <div className={flexContainer}>
          <div className='px4 basis-1/2'>
            <img
              alt='Explore your own backyard'
              className='w350px mxa mb8 md:mb0 md:w80% block'
              src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/7d8a126a-b0c6-11ee-b021-9a1e15f879c1'
            />
          </div>
          <div className='px4 basis-1/2'>
            <p className={overlineText}>Explore Your Own Backyard</p>
            <h2 className={titleText}>Get More Out Of Every Home Game</h2>
            <p className='type-body-1 mb4'>
              We feel immensely proud to be partners with the Utah Jazz. As a
              Utah-based company eager to explore our own backyard as much as
              our favorite cities in the U.S., we aim to provide a collaboration
              that brings as much value to the fans as it does the team.
            </p>
            <p className='type-body-1 mb4'>
              Now you can easily travel with the team for away games, or use
              Travelpass to make home games an opportunity for adventure. Get
              the inside scoop about a new restaurant to try before tipoff, or
              head to the city early to truly explore Salt Lake City before
              (loudly) cheering on the Jazz at the Delta Center.
            </p>
            <p className='type-body-1'>
              Each itinerary and recommendation comes straight from our team of
              travel insiders. Travelpass is your key to travel, knowing you’ll
              experience the best of the best.
            </p>
          </div>
        </div>
      </PageLayoutContainer>

      <Divider />

      <PageLayoutContainer>
        <div className={flexContainerReverse}>
          <div className='px4 basis-1/2'>
            <p className={overlineText}>Adventure Awaits</p>
            <h2 className={titleText}>Eager For More Adventure?</h2>
            <p className={bodyText}>
              Take a ski trip here (or nearby), or escape the snow to hit the
              beach. Plan a quick getaway, an epic road trip, or finally make
              that family vacation happen. Easily find top-rated hotels and
              incredible activities in all of your favorite cities with
              Travelpass.
            </p>
            <Button
              label='Send Me Insider Travel Info'
              size='large'
              onClick={onFormClick}
            />
          </div>
          <div className='px4 basis-1/2'>
            <img
              alt='Adventure awaits'
              className='w350px mxa mb8 md:mb0 md:w80% block'
              src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/2d31694a-b0ca-11ee-b5d9-b2d50d4938bd'
            />
          </div>
        </div>
      </PageLayoutContainer>
    </>
  )
}

const metaDescription =
  'Welcome, Utah Jazz fans! Unlock exclusive deals on hotels and experiences by becoming a Travelpass member.'
const pageName = 'Welcome, Jazz fans!'

const flexContainer = 'wfull flex flex-col md:flex-row items-center py8'
const flexContainerReverse =
  'wfull flex flex-col flex-col-reverse md:flex-row items-center py8'
const heroTitleText =
  'relative z-2 text-center c-white font-archivo font-not-oblique font-700 tracking-5% leading-18 text-10 lg:text-20'
const overlineText = 'type-overline uppercase c-canyonDark mb2'
const titleText = 'type-h1 mb4'
const bodyText = 'type-body-1 mb8'
const buttonContainer = 'w350px mxa md:ml0'
