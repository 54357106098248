import { Button, Divider } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { FormCheckbox } from 'src/common/components/FormCheckbox'

const emailFormFields = [
  'sendFeatureUpdatesEmail',
  'sendInspirationEmail',
  'sendPromotionEmail',
  'sendRecentReviewsEmail',
]

const smsFormFields = [
  'sendFeatureUpdatesSms',
  'sendInspirationSms',
  'sendPromotionSms',
  'sendRecentReviewsSms',
]

export const DashboardAccountSettingsFormNotifications = () => {
  const { setValue, watch } = useFormContext()
  const isEmailUnsubscribeDisabled = !watch(emailFormFields).some(
    field => field
  )
  const isSmsUnsubscribeDisabled = !watch(smsFormFields).some(field => field)

  const onUnsubscribeAll = (isEmail = false) => {
    const fieldsToUnsubscribe = isEmail ? emailFormFields : smsFormFields

    fieldsToUnsubscribe.forEach(field => setValue(field, false))
  }

  return (
    <div className='space-y-8'>
      <section className='rounded-4 b-1 b-grey-300 b-solid space-y-6 bg-white p-6'>
        <h3 className='type-h6'>Email Preferences</h3>
        <div className='space-y-1 md:space-y-2'>
          <FormCheckbox label='Deals & Promotions' name='sendPromotionEmail' />
          <FormCheckbox
            label='Travel tips and inspiration'
            name='sendInspirationEmail'
          />
          <FormCheckbox
            label='Product updates and features'
            name='sendFeatureUpdatesEmail'
          />
          <FormCheckbox
            label='Invitations to share a quick review or participate in a brief survey about your recent travel experience'
            name='sendRecentReviewsEmail'
          />
        </div>
        <div className='space-y-4'>
          <Divider />
          <p className='text-body-2'>
            You will still receive service emails for your bookings, account
            updates, loyalty program if you are a member of the <br /> program
            and other important information. Please note that this may take up
            to 10 business days to be effective.
          </p>
          <Button
            isDisabled={isEmailUnsubscribeDisabled}
            label='Unsubscribe all emails'
            size='small'
            type='button'
            variant='outlined'
            onClick={() => onUnsubscribeAll(true)}
          />
        </div>
      </section>
      <section className='rounded-4 b-1 b-grey-300 b-solid space-y-6 bg-white p-6'>
        <h3 className='type-h6'>SMS Preferences</h3>
        <div className='space-y-1 md:space-y-2'>
          <FormCheckbox label='Deals & Promotions' name='sendPromotionSms' />
          <FormCheckbox
            label='Travel tips and inspiration'
            name='sendInspirationSms'
          />
          <FormCheckbox
            label='Product updates and features'
            name='sendFeatureUpdatesSms'
          />
          <FormCheckbox
            label='Invitations to share a quick review or participate in a brief survey about your recent travel experience'
            name='sendRecentReviewsSms'
          />
        </div>
        <div className='space-y-4'>
          <Divider />
          <p className='text-body-2'>
            You will still receive notifications for bookings, account updates,
            and other important communication. Please note that this may take up
            to 10 business days to be effective.
          </p>
          <Button
            isDisabled={isSmsUnsubscribeDisabled}
            label='Unsubscribe all SMS'
            size='small'
            variant='outlined'
            onClick={() => onUnsubscribeAll()}
          />
        </div>
      </section>
    </div>
  )
}
