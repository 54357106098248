import {
  Button,
  Icon,
  Illustration,
  SkeletonDots,
  Toggle,
} from '@travelpass/design-system'
import { GuideStatus } from 'src/__generated__/graphql'
import { useCreateGuideFromGuideDraftMutation } from 'src/pages/guides/useCreateGuideFromGuideDraftMutation'
import { useUpdateGuideMutation } from 'src/pages/guides/useUpdateGuideMutation'
import { getGuideDraftInvalidFields } from '../../../guideDraftUtils'
import type { GuideDraftData } from '../../../types'
import { GuideDraftGroup } from '../../GuideDraftGroup'

interface GuideDraftShareIntroSettingsProps {
  guideDraftData: GuideDraftData
  onStepChange: (updatedStep: number) => void
}

export const GuideDraftShareIntroSettings = ({
  guideDraftData,
  onStepChange,
}: GuideDraftShareIntroSettingsProps) => {
  const [createGuideFromGuideDraft, createGuideFromGuideDraftResult] =
    useCreateGuideFromGuideDraftMutation(guideDraftData?.guide?.id)
  const [updateGuide, updateGuideResult] = useUpdateGuideMutation()
  const isLoading =
    createGuideFromGuideDraftResult?.loading || updateGuideResult?.loading
  const isPublished = guideDraftData?.guide?.status === GuideStatus.Published

  const onChange = async () => {
    await updateGuide({
      variables: {
        updateGuideInput: {
          guideId: guideDraftData?.guide?.id,
          status: isPublished
            ? GuideStatus.ViewableWithLink
            : GuideStatus.Published,
        },
      },
    })
  }

  const onShare = async () => {
    const invalidFields = getGuideDraftInvalidFields(guideDraftData)

    if (!invalidFields?.length) {
      try {
        await createGuideFromGuideDraft({
          variables: {
            input: {
              guideDraftId: guideDraftData?.id,
            },
          },
        })
        await updateGuide({
          variables: {
            updateGuideInput: {
              guideId: guideDraftData?.guide?.id,
              status: GuideStatus.Published,
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    }

    onStepChange(!invalidFields?.length ? 3 : 2)
  }

  if (isPublished)
    return (
      <section className='md:p-t-3 md:p-x-6 md:p-b-4 md:bg-warm-grey md:rounded-3 space-y-5'>
        <header className='space-y-3'>
          <h4 className='c-black type-h5-mobile lg:type-h6-desktop'>
            Privacy Settings
          </h4>
          <p className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop'>
            When published, your guide will automatically be public within our
            travel community. Toggle the switch to make your guide public.
          </p>
        </header>
        <div className='flex flex-row items-center gap-3'>
          <div className='grow space-y-1'>
            <h5 className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop [&>i]:c-new-forest flex flex-row items-center gap-1'>
              <Icon name='visibility' size='small' />
              <span className='font-700'>Your Guide is Public</span>
              <span className='bg-valleyrounded-1/2 h-3 w-3' />
            </h5>
            <p className='color-grey-800 type-body-2'>
              Anyone on Travelpass can view
            </p>
          </div>
          {isLoading && (
            <div className='h-10 content-center'>
              <SkeletonDots />
            </div>
          )}
          {!isLoading && (
            <Toggle
              autoFocus={true}
              checked={isPublished}
              name='checking-checking'
              onChange={onChange}
            />
          )}
        </div>
      </section>
    )

  return (
    <GuideDraftGroup className='flex flex-col-reverse items-center justify-center gap-6 text-center md:flex-row md:text-left'>
      <header className='grow space-y-3'>
        <h4 className='c-black type-h4'>Share Your Guide</h4>
        <p className='type-body-2 c-grey-900'>
          Don&apos;t be shy, it&apos;s time to share your expert tips! Publicly
          shared Guides are visible to anyone who visits Travelpass.com.
        </p>
        <div className='m-a w-fit md:w-auto'>
          <Button autoFocus={true} isDisabled={isLoading} onClick={onShare}>
            Share Guide
          </Button>
        </div>
      </header>
      <div className='w-56 min-w-56'>
        <Illustration name='scooter' />
      </div>
    </GuideDraftGroup>
  )
}
