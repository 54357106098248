import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  BookingTimeState,
  ExperienceBookingTimeState,
  GuideDraftStatus,
  GuideStatus,
  TripStatus,
} from 'src/__generated__/graphql'
import { type GetCurrentUserQueryInDashboardQuery } from 'src/__generated__/graphql'

/** @todo remove activityFeed when dashboardV2HideActivityFeed is enabled */
export const dashboardCurrentUserQuery = gql(`
  query GetCurrentUserQueryInDashboard($searchUserExperienceBookingsArgs: SearchUserExperienceBookingsArgs!, $searchUserHotelBookingsArgs: SearchUserHotelBookingsArgs! $tripSearchInput: TripSearchInput!, $userGuidesArgs: UserGuidesArgs!, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    currentUser {
      id
      userRoles
      collections(first: 1) {
        totalCount
      }
      experienceBookings(first: 1, searchUserExperienceBookingsArgs: $searchUserExperienceBookingsArgs) {
        totalCount
      }
      firstName
      hotelBookings(first: 1, searchUserHotelBookingsArgs: $searchUserHotelBookingsArgs) {
        totalCount
      }
      searchPreferences {
        id
      }
      trips(first: 1, tripSearchInput: $tripSearchInput) {
        totalCount
      }
      userGuides(first: 5, userGuidesArgs: $userGuidesArgs) {
        edges {
          node {
            id
            name
            imageUrl
            images {
              id
              source
              url
            }
          }
        }
        totalCount,
      }
      userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
        totalCount
      }
      guideLikesCount
      guideSavesCount
      guideSharesCount
      guideViewsCount
      userProfile {
        id
        accountHandle
        activeBannerImage {
          id
          extraSmallUrl
          largeUrl
          mediumUrl
          smallUrl
        }
        displayName
        followCount {
          countOfFollowed
          countOfFollowing
        }
        followersConnection(first: 10, timeRange: 7) {
          edges {
            node {
              id
              accountHandle
              profileImageUrl
              displayName
              isFollowed
              userId
            }
          }
          totalCount
        }
        guideCount(guideStatus: PUBLISHED)
        isUserOwner
        profileCompletion {
          completionPercentage
          hasBio
          hasProfileImage
          hasSocialLinks
          hasTravelStats
          hasTravelStyle
        }
        competitionInfo {
          status
          leaderboardRanking
          voteCount
          competitionCheck{
            hasProfileImage
            hasBio
            hasVideoLink
            highQualityGuideCount
            isCompetitionReady
          }
        }
        profileImageSource
        profileImageUrl
        userId
      }
    }
  }
`)

export type UseGetDashboardQuery = {
  dashboardData: GetCurrentUserQueryInDashboardQuery
  hasError: ApolloError
  isLoading: boolean
  refetch
}

/** @todo waiting on BE to return follower feed */
export const useGetDashboardQuery = (): UseGetDashboardQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
    refetch,
  } = useQuery(dashboardCurrentUserQuery, {
    fetchPolicy: 'network-only',
    variables: {
      searchUserExperienceBookingsArgs: {
        bookingTimeState: ExperienceBookingTimeState.Upcoming,
      },
      searchUserHotelBookingsArgs: {
        bookingTimeState: BookingTimeState.Upcoming,
      },
      tripSearchInput: {
        status: TripStatus.Upcoming,
      },
      userGuidesArgs: {
        includeStatuses: [GuideStatus.Published],
      },
      userGuideDraftsArgs: {
        includeStatuses: [GuideDraftStatus.Active],
      },
    },
  })
  const dashboardData = data ?? null

  return {
    dashboardData,
    hasError,
    isLoading,
    refetch,
  }
}
