import { useEffect, useRef, useState } from 'react'
import {
  Avatar,
  Button,
  Input,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import type { SubmitHandler, UseFormGetValues } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import type {
  EnterCompetitionMutationVariables,
  UserProfileRequest,
} from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getGuideOwnerUrl } from 'src/utils'
import type {
  CompetitionUserProfile,
  PersonalInformationInputFields,
} from './Apply'
import { COMPETITION_SESSION_STORAGE } from './competitionConstants'
import { useEnterCompetitionMutation } from './hooks/useEnterCompetitionMutation'
import { hasUserEnteredCompetition } from './utils'
import { UploadAvatarModal } from '../profile/components/UploadAvatarModal/UploadAvatarModal'
import { useUpsertCurrentUserProfile } from '../profile/components/hooks/useUpsertCurrentUserProfile'

type BasicProfileInputFields = {
  accountHandle: string
  displayName: string
}

const constructUserProfileRequest = (
  data: CompetitionUserProfile
): UserProfileRequest => {
  const userProfileRequest: UserProfileRequest = {
    accountHandle: data.accountHandle,
    bannerImageId: data.activeBannerImage?.id,
    bio: data.bio,
    bragCities: data.bragCities,
    bragContinents: data.bragContinents,
    bragCountries: data.bragCountries,
    bragStates: data.bragStates,
    displayName: data.displayName,
    introVideoEmbed: data.introVideoEmbed,
    links: data.links?.map(({ title, url }) => ({
      title,
      url,
    })),
    profileImageSource: data.profileImageSource,
    profileImageUrl: data.profileImageUrl,
    profileTagIds: data.tags?.map(tag => tag.id),
    socialLinks: data.socialLinks?.map(({ identifier, network }) => ({
      identifier,
      network,
    })),
    visibilityLevel: data.visibilityLevel,
  }
  return userProfileRequest
}

export const BasicProfileInfoForm = ({
  changeStep,
  getPersonalInformation,
  userProfile,
}: {
  changeStep: VoidFunction
  getPersonalInformation: UseFormGetValues<PersonalInformationInputFields>
  userProfile: CompetitionUserProfile
}) => {
  // Custom hooks
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const enableSMSCompetition2024 = useFlag('enableSMSCompetition2024')
  const { addErrorSnack } = useSnackbar()
  const { isMobileScreen } = useScreenQuery()
  const to = useNavigate()
  const personalInformation = getPersonalInformation()
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    watch,
  } = useForm<BasicProfileInputFields>({
    defaultValues: {
      displayName:
        `${personalInformation?.firstName} ${personalInformation?.lastName}` ||
        '',
      accountHandle: userProfile?.accountHandle || '',
    },
  })

  // Queries
  const [enterCompetition, { loading }] = useEnterCompetitionMutation()
  const [upsertUserProfile] = useUpsertCurrentUserProfile()

  // State
  const [isModalOpen, setModalOpen] = useState(false)
  const scrollRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const onSubmit: SubmitHandler<BasicProfileInputFields> = async formData => {
    try {
      // update the account handle and display name with the user's input while also passing in their existing data to make sure it does not get deleted
      const { data: upsertData } = await handleUpdateUser({
        ...userProfile,
        ...formData,
      })

      const { accountHandle, displayName } =
        upsertData?.upsertProfile?.userProfile

      if (accountHandle && displayName) handleEnterCompetition(accountHandle)
    } catch (e) {
      if (e?.message?.includes('account_handle already exists')) {
        setError('accountHandle', {
          message: 'Account handle is already in use',
        })
      } else {
        addErrorSnack({
          title: 'Oops, something went wrong! Try again later',
        })
      }
    }
  }

  const handleEnterCompetition = async (accountHandle: string) => {
    const referrerId = sessionStorage.getItem(COMPETITION_SESSION_STORAGE)

    if (personalInformation?.agreedToSMS && enableSMSCompetition2024) {
      pushDataToDataLayer('double_opt_in', {
        phone: personalInformation.phoneNumber,
        source: 'SMS Sign-up Unit - Desktop & Mobile',
      })
    }
    delete personalInformation.agreedToSMS

    const variables: EnterCompetitionMutationVariables = {
      input: { userProfileId: userProfile.id, ...personalInformation },
    }

    if (referrerId && enableCompetition2024)
      variables.input.referrerId = referrerId

    const result = await enterCompetition({ variables })
    const success = hasUserEnteredCompetition(
      result?.data?.enterCompetition?.participant?.status
    )

    /** @todo: should there be a success message on the UI? */
    if (success) {
      sessionStorage.removeItem(COMPETITION_SESSION_STORAGE)
      to('/dashboard?tab=guides&collapsed=false')
      pushDataToDataLayer('competition_entry', {
        user_id: result?.data?.enterCompetition?.participant?.id,
        status: result?.data?.enterCompetition?.participant?.status,
        user_email: personalInformation?.email,
        profile_url: `${window.location.origin}${getGuideOwnerUrl(accountHandle)}`,
        timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
      })
    }
  }

  const handleUpdateUser = async (data: CompetitionUserProfile) => {
    const userProfileRequest = constructUserProfileRequest(data)
    const upsertData = await upsertUserProfile({
      variables: { input: { userProfileRequest } },
    })
    return upsertData
  }

  return (
    <>
      {isModalOpen && (
        <UploadAvatarModal
          avatar={userProfile?.profileImageUrl}
          onClose={() => setModalOpen(false)}
        />
      )}
      <div className='job-promotion max-w-588px mb-30 mx-auto mt-12 space-y-11 md:mt-16 md:space-y-12'>
        <h1 className='text-8 lg:text-9 m-unset text-center' ref={scrollRef}>
          Set Up Your Profile
        </h1>
        <section className='flex flex-col gap-11 md:flex-row md:items-center md:gap-12'>
          <div className='[&_button]:type-button md:[&_img]:min-w-55 md:[&_img]:min-h-55 flex flex-col items-center justify-center [&_button]:capitalize'>
            <Avatar
              border={isMobileScreen ? 'none' : 'lg'}
              size='lg'
              src={userProfile?.profileImageUrl}
            />
            <Button
              className='type-button'
              label='Edit photo'
              size='small'
              startIcon='modeEdit'
              variant='text'
              onClick={() => setModalOpen(true)}
            />
          </div>
          <form
            className='flex w-full flex-col space-y-6 pr-12 max-md:px-6'
            id='basic-profile-info-form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              fullWidth
              {...register('displayName', {
                maxLength: { value: 25, message: 'Max 25 characters' },
                minLength: {
                  value: 1,
                  message: 'Display name must not be empty',
                },
              })}
              required
              errorText={errors?.displayName?.message}
              helperText={`${watch('displayName').length}/25. Name displayed on your profile.`}
              label='Display Name'
            />
            <Input
              fullWidth
              {...register('accountHandle', {
                maxLength: { value: 25, message: 'Max 25 characters' },
                minLength: {
                  value: 3,
                  message: 'Account handle must be at least 3 characters',
                },
                pattern: {
                  value: /[1-9A-Za-z._]/,
                  message: 'Invalid account handle',
                },
              })}
              required
              errorText={errors?.accountHandle?.message}
              helperText={`${watch('accountHandle').length}/25. Use only letters, numbers, ".", and "_". No spaces.`}
              label='Account Handle'
            />
          </form>
        </section>
        <div className='flex w-full flex-row-reverse items-center justify-center gap-6'>
          <Button
            aria-label='Save'
            disabled={loading}
            endIcon='check'
            form='basic-profile-info-form'
            label='Save'
            size='large'
            type='submit'
          />
          <Button
            aria-label='Back'
            disabled={loading}
            label='back'
            size='large'
            startIcon='arrowBack'
            variant='outlined'
            onClick={() => changeStep()}
          />
        </div>
      </div>
    </>
  )
}
