import { getDashboardFeedSuggestedGuidesSlicedData } from 'src/pages/dashboard/dashboardUtils'
import { DashboardFeedSuggestedGuides } from './DashboardFeedSuggestedGuides'
import { DashboardFeedSuggestedTravelpassGuides } from './DashboardFeedSuggestedTravelpassGuides'
import type { UseGetDashboardFeedQuery } from '../../../useGetDashboardFeedQuery'

const guidesCount = 10
const guideInterval = 10
const travelpassGuideInterval = 30

interface DashboardFeedSuggestedGuidesWrapperProps {
  dashboardFeedSuggestedGuidesData: UseGetDashboardFeedQuery['dashboardFeedSuggestedGuidesData']
  dashboardFeedSuggestedTravelpassGuidesData: UseGetDashboardFeedQuery['dashboardFeedSuggestedTravelpassGuidesData']
  feedIndex: number
  isDashboardV3FeedSuggestedGuidesEnabled: boolean
  isDashboardV3FeedTravelpassGuidesEnabled: boolean
}

export const DashboardFeedSuggestedGuidesWrapper = ({
  dashboardFeedSuggestedGuidesData,
  dashboardFeedSuggestedTravelpassGuidesData,
  feedIndex,
  isDashboardV3FeedSuggestedGuidesEnabled,
  isDashboardV3FeedTravelpassGuidesEnabled,
}: DashboardFeedSuggestedGuidesWrapperProps) => {
  if (feedIndex === 0) return null

  /** Travelpass Guides */
  if (
    isDashboardV3FeedTravelpassGuidesEnabled &&
    (feedIndex + 1) % travelpassGuideInterval === 0
  ) {
    const slicedData = getDashboardFeedSuggestedGuidesSlicedData({
      guidesCount,
      isTravelpassGuide: true,
      dashboardFeedSuggestedTravelpassGuidesData,
    })

    return (
      <DashboardFeedSuggestedTravelpassGuides
        dashboardFeedSuggestedTravelpassGuidesData={
          slicedData as UseGetDashboardFeedQuery['dashboardFeedSuggestedTravelpassGuidesData']
        }
      />
    )
  }

  /** Suggested Guides*/
  if (
    isDashboardV3FeedSuggestedGuidesEnabled &&
    (feedIndex + 1) % guideInterval === 0
  ) {
    const slicedData = getDashboardFeedSuggestedGuidesSlicedData({
      guidesCount,
      isTravelpassGuide: false,
      dashboardFeedSuggestedGuidesData,
    })

    return (
      <DashboardFeedSuggestedGuides
        dashboardFeedSuggestedGuidesData={
          slicedData as UseGetDashboardFeedQuery['dashboardFeedSuggestedGuidesData']
        }
      />
    )
  }

  return
}
