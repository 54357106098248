import { useSearchParams } from 'react-router-dom'
import type { GuideSorts } from 'src/__generated__/graphql'

enum GuidesSearchParams {
  displayName = 'displayName',
  similarName = 'similarName',
  latitude = 'latitude',
  longitude = 'longitude',
  location = 'location',
  sortBy = 'sort',
  spGuideType = 'type',
  country = 'country',
  state = 'state',
}

export const useGuidesSearchParams = () => {
  const [searchParams] = useSearchParams()

  return {
    displayName: searchParams.get(GuidesSearchParams.displayName),
    similarName: searchParams.get(GuidesSearchParams.similarName),
    latitude: searchParams.get(GuidesSearchParams.latitude),
    longitude: searchParams.get(GuidesSearchParams.longitude),
    location: searchParams.get(GuidesSearchParams.location),
    sortBy: searchParams.get(GuidesSearchParams.sortBy) as GuideSorts,
    spGuideType: searchParams.get(GuidesSearchParams.spGuideType),
    country: searchParams.get(GuidesSearchParams.country),
    state: searchParams.get(GuidesSearchParams.state),
  }
}
