import {
  Divider,
  Icon,
  IconButton,
  Input,
  useSnackbar,
} from '@travelpass/design-system'
import { GuideImage } from 'src/pages/guides/details/common'
import {
  constructAddress,
  copyToClipboard,
  getGuideDetailsUrl,
} from 'src/utils'
import type { GuideDraftData } from '../../types'

interface GuideDraftShareSuccessProps {
  guideDraftData: GuideDraftData
}

export const GuideDraftShareSuccess = ({
  guideDraftData,
}: GuideDraftShareSuccessProps) => {
  const { addSuccessSnack } = useSnackbar()
  const { addresses, guide } = guideDraftData ?? {}
  const address = constructAddress({
    addressFirstLine: addresses?.[0]?.addressLine1,
    city: addresses?.[0]?.city,
    state: addresses?.[0]?.state,
  })
  const image = guideDraftData?.images?.[0]?.url
  const url = `${window.location.origin}${getGuideDetailsUrl({
    id: guide?.id,
    name: guide?.name,
  })}`

  const onCopy = () => {
    copyToClipboard(url)
    addSuccessSnack({
      title: 'Copied to clipboard!',
    })
  }

  return (
    <section className='md:bg-warm-grey md:p-x-6 md:p-y-4 md:rounded-3 flex flex-col gap-4 md:flex-row md:justify-center md:gap-6'>
      <header className='min-h-50 p-x-6 p-y-4 of-hidden rounded-2 relative flex flex-col justify-end gap-1 md:w-72 md:min-w-72'>
        <GuideImage className='absolute inset-0' src={image} />
        <div className='from-black/58 absolute inset-0 bg-gradient-to-l via-black/30 via-40% to-black/65 to-100%' />
        <h3 className='c-white type-h3-mobile relative'>
          {guideDraftData?.name}
        </h3>
        {address && (
          <p className='c-white type-overline-mobile relative'>{address}</p>
        )}
      </header>
      <div className='md:p-y-10 grow space-y-4 md:space-y-6'>
        <div className='type-h6-desktop flex flex-row items-center gap-2'>
          <Icon name='visibility' />
          Your Guide is Live
          <span className='rounded-1/2 bg-valley h-3 w-3' />
        </div>
        <Divider />
        <div className='space-y-3'>
          <h4 className='c-grey-900 type-h5-mobile lg:type-h6-desktop'>
            View And Share
          </h4>
          <p className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop'>
            View your Guide below or copy the link to share it with friends and
            family.
          </p>
        </div>
        <Input
          fullWidth={true}
          readOnly={true}
          slotBefore={
            <IconButton
              aria-label='Copy guide URL'
              color='transparent'
              icon='contentCopy'
              onClick={onCopy}
            />
          }
          value={url}
        />
      </div>
    </section>
  )
}
