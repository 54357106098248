import { useQuery } from '@apollo/client'
import type { AvailableIcons } from '@travelpass/design-system'
import { gql } from 'src/__generated__'

const PREDEFINED_GUIDE_TAGS_QUERY = gql(`
  query GetPredefinedGuideTags {
    getPredefinedGuideTags {
      id
      name
    }
  }
`)

const iconsTest = {
  Family: 'peopleOutline',
  Relax: 'hottub',
  Adventure: 'mapOutlined',
  Art: 'brush',
  Food: 'restaurant',
  Outdoors: 'nordicWalking',
  Culture: 'language',
  Sports: 'downhillSkiing',
  City: 'business',
  Water: 'pool',
  Beach: 'beachAccess',
  Mountain: 'forest',
  Music: 'musicNote',
  Museums: 'museum',
  Nightlife: 'star',
  Shopping: 'shoppingBag',
  Scenic: 'spa',
  'Hidden Gems': 'stream',
}

type GuideTags = { id: string; name: string; icon?: AvailableIcons }[]

export const useGetPredefinedGuideTagsQuery = (
  addIcons = false,
  skip = false
) => {
  const {
    data: { getPredefinedGuideTags: predefinedGuideTags } = {},
    loading,
  } = useQuery(PREDEFINED_GUIDE_TAGS_QUERY, { skip })

  predefinedGuideTags?.map(({ name, id }) => ({
    id,
    name,
    icon: iconsTest[name],
  }))
  const withoutIcons = predefinedGuideTags
  const withIcons = !addIcons
    ? []
    : predefinedGuideTags?.map(({ name, id }) => ({
        id,
        name,
        icon: iconsTest[name],
      }))

  return { tags: (addIcons ? withIcons : withoutIcons) as GuideTags, loading }
}
