import type { DragEvent } from 'react'
import { useRef, useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  SkeletonDots,
} from '@travelpass/design-system'
import classNames from 'classnames'
import {
  ProfileImageSource,
  type ProfileByAccountHandleQuery,
} from 'src/__generated__/graphql'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { ImageCropper } from 'src/pages/profile/components/ImageCropper'
import { useDashboardProfileAvatar } from '../../useDashboardProfileAvatar'

interface DashboardProfileAvatarModalProps {
  onDismiss: VoidFunction
  profile: ProfileByAccountHandleQuery['viewUserProfile']
}

export const DashboardProfileAvatarModal = ({
  onDismiss,
  profile,
}: DashboardProfileAvatarModalProps) => {
  const { providerData } = useFirebaseUser()
  const isConnectedToGoogle = providerData?.some(
    ({ providerId }) => providerId === 'google.com'
  )
  const {
    isUpdateProfileInfoLoading,
    onCroppedAreaPixelsChange,
    onDelete,
    onFileChange,
    onProfileImageSourceChange,
    onSubmit,
    previewUrl,
    profileImageSource,
  } = useDashboardProfileAvatar({ profile, onDismiss })
  const inputRef = useRef<HTMLInputElement>(null)
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const { profileImageUrl } = profile ?? {}

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingOver(false)
  }

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingOver(true)
  }

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingOver(false)
    const files = event.dataTransfer.files

    if (files.length) onFileChange(files?.[0])
  }

  return (
    <Modal title='Edit Profile Image' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div
          className={classNames(
            'drop-area p-x-2 md:p-x-0 flex flex-col items-center',
            {
              'bg-pink': isDraggingOver,
            }
          )}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          {profileImageSource !== ProfileImageSource.ClaimsImage ? (
            <ImageCropper
              image={previewUrl || profileImageUrl}
              onCropComplete={onCroppedAreaPixelsChange}
            />
          ) : (
            <img
              alt=''
              className='max-h-50 max-w-50 border-12 rounded-full border-solid border-white object-cover'
              src={previewUrl || profileImageUrl}
            />
          )}
          <p className='type-body-1 p-y-6 text-gray-700'>
            {profileImageSource === ProfileImageSource.ClaimsImage
              ? `Looks like your image is coming from ${isConnectedToGoogle ? 'Google' : 'Facebook'}. If you would like to change the size or position, please upload a new image.`
              : 'Edit your image by moving or adjusting the size of the circle.'}
          </p>
          <div>
            <div className='hidden gap-6 md:flex'>
              <Button
                isDisabled={
                  profileImageSource === ProfileImageSource.DefaultImage ||
                  profileImageSource === ProfileImageSource.ClaimsImage
                }
                label='Delete image'
                startIcon='deleteOutline'
                variant='outlined'
                onClick={onDelete}
              />
              <Button
                label='Upload image'
                startIcon='upload'
                variant='outlined'
                onClick={() => inputRef?.current.click()}
              />
            </div>
            <div className='flex flex-col flex-col-reverse gap-6 md:hidden'>
              <Button
                isDisabled={
                  profileImageSource === ProfileImageSource.DefaultImage ||
                  profileImageSource === ProfileImageSource.ClaimsImage
                }
                label='Delete image'
                size='small'
                startIcon='deleteOutline'
                variant='outlined'
                onClick={onDelete}
              />
              <Button
                label='Upload image'
                size='small'
                startIcon='upload'
                variant='outlined'
                onClick={() => inputRef.current.click()}
              />
            </div>
          </div>
          <input
            accept='image/*'
            ref={inputRef}
            style={{
              display: 'none',
            }}
            type='file'
            onChange={event => {
              onFileChange(event.target.files[0])
              onProfileImageSourceChange(ProfileImageSource.UserUploadedImage)
            }}
          />
        </div>
      </ModalScrollContents>
      <ModalActions>
        {isUpdateProfileInfoLoading ? (
          <div className='flex min-h-10 w-full flex-col items-center justify-center'>
            <SkeletonDots />
          </div>
        ) : (
          <Button autoFocus={true} onClick={onSubmit}>
            Save Image
          </Button>
        )}
      </ModalActions>
    </Modal>
  )
}
