import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  TextArea,
  Chip,
  Input,
} from '@travelpass/design-system'
import type { GuideDraft } from 'src/__generated__/graphql'
import { Geocoder } from 'src/common/components'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import { GuideDraftGroup, GuideDraftGroupHeader } from './GuideDraftGroup'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from '../guideDraftConstants'
import { useUpdateGuideDraftForm } from '../useUpdateGuideDraftForm'

interface GuideDraftEditModalProps {
  guideDraftData: Pick<
    GuideDraft,
    'addresses' | 'description' | 'guide' | 'id' | 'name'
  >
  onDismiss: VoidFunction
}

export const GuideDraftEditModal = ({
  guideDraftData,
  onDismiss,
}: GuideDraftEditModalProps) => {
  const { tags: predefinedGuidesTagsData, loading: isLoading } =
    useGetPredefinedGuideTagsQuery()
  const {
    formErrors,
    formFields,
    formHelperText,
    onFieldChange,
    onTagIdsChange,
    onSubmit,
  } = useUpdateGuideDraftForm({
    guideDraftData,
    onDismiss,
  })
  const { description, geocoder, name, tagIds } = formFields ?? {}

  return (
    <Modal title='Edit Guide Details' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
          <GuideDraftGroup>
            <GuideDraftGroupHeader title='Guide Title' />
            <Input
              aria-label='Add a name for your Guide'
              errorText={formErrors?.name}
              fullWidth={true}
              helperText={formHelperText?.name}
              maxLength={guideDraftNameMaxLength}
              name='name'
              required={true}
              value={name}
              onChange={event => onFieldChange('name', event.target.value)}
            />
          </GuideDraftGroup>
          <GuideDraftGroup>
            <GuideDraftGroupHeader title='Location' />
            <Geocoder
              aria-label='Search and add a destination for this guide'
              config={{
                requestOptions: {
                  componentRestrictions: {
                    country: null,
                  },
                },
              }}
              errorText={formErrors?.geocoder}
              focusOnInput={false}
              fullWidth={true}
              geocoder={geocoder}
              placeholder='Search Destination'
              required={true}
              onResult={updatedGeocoder =>
                onFieldChange('geocoder', updatedGeocoder)
              }
            />
          </GuideDraftGroup>
          <GuideDraftGroup>
            <GuideDraftGroupHeader title='Guide Description' />
            <TextArea
              aria-label='Add a description for your guide'
              errorText={formErrors?.description}
              helperText={formHelperText?.description}
              maxLength={guideDraftDescriptionMaxLength}
              required={true}
              rows={4}
              value={description}
              onInput={event =>
                onFieldChange('description', event.currentTarget.value)
              }
            />
          </GuideDraftGroup>
          {!isLoading && (
            <GuideDraftGroup>
              <GuideDraftGroupHeader
                subtitle='Select up to 5 tags to best describe your experience and help others discover your Guide'
                title='Tag Your Guide'
              />
              <div>
                <div className='b-1 b-solid rounded-2 flex flex-row flex-wrap gap-x-1 gap-y-2 bg-white p-1 md:gap-3 md:p-6'>
                  {predefinedGuidesTagsData?.map(({ id, name }) => (
                    <div key={id}>
                      <Chip
                        isSelected={tagIds.includes(id)}
                        label={name}
                        onClick={() => onTagIdsChange(id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {!!formHelperText?.tagIds && (
                <p className='type-body-1-medium c-forest-light text-right'>
                  {formHelperText?.tagIds}
                </p>
              )}
            </GuideDraftGroup>
          )}
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button autoFocus={true} onClick={onSubmit}>
          Save
        </Button>
        <Button variant='outlined' onClick={onDismiss}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
