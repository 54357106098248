import { useEffect, useRef, useState } from 'react'
import {
  Autocomplete,
  Divider,
  Icon,
  IconButton,
  KeyCode,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { getGeocode, getLatLng, type Suggestion } from 'use-places-autocomplete'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import type { ExploreGeolocation } from '../../exploreTypes'

interface LocationSearchBarProps {
  onSelection?: (selection: ExploreGeolocation) => void
  hideSlotBefore?: boolean
  clearOnSelection?: boolean
  placeholder?: string
  className?: string
}

export const LocationSearchBar = ({
  placeholder = 'Search',
  hideSlotBefore = false,
  clearOnSelection = false,
  onSelection,
  className,
}: LocationSearchBarProps) => {
  //Hooks
  const [showSearchTerm, setShowSearchTerm] = useState(false)
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const autocompleteParentRef = useRef<HTMLDivElement>(null)
  const { isMobileScreen } = useScreenQuery()
  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch({
      requestOptions: { componentRestrictions: { country: [] } },
      cacheKey: CACHE_KEYS.withoutRestriction,
    })
  const [searchParams] = useSearchParams()
  //Others
  const locationTitle = searchParams.get('locationTitle')
  const options = suggestions?.data || []
  const showOptions = Boolean(options?.length)
  const searchTerm = value?.trim()

  const handleClearAndFocus = ({
    newValue,
    focusesInput,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput: boolean
    shouldFetchData?: boolean
  }) => {
    newValue !== null && setValue('', shouldFetchData)
    clearSuggestions()
    setShowSearchTerm(false)
    // hacky way to force the functionality to work with ESC key
    autocompleteRef?.current?.blur()
    focusesInput && autocompleteRef?.current?.focus()
  }

  const handleOptionSelection = async (selection: Suggestion | string) => {
    const [result] = await getGeocode({
      placeId: typeof selection === 'string' ? selection : selection?.place_id,
    })
    const { lat, lng } = getLatLng(result)

    if (clearOnSelection) onClear()
    onSelection({
      title: result?.formatted_address,
      lat,
      lng,
      location:
        result?.address_components?.[0]?.long_name ||
        result?.address_components?.[0]?.short_name ||
        result?.formatted_address,
    })

    handleClearAndFocus({
      newValue: result.formatted_address,
      focusesInput: !isMobileScreen,
    })
  }

  const handleSearchTermEnter = e => {
    if (e.key === KeyCode.ENTER) {
      if (showSearchTerm) handleOptionSelection(searchTerm)
      else if (showOptions) handleOptionSelection(options[0])
    }
    if (e.key === KeyCode.ESC) onClear()
  }

  const ClearButton = () => {
    if (!value) return null

    return (
      <span className='mr--3'>
        <IconButton icon='clear' onClick={onClear} />
      </span>
    )
  }

  const onClear = () =>
    handleClearAndFocus({ newValue: '', focusesInput: true })

  const SlotBefore = () => {
    if (hideSlotBefore) return null
    return (
      <span className='color-new-forest flex'>
        <Icon name='search' />
      </span>
    )
  }

  return (
    <div
      className={classNames(
        'mx-auto flex w-full items-center justify-center [&>div:first-of-type]:w-full',
        className
      )}
      ref={autocompleteParentRef}
    >
      <Autocomplete
        autoExpand
        fullWidth
        className='w-full'
        id='guides-autocomplete-input'
        isDisabled={!ready}
        placeholder={placeholder}
        ref={autocompleteRef}
        slotAfter={<ClearButton />}
        slotBefore={<SlotBefore />}
        value={value}
        onChange={e => {
          setShowSearchTerm(true)
          setValue(e.target.value)
        }}
        onFocus={
          isMobileScreen
            ? () => {
                autocompleteParentRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            : null
        }
        onKeyDown={handleSearchTermEnter}
        onOptionSelect={handleOptionSelection}
      >
        {showOptions &&
          options.map(option => (
            <GeocoderOption
              key={option.place_id}
              option={option}
              searchTerm={searchTerm}
            />
          ))}

        {showOptions && showSearchTerm && value?.trim() && <Divider />}
      </Autocomplete>
    </div>
  )
}
