import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { GuideDrawerContent } from './GuideDrawerContent'
import type { GuideOwner } from '../../types'
import { onGuideSessionStorageSelectedIdChange } from '../../useGuideSessionStorageIds'

interface GuideDrawerProps {
  className?: string
  guideDraftId: string
  isEdit: boolean
  owner: GuideOwner
  points: PublishedEvent[]
  selectedId: string
}

export const GuideDrawer = ({
  className,
  guideDraftId,
  isEdit,
  owner,
  points,
  selectedId,
}: GuideDrawerProps) => {
  const isOpen = !!selectedId
  const selectedEvent = points?.find(({ id }) => id === selectedId)

  const onClose = () => onGuideSessionStorageSelectedIdChange('')

  return (
    <>
      {createPortal(
        <div
          className={classNames(
            'h-100dvh z-5 fixed bottom-0 left-0 right-0 top-0 w-full flex-col bg-white lg:hidden',
            {
              hidden: !isOpen,
              'visible flex': isOpen,
            }
          )}
        >
          <div className='flex justify-end'>
            <IconButton icon='clear' size='large' onClick={onClose} />
          </div>
          <GuideDrawerContent
            guideDraftId={guideDraftId}
            isEdit={isEdit}
            owner={owner}
            selectedEvent={selectedEvent}
          />
        </div>,
        document.body
      )}
      <div
        className={classNames(
          className,
          'transition-transform-175 w-130 z-2 lg:b-r-grey-200 lg:b-r-solid lg:b-r-1 fixed relative hidden bg-white ease-[cubic-bezier(0,0,0.2,1)] lg:sticky lg:flex lg:flex-col',
          {
            '-translate-x-150': !isOpen,
          }
        )}
      >
        <div className='flex justify-end'>
          <IconButton
            aria-label='Close the modal'
            icon='clear'
            size='large'
            onClick={onClose}
          />
        </div>
        <GuideDrawerContent
          guideDraftId={guideDraftId}
          isEdit={isEdit}
          owner={owner}
          selectedEvent={selectedEvent}
        />
      </div>
    </>
  )
}
