import { useRef } from 'react'
import { Icon, StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import {
  GuideStatus,
  type GetGuideDraftsForUserQueryInDashboardQuery,
} from 'src/__generated__/graphql'
import { defaultLocationImage } from 'src/constants'
import { GuideBadge } from 'src/pages/guides/details-old/common/GuideBadge'
import { getGuideAddress, getGuideDetailsUrl } from 'src/utils'
import { DashboardGuideDraftCardMetrics } from './DashboardGuideDraftCardMetrics'
import { DashboardGuideDraftCardPopoverMenu } from './DashboardGuideDraftCardPopoverMenu'
import { FallbackImage } from '../../../profile/content-section/common/FallbackImage'
import { FormattedLocation } from '../../../profile/content-section/common/FormattedLocation'

const GuideImage = ({ image }) => (
  <FallbackImage
    alt='City'
    className='block h-full w-full object-cover'
    fallbackSrc={defaultLocationImage}
    src={image}
  />
)

export const DashboardGuideDraftCard = ({
  guideDraft,
  isGuidePrivatePublicEnabled,
}: {
  guideDraft: GetGuideDraftsForUserQueryInDashboardQuery['getGuideDraftsForUser'][0]
  isGuidePrivatePublicEnabled: boolean
}) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const { description, images, guide, addresses, name } = guideDraft ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const { status } = guide ?? {}
  const address = getGuideAddress({
    city,
    country,
    state,
  })
  const image = images?.[0]?.url
  const href = getGuideDetailsUrl({
    id: guide?.id,
    name: guide?.name,
  })

  return (
    /** @todo refactor this to be more like DashboardFeedCard */
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      className={classNames(
        'rounded-3 border-grey-300 of-hidden shadow-3 relative border-2 border-solid hover:cursor-pointer',
        /**
         * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
         * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
         */
        'group'
      )}
      onClick={() => anchorRef?.current?.click?.()}
    >
      <div className='relative flex flex-col justify-between gap-4 p-3 md:min-h-52 md:flex-row md:justify-start lg:gap-6'>
        {/* Top (Mobile) Picture */}
        <div className='relative h-44 w-full overflow-hidden rounded-lg md:hidden'>
          {isGuidePrivatePublicEnabled && status === GuideStatus.Published && (
            <div className='absolute left-3 top-3 w-fit'>
              <GuideBadge>
                <Icon name='visibility' size='small' />
                <span>Public</span>
              </GuideBadge>
            </div>
          )}
          <GuideImage image={image} />
        </div>
        {/* Lefthand (Desktop) Picture */}
        <div className='rounded-3 max-w-50 min-w-50 hidden h-48 overflow-hidden md:block'>
          <GuideImage image={image} />
        </div>
        <div className='grow space-y-3 lg:space-y-4'>
          <div className='space-y-3'>
            <div className='flex min-w-0 flex-col gap-2'>
              {/* important for SEO! Preserving referrer allows search engine bots to crawl internal links */}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <StopPropagation>
                <div className='flex flex-row items-center justify-between gap-2'>
                  <Link
                    className='outline-offset-5 focus-visible:outline-emerald type-h3 lg:type-h4 c-forest inline-block no-underline'
                    ref={anchorRef}
                    to={href}
                  >
                    {name}
                  </Link>
                  <div className='flex min-w-fit flex-row items-center'>
                    {isGuidePrivatePublicEnabled &&
                      status === GuideStatus.Published && (
                        <div className='hidden w-fit md:block'>
                          <GuideBadge>
                            <Icon name='visibility' size='small' />
                            <span>Public</span>
                          </GuideBadge>
                        </div>
                      )}
                    <DashboardGuideDraftCardPopoverMenu
                      guideDraftData={guideDraft}
                    />
                  </div>
                </div>
              </StopPropagation>
              <FormattedLocation address={address} />
            </div>
            <div className='lg:type-body-2 lg:min-h-13.5 type-body-1 line-clamp-2 min-h-9 lg:line-clamp-3 [&>button]:gap-1'>
              {description}
            </div>
          </div>
          <DashboardGuideDraftCardMetrics guideData={guide} />
        </div>
      </div>
    </div>
  )
}
