import { useRef, useState } from 'react'
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
} from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { dashboardPath } from 'src/constants'
import { getGuideDetailsUrl } from 'src/utils'
import type { GuideDraftData } from '../../types'
import { GuideDraftDeleteModal } from '../GuideDraftDeleteModal'
import { GuideDraftEditModal } from '../GuideDraftEditModal'
import { GuideDraftPublish, GuideDraftRepublish } from '../GuideDraftPublish'
import { GuideDraftShareModal } from '../GuideDraftShare'

interface GuideDraftTopNavMenuProps {
  guideDraftData: GuideDraftData
}

export const GuideDraftTopNavMenu = ({
  guideDraftData,
}: GuideDraftTopNavMenuProps) => {
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalRepublishOpen, setIsModalRepublishOpen] = useState(false)
  const [isModalShareOpen, setIsModalShareOpen] = useState(false)
  const [isModalPublishOpen, setIsModalPublishOpen] = useState(false)
  const { guide } = guideDraftData ?? {}
  const hasGuide = !!guide?.id

  const onPublish = () => {
    if (guide?.id) return setIsModalRepublishOpen(true)

    setIsModalPublishOpen(true)
  }

  const onViewGuide = () =>
    navigate(
      getGuideDetailsUrl({
        id: guide?.id,
        name: guide?.name,
      })
    )

  /** @todo once users are able to edit photos without going through the modal flow, on Desktop there will be no popover, just open the GuideDraftSettingsModal */
  return (
    <>
      <div className='flex flex-row items-center gap-6'>
        <div className='hidden flex-row items-center gap-6 lg:flex'>
          {/** @todo revisit this condition once BE has made it so that a private Guide is created upon GuideDraft creation */}
          {hasGuide && (
            <>
              <Button
                aria-label='View Guide'
                size='small'
                variant='outlined'
                onClick={onViewGuide}
              >
                View Guide
              </Button>
              <Divider className='w-1px m-0 min-h-8' />
              <Button
                aria-label='Share Guide'
                size='small'
                onClick={() => setIsModalShareOpen(true)}
              >
                Share
              </Button>
            </>
          )}
        </div>
        {!hasGuide && (
          <Button
            aria-label={hasGuide ? 'Publish Update' : 'Publish'}
            size='small'
            startIcon='campaign'
            onClick={onPublish}
          >
            {hasGuide ? 'Publish Update' : 'Publish'}
          </Button>
        )}
      </div>
      <Popover
        containerClassName='z-5'
        content={
          <div className='shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
            <MenuItem
              label='Edit Details'
              startIcon='modeEdit'
              value='Edit Details'
              onClick={() => {
                setIsModalEditOpen(true)
                setIsPopoverOpen(false)
              }}
            />
            <MenuItem
              label='Delete Guide'
              startIcon='delete'
              value='Delete Guide'
              onClick={() => {
                setIsModalDeleteOpen(true)
                setIsPopoverOpen(false)
              }}
            />
            <MenuItem
              aria-label='Publish Update'
              label={hasGuide ? 'Publish Update' : 'Publish'}
              startIcon='listAlt'
              value='Publish Update'
              onClick={onPublish}
            />
            {/** @todo revisit this condition once BE has made it so that a private Guide is created upon GuideDraft creation */}
            {hasGuide && (
              <div className='lg:hidden'>
                <MenuItem
                  aria-label='View Guide'
                  label='View Guide'
                  startIcon='campaign'
                  value='View Guide'
                  onClick={() => {
                    onViewGuide()
                    setIsPopoverOpen(false)
                  }}
                />
              </div>
            )}
          </div>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div ref={ref}>
          <div className='hidden lg:block'>
            <IconButton
              aria-label='Guide Menu'
              icon='moreVert'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
          <div className='block lg:hidden'>
            <IconButton
              aria-label='Guide Menu'
              color='transparent'
              icon='menuFilled'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
        </div>
      </Popover>
      {isModalDeleteOpen && (
        <GuideDraftDeleteModal
          guideDraftData={guideDraftData}
          onDelete={() => navigate(dashboardPath)}
          onDismiss={() => {
            setIsModalDeleteOpen(false)
          }}
        />
      )}
      {isModalEditOpen && (
        <GuideDraftEditModal
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalEditOpen(false)}
        />
      )}
      {/** @todo remove Republish/Publish Modals once users are able to edit photos without going through the modal flow */}
      {isModalPublishOpen && (
        <GuideDraftPublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalPublishOpen(false)}
        />
      )}
      {isModalRepublishOpen && (
        <GuideDraftRepublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalRepublishOpen(false)}
        />
      )}
      {isModalShareOpen && (
        <GuideDraftShareModal
          guideDraftData={guideDraftData}
          onDismiss={() => setIsModalShareOpen(false)}
        />
      )}
    </>
  )
}
