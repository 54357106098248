interface SortOption {
  label: string
  value: string
  key: string
}

export function enumToArray<T extends Record<string, string>>(
  enumObj: T
): SortOption[] {
  return Object.entries(enumObj).map(([key, value]) => {
    const label = key.replace(/([A-Z])/g, ' $1').trim() // Adds spaces before capital letters and trims
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase() // Formats to capitalize the first letter
    return {
      label: formattedLabel,
      value: value,
      key: key,
    }
  })
}
