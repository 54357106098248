import type { QueryHookOptions } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  KeywordGuideSearchQuery,
  KeywordGuideSearchQueryVariables,
} from 'src/__generated__/graphql'

const KEYWORD_GUIDE_SEARCH = gql(`
    query KeywordGuideSearch($keywordSearchArgs: KeywordSearchArgs!, $first: Int, $after: String) {
        keywordGuideSearch (searchArgs: $keywordSearchArgs, first: $first, after: $after) {
            totalCount
            pageInfo{
                hasNextPage
                endCursor
            }
            edges{
                node{
                    id
                    addresses {
                        id
                        city
                        country
                        state
                    }
                    isCollected
                    endDate
                    guideType
                    images {
                        id
                        source
                        type
                        url
                    }
                    imageUrl
                    insertedAt
                    name
                    ownerProfile {
                        id
                        accountHandle
                        displayName
                        profileImageUrl
                    }
                    startDate
                    updatedAt
                    timeZone
                }
            }
        }
    }    
`)

export const useKeywordGuideSearch = (
  options?: QueryHookOptions<
    KeywordGuideSearchQuery,
    KeywordGuideSearchQueryVariables
  >
) => useQuery(KEYWORD_GUIDE_SEARCH, options)
