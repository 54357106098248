import {
  EventType,
  GuideDraftStatus,
  GuideStatus,
  ProfileImageSource,
  UserImageSource,
  UserImageType,
} from 'src/__generated__/graphql'
import { predefinedGuidesTagsMock } from 'src/pages/guides/__tests__/__mocks__'
import { getGuideDraftDragItems } from '../../guideDraftUtils'
import type { GuideDraftData } from '../../types'

export const guideDraftMock: GuideDraftData = {
  id: 'R3VpZGU6Z2RlXzAxSFlFQVlSWFFaMlZXTktGOEdIQjUwRjJT',
  addresses: [
    {
      id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSFlHOE1SNDdCTVZNUlo0TVROSjdUUUsz',
      addressLine1: null,
      city: 'New York',
      country: 'United States',
      googlePlaceId: 'ChIJOwg_06VPwokRYv534QaPC8g',
      lat: '40.7127753',
      long: '-74.0059728',
      state: 'New York',
      __typename: 'GenericAddress',
    },
  ],
  description:
    "Experience the energy and excitement of New York City, a global hub of culture, art, and dining. From the neon lights of Times Square to the serene paths of Central Park, there's no shortage of experiences waiting to be discovered in this iconic metropolis.",
  eventCounts: {
    numberEvents: 3,
  },
  guide: {
    id: 'VXNlckltYWdlOnVzaW1nXzAxSjAxTkg1RDZUMkNOV1AxQzRQUzABSkNK',
    collectedCount: 0,
    isCollected: false,
    name: 'New York List Guide Mock',
    numberOfLikes: 0,
    shareCount: 0,
    status: GuideStatus.Published,
    __typename: 'Guide',
  },
  hasUnpublishedChanges: false,
  insertedAt: '2024-05-21T19:44:51.127314Z',
  images: [
    {
      id: 'VXNlckltYWdlOnVzaW1nXzAxSjAxTkg1RDZUMkNOV1AxQzRQUzBBSkNK',
      source: UserImageSource.UserUploadedImage,
      type: UserImageType.Guide,
      url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ2K4ARTCPYFEFE7HPXWQ6G/guide_images/e3d579c0-8e5f-4f3c-a42a-66152aafd902',
      __typename: 'UserImage',
    },
    {
      id: 'VXNlckltYWdlOnVzaW1nXzAxSjAxTkg1RDZUMkNOV1AxQzRQUzABSkNK',
      source: UserImageSource.PixabayImage,
      type: UserImageType.Guide,
      url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ2K4ARTCPYFEFE7HPXWQ6G/guide_images/0c18614e-5325-406d-ad80-f8d2677c82a7',
    },
  ],
  name: 'New York List Guide Mock',
  owner: {
    id: 'VXNlcjp1c3JfMDFHRVEySzRBUlRDUFlGRUZFN0hQWFdRNkc=',
  },
  ownerProfile: {
    id: 'VXNlclByb2ZpbGU6dXNwcm9mXzAxSFc1UlpXVlJYUDc1UktXNkUzQ1RQMEsx',
    accountHandle: 'mattint',
    displayName: 'Matt',
    isUserOwner: true,
    profileImageUrl:
      'https://static.travelpass.com/assets/users/default-avatar-lg.webp',
    profileImageSource: ProfileImageSource.DefaultImage,
    userId: 'VXNlcjp1c3JfMDFIVzVSWkRUU0ZYWTVYMUg4WTZWWFBYUFo=',
    __typename: 'UserProfile',
  },
  paginatedEventCategories: {
    edges: [
      {
        node: {
          id: 'UHVibGlzaGVkRXZlbnRDYXRlZ29yeTpwYmRldnRjYXRfMDFKMzNENEhEMzRFTUMyMkRFR0taRjJROFM=',
          description:
            'New York is composed of five boroughs – Brooklyn, the Bronx, Manhattan, Queens and Staten Island - is home to 8.4 million people who speak more than 200 languages.',
          publishedEvents: {
            edges: [
              {
                node: {
                  id: 'UHVibGlzaGVkRXZlbnQ6cGJkZXZ0XzAxSFlHWU5LQjI4OTEyWkc5OUtEOEYwRzI4',
                  addresses: [
                    {
                      id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSFlHWU5LQjUyM01ZTTVHR0g0Vzk1TVNE',
                      addressLine1: '160 Broadway',
                      city: 'New York',
                      country: 'United States',
                      googlePlaceId: 'ChIJ2_VLfRdawokRXKfA6CMf5YQ',
                      lat: '40.7094789',
                      long: '-74.01003639999999',
                      state: 'New York',
                      __typename: 'GenericAddress',
                    },
                  ],
                  description: null,
                  externalTypeId: null,
                  images: [
                    {
                      id: 'VXNlckltYWdlOnVzaW1nXzAxSjhRNjk5VzZKQU1DM0Q1Q0cySlRCMU5W',
                      source: UserImageSource.UserUploadedImage,
                      type: UserImageType.PublishedEvent,
                      url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ2K4ARTCPYFEFE7HPXWQ6G/guide_images/150b245a-ca6f-4676-a005-4b71e157f0cd',
                      __typename: 'UserImage',
                    },
                    {
                      id: 'VXNlckltYWdlOnVzaW1nXzAxSjAxTkg1RDZUMkNOV1AxQzRQUzBBSkNK',
                      source: UserImageSource.UserUploadedImage,
                      type: UserImageType.PublishedEvent,
                      url: 'https://s3.us-west-2.amazonaws.com/tpg-user-uploaded-int/images/usr_01GEQ2K4ARTCPYFEFE7HPXWQ6G/guide_images/e3d579c0-8e5f-4f3c-a42a-66152aafd902',
                      __typename: 'UserImage',
                    },
                  ],
                  imageUrl:
                    'https://maps.googleapis.com/maps/api/place/photo?photo_reference=AUc7tXWB_kxvJUOIMVyTIDbqGz7xkDInNBJOFxykqr8fBcOJUKeesa9ZOkGtosLKHCQ4k5pB7pZwsTZewcy4PsB1gL5ZOye7wK58vl4WBAvhl7_SdDKIYCf-zdMoVYz6yiVPhEa9edn8rQEo1TYN5UVtttFaTW3_bb0YYh_dQJ2wXJWlO8q5&key=AIzaSyCdwt-67yAZ8iAsyKm2Rbf9E54T8UaBf7I',
                  name: "McDonald's",
                  notes: null,
                  productId: null,
                  type: EventType.FoodAndDrink,
                  __typename: 'PublishedEvent',
                },
                __typename: 'PublishedEventEdge',
              },
              {
                node: {
                  id: 'UHVibGlzaGVkRXZlbnQ6cGJkZXZ0XzAxSFlHWU5TUFBRUDcwU0NDQTNCQk05TjJK',
                  addresses: [
                    {
                      id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSFlHWU5TUFMxUkcwVEdCNjA0VDFUM1FC',
                      addressLine1: '4 Pennsylvania Plaza',
                      city: 'New York',
                      country: 'United States',
                      googlePlaceId: 'ChIJhRwB-yFawokR5Phil-QQ3zM',
                      lat: '40.7505045',
                      long: '-73.9934387',
                      state: 'New York',
                      __typename: 'GenericAddress',
                    },
                  ],
                  description: null,
                  externalTypeId: null,
                  images: [],
                  imageUrl:
                    'https://maps.googleapis.com/maps/api/place/photo?photo_reference=AUc7tXXzOeMVmt4V3juUxKEjr6ZNg3ScE-5rPupfrLdGo8uhIx35V-_uMBxjnxNWUr7qxfGZ_BoJVMGjtG_tuqLItjewMAQwiIRaulzHICS7R0_BICHp9027IacGzvR_z1QrgKxVfTlqN6SHNVycZq4iAjlAaKGC9qj7ctEDB3szK9_MygLe&key=AIzaSyCdwt-67yAZ8iAsyKm2Rbf9E54T8UaBf7I',
                  name: 'Madison Square Garden',
                  notes: null,
                  productId: null,
                  type: EventType.Experience,
                  __typename: 'PublishedEvent',
                },
                __typename: 'PublishedEventEdge',
              },
            ],
            pageInfo: {
              endCursor: 'Y3Vyc29yOnYyOpHOg9g=',
              hasNextPage: false,
            },
            totalCount: 1,
            __typename: 'PublishedEventConnection',
          },
          name: 'Example',
          __typename: 'PublishedEventCategory',
        },
      },
    ],
    pageInfo: {
      endCursor: 'Y3Vyc29yOnYyOpHOg9g=',
      hasNextPage: false,
    },
    totalCount: 2,
    __typename: 'PublishedEventCategoryConnection',
  },
  status: GuideDraftStatus.Active,
  tags: predefinedGuidesTagsMock.getPredefinedGuideTags,
  timeZone: 'America/New_York',
  uncategorizedPublishedEvents: {
    edges: [
      {
        node: {
          id: 'UHVibGlzaGVkRXZlbnQ6cGJkZXZ0XzAxSjMzRDE1RFdNNjI5WlMxM1lFMURDQTg1',
          addresses: [
            {
              id: 'R2VuZXJpY0FkZHJlc3M6YWRkXzAxSjMzRDE1RFkyV0pGVjU4S1BLN00yVDhF',
              addressLine1: null,
              city: 'New York',
              country: 'United States',
              googlePlaceId: 'ChIJOwg_06VPwokRYv534QaPC8g',
              lat: '40.7127753',
              long: '-74.0059728',
              state: 'New York',
              zipcode: null,
              __typename: 'GenericAddress',
            },
          ],
          description: null,
          externalTypeId: null,
          images: [],
          imageUrl:
            'https://maps.googleapis.com/maps/api/place/photo?photo_reference=AUc7tXWB_kxvJUOIMVyTIDbqGz7xkDInNBJOFxykqr8fBcOJUKeesa9ZOkGtosLKHCQ4k5pB7pZwsTZewcy4PsB1gL5ZOye7wK58vl4WBAvhl7_SdDKIYCf-zdMoVYz6yiVPhEa9edn8rQEo1TYN5UVtttFaTW3_bb0YYh_dQJ2wXJWlO8q5&key=AIzaSyCdwt-67yAZ8iAsyKm2Rbf9E54T8UaBf7I',
          name: 'New York',
          notes: '111',
          productId: null,
          type: EventType.Experience,
          __typename: 'PublishedEvent',
        },
        __typename: 'PublishedEventEdge',
      },
    ],
    pageInfo: {
      endCursor: 'Y3Vyc29yOnYyOpHOg9g=',
      hasNextPage: false,
    },
    totalCount: 1,
    __typename: 'PublishedEventConnection',
  },
  updatedAt: '2024-05-22T13:43:06.414915Z',
  __typename: 'GuideDraft',
}

/** @todo remove and use eventCounts in guideDraftMock when BE fixes deduping in TMNT-3072. */
export const guideDraftEventCountMock =
  guideDraftMock.paginatedEventCategories?.edges?.flatMap(
    paginatedEventCategory =>
      paginatedEventCategory?.node?.publishedEvents?.edges
  )?.length ?? 0

export const guideDraftDraftItemMock = getGuideDraftDragItems({
  paginatedEventCategories: guideDraftMock.paginatedEventCategories,
  uncategorizedPublishedEvents: guideDraftMock.uncategorizedPublishedEvents,
})
