import {
  GuideMetricLike,
  GuideMetricSave,
  GuideMetricShare,
} from 'src/pages/guides/common'
import type { GuideHeaderData } from '../../types'

interface GuideHeaderActionsProps {
  guideHeaderData: Pick<
    GuideHeaderData,
    | 'collectedCount'
    | 'id'
    | 'isCollected'
    | 'name'
    | 'numberOfLikes'
    | 'ownerProfile'
    | 'shareCount'
  >
  isEdit: boolean
}

export const GuideHeaderActions = ({
  guideHeaderData,
  isEdit,
}: GuideHeaderActionsProps) => {
  if (!guideHeaderData?.id) return

  return (
    <div className='[&>button]:p-x-0 [&>button>i]:color-forest-light [&>button>div]:color-forest-light flex flex-row items-center justify-end gap-3 [&>button]:gap-1'>
      <GuideMetricSave
        guideData={guideHeaderData}
        hideLabel={true}
        isDisabled={isEdit}
        size='small'
        variant='white'
      />
      <GuideMetricLike
        guideData={guideHeaderData}
        hideLabel={true}
        isDisabled={isEdit}
        size='small'
        variant='white'
      />
      <GuideMetricShare
        guideData={guideHeaderData}
        hideLabel={true}
        isDisabled={isEdit}
        size='small'
        variant='white'
      />
    </div>
  )
}
