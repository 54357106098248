// Taken from https://usehooks.com/useLocalStorage

import { useState, useEffect } from 'react'

export const localStorageFlagKey = 'tpg_feature_flags'
let initialValue = {}

const logError = error => {
  console.log(error) // eslint-disable-line no-console
}

type UseLocalStorageFlagsReturn = [
  Record<string, boolean>,
  (newFlags: Record<string, boolean>) => void,
]

export const useLocalStorageFlags = (): UseLocalStorageFlagsReturn => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [localFlags, setlocalFlagsState] = useState<Record<string, boolean>>(
    () => {
      if (typeof window === 'undefined') {
        return initialValue
      }
      try {
        const item = window.localStorage.getItem(localStorageFlagKey)

        return item ? JSON.parse(item) : initialValue
      } catch (error) {
        logError(error)
        return initialValue
      }
    }
  )

  const localstorageEventListener = () => {
    try {
      const item = JSON.parse(window.localStorage.getItem(localStorageFlagKey))
      setlocalFlagsState({ ...item }) //<----spread it which will cause reference to change
    } catch (error) {
      logError(error)
    }
  }

  useEffect(() => {
    window.addEventListener('storage', localstorageEventListener)

    return () =>
      window.removeEventListener('storage', localstorageEventListener)
  }, [])

  const setLocalFlags = (newFlags: Record<string, boolean>) => {
    if (!(newFlags instanceof Object))
      throw new Error('Must set local flags using JS object')

    try {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(
          localStorageFlagKey,
          JSON.stringify(newFlags)
        )
        window.dispatchEvent(new Event('storage'))
      }
    } catch (error) {
      logError(error)
    }
  }
  return [localFlags, setLocalFlags]
}
