import {
  Button,
  Divider,
  IconButton,
  Input,
  useSnackbar,
} from '@travelpass/design-system'
import { GuideImage } from 'src/pages/guides/details/common'
import {
  constructAddress,
  copyToClipboard,
  getGuideDetailsUrl,
} from 'src/utils'
import { GuideDraftShareIntroSettings } from './GuideDraftShareIntroSettings'
import type { GuideDraftData } from '../../../types'

interface GuideDraftShareIntroProps {
  guideDraftData: GuideDraftData
  onStepChange: (updatedStep: number) => void
}

export const GuideDraftShareIntro = ({
  guideDraftData,
  onStepChange,
}: GuideDraftShareIntroProps) => {
  const { addSuccessSnack } = useSnackbar()
  const { addresses, guide } = guideDraftData ?? {}
  const address = constructAddress({
    addressFirstLine: addresses?.[0]?.addressLine1,
    city: addresses?.[0]?.city,
    state: addresses?.[0]?.state,
  })
  const image = guideDraftData?.images?.[0]?.url
  const url = `${window.location.origin}${getGuideDetailsUrl({
    id: guide?.id,
    name: guide?.name,
  })}`

  const onCopy = () => {
    copyToClipboard(url)
    addSuccessSnack({
      title: 'Copied to clipboard!',
    })
  }

  return (
    <section className='space-y-4'>
      <header className='min-h-50 p-x-6 p-y-4 of-hidden rounded-2 relative flex flex-col justify-end gap-1'>
        <GuideImage className='absolute inset-0' src={image} />
        <div className='from-black/58 absolute inset-0 bg-gradient-to-l via-black/30 via-40% to-black/65 to-100%' />
        <h3 className='c-white type-h3 relative'>{guideDraftData?.name}</h3>
        {address && <p className='c-white type-body-1 relative'>{address}</p>}
      </header>
      <div className='space-y-4'>
        <GuideDraftShareIntroSettings
          guideDraftData={guideDraftData}
          onStepChange={onStepChange}
        />
        <Divider />
        <section className='space-y-6'>
          <header className='space-y-3'>
            <h4 className='c-black type-h4'>Just Share The Link</h4>
            <p className='type-body-2 c-grey-900'>
              If you&apos;d like to keep your Guide to yourself, you can still
              give viewing access to someone by sharing the link below.
            </p>
          </header>
          <div className='flex flex-col items-center gap-2 md:flex-row'>
            <Input
              fullWidth={true}
              readOnly={true}
              slotBefore={
                <div className='hidden md:block'>
                  <IconButton
                    aria-label='Copy guide URL'
                    color='transparent'
                    icon='contentCopy'
                    onClick={onCopy}
                  />
                </div>
              }
              value={url}
            />
            <div className='min-w-fit'>
              <Button
                size='large'
                startIcon='link'
                variant='outlined'
                onClick={onCopy}
              >
                Copy link
              </Button>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}
