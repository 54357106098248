enum DashboardSearchParam {
  settingsTab = 'settingsTab',
  tab = 'tab',
}

enum DashboardTab {
  bookings = 'bookings',
  collections = 'collections',
  feed = 'feed',
  guides = 'guides',
  trips = 'trips',
}

export { DashboardSearchParam, DashboardTab }
