import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const addPublishedEventImagesMutation = gql(`
  mutation AddGuideDraftEventImagesOld($input: AddPublishedEventImagesInput!) {
    addPublishedEventImages(input: $input) {
      addedImages {
        id
        isFlagged
        url
      }
      errorImages {
        reason
        url
      }
      publishedEvent {
        id 
        images {
          id
          url
        }
      }
    }
  }
`)

export const useAddGuideDraftEventImagesMutation = () =>
  useMutation(addPublishedEventImagesMutation)
