import { useEffect, useState } from 'react'
import { DrawerNew, Popover, useScreenQuery } from '@travelpass/design-system'
import { useLocation } from 'react-router-dom'
import { MenuContent } from './MenuContent'
import { MenuTrigger } from './MenuTrigger'

const MenuDropdown = () => (
  <Popover
    autoClose={false}
    placement='bottom-end'
    trigger={
      <div>
        <MenuTrigger />
      </div>
    }
  >
    <div className='w-75'>
      <MenuContent />
    </div>
  </Popover>
)

const MenuDrawer = () => {
  const [isOpen, setIsOpen] = useState(false)
  const openDrawer = () => setIsOpen(true)
  const closeDrawer = () => setIsOpen(false)
  const { pathname, search } = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [pathname, search])

  return (
    <>
      <MenuTrigger onClick={openDrawer} />
      <DrawerNew isOpen={isOpen} placement='right' onDismiss={closeDrawer}>
        <MenuContent />
      </DrawerNew>
    </>
  )
}

export const Menu = () => {
  const { isMobileScreen } = useScreenQuery()
  if (isMobileScreen) return <MenuDrawer />
  return <MenuDropdown />
}
