import { Divider, Skeleton } from '@travelpass/design-system'

export const GuideDraftTopNavMenuLoading = () => (
  <div className='flex flex-row items-center gap-6'>
    <div className='hidden flex-row items-center gap-6 lg:flex'>
      <div className='w-34.5 h-8'>
        <Skeleton variant='rounded' />
      </div>
      <Divider className='w-1px m-0 min-h-8' />
    </div>
    <div className='h-8 w-24'>
      <Skeleton variant='rounded' />
    </div>
    <div className='p-y-2'>
      <div className='h-8 w-8'>
        <Skeleton variant='rounded' />
      </div>
    </div>
  </div>
)
