import type { ContextType } from 'react'
import { useRef } from 'react'
import { Divider } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import type {
  CurrentUserExperienceBookingsQueryQuery,
  CurrentUserHotelBookingsQueryQuery,
} from 'src/__generated__/graphql'
import { Scroller, ScrollerButton } from 'src/common/components'
import { ExperienceBooking } from 'src/pages/bookings/MyBookings/ExperienceBookings/ExperienceBooking'
import { HotelBooking } from 'src/pages/bookings/MyBookings/HotelBookings/HotelBooking'
import { DashboardBookingsEmpty } from './DashboardBookingsEmpty'
type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

interface DashboardBookingsUpcomingProps {
  experienceBookingsUpcomingData: CurrentUserExperienceBookingsQueryQuery['currentUser']['experienceBookings']['edges']
  hotelBookingsUpcomingData: CurrentUserHotelBookingsQueryQuery['currentUser']['hotelBookings']['edges']
}

export const DashboardBookingsUpcoming = ({
  experienceBookingsUpcomingData,
  hotelBookingsUpcomingData,
}: DashboardBookingsUpcomingProps) => {
  const apiRef = useRef({} as ScrollVisibilityApiType)
  const hasNoUpcomingBookings =
    !experienceBookingsUpcomingData?.length &&
    !hotelBookingsUpcomingData?.length

  if (hasNoUpcomingBookings) return <DashboardBookingsEmpty />

  return (
    <div className='space-y-9'>
      {!hasNoUpcomingBookings && (
        <div className='space-y-6'>
          <div className='space-y-3'>
            <p className='type-overline'>
              Upcoming Hotel Bookings ({hotelBookingsUpcomingData?.length})
            </p>
            <Divider />
          </div>
          <div className='w-full max-w-full'>
            <Scroller
              LeftArrow={
                <span className='block pr-5'>
                  <ScrollerButton icon='arrowBackIos' size='small' />
                </span>
              }
              RightArrow={
                <span className='block pl-5'>
                  <ScrollerButton isNext icon='arrowForwardIos' size='small' />
                </span>
              }
              apiRef={apiRef}
              scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
            >
              {hotelBookingsUpcomingData?.map((item, index) => (
                <div key={index} className='ws-nowrap'>
                  <HotelBooking
                    booking={item}
                    className='min-w-55 w-55 md:min-w-66 md:w-66'
                  />
                </div>
              ))}
            </Scroller>
          </div>
        </div>
      )}
      {!hasNoUpcomingBookings && (
        <div className='space-y-6'>
          <div className='space-y-3'>
            <p className='type-overline'>
              Upcoming Experiences ({experienceBookingsUpcomingData?.length})
            </p>
            <Divider />
          </div>
          <div className='w-full max-w-full'>
            <Scroller
              LeftArrow={
                <span className='block pr-5'>
                  <ScrollerButton icon='arrowBackIos' size='small' />
                </span>
              }
              RightArrow={
                <span className='block pl-5'>
                  <ScrollerButton isNext icon='arrowForwardIos' size='small' />
                </span>
              }
              apiRef={apiRef}
              scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
            >
              {experienceBookingsUpcomingData?.map((item, index) => (
                <div key={index} className='ws-nowrap'>
                  <ExperienceBooking
                    booking={item}
                    className='min-w-55 w-55 md:min-w-66 md:w-66'
                  />
                </div>
              ))}
            </Scroller>
          </div>
        </div>
      )}
    </div>
  )
}
