import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideImage } from 'src/pages/guides/details/common'
import { getGuideDetailsUrl } from 'src/utils'
import { DashboardFeedCard } from './DashboardFeedCard'
import { DashboardFeedCardHeaderGuide } from './DashboardFeedCardHeaderGuide'
import { getDashboardFeedGuideImages } from '../../../dashboardUtils'
import type { DashboardFeedItem } from '../../../types'

interface DashboardFeedCardRepublishedGuidePhotosProps {
  dashboardFeedItem: DashboardFeedItem
}

export const DashboardFeedCardRepublishedGuidePhotos = ({
  dashboardFeedItem,
}: DashboardFeedCardRepublishedGuidePhotosProps) => {
  const { id, latestUpdates, name } = dashboardFeedItem?.guide ?? {}
  const images = getDashboardFeedGuideImages({
    images: latestUpdates?.newImages?.images,
    imageUrl: dashboardFeedItem?.guide?.imageUrl,
  })
  const imageLengthText =
    images?.length > 1
      ? `${images?.length} new photos`
      : `${images?.length} new 
      photo`
  const url = getGuideDetailsUrl({
    id,
    name,
  })

  return (
    <DashboardFeedCard href={url}>
      <article>
        <DashboardFeedCardHeaderGuide dashboardFeedItem={dashboardFeedItem}>
          <p className='type-body-2 line-clamp-2'>
            <span className='m-r-1 lg:top-0.75 relative top-1'>
              <Icon name='camera' size='small' />
            </span>
            <span className='min-w-fit'>Added {imageLengthText} to</span>
            <a
              className='c-new-forest font-600 transition-opacity-200 relative min-w-0 grow no-underline ease-linear hover:opacity-70'
              href={url}
              rel='noreferrer'
              target='_blank'
            >
              <q>{name}</q>
            </a>
          </p>
        </DashboardFeedCardHeaderGuide>
        <div
          className={classNames(
            'md:h-67 pointer-events-none grid h-60 grid-rows-2 gap-1',
            {
              'grid-cols-1': images?.length === 1,
              'grid-cols-2': images?.length > 1,
            }
          )}
        >
          {images.map(({ url }, index) => (
            <div
              key={index}
              className={classNames({
                'col-span-2 md:col-span-1': images?.length > 1 && index === 2,
                'row-span-2': images?.length < 3,
                'md:row-span-2':
                  index === 0 || (images?.length === 2 && index === 1),
              })}
            >
              <GuideImage className='rounded-1' src={url} />
            </div>
          ))}
        </div>
      </article>
    </DashboardFeedCard>
  )
}
