import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetGuideEventsQueryInGuideDetailsQuery,
  GetGuideEventsQueryInGuideDetailsQueryVariables,
  PublishedEventCategory,
  Scalars,
} from 'src/__generated__/graphql'
import { getGuideUpdateQuery } from './guideUtils'
import type { GuideEventsData } from './types'

const getGuideEventsQuery = gql(`
  query GetGuideEventsQueryInGuideDetailsOld($after: String, $first: Int = 20, $id: ID!) {
    node(id: $id) {
      ... on Guide {
        id
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(after: $after, first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFields
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        uncategorizedPublishedEvents(after: $after, first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

type UseGetGuideEventsQuery = {
  data: GetGuideEventsQueryInGuideDetailsQuery
  guideEventsData: GuideEventsData
  hasError: ApolloError
  isLoading: boolean
  onGetMoreResults: ({
    after,
    id,
  }: {
    after: Scalars['String']['input']
    id: PublishedEventCategory['id']
  }) => Promise<void>
}

const useGetGuideEventsQuery = (
  guideId: GetGuideEventsQueryInGuideDetailsQueryVariables['id']
): UseGetGuideEventsQuery => {
  const {
    data,
    error,
    fetchMore: onGetMoreResultsQuery,
    loading,
  } = useQuery(getGuideEventsQuery, {
    errorPolicy: 'ignore',
    skip: !guideId,
    variables: {
      id: guideId,
    },
  })

  // Fetch more events for a specific category
  const onGetMoreResults = async ({
    after,
    id,
  }: {
    after: Scalars['String']['input']
    id: PublishedEventCategory['id']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: {
          after,
        },
        // Necessary to update the cache because the after cursor is the same for all published events. Hopefully this will be fixed in the future.
        updateQuery: (previousResult, { fetchMoreResult }) =>
          getGuideUpdateQuery({
            fetchMoreResult,
            id,
            previousResult,
          }),
      })
    } catch (error) {
      console.error('Server error')
    }
  }

  return {
    data,
    guideEventsData: data?.node as GuideEventsData,
    hasError: error,
    isLoading: loading,
    onGetMoreResults,
  }
}

export type { UseGetGuideEventsQuery }
export { useGetGuideEventsQuery }
