import type { ButtonVariant, IconButtonProps } from '@travelpass/design-system'
import { Button, IconButton, useSnackbar } from '@travelpass/design-system'
import type { Guide } from 'src/__generated__/graphql'
import { useShareGuide } from 'src/pages/guides/useShareGuide'
import { useIncrementGuideEventShareCountMutation } from '../../useIncrementGuideEventShareCountMutation'

interface GuideEventMetricShareProps {
  color?: IconButtonProps['color']
  guideEventId: string
  guideName: Guide['name']
  isDisabled?: boolean
  isIconButton?: boolean
  isUserOwner: boolean
  size?: IconButtonProps['size']
  variant?: ButtonVariant
}

/** @todo extract business logic into a custom hook, leave the styling up to the component using the hook */
export const GuideEventMetricShare = ({
  color = 'forestLight',
  guideEventId,
  guideName,
  isDisabled,
  isIconButton = false,
  isUserOwner,
  size = 'small',
  variant = 'text',
}: GuideEventMetricShareProps) => {
  const { addSuccessSnack } = useSnackbar()
  const shareGuide = useShareGuide()
  const [incrementGuideEventShareCount] =
    useIncrementGuideEventShareCountMutation()

  const onShareGuideEvent = async () => {
    await shareGuide({
      guideDraftTitle: guideName,
      isUserOwner,
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({ timeout: 1000, title: 'Guide URL copied' })
      },
    })
    await incrementGuideEventShareCount({
      variables: {
        publishedEventId: guideEventId,
      },
    })
  }

  return isIconButton ? (
    <IconButton
      aria-label='Share this Guide event'
      color={color}
      icon='iosShare'
      isDisabled={isDisabled}
      outline='round'
      size={size}
      onClick={onShareGuideEvent}
    />
  ) : (
    <Button
      aria-label='Share this Guide event'
      isDisabled={isDisabled}
      size={size}
      startIcon='iosShare'
      variant={variant}
      onClick={onShareGuideEvent}
    >
      Share
    </Button>
  )
}
