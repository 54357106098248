import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updateCommunicationPreferencesMutation = gql(`
  mutation UpdateDashboardAccountSettingsFormNotificationsMutationInDashboard($communicationPreferencesInput: UpdateCommunicationPreferencesInput!) {
    updateCommunicationPreferences(communicationPreferencesInput: $communicationPreferencesInput) {
      id
      sendFeatureUpdatesEmail
      sendFeatureUpdatesSms
      sendInspirationEmail
      sendInspirationSms
      sendPromotionEmail
      sendPromotionSms
      sendRecentReviewsEmail
      sendRecentReviewsSms
      sendSurveyRequestsEmail
      sendSurveyRequestsSms
    }
  }
`)

export const useUpdateDashboardAccountSettingsFormNotificationsMutation = () =>
  useMutation(updateCommunicationPreferencesMutation)
