import { useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { GuideDraftSection } from './GuideDraftSection'
import { GuideDraftSectionAddModal } from './GuideDraftSectionAddModal'
import { GuideDraftSectionsOverlay } from './GuideDraftSectionsOverlay'
import type { GuideDraftData } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { useGuideDraftDrag } from '../../useGuideDraftDrag'

interface GuideDraftSectionsProps {
  data: UseGetGuideDraftQuery['data']
  eventCount: number
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  owner: GuideOwner
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  selectedId: string
}

export const GuideDraftSections = ({
  data,
  eventCount,
  onMapMarkerCenterChange,
  owner,
  paginatedEventCategories,
  selectedId,
}: GuideDraftSectionsProps) => {
  const {
    activeId,
    containers,
    dragProps,
    dropAnimation,
    isSortingContainer,
    items,
  } = useGuideDraftDrag(data)
  const [isSectionAddModalOpen, setIsSectionAddModalOpen] = useState(false)
  const { id } = data?.node as GuideDraftData

  const onSectionAddClick = () => {
    setIsSectionAddModalOpen(true)
    pushDataToDataLayer('guide_draft_section_add_modal_opened', {
      guide_draft_id: id,
      trigger_url: window.location.href,
    })
  }

  if (isEmpty(items)) return

  return (
    <>
      <div className='space-y-8 lg:space-y-10'>
        {!eventCount && (
          <div className='max-w-124 m-x-a p-x-2 space-y-2 text-center'>
            <h4 className='type-h5-mobile c-grey-800'>
              Search and Add to your Guide!
            </h4>
            <p className='type-small-text-desktop c-grey-800'>
              Use the search bar above or zoom in on the map to find things to
              add to your Guide. Add a note or description, then drag them into
              the editable categories below.
            </p>
          </div>
        )}
        <DndContext {...dragProps}>
          <SortableContext
            items={containers}
            strategy={verticalListSortingStrategy}
          >
            {containers?.map(container => (
              <GuideDraftSection
                key={items?.[container]?.id}
                isSortingContainer={isSortingContainer}
                owner={owner}
                paginatedEventCategories={paginatedEventCategories}
                paginatedEventCategory={items?.[container]}
                selectedId={selectedId}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
              />
            ))}
          </SortableContext>
          <GuideDraftSectionsOverlay
            activeId={activeId}
            containers={containers}
            dropAnimation={dropAnimation}
            items={items}
            owner={owner}
            paginatedEventCategories={paginatedEventCategories}
          />
        </DndContext>
        <Button
          startIcon='addCircleOutline'
          variant='text'
          onClick={onSectionAddClick}
        >
          Add New Section
        </Button>
      </div>
      {isSectionAddModalOpen && (
        <GuideDraftSectionAddModal
          guideDraftId={id}
          onDismiss={() => setIsSectionAddModalOpen(false)}
        />
      )}
    </>
  )
}
