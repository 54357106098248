import { useState } from 'react'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import { gql } from 'src/__generated__'
import { LodgingIdType } from 'src/__generated__/graphql'
import { useGetTripDetailsQuery } from 'src/pages/trips/hooks'
import { useStayPlacesData } from './useStayPlacesData'
import { useStayProductData } from './useStayProductData'
import { groupStayData } from '../utils'

const nodeQuery = gql(`
  query StayEventData($eventId: ID!) {
    node(id: $eventId) {
      ... on Event {
        id
        addresses {
          id
          addressLine1
          addressLine2
          city
          country
          googlePlaceId
          lat
          long
          state
          zipcode
        }
        booking {
          id
          arrival
          bookingDetails {
            cancelPolicy {
              refundType
            }
            occupancies {
              adults
              childAges
            }
            price {
              allInTotal {
                amount
                currency
              }
              additionalFees {
                amount {
                  amount
                }
              }
              dueLater {
                amount
              }
              dueNow {
                amount
              }
              pricingPerRoomOccupancy {
                subtotal {
                  amount
                }
                surcharges {
                  amount {
                    amount
                  }
                  type
                }
                total {
                  amount
                }
              }
              surchargeTotal {
                amount
              }
            }
            hotelDetails {
              room {
                description
                images {
                  caption
                  href
                }
                name
              }
            }
          }
          departure
          hotelId
          externalConfirmationId
          provider
        }
        description
        endDate
        imageUrl
        name
        notes
        productId
        startDate
        status
        useEventTime
      }
    }
  }
`)

const defaultDataSources = {
  hasPlacesData: false,
  hasProductData: false,
}

export const useStayData = ({
  eventId,
  tripId,
}: {
  eventId: string | null
  tripId: string | null
}) => {
  const [dataSources, setDataSources] =
    useState<typeof defaultDataSources>(defaultDataSources)
  const {
    getStayPlacesData,
    data: placeData,
    loading: placeLoading,
    error: placeError,
  } = useStayPlacesData()
  const {
    getStayProductData,
    data: productData,
    loading: productLoading,
    error: productError,
  } = useStayProductData()
  const {
    hasError: tripError,
    isLoading: tripLoading,
    tripDetailsData,
  } = useGetTripDetailsQuery(tripId)
  const {
    data: eventNodeData,
    loading: eventLoading,
    error: eventError,
    refetch,
  } = useQuery(nodeQuery, {
    variables: { eventId: eventId ?? 'Failed to load eventId' },
    skip: !eventId,
    onCompleted: ({ node }) => {
      const eventNode = node?.__typename === 'Event' ? node : null
      const { addresses, booking, productId } = eventNode ?? {}
      const placeId = addresses?.[0]?.googlePlaceId ?? null

      const updatedDataSources: typeof defaultDataSources =
        cloneDeep(defaultDataSources)

      if (placeId) {
        updatedDataSources.hasPlacesData = true

        getStayPlacesData({
          variables: {
            placeDetailsRequest: {
              isHotelSearch: true,
              placeId,
            },
          },
        })
      }

      const standardHotelId = productId ?? booking?.[0]?.hotelId
      if (standardHotelId) {
        updatedDataSources.hasProductData = true

        getStayProductData({
          variables: {
            standardHotelId,
            idType: LodgingIdType.Standard,
            listHotelReviewsArgs: {
              standardHotelId: parseInt(standardHotelId),
            },
          },
        })
      }

      setDataSources(updatedDataSources)
    },
  })
  const loading = eventLoading || productLoading || placeLoading || tripLoading
  const error = eventError || productError || placeError || tripError
  const eventData =
    eventNodeData?.node?.__typename === 'Event' ? eventNodeData.node : null
  const hotelData = productData?.lodging ?? null
  const hotelReviewData = productData?.listHotelReviews ?? null
  const data = loading
    ? null
    : groupStayData({
        eventData,
        hotelData: dataSources?.hasProductData ? hotelData : null,
        hotelReviewData: dataSources?.hasProductData ? hotelReviewData : null,
        placeData: dataSources?.hasPlacesData ? placeData : null,
        tripData: tripDetailsData,
      })

  return {
    data,
    tripData: tripDetailsData,
    loading,
    error,
    refetch,
  }
}
