import { useLayoutEffect } from 'react'
import { Button } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { ReportProfileModal } from './ReportProfileModal'

const REPORT_CONSTANT = 'report'
const SIGN_IN_CONSTANT = 'signin'

export const ReportProfileButton = () => {
  const { isAnonymous } = useFirebaseUser()
  const [searchParams, setSearchParams] = useSearchParams()

  const isReporting = !!searchParams.get(REPORT_CONSTANT)
  const showReportModal = !isAnonymous && isReporting

  const isSigningIn = !!searchParams.get(SIGN_IN_CONSTANT)

  const addReportingSP = () =>
    setSearchParams(sp => {
      sp.set(REPORT_CONSTANT, 'true')
      return sp
    })
  const removeReportingSP = () =>
    setSearchParams(sp => {
      sp.delete(REPORT_CONSTANT)
      return sp
    })

  const openReportModal = () => addReportingSP()
  const closeReportModal = () => removeReportingSP()

  const openSignInModal = () =>
    dispatch('openSignin', {
      isCreateAccount: false,
    })

  const handleOnClick = () => {
    if (isAnonymous) openSignInModal()
    openReportModal()
  }

  // Prevents the presence of unwated remaining reporting search params state
  useLayoutEffect(() => {
    if (isAnonymous && !isSigningIn) removeReportingSP()
  }, [isAnonymous, isSigningIn])

  return (
    <div className='mx-auto'>
      <Button
        className='mx-auto mt-4 block'
        startIcon='warningAmber'
        variant='text'
        onClick={handleOnClick}
      >
        Report User
      </Button>
      {showReportModal && <ReportProfileModal onClose={closeReportModal} />}
    </div>
  )
}
