import { useEffect, useMemo, useReducer, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Link, ModalNext } from '@travelpass/design-system'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { gql } from 'src/__generated__/'
import { PrivacyModal } from 'src/common/components'
import { AccountDeletedModal } from './AccountDeletedModal'
import { CreateAccountApollo } from './CreateAccountApollo'
import { ForgotPasswordModal } from './ForgotPasswordModal'
import { OnboardingModal } from './OnboardingModal'
import { SignInWithAccount } from './SignInWithAccount'
import { TermsModal } from './TermsModal'
import type { alternateModal } from './types'

const getIsUserDeleted = gql(`
  query GetIsUserDeletedSignInModal {
    currentUser {
      id
      isDeleted
    }
  }
`)

interface SignInModalProps {
  initiallyShowCreateAccount?: boolean
  onClose(loggedIn: boolean): void
}

const initialState = {
  isCreatingAccount: false,
  alternateModalOpen: null as alternateModal | null,
  errorText: '',
}

type State = typeof initialState

type Action =
  | { type: 'SET_IS_CREATING_ACCOUNT'; payload: boolean }
  | { type: 'SET_ALTERNATE_MODAL_OPEN'; payload: alternateModal | null }
  | { type: 'SET_ERROR_TEXT'; payload: string }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_CREATING_ACCOUNT':
      return { ...state, isCreatingAccount: action.payload }
    case 'SET_ALTERNATE_MODAL_OPEN':
      return { ...state, alternateModalOpen: action.payload }
    case 'SET_ERROR_TEXT':
      return { ...state, errorText: action.payload }
    default:
      return state
  }
}

export const SignInModal = ({
  initiallyShowCreateAccount = false,
  onClose,
}: SignInModalProps): JSX.Element => {
  const [attemptingCreation, setAttemptingCreation] = useState(false)
  const { pathname } = useLocation()
  const initialPathname = useMemo(() => pathname, [])
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isCreatingAccount: initiallyShowCreateAccount,
  })

  const [getUser] = useLazyQuery(getIsUserDeleted, {
    onCompleted: ({ currentUser }) => {
      if (currentUser.isDeleted) {
        return dispatch({
          type: 'SET_ALTERNATE_MODAL_OPEN',
          payload: 'accountDeleted',
        })
      }
      onClose(true)
    },
  })

  const methods = useForm()

  const onCloseModal = () => getUser()

  const createAccountOrSignInMessage = () => {
    if (!state.isCreatingAccount) {
      return (
        <div>
          Don’t have an account?{' '}
          <Link
            showUnderlineOnlyOnHover={true}
            onClick={() => {
              dispatch({ type: 'SET_IS_CREATING_ACCOUNT', payload: true })
              dispatch({ type: 'SET_ERROR_TEXT', payload: '' })
            }}
          >
            Sign up for free
          </Link>
        </div>
      )
    }

    return (
      <div>
        Already have an account?{' '}
        <Link
          showUnderlineOnlyOnHover={true}
          onClick={() => {
            dispatch({ type: 'SET_IS_CREATING_ACCOUNT', payload: false })
            dispatch({ type: 'SET_ERROR_TEXT', payload: '' })
          }}
        >
          Sign in here
        </Link>
      </div>
    )
  }

  const alternateModalOpenObj = {
    forgotPassword: <ForgotPasswordModal onClose={() => onCloseModal()} />,
    privacy: (
      <PrivacyModal
        onClose={() =>
          dispatch({ type: 'SET_ALTERNATE_MODAL_OPEN', payload: null })
        }
      />
    ),
    accountDeleted: <AccountDeletedModal onClose={() => onCloseModal()} />,
    terms: (
      <TermsModal
        onClose={() =>
          dispatch({ type: 'SET_ALTERNATE_MODAL_OPEN', payload: null })
        }
      />
    ),
    onboarding: <OnboardingModal onClose={() => onCloseModal()} />,
  }

  useEffect(() => {
    if (!attemptingCreation && initialPathname !== pathname) onClose(false)
  }, [pathname, attemptingCreation])

  return (
    <FormProvider {...methods}>
      {state.alternateModalOpen &&
        alternateModalOpenObj[state.alternateModalOpen]}
      {!state.alternateModalOpen && (
        <ModalNext
          className='children-[.modal-container]:px-14'
          size='small'
          title={state.isCreatingAccount ? 'Create Account' : 'Sign in'}
          onClose={() => onClose(false)}
        >
          {state.isCreatingAccount ? (
            <CreateAccountApollo
              createAccountOrSignInMessage={createAccountOrSignInMessage()}
              errorText={state.errorText}
              setAttemptingCreation={setAttemptingCreation}
              setErrorText={(errorText: string) =>
                dispatch({ type: 'SET_ERROR_TEXT', payload: errorText })
              }
              setIsCreatingAccount={(isCreatingAccount: boolean) =>
                dispatch({
                  type: 'SET_IS_CREATING_ACCOUNT',
                  payload: isCreatingAccount,
                })
              }
              onCloseModal={onCloseModal}
              onOpenAlternateModal={(modal: alternateModal) =>
                dispatch({ type: 'SET_ALTERNATE_MODAL_OPEN', payload: modal })
              }
            />
          ) : (
            <SignInWithAccount
              createAccountOrSignInMessage={createAccountOrSignInMessage()}
              errorText={state.errorText}
              setErrorText={(errorText: string) =>
                dispatch({ type: 'SET_ERROR_TEXT', payload: errorText })
              }
              onCloseModal={onCloseModal}
              onOpenAlternateModal={(modal: alternateModal) =>
                dispatch({ type: 'SET_ALTERNATE_MODAL_OPEN', payload: modal })
              }
            />
          )}
        </ModalNext>
      )}
    </FormProvider>
  )
}
