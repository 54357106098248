import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import type { UpdateUserNeedsGuideOnboardingInGuideDraftMutationVariables } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { getImageSrcSet } from 'src/utils'
import type { UseGetGuideDraftQuery } from '../useGetGuideDraftQueryOld'

const updateUserOnboardingMutation = gql(`
  mutation UpdateUserNeedsGuideOnboardingInGuideDraft(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      user {
        id
        needsGuideOnboarding
      }
    }
  }
`)

interface GuideDraftOnboardingProps {
  currentUser: UseGetGuideDraftQuery['data']['currentUser']
}

export const GuideDraftOnboarding = ({
  currentUser,
}: GuideDraftOnboardingProps) => {
  const isGuideDraftOnboardingUserRolesEnabled = useFlag(
    'guideDraftOnboardingUserRoles'
  )
  const [updateUser, { loading: isUpdateUserLoading }] = useMutation(
    updateUserOnboardingMutation
  )
  const [step, setStep] = useState(1)
  const { id, needsGuideOnboarding, userRoles } = currentUser ?? {}

  const onBack = () => {
    setStep(previousStep => previousStep - 1)
  }

  const onClose = async () => {
    if (!isUpdateUserLoading)
      try {
        const variables: UpdateUserNeedsGuideOnboardingInGuideDraftMutationVariables =
          {
            input: {
              id,
              needsGuideOnboarding: false,
            },
          }

        if (isGuideDraftOnboardingUserRolesEnabled && !!userRoles?.length) {
          variables.input.userRoles = userRoles
        }

        await updateUser({
          variables,
        })
        setStep(-1)
      } catch (error) {
        console.error(error)
      }
  }

  if (!needsGuideOnboarding) return

  return (
    <>
      {step === 1 && (
        <Modal
          size='medium'
          title='Add Anything To Your Guide'
          onDismiss={() => setStep(-1)}
        >
          <ModalScrollContents>
            <div className='space-y-3'>
              <p className='type-body-1 color-grey-900'>
                Search the web for activities, unique experiences, local
                restaurants, and more, then easily add them to your Guide.
              </p>
              <div className='min-h-51.5'>
                <img
                  alt=''
                  className='m-x-a block w-auto max-w-full md:h-72'
                  src='https://static.travelpass.com/assets/guides/guide-onboarding-1.webp'
                  srcSet={getImageSrcSet({
                    desktop:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-1.webp',
                    mobile:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-1-mobile.webp',
                  })}
                />
              </div>
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button
              aria-label='Continue Guide onboarding'
              variant='filled'
              onClick={() => {
                setStep(2)
              }}
            >
              1/4 Next
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 2 && (
        <Modal
          size='medium'
          title='Explore The Map'
          onDismiss={() => setStep(-1)}
        >
          <ModalScrollContents>
            <div className='space-y-3'>
              <p className='type-body-1 color-grey-900'>
                Use the map feature to discover new recommendations for your
                Guide. Just zoom in or use the filters above the map to help
                you.
              </p>
              <div className='min-h-51.5'>
                <img
                  alt=''
                  className='m-x-a block w-auto max-w-full md:h-72'
                  src='https://static.travelpass.com/assets/guides/guide-onboarding-2.webp'
                  srcSet={getImageSrcSet({
                    desktop:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-2.webp',
                    mobile:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-2-mobile.webp',
                  })}
                />
              </div>
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button
              aria-label='Continue Guide onboarding'
              variant='filled'
              onClick={() => {
                setStep(3)
              }}
            >
              2/4 Next
            </Button>
            <Button
              aria-label='Go back to the previous Guide onboarding step'
              variant='outlined'
              onClick={onBack}
            >
              Back
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 3 && (
        <Modal
          size='medium'
          title='Keep It Organized'
          onDismiss={() => setStep(-1)}
        >
          <ModalScrollContents>
            <div className='space-y-3'>
              <p className='type-body-1 color-grey-900'>
                Add, rename, and reorder sections to keep everything in your
                Guide easy to find.
              </p>
              <div className='min-h-51.5 md:p-t-5'>
                <img
                  alt=''
                  className='m-x-a block w-auto max-w-full md:h-72'
                  src='https://static.travelpass.com/assets/guides/guide-onboarding-3.webp'
                  srcSet={getImageSrcSet({
                    desktop:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-3.webp',
                    mobile:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-3-mobile.webp',
                  })}
                />
              </div>
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button
              aria-label='Continue Guide onboarding'
              variant='filled'
              onClick={() => {
                setStep(4)
              }}
            >
              3/4 Next
            </Button>
            <Button
              aria-label='Go back to the previous Guide onboarding step'
              variant='outlined'
              onClick={onBack}
            >
              Back
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 4 && (
        <Modal
          size='medium'
          title='Personalize Your Guide'
          onDismiss={() => setStep(-1)}
        >
          <ModalScrollContents>
            <div className='space-y-3'>
              <p className='type-body-1 color-grey-900'>
                Add photos, include notes and descriptions, and customize
                sections to create a Guide that&apos;s uniquely yours.
              </p>
              <div className='min-h-51.5'>
                <img
                  alt=''
                  className='m-x-a block w-auto max-w-full md:h-72'
                  src='https://static.travelpass.com/assets/guides/guide-onboarding-4.webp'
                  srcSet={getImageSrcSet({
                    desktop:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-4.webp',
                    mobile:
                      'https://static.travelpass.com/assets/guides/guide-onboarding-4-mobile.webp',
                  })}
                />
              </div>
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button
              aria-label='Close Guide onboarding'
              variant='filled'
              onClick={() => {
                onClose()
              }}
            >
              Get Started
            </Button>
            <Button
              aria-label='Go back to the previous Guide onboarding step'
              variant='outlined'
              onClick={onBack}
            >
              Back
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  )
}
