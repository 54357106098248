import { Divider } from '@travelpass/design-system'
import { MobileBottomNav } from 'src/common/components/MobileBottomNav'
import { DashboardAccountSettingsLoading } from './DashboardAccountSettings'
import { DashboardAchievementsLoading } from './DashboardAchievements'
import { DashboardBookingsLoading } from './DashboardBookings'
import { DashboardCollectionsLoading } from './DashboardCollections'
import { DashboardCtasLoading } from './DashboardCtas'
import { DashboardFeedLoading } from './DashboardFeed'
import { DashboardGuideDraftsLoading } from './DashboardGuideDrafts'
import { DashboardHeaderLoading } from './DashboardHeader'
import { DashboardNavLoading } from './DashboardNav'
import { DashboardProfileLoading } from './DashboardProfile'
import { DashboardTripsLoading } from './DashboardTrips'
import { DashboardTab } from '../dashboardConstants'
import '../dashboardCustomStyles.css'

interface DashboardLoadingProps {
  isTripsHideEnabled: boolean
  tab: string
}

export const DashboardLoading = ({
  isTripsHideEnabled,
  tab,
}: DashboardLoadingProps) => (
  <div className='md:bg-warm-grey pb-8'>
    <DashboardHeaderLoading />
    <div className='max-w-452 mx-auto my-0 w-full md:px-6 lg:px-12'>
      <div className='lg:mt--43 relative flex flex-col lg:min-h-[calc(100vh-78px)] lg:flex-row lg:gap-4 lg:px-0'>
        <aside className='min-w-84 w-84 rounded-3 hidden space-y-10 lg:block'>
          <DashboardProfileLoading />
          <DashboardAchievementsLoading />
          <DashboardAccountSettingsLoading />
        </aside>
        <div className='dashboard-center-column shadow-3 rounded-3 flex min-w-0 grow flex-col px-3.5 py-6 md:bg-white'>
          <DashboardNavLoading />
          <Divider />
          <div className='md:bg-warm-grey md:border-1px md:border-grey-200 rounded-b-3 md:border-t-none grow border-none px-6 py-12 md:border-solid'>
            {tab === DashboardTab.feed && <DashboardFeedLoading />}
            {tab === DashboardTab.guides && <DashboardGuideDraftsLoading />}
            {tab === DashboardTab.trips && !isTripsHideEnabled && (
              <DashboardTripsLoading />
            )}
            {tab === DashboardTab.bookings && <DashboardBookingsLoading />}
            {tab === DashboardTab.collections && (
              <DashboardCollectionsLoading />
            )}
          </div>
        </div>
        <div className='min-w-89 w-89 shadow-3 rounded-3 dashboard-right-column hidden h-auto space-y-6 bg-white'>
          <DashboardCtasLoading />
        </div>
      </div>
    </div>
    <MobileBottomNav />
  </div>
)
