import { Dropdown, DropdownOption } from '@travelpass/design-system'

export const FollowingDropdown = ({
  loading,
  onUnfollowChoice,
}: {
  loading: boolean
  onUnfollowChoice: (choice: 'Yes' | 'No') => void
}) => {
  return (
    <div className='lg:min-w-206px w-190px type-button hover:[&>div>span]:bg-forest [&>div>span]:bg-forest-light [&>div>span]:c-white focus:[&>div>span]:b-none aria-expanded:[&>div>span]:b-none relative w-full text-nowrap lg:w-fit [&>div>span]:my-0 [&>div>span]:h-full [&>div>span]:py-0 [&>div]:h-8 md:[&>div]:h-10 lg:[&>div]:h-12'>
      <Dropdown
        value='Unfollow?'
        onChange={(value: 'Yes' | 'No') => {
          onUnfollowChoice(value)
        }}
      >
        <DropdownOption disabled={loading} label='Yes' value='Yes'>
          Yes
        </DropdownOption>
        <DropdownOption disabled={loading} label='No' value='No'>
          No
        </DropdownOption>
      </Dropdown>
    </div>
  )
}
