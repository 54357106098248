import { GuideSectionLoading } from './GuideSectionLoading'
import { GuideEventCardLoading } from '../GuideEventCard'

export const GuideSectionsLoading = () => (
  <div className='p-t-4 grow space-y-6' data-testid='GuideSectionsLoading'>
    <GuideSectionLoading>
      <GuideEventCardLoading />
      <GuideEventCardLoading />
    </GuideSectionLoading>
    <GuideSectionLoading>
      <GuideEventCardLoading />
      <GuideEventCardLoading />
      <GuideEventCardLoading />
    </GuideSectionLoading>
  </div>
)
