import { useState } from 'react'
import { Icon, IconButton, Input } from '@travelpass/design-system'
import { constructAddress } from 'src/utils'
import { GuideDraftImagesEditSearchContent } from './GuideDraftImagesEditSearchContent'
import type {
  GuideDraftImagesEditData,
  UseGuideDraftImagesEdit,
} from '../../../useGuideDraftImagesEdit'

interface GuideDraftImagesEditSearchProps {
  guideDraftImagesEditData: GuideDraftImagesEditData
  onSelected: UseGuideDraftImagesEdit['onSelected']
  images: UseGuideDraftImagesEdit['images']
}

export const GuideDraftImagesEditSearch = ({
  guideDraftImagesEditData,
  onSelected,
  images,
}: GuideDraftImagesEditSearchProps) => {
  const [searchString, setSearchString] = useState(
    constructAddress({
      addressFirstLine: guideDraftImagesEditData?.addresses?.[0]?.addressLine1,
      city: guideDraftImagesEditData?.addresses?.[0]?.city,
      state: guideDraftImagesEditData?.addresses?.[0]?.state,
      country: guideDraftImagesEditData?.addresses?.[0]?.country,
    })
  )

  return (
    <div className='space-y-3'>
      <Input
        aria-label='Search a Location to Find Photos'
        fullWidth={true}
        placeholder='Search a Location to Find Photos'
        slotAfter={
          searchString.trim() !== '' && (
            <IconButton icon='clear' onClick={() => setSearchString('')} />
          )
        }
        slotBefore={<Icon name='search' size='small' />}
        value={searchString}
        onChange={event => setSearchString(event.target.value)}
      />
      <GuideDraftImagesEditSearchContent
        images={images}
        searchString={searchString}
        onSelected={onSelected}
      />
      <div className='gap-.5 flex flex-row items-center justify-end'>
        <span className='color-grey-800 type-small-text'>
          Photos supported by
        </span>
        <img
          alt='Pixabay'
          className='h-3.5'
          src='https://static.travelpass.com/assets/brands/pixabay/pixabay-logo.webp'
        />
      </div>
    </div>
  )
}
