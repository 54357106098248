import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const deleteUserMutation = gql(`
  mutation DeleteDashboardAccountSettingsFormSecurityMutationInDashboard($input: DeleteUserInput!) { 
    deleteUser(input: $input) {
      user {
        id
        isDeleted
      }
    }
  }
`)

export const useDeleteDashboardAccountSettingsFormNotificationsMutation = () =>
  useMutation(deleteUserMutation)
