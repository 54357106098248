import { Button, IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { getGuideDraftDetailsUrl } from 'src/utils'
import {
  GuideMetricLike,
  GuideMetricSave,
  GuideMetricShare,
} from '../../common'
import { GuideSearchParam } from '../guideConstants'
import type { GuideData } from '../types'

interface GuideStickyActionButtonsProps {
  isUserOwner: boolean
  guideData: GuideData
}

export const GuideStickyActionButtons = ({
  isUserOwner,
  guideData,
}: GuideStickyActionButtonsProps) => {
  const { name, guideDraft } = guideData ?? {}
  const [searchParams, setSearchParams] = useSearchParams()

  const guideDraftUrl = getGuideDraftDetailsUrl({
    id: guideDraft?.id,
    isUserOwner,
    name,
  })

  const onMapClick = () => {
    searchParams.set(GuideSearchParam.mapExpanded, 'true')
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <>
      <div className='flex w-full flex-row items-center justify-between gap-2.5'>
        <Button
          aria-label='View Guide Map'
          size='small'
          startIcon='mapOutlined'
          onClick={onMapClick}
        >
          Map
        </Button>
        {isUserOwner ? (
          <div className='[&>button]:p-x-0 [&>button>i]:color-forest-light [&>button>div]:color-forest-light flex flex-row items-center gap-2.5'>
            <IconButton
              aria-label='Edit this Guide'
              color='white'
              icon='modeEdit'
              outline='round'
              size='medium'
              onClick={() => window.open(guideDraftUrl, '_blank')}
            />
            <GuideMetricShare
              color='white'
              guideData={guideData}
              isIconButton={true}
            />
          </div>
        ) : (
          <div className='[&>button]:p-x-0 [&>button>i]:color-forest-light [&>button>div]:color-forest-light flex flex-row items-center gap-2.5'>
            <GuideMetricSave
              color='white'
              guideData={guideData}
              isIconButton={true}
            />
            <GuideMetricShare
              color='white'
              guideData={guideData}
              isIconButton={true}
            />
            <GuideMetricLike
              color='white'
              guideData={guideData}
              isIconButton={true}
            />
          </div>
        )}
      </div>
    </>
  )
}
