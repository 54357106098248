import type { FormEvent } from 'react'
import { useEffect, useState } from 'react'
import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import {
  PageLayoutContainer,
  ResultsGeocoder,
  ResultsGuests,
} from 'src/common/components'
import { useLucencyNumber } from 'src/config/analytics/useLucencyNumber'
import type { DatesType, GeocoderType, GuestsType } from 'src/constants/user'
import { initialArrivalDate, initialGeocoder } from 'src/constants/user'
import {
  constructDates,
  constructGeocoder,
  constructGuests,
  stringToNumber,
} from 'src/utils'
import { HotelResultsSearchDates } from './HotelResultsSearchDates'
import { HotelResultsSearchDivider } from './common'
import { setHotelResultsSearchSearchParams } from './hotelResultsSearchUtils'
import { HotelResultsSearchParams } from '../hotelResultsConstants'

export const HotelResultsSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = searchParams.get(HotelResultsSearchParams.location)
  const [dates, setDates] = useState<DatesType>(
    constructDates({
      arrival: searchParams.get(HotelResultsSearchParams.arrival),
      departure: searchParams.get(HotelResultsSearchParams.departure),
    })
  )
  const [geocoder, setGeocoder] = useState<GeocoderType>(
    constructGeocoder({
      latitude: stringToNumber(
        searchParams.get(HotelResultsSearchParams.latitude)
      ),
      longitude: stringToNumber(
        searchParams.get(HotelResultsSearchParams.longitude)
      ),
      placeId: searchParams.get(HotelResultsSearchParams.placeId),
      placeName: searchParams.get(HotelResultsSearchParams.location),
    })
  )
  const [guests, setGuests] = useState<GuestsType>(
    constructGuests({
      adults: searchParams.get(HotelResultsSearchParams.adults),
      kids: searchParams.get(HotelResultsSearchParams.kids),
    })
  )
  const [hasGeocoderError, setHasGeocoderError] = useState(false)
  const { updateLucency } = useLucencyNumber()

  useEffect(() => {
    if (
      searchParams.get(HotelResultsSearchParams.arrival) &&
      searchParams.get(HotelResultsSearchParams.departure)
    ) {
      setDates(
        constructDates({
          arrival: searchParams.get(HotelResultsSearchParams.arrival),
          departure: searchParams.get(HotelResultsSearchParams.departure),
        })
      )
    }
  }, [searchParams])

  const showSearch = !!dates && !!geocoder && !!guests

  const onDateSelect = range => {
    // track checkin and checkout dates to lucency
    updateLucency({
      checkin: range.from,
      checkout: range?.to,
    })
    setDates([range.from, range?.to])
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    const arrival = dates?.[0] ?? initialArrivalDate
    const departure = dates?.[1] ?? arrival?.add(1, 'day')
    const updatedHasDatesError = !dates?.[0] || !dates?.[1]
    const updatedHasGeocoderError =
      isEmpty(geocoder) || geocoder === initialGeocoder
    setHasGeocoderError(updatedHasGeocoderError)

    if (updatedHasDatesError)
      onDateSelect({
        from: arrival,
        to: departure,
      })

    if (!updatedHasGeocoderError) {
      setHotelResultsSearchSearchParams({
        dates: [arrival, departure],
        geocoder,
        guests,
        onChange: (updatedSearchParams: URLSearchParams) =>
          setSearchParams(updatedSearchParams),
        searchParams,
      })
    }
  }

  useEffect(() => {
    setGeocoder(s => ({
      ...s,
      placeName: location,
    }))
  }, [location])

  return (
    showSearch && (
      <div className='hidden lg:block lg:py-4'>
        <PageLayoutContainer>
          <form
            noValidate
            className='flex items-center gap-6 lg:gap-3'
            onSubmit={onSubmit}
          >
            <div className='search-gap flex grow items-center justify-center gap-4 md:gap-3'>
              <div className='search-geocoder md:w-55 w-64 max-w-full'>
                <ResultsGeocoder
                  checkTypes
                  errorText={
                    !!hasGeocoderError && 'Please select a destination.'
                  }
                  focusOnInput={false}
                  geocoder={geocoder}
                  isInvalid={hasGeocoderError}
                  label='Destination'
                  placeholder='Where are you going?'
                  onClick={event =>
                    event.currentTarget.querySelector('input')?.select()
                  }
                  onResult={updatedGeocoder => setGeocoder(updatedGeocoder)}
                />
              </div>
              <HotelResultsSearchDivider />
              <HotelResultsSearchDates dates={dates} onSelect={onDateSelect} />
              <HotelResultsSearchDivider />
              <div className='search-guests w-32 max-w-full'>
                <ResultsGuests
                  guests={guests}
                  onChange={updatedGuests => setGuests(updatedGuests)}
                />
              </div>
            </div>
            <div>
              <Button
                fullWidth
                label='Search'
                size='large'
                startIcon='search'
                type='submit'
              />
            </div>
          </form>
        </PageLayoutContainer>
      </div>
    )
  )
}
