import { Icon } from '@travelpass/design-system'
import {
  auth,
  firebaseCheckConnectedProviders,
} from 'src/config/firebase/firebaseUtils.js'
import { DashboardAccountSettingsFormSecurityDelete } from './DashboardAccountSettingsFormSecurityDelete.tsx'
import { DashboardAccountSettingsFormSecurityPassword } from './DashboardAccountSettingsFormSecurityPassword.js'

export const DashboardAccountSettingsFormSecurity = () => {
  const { email } = auth?.currentUser
  const { isConnectedToEmail, isConnectedToGoogle } =
    firebaseCheckConnectedProviders()

  return (
    <div className='space-y-8'>
      <section className='space-y-6'>
        <h5 className='type-h6'>
          {isConnectedToEmail ? 'Login' : 'Sign-in method'}
        </h5>
        <div className='space-y-6'>
          {isConnectedToEmail ? (
            <>
              <p className='type-body-1-desktop c-grey-700'>{email}</p>
              <DashboardAccountSettingsFormSecurityPassword />
            </>
          ) : (
            <p className='type-body-1-desktop c-grey-700 flex flex-row flex-wrap items-center gap-3'>
              <Icon
                name={isConnectedToGoogle ? 'coloredGoogle' : 'coloredFacebook'}
                size='large'
              />
              <span>
                Signed in with {isConnectedToGoogle ? 'Google' : 'Facebook'}
              </span>
              <span className='c-black'>{email}</span>
            </p>
          )}
        </div>
      </section>
      <DashboardAccountSettingsFormSecurityDelete />
    </div>
  )
}
