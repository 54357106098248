import { useScreenQuery } from '@travelpass/design-system'
import { EditingProfileModal } from './EditingProfileModal'
import { EditingProfileOverview } from './EditingProfileOverview'
import { useCheckEditingMode } from '../hooks/useCheckEditingMode'

export const EditingProfileTabs = () => {
  //Hooks
  const { isEditingMode } = useCheckEditingMode()
  const { isMobileScreen } = useScreenQuery()
  //Others

  return (
    <>
      {/*Desktop version */}
      <div className='w-full bg-white pt-10 sm:hidden md:block md:overflow-auto'>
        <EditingProfileOverview />
      </div>

      {/*Mobile version */}
      {isMobileScreen && isEditingMode && <EditingProfileModal />}
    </>
  )
}
