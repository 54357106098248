import { Button, Link } from '@travelpass/design-system'
import { useLocation } from 'react-router-dom'
import { SortBy } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'
import {
  generateHotelResultsUrl,
  generateExperiencesResultsUrl,
} from 'src/utils'
import { LandingPageSearchHotelsResults } from '../../../common/LandingPageSearchHotelsResults'

export const SouthernUtah = () => {
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  const date = () => new Date()
  const today = date().toISOString().split('T')[0]
  const dateTomorrow = () => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }
  const tomorrow = dateTomorrow().toISOString().split('T')[0]

  const onNationalParksHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Map area',
      latitude: 37.909141205983595,
      longitude: -112.00549798804116,
      mapBounds: JSON.stringify({
        south: 37.25847975495991,
        west: -113.26720550704007,
        north: 38.55980265700728,
        east: -110.74379046904225,
      }),
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onNationalParkExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'Map area',
      latitude: 37.0965278,
      longitude: -113.5684164,
    })
    window.open(url)
  }

  const onMoabHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Moab, UT, USA',
      latitude: 38.5733155,
      longitude: -109.5498395,
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onMoabExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'Moab, UT, USA',
      latitude: 38.5733155,
      longitude: -109.5498395,
    })
    window.open(url)
  }

  const onKanabHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Kanab, UT, USA',
      latitude: 37.0474855,
      longitude: -112.5263145,
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onKanabExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'Kanab, UT, USA',
      latitude: 37.0474855,
      longitude: -112.5263145,
    })
    window.open(url)
  }

  const onStGeorgeHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'St. George, UT, USA',
      latitude: 37.0965278,
      longitude: -113.5684164,
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onStGeorgeExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'St. George, UT, USA',
      latitude: 37.0965278,
      longitude: -113.5684164,
    })
    window.open(url)
  }

  const jumpToNationalParks = e => {
    e.preventDefault()
    const node = document.getElementById('national-parks')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToMoab = e => {
    e.preventDefault()
    const node = document.getElementById('moab')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToKanab = e => {
    e.preventDefault()
    const node = document.getElementById('kanab')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToStGeorge = e => {
    e.preventDefault()
    const node = document.getElementById('st-george')
    node?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />

      <header className='h-200px lg:h-300px bg-bottom-center lg:bg-bottom-center w-full bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/a96704a4-e79b-11ee-9ca9-8e1da778f97e)] bg-cover'></header>

      <PageLayoutContainer size='medium'>
        <div className='flex w-full flex-col gap-4 py-10'>
          <h1 className='font-archivo font-700 tracking-5% m-0 flex flex-col text-center'>
            <span className='text-6 lg:leading-14 lg:text-10 font-900 leading-6'>
              Experience
            </span>
            <span className='text-14 lg:leading-20 lg:text-26 font-900'>
              Southern Utah
            </span>
          </h1>
          <p className='type-subtitle-2 font-300 lg:line-height-8 tracking-wide lg:mt-8'>
            Escape to the state’s famous red rock cliffs to explore national
            parks and favorite cities like St. George, Moab, or even an
            under-radar-gem like Kanab. Each of these destinations are close to
            at least one national park, making it easy to visit a couple places
            on your Utah bucket list. You’re in for fantastic hikes,
            high-adventure tours, top restaurants, and spectacular views in this
            corner of the state.
          </p>
        </div>
      </PageLayoutContainer>
      <PageLayoutContainer>
        <div className='pb-15 flex flex-col items-center justify-center gap-4 pt-5 lg:flex'>
          <Button
            fullWidth
            label='The National Parks'
            size='large'
            variant='filledDark'
            onClick={jumpToNationalParks}
          />
          <Button
            fullWidth
            label='Moab'
            size='large'
            variant='filledDark'
            onClick={jumpToMoab}
          />
          <Button
            fullWidth
            label='Kanab'
            size='large'
            variant='filledDark'
            onClick={jumpToKanab}
          />
          <Button
            fullWidth
            label='St. George'
            size='large'
            variant='filledDark'
            onClick={jumpToStGeorge}
          />
        </div>
      </PageLayoutContainer>

      <div
        className='bg-warmGrey flex w-full flex-col items-stretch justify-center lg:flex-row'
        id='national-parks'
      >
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/a694d0e2-e7ca-11ee-8123-8e1da778f97e)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <h2 className='type-h1 c-white mb-4 text-center'>
                The National Parks
              </h2>
              <PageLayoutContainer size='medium'>
                <p className='type-body-1 c-white mb-4 text-center'>
                  Nicknamed the{' '}
                  <span className='font-900'>The Mighty Five</span>, you have
                  quite the choice of parks to visit while in Utah! Zion and
                  Arches might be the most popular, but we love the less-visited
                  Capitol Reef park.
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip:</b> The locally-made fruit pies available in
                  the park are the best post-hike snack.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[101859720, 103115631, 102766260]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onNationalParksHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjY5Nzcw/East-Zion%3A-Coral-Sands-Half-day-Canyoneering-Tour?arrival=${today}&departure=${tomorrow}`}
                    label='East Zion Canyoneering'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjcyNzM3/Hiking-Experience-in-Bryce-Canyon-National-Park?arrival=${today}&departure=${tomorrow}`}
                    label='Hiking in Bryce Canyon'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6NTI3Njc=/Canyonlands-National-Park-Airplane-Tour?arrival=${today}&departure=${tomorrow}`}
                    label='Canyonlands by Airplane'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onNationalParkExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
      </div>

      <div
        className='flex w-full flex-col flex-col-reverse items-stretch justify-center bg-white lg:flex-row'
        id='moab'
      >
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[100700362, 100152151, 102794302]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onMoabHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjgzMTgx/Hell%27s-Revenge-4x4-Off-Roading-Tour-from-Moab?arrival=${today}&departure=${tomorrow}`}
                    label='Hell’s Revenge Off-Road Touring'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MTkyNDg5/Flatwater-Fun%3A-Moab-Stand-Up-Paddleboarding?arrival=${today}&departure=${tomorrow}`}
                    label='Paddleboard the Colorado River'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzI5NzI2/Guided-Hot-Air-Balloon-Rides-in-Moab?arrival=${today}&departure=${tomorrow}`}
                    label='Hot Air Ballon Tours'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onMoabExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/af55ac8e-e7d3-11ee-8561-da394f58b37a)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <h2 className='type-h1 c-white mb-4 text-center'>Moab</h2>
              <PageLayoutContainer size='medium'>
                <p className='type-body-1 c-white mb-4 text-center'>
                  What used to be a quiet town is now a bustling hub of
                  adventure. Arches National Park is nearby, along with plenty
                  of bike shops, rock shops, and countless opportunities to get
                  your heart pumping.
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip:</b> If you’re looking for ideas, the team at
                  Pagan Mountaineering typically share some great suggestions on
                  things to do.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
      </div>

      <div
        className='bg-warmGrey flex w-full flex-col items-stretch justify-center lg:flex-row'
        id='kanab'
      >
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/39b9805c-e875-11ee-94dc-3e887b2f90b9)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <h2 className='type-h1 c-white mb-4 text-center'>Kanab</h2>
              <PageLayoutContainer size='medium'>
                <p className='type-body-1 c-white mb-4 text-center'>
                  Kanab is a little bit of a local’s secret, full of outdoorsy
                  shops, local hikes, fantastic restaurants, and tons of
                  recommended things to do (swing by the friendly visitor’s
                  center for advice).
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip:</b> We love the restaurants here just as much
                  as the hiking trails. Don’t miss out on dinner at Rocking V’s
                  or Wild Thyme.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[100439357, 102028614, 102339729]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onKanabHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6NzM3MDc=/White-Pocket-Adventure?arrival=${today}&departure=${tomorrow}`}
                    label='White Pocket Adventure (Nearby)'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjkzMzg1/Hiking-in-Kanab%3A-Walk-and-Photograph-the-incredible-Wire-Pass-Slot-Canyon?arrival=${today}&departure=${tomorrow}`}
                    label='Hike Wire Pass Slot Canyon'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzM2MTgx/Aztec-Trail-Horseback-Ride?arrival=${today}&departure=${tomorrow}`}
                    label='Horseback Through Johnson’s Canyon'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onKanabExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
      </div>

      <div
        className='flex w-full flex-col flex-col-reverse items-stretch justify-center bg-white lg:flex-row'
        id='st-george'
      >
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[101846780, 103113379, 103116394]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onStGeorgeHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjEwNDA5/Sand-Hollow-Jet-Ski-Rentals---Quail-Creek-Reservoir-Waverunner-Adventure?arrival=${today}&departure=${tomorrow}`}
                    label='Jet Ski at Sand Hollow'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6NzQ1NDg=/Sunset-UTV-Group-Tour?arrival=${today}&departure=${tomorrow}`}
                    label='Sunset UTV Tour'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`http://travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MTA1NjU0/Cliffs-of-Insanity-UTV-Tour?arrival=${today}&departure=${tomorrow}`}
                    label='Cliffs Of Insanity UTV Tour'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onStGeorgeExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/3f24a93a-e880-11ee-a599-4e9c09eaacc2)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <h2 className='type-h1 c-white mb-4 text-center'>St. George</h2>
              <PageLayoutContainer size='medium'>
                <p className='type-body-1 c-white mb-4 text-center'>
                  Hiking, golfing, shopping, biking, dining, and sunshine all
                  await you in this popular Utah getaway.
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip:</b> Have a pastry breakfast at Farmstead, then
                  find incredible views, unique trails, and biking paths in Snow
                  Canyon State Park. Or take a day trip to Sand Hollow for a
                  swim.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
      </div>

      <div className='bg-forest lg:py-15 flex w-full flex-col items-center justify-center py-10'>
        <h3 className='type-h2 c-white mb-10'>Explore Your Utah</h3>

        <div className='w-94% max-w-1000px mx-a grid grid-cols-1 gap-4 md:grid-cols-2'>
          <div className='h-200px bg-forest relative col-span-2 row-span-5 overflow-hidden lg:col-span-1'>
            <a href='/inside-scoop/salt-lake-city'>
              <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4'>
                SALT LAKE CITY
              </p>
              <img
                alt='Salt Lake City, Utah'
                className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-60'
                src='https://static.travelpass.com/assets/destinations-page/salt-lake-city-utah/book-a-hotel.webp'
              />
            </a>
          </div>

          <div className='h-200px bg-forest relative col-span-2 overflow-hidden lg:col-span-1 lg:row-span-2'>
            <a href='/inside-scoop/utah-county'>
              <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4'>
                UTAH COUNTY
              </p>
              <img
                alt='Provo, Utah'
                className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-60'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/c8619e24-e70e-11ee-9ea9-ca13f8e0f9ae'
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const metaDescription =
  'Discover the best of Southern Utah with our comprehensive travel guide. Explore the breathtaking landscapes of Zion and Bryce Canyon National Parks, embark on unforgettable hiking trails, and experience the unique culture and history of the region. Plan your perfect adventure with tips on accommodations and must-see attractions. Your Southern Utah journey starts here!'
const pageName = 'Inside Scoop: Southern Utah'
