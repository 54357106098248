import isEmpty from 'lodash.isempty'
import { useParams, useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { awayGameDetails } from '../awayGameDetails'

export const AwayGameHero = () => {
  const { teamName } = useParams()
  const [searchParams] = useSearchParams()

  const gameDate = searchParams.get('date')

  const game = Object.values(awayGameDetails).find(
    game => game.team.toLowerCase().replaceAll(' ', '-') === teamName
  )

  const team = game.team.split(' ').pop()

  if (isEmpty(game.team)) {
    return (
      <PageLayoutContainer>
        <p className='text-8 md:text-12 c-black font-900'>
          No Away Games vs {game.team} this season!
        </p>
      </PageLayoutContainer>
    )
  }

  return (
    <header className='p-t-8 p-b-12 lg:p-t-12 lg:p-b-20 bg-white'>
      <PageLayoutContainer>
        <div className='grid grid-cols-1 place-content-center gap-6 md:grid-cols-2 lg:gap-4'>
          <div>
            <img
              alt='Utah Jazz + Travelpass logo lockup'
              className='m-x-a md:m-x-0 m-b-7 md:m-b-20 lg:w-75 block w-60'
              src='https://static.travelpass.com/assets/landing-pages/utah-jazz/jazz-travelpass-logo-lockup_black.webp'
            />
            <h1 className='text-14 lg:text-18 font-900 c-black m-t-0 m-b-4 lg:m-y-2 line-height-100% md:line-height-108% m-0 text-balance text-center md:text-left'>
              Explore{' '}
              <span className='from-valley to-newForest bg-gradient-to-r bg-clip-text text-transparent'>
                {game.city}
              </span>
            </h1>
            <h2 className='p-x-5 md:p-x-0 text-5 lg:text-6 font-400 c-grey-800 m-b-15 lg:m-b-20 m-0 text-balance text-center md:text-left'>
              Cheer on the Jazz in {game.city} vs the {team}!
            </h2>

            <div className='of-hidden rounded-4 h-100px lg:max-w-400px md:m-l-0 m-x-5 relative flex w-auto flex-row items-center shadow-md'>
              <div className='w-100px h-100px place-content-center bg-black'>
                <img
                  alt='Jazz note logo'
                  className='w-70px m-x-a block'
                  src='https://static.travelpass.com/assets/landing-pages/utah-jazz/jazz-note-white.webp'
                />
              </div>
              <div className='h-100px w-[calc(100%-100px)] place-content-center bg-white'>
                <div className='m-l-5'>
                  <p className='text-5 lg:text-6 font-900 c-black m-b-1 m-0'>
                    Jazz @{' '}
                    <span className='from-valley to-newForest bg-gradient-to-r bg-clip-text text-transparent'>
                      {team}
                    </span>
                  </p>
                  <p className='text-4 lg:text-5 c-grey-800 m-0'>{gameDate}</p>
                </div>
              </div>
            </div>
          </div>

          <div className='hidden place-content-center md:block'>
            <div className='m-x-a md:rounded-6 of-hidden h-full w-full shadow-lg'>
              <img
                alt={`${game.city}, ${game.state}`}
                className='block h-full w-full object-cover'
                src={`${game.cityImg}`}
              />
            </div>
          </div>
        </div>
      </PageLayoutContainer>
    </header>
  )
}
