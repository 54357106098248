import { useCallback, useEffect, useRef, useState } from 'react'
import type {
  CollisionDetection,
  DndContextProps,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  DropAnimation,
  DroppableContainer,
  KeyboardCoordinateGetter,
  UniqueIdentifier,
} from '@dnd-kit/core'
import {
  closestCenter,
  closestCorners,
  defaultDropAnimationSideEffects,
  getFirstCollision,
  KeyboardCode,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import isEmpty from 'lodash.isempty'
import type { UpdatePublishedEventSortOrderMutationInGuideDraftMutationVariables } from 'src/__generated__/graphql'
import {
  getGuideDraftDragItems,
  getGuideDraftPublishedEventCategorySortOrder,
} from './guideDraftUtils'
import type { GuideDraftData, GuideDraftDragItems } from './types'
import type { UseGetGuideDraftQuery } from './useGetGuideDraftQueryOld'
import { useUpdateGuideDraftEventSortOrderMutation } from './useUpdateGuideDraftEventSortOrderMutation'
import { useUpdateGuideDraftMutationOld } from './useUpdateGuideDraftMutationOld'

const coordinateGetter: KeyboardCoordinateGetter = (
  event,
  { context: { active, droppableRects, droppableContainers, collisionRect } }
) => {
  if (directions.includes(event.code)) {
    event.preventDefault()

    if (!active || !collisionRect) return

    const filteredContainers: DroppableContainer[] = []

    droppableContainers.getEnabled().forEach(entry => {
      if (!entry || entry?.disabled) return

      const rect = droppableRects.get(entry.id)

      if (!rect) return

      const data = entry.data.current

      if (data) {
        const { type, children } = data

        if (
          type === 'container' &&
          children?.length > 0 &&
          active.data.current?.type !== 'container'
        ) {
          return
        }
      }

      switch (event.code) {
        case KeyboardCode.Down:
          if (collisionRect.top < rect.top) {
            filteredContainers.push(entry)
          }
          break
        case KeyboardCode.Up:
          if (collisionRect.top > rect.top) {
            filteredContainers.push(entry)
          }
          break
        case KeyboardCode.Left:
          if (collisionRect.left >= rect.left + rect.width) {
            filteredContainers.push(entry)
          }
          break
        case KeyboardCode.Right:
          if (collisionRect.left + collisionRect.width <= rect.left) {
            filteredContainers.push(entry)
          }
          break
      }
    })

    const collisions = closestCorners({
      active,
      collisionRect: collisionRect,
      droppableRects,
      droppableContainers: filteredContainers,
      pointerCoordinates: null,
    })
    const closestId = getFirstCollision(collisions, 'id')

    if (closestId) {
      const newDroppable = droppableContainers.get(closestId)
      const newNode = newDroppable?.node.current
      const newRect = newDroppable?.rect.current

      if (newNode && newRect) {
        if (newDroppable.data.current?.type === 'container') {
          return {
            x: newRect.left + 20,
            y: newRect.top + 74,
          }
        }

        return {
          x: newRect.left,
          y: newRect.top,
        }
      }
    }
  }

  return undefined
}

const directions: string[] = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
]

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
}

type UseGuideDraftDrag = {
  activeId: UniqueIdentifier
  containers: UniqueIdentifier[]
  dragProps: DndContextProps
  dropAnimation: DropAnimation
  getContainerId: (id: UniqueIdentifier) => UniqueIdentifier
  isSortingContainer: boolean
  items: GuideDraftDragItems
}

export const useGuideDraftDrag = (
  data: UseGetGuideDraftQuery['data']
): UseGuideDraftDrag => {
  const lastOverId = useRef<UniqueIdentifier>(null)
  const recentlyMovedToNewContainer = useRef(false)
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  )
  const [activeId, setActiveId] = useState<UniqueIdentifier>(null)
  const [clonedItems, setClonedItems] = useState<GuideDraftDragItems>(null)
  const [items, setItems] = useState<GuideDraftDragItems>(
    getGuideDraftDragItems({
      paginatedEventCategories: (data?.node as GuideDraftData)
        ?.paginatedEventCategories,
      uncategorizedPublishedEvents: (data?.node as GuideDraftData)
        ?.uncategorizedPublishedEvents,
    })
  )
  const [containers, setContainers] = useState<UniqueIdentifier[]>(
    Object.keys(items ?? {})
  )
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            container => container.id in items
          ),
        })
      }

      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args)
      let overId = getFirstCollision(intersections, 'id')

      if (overId) {
        if (overId in items) {
          const containerItems = items?.[overId]?.publishedEvents ?? []

          if (containerItems?.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container =>
                  container?.id !== overId &&
                  containerItems?.findIndex(
                    event => event?.id === container.id
                  ) > -1
              ),
            })[0]?.id
          }
        }

        lastOverId.current = overId

        return [{ id: overId }]
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId, items]
  )
  const [updateGuideDraft] = useUpdateGuideDraftMutationOld()
  const [updatePublishedEventSortOrder] =
    useUpdateGuideDraftEventSortOrderMutation()

  const isSortingContainer = activeId ? containers.includes(activeId) : false

  useEffect(() => {
    if (isEmpty(data)) return

    requestAnimationFrame(() => {
      const updatedItems = getGuideDraftDragItems({
        paginatedEventCategories: (data?.node as GuideDraftData)
          ?.paginatedEventCategories,
        uncategorizedPublishedEvents: (data?.node as GuideDraftData)
          ?.uncategorizedPublishedEvents,
      })
      setContainers(Object.keys(updatedItems ?? {}))
      setItems(updatedItems)
    })
  }, [data])

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [items])

  const getContainerId = (id: UniqueIdentifier): UniqueIdentifier => {
    if (id in items) return id

    /** @todo we might be able to replace Object.keys(items).find with containers.find */
    return Object.keys(items).find(
      container =>
        items?.[container]?.publishedEvents?.findIndex(
          event => event?.id === id
        ) > -1
    )
  }

  const onDragCancel = () => {
    if (clonedItems) setItems(clonedItems)

    setActiveId(null)
    setClonedItems(null)
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id in items && over?.id) {
      setContainers(containers => {
        const activeIndex = containers.indexOf(active.id)
        const overIndex = containers.indexOf(over.id)
        const updatedContainers = arrayMove(containers, activeIndex, overIndex)
        const publishedEventCategorySortOrder =
          getGuideDraftPublishedEventCategorySortOrder(updatedContainers)

        try {
          updateGuideDraft({
            variables: {
              input: {
                guideDraftId: (data?.node as GuideDraftData)?.id,
                publishedEventCategorySortOrder,
              },
            },
          })
        } catch (error) {
          console.error(error)
        }

        return updatedContainers
      })
    }

    const activeContainer = getContainerId(active.id)

    if (!activeContainer) {
      setActiveId(null)
      return
    }

    const overId = over?.id

    if (overId == null) {
      setActiveId(null)
      return
    }

    const overContainer = getContainerId(overId)

    if (overContainer) {
      const activeIndex = items?.[activeContainer]?.publishedEvents?.findIndex(
        event => event?.id === active.id
      )
      const overIndex = items?.[overContainer]?.publishedEvents?.findIndex(
        event => event?.id === overId
      )

      const updatedPublishedEvents = arrayMove(
        items?.[overContainer]?.publishedEvents,
        activeIndex,
        overIndex
      )

      if (activeIndex !== overIndex) {
        setItems(items => {
          return {
            ...items,
            [overContainer]: {
              ...items?.[overContainer],
              publishedEvents: updatedPublishedEvents,
            },
          }
        })
      }

      if (!items[active?.id]) {
        try {
          const updatedPublishedEventIds = updatedPublishedEvents?.map(
            ({ id }) => id
          )
          const input: UpdatePublishedEventSortOrderMutationInGuideDraftMutationVariables['input'] =
            {
              publishedEventId: activeId as string,
            }

          if (overContainer === 'null') {
            input.uncategorizedEventSortOrder = updatedPublishedEventIds
          } else {
            input.publishedEventCategoryId = items?.[overContainer]?.id
            input.eventSortOrder = updatedPublishedEventIds
          }
          updatePublishedEventSortOrder({
            variables: {
              input,
            },
          })
        } catch (error) {
          console.error(error)
        }
      }
    }

    setActiveId(null)
  }

  const onDragOver = ({ active, over }: DragOverEvent) => {
    const overId = over?.id

    if (overId == null || active.id in items) return

    const overContainer = getContainerId(overId)
    const activeContainer = getContainerId(active.id)

    if (!overContainer || !activeContainer) return

    if (activeContainer !== overContainer) {
      setItems(items => {
        const activeItems = items?.[activeContainer]
        const overItems = items?.[overContainer]
        const overIndex = overItems?.publishedEvents.findIndex(
          event => event?.id === overId
        )
        const activeIndex = activeItems?.publishedEvents.findIndex(
          event => event?.id === active.id
        )

        let newIndex: number

        if (overId in items) {
          newIndex = overItems?.publishedEvents?.length + 1
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          newIndex =
            overIndex >= 0
              ? overIndex + modifier
              : overItems?.publishedEvents?.length + 1
        }

        recentlyMovedToNewContainer.current = true

        return {
          ...items,
          [activeContainer]: {
            ...items?.[activeContainer],
            publishedEvents: items?.[activeContainer]?.publishedEvents?.filter(
              item => item?.id !== active.id
            ),
          },
          [overContainer]: {
            ...items?.[overContainer],
            publishedEvents: [
              ...items?.[overContainer]?.publishedEvents?.slice(0, newIndex),
              items?.[activeContainer]?.publishedEvents?.[activeIndex],
              ...items?.[overContainer]?.publishedEvents?.slice(
                newIndex,
                items?.[overContainer]?.publishedEvents?.length
              ),
            ],
          },
        }
      })
    }
  }

  const onDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id)
    setClonedItems(items)
  }

  return {
    activeId,
    containers,
    getContainerId,
    dragProps: {
      collisionDetection: collisionDetectionStrategy,
      measuring: {
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      },
      onDragCancel,
      onDragEnd,
      onDragOver,
      onDragStart,
      sensors,
    },
    dropAnimation,
    isSortingContainer,
    items,
  }
}
