import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  Divider,
  Icon,
  KeyCode,
} from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import type { ExploreFilterUpdateFunction } from '../../exploreTypes'

interface KeywordsFilterProps {
  keyword: string
  onSelection: ExploreFilterUpdateFunction
}

export const KeywordsFilter = ({
  onSelection,
  keyword,
}: KeywordsFilterProps) => {
  const [searchedKeyword, setSearchedKeyword] = useState('')
  const [searchParams] = useSearchParams()
  const locationTitle = searchParams.get('title')

  const handlePersistLocationData = async () => {
    const [result] = await getGeocode({ address: locationTitle })

    const { lat, lng } = getLatLng(result)

    onSelection('location', {
      lat,
      lng,
      title: locationTitle,
      location:
        result?.address_components?.[0]?.short_name ||
        result?.formatted_address,
    })
  }

  const onOptionSelect = e => {
    if (e.trim()) {
      onSelection('keyword', e)
      setSearchedKeyword('')
      handlePersistLocationData()
    }
  }

  const onKeyDown = e => {
    if (e.key === KeyCode.ENTER) {
      onSelection('keyword', e.target.value)
      setSearchedKeyword('')
      handlePersistLocationData()
    }
    if (e.key === KeyCode.ESC) setSearchedKeyword('')
  }

  return (
    <>
      <h4 className='type-h5'>Keywords</h4>
      <Autocomplete
        autoExpand
        fullWidth
        placeholder='Search Keywords'
        slotBefore={
          <span className='color-new-forest'>
            <Icon name='search' />
          </span>
        }
        value={searchedKeyword}
        onChange={({ target }) => setSearchedKeyword(target.value)}
        onKeyDown={onKeyDown}
        onOptionSelect={onOptionSelect}
      >
        {searchedKeyword.trim() && (
          <AutocompleteOption
            className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey mt- mt-2 flex w-full cursor-pointer flex-row items-center gap-2 px-4 text-left outline-none'
            value={searchedKeyword}
          >
            <span className='c-valleyDark mt1'>
              <Icon name='search' />
            </span>
            Search for Keyword
            <span className='font500'>&quot;{searchedKeyword}&quot;</span>
          </AutocompleteOption>
        )}
      </Autocomplete>
      {keyword && (
        <Chip
          includeCloseIcon
          isSelected
          label={keyword}
          onClick={() => onSelection('keyword', null)}
        />
      )}
      <Divider />
    </>
  )
}
