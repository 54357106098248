import { useEffect, useRef, useState } from 'react'
import { Button, Icon } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { GeocoderWithTextSearchProps } from 'src/common/components/GeocoderWithTextSearch/GeocoderWithTextSearch'
import { GeocoderWithTextSearch } from 'src/common/components/GeocoderWithTextSearch/GeocoderWithTextSearch'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'

const PlaceOutlineIcon = () => (
  <span className='c-new-forest'>
    <Icon name='placeOutline' />
  </span>
)

interface SearchBarProps {
  allowTextSearch?: boolean
  placeholder?: string
}

export const SearchBar = ({
  allowTextSearch = false,
  placeholder = 'Where are you going?',
}: SearchBarProps) => {
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const displayName = searchParams.get('displayName')
  const similarName = searchParams.get('similarName')
  const loc = searchParams.get('location')
  const lat = searchParams.get('latitude')
  const lng = searchParams.get('longitude')

  const [error, setError] = useState('')

  const [geocoder, setGeocoder] = useState(() => {
    return loc
      ? {
          center: [parseFloat(lat), parseFloat(lng)],
          placeName: loc,
        }
      : initialGeocoder
  })

  const defaultValue = geocoder?.placeName || similarName || displayName || ''

  const [{ textString, updatedGeocoder }, setOnResultData] = useState<{
    textString: string
    updatedGeocoder?: GeocoderType
  }>({
    textString: defaultValue,
    updatedGeocoder: geocoder,
  })

  const onResult: GeocoderWithTextSearchProps['onResult'] = (
    textString,
    updatedGeocoder
  ) =>
    setOnResultData({
      textString: textString || '',
      updatedGeocoder,
    })

  const onClick: GeocoderWithTextSearchProps['onResult'] = (
    _,
    updatedGeocoder
  ) => {
    if (
      autocompleteRef?.current?.value?.trim() === '' ||
      (!allowTextSearch && (!updatedGeocoder || !updatedGeocoder?.placeName))
    ) {
      return setError('Please select a destination to search')
    }
    setSearchParams(sp => {
      if (allowTextSearch && !!autocompleteRef?.current?.value) {
        sp.delete('latitude')
        sp.delete('longitude')
        sp.delete('location')
        sp.set('displayName', autocompleteRef?.current?.value)
      }

      if (updatedGeocoder?.placeName) {
        sp.set('displayName', updatedGeocoder.placeName)
        sp.set('location', updatedGeocoder.placeName)
        sp.set('latitude', updatedGeocoder.center?.[0]?.toString())
        sp.set('longitude', updatedGeocoder.center?.[1]?.toString())
      }
      return sp
    })
  }

  useEffect(() => {
    autocompleteRef.current.value = displayName
  }, [displayName])

  return (
    <div className='border-b-solid border-b-grey-400 border-b-1 m-auto flex flex-col gap-6 bg-white px-6 py-5 md:max-w-3xl md:flex-row md:items-center md:rounded-2xl md:border-b-0'>
      <div className='flex-grow-1'>
        <GeocoderWithTextSearch
          checkTypes
          fullWidth
          allowTextSearch={allowTextSearch}
          defaultValue={defaultValue}
          errorText={error}
          focusOnInput={false}
          geocoder={geocoder}
          isInvalid={false}
          placeholder={placeholder}
          ref={autocompleteRef}
          slotBefore={<PlaceOutlineIcon />}
          onClick={event =>
            event.currentTarget.querySelector('input')?.select()
          }
          onFocus={() => setError('')}
          onResult={onResult}
        />
      </div>
      <div>
        <Button
          fullWidth
          aria-label='Search for guides'
          size='large'
          startIcon='search'
          onClick={() => onClick(textString || '', updatedGeocoder)}
        >
          Search
        </Button>
      </div>
    </div>
  )
}
