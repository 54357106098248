import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl, faqPath } from 'src/constants'
import { FaqDetails } from '../common/components/FaqDetails/FaqDetails'
import { Title } from '../common/components/Title/Title'
import { infoStackingClasses } from '../infoStyles'
import { Reviews } from '../reviews/Reviews'

export const Faq = () => {
  return (
    <>
      <Helmet canonicalUrl={`${baseUrl}${faqPath}`} pageName='FAQ' />
      <PageLayoutContainer includeTopPadding>
        <Title
          alignText='center'
          caption='NEED HELP?'
          title='Frequently Asked Questions'
        />
        <div className={infoStackingClasses}>
          <FaqDetails hasSearch />
          <Reviews />
        </div>
      </PageLayoutContainer>
    </>
  )
}
