import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ParticipantStatus } from 'src/__generated__/graphql'
import { ButtonLink, Helmet } from 'src/common/components'
import { MobileBottomNav } from 'src/common/components/MobileBottomNav'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  DashboardAccountSettings,
  DashboardAchievements,
  DashboardBookings,
  DashboardCollections,
  DashboardCtas,
  DashboardFeed,
  DashboardGuideDrafts,
  DashboardHeader,
  DashboardLoading,
  DashboardNav,
  DashboardProfile,
  DashboardProfileAvatarModal,
  DashboardProfileBannerModal,
  DashboardProfileInfoModal,
  DashboardTrips,
  CompetitionDashboardHeader,
} from './common'
import { GetTheApp } from './common/GetTheAppCta/GetTheApp'
import { DashboardSearchParam, DashboardTab } from './dashboardConstants'
import { useGetDashboardQuery } from './useGetDashboardQuery'
import { FollowersModal } from '../profile/components/FollowersModal/FollowersModal'
import { useGetProfile } from '../profile/components/hooks/useGetProfile'
import type { FollowersModalType } from '../profile/types'
import './dashboardCustomStyles.css'

export const Dashboard = () => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const isTripsHideEnabled = useFlag('tripsHide')
  const isAppCtaEnabled = useFlag('appCtaEnabled')
  const { profile } = useGetProfile()
  const { isAnonymous } = useFirebaseUser()
  const { dashboardData, isLoading, refetch } = useGetDashboardQuery()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false)
  const [isModalAvatarOpen, setIsModalAvatarOpen] = useState(false)
  const [isModalBannerOpen, setIsModalBannerOpen] = useState(false)
  const [scrollId, setScrollId] = useState('')
  const [showModalFollowers, setShowModalFollowers] =
    useState<FollowersModalType>(null)
  const { currentUser } = dashboardData ?? {}
  const {
    experienceBookings,
    hotelBookings,
    collections,
    trips,
    userGuideDrafts,
    userProfile,
    id,
    firstName,
  } = currentUser ?? {}
  /** @todo see if we can get a 'bookingsCount' on the BE instead of adding the two types together on the FE */
  const bookingsCount =
    experienceBookings?.totalCount + hotelBookings?.totalCount
  const { competitionInfo, guideCount } = userProfile ?? {}
  const { status } = competitionInfo ?? {}
  const tab = searchParams.get(DashboardSearchParam.tab) ?? DashboardTab.feed

  const onModalAvatarOpen = () => {
    setIsModalAvatarOpen(true)
    setIsModalInfoOpen(false)
  }

  const onModalAvatarDismiss = () => {
    setIsModalAvatarOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalBannerOpen = () => {
    setIsModalInfoOpen(false)
    setIsModalBannerOpen(true)
  }

  const onModalBannerDismiss = () => {
    setIsModalBannerOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalInfoOpen = () => setIsModalInfoOpen(true)

  const onScrollIdChange = (updatedScrollId: string) =>
    setScrollId(updatedScrollId)

  const toggleFollowersModal = (viewType: FollowersModalType) =>
    setShowModalFollowers(viewType)

  useEffect(() => {
    if (isAnonymous) navigate('/', { replace: true })
  }, [isAnonymous])

  if (isLoading)
    return (
      <DashboardLoading isTripsHideEnabled={isTripsHideEnabled} tab={tab} />
    )

  const showCompetitionHeader =
    enableCompetition2024 &&
    (status == ParticipantStatus.Entered || status == ParticipantStatus.Ready)

  return (
    <>
      <Helmet pageName={`My Home${firstName ? ` | ${firstName}` : ''}`} />
      <section className='md:bg-warm-grey pb-8'>
        {showCompetitionHeader ? (
          <CompetitionDashboardHeader
            currentUser={currentUser}
            toggleFollowersModal={toggleFollowersModal}
            onModalInfoOpen={onModalInfoOpen}
            onScrollIdChange={onScrollIdChange}
          />
        ) : (
          <DashboardHeader
            currentUser={currentUser}
            toggleFollowersModal={toggleFollowersModal}
            onModalInfoOpen={onModalInfoOpen}
            onScrollIdChange={onScrollIdChange}
          />
        )}
        {/** @todo created a one-off page layout container for now */}
        <div className='max-w-452 mx-auto my-0 w-full md:px-6 lg:px-12'>
          <div className='lg:mt--43 relative flex flex-col lg:min-h-[calc(100vh-78px)] lg:flex-row lg:gap-4 lg:px-0'>
            <aside className='min-w-84 w-84 rounded-3 hidden space-y-10 lg:block'>
              <DashboardProfile
                dashboardData={dashboardData}
                toggleFollowersModal={toggleFollowersModal}
                onModalInfoOpen={onModalInfoOpen}
                onScrollIdChange={onScrollIdChange}
              />
              <DashboardAchievements dashboardData={dashboardData} />
              <DashboardAccountSettings userId={id} />
            </aside>
            <section className='dashboard-center-column shadow-3 rounded-3 flex min-w-0 grow flex-col px-3.5 py-6 md:bg-white'>
              <DashboardNav
                bookingsCount={bookingsCount}
                collectionsCount={collections?.totalCount ?? 0}
                guideDraftsCount={userGuideDrafts?.totalCount ?? 0}
                tripsCount={trips?.totalCount ?? 0}
              />
              <div className='md:bg-warm-grey md:border-1px md:border-grey-200 md:border-t-none rounded-b-3 grow border-none px-6 py-12 md:border-solid md:bg-opacity-50'>
                {tab === DashboardTab.feed && (
                  <DashboardFeed toggleFollowersModal={toggleFollowersModal} />
                )}
                {tab === DashboardTab.guides && <DashboardGuideDrafts />}
                {tab === DashboardTab.trips && !isTripsHideEnabled && (
                  <DashboardTrips />
                )}
                {tab === DashboardTab.bookings && <DashboardBookings />}
                {tab === DashboardTab.collections && <DashboardCollections />}
              </div>
            </section>
            <aside className='min-w-89 w-89 shadow-3 rounded-3 dashboard-right-column hidden h-fit space-y-6 bg-white'>
              {enableCompetition2024 &&
                (!currentUser?.userProfile?.competitionInfo ||
                  status === ParticipantStatus.NotEntered) && (
                  <div className='min-h-35 bg-top-center mx-3 mt-5 bg-[url(https://static.travelpass.com/assets/job-campaign-2024/enter-competition-banner-bg.webp)] bg-contain bg-no-repeat px-4 py-8 pt-72 text-center'>
                    <h3 className='type-title-3 text-2xl capitalize'>
                      Win A $20,000 Vacation!
                    </h3>
                    <p className='c-zinc-5 font-medium'>
                      To enter, just set up a free account, create travel
                      Guides, and get votes.
                    </p>
                    <div className='mx-auto mt-3 w-fit'>
                      <ButtonLink
                        className='capitalize'
                        to='/competition/apply'
                      >
                        Enter To Win
                      </ButtonLink>
                    </div>
                  </div>
                )}
              <DashboardCtas
                dashboardData={dashboardData}
                toggleFollowersModal={toggleFollowersModal}
              />
            </aside>
          </div>
        </div>
      </section>
      <MobileBottomNav />
      {isModalAvatarOpen && (
        <DashboardProfileAvatarModal
          profile={profile}
          onDismiss={onModalAvatarDismiss}
        />
      )}
      {isModalBannerOpen && (
        <DashboardProfileBannerModal
          profile={profile}
          onDismiss={onModalBannerDismiss}
        />
      )}
      {isModalInfoOpen && (
        <DashboardProfileInfoModal
          profile={profile}
          scrollId={scrollId}
          onDismiss={() => setIsModalInfoOpen(false)}
          onModalAvatarOpen={onModalAvatarOpen}
          onModalBannerOpen={onModalBannerOpen}
          onSubmitCompleted={() => refetch()}
        />
      )}
      {showModalFollowers && (
        <FollowersModal
          accountHandle={profile?.accountHandle}
          toggleModal={toggleFollowersModal}
          userId={profile?.userId}
          viewType={showModalFollowers}
          onClose={() => setShowModalFollowers(null)}
        />
      )}
      {isAppCtaEnabled && (
        <div className='fixed bottom-5 right-10'>
          <GetTheApp />
        </div>
      )}
    </>
  )
}
