import { useMutation } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { gql } from 'src/__generated__'
import type { UpdateGuideDraftMutationInGuideDraftMutationVariables } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GeocoderType } from 'src/constants/user'
import { constructAddressInput } from 'src/pages/trips/utils'
import { constructAddress } from 'src/utils'
import {
  guideDraftPublishedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQueryOld'
import type { GuideDraftEditFormFields } from './useUpdateGuideDraftForm'

const getUpdateGuideDraftVariables = ({
  description,
  geocoder,
  guideDraftId,
  name,
  tagIds,
}: GuideDraftEditFormFields & {
  guideDraftId: string
  geocoder: GeocoderType
  tagIds: string[]
}): UpdateGuideDraftMutationInGuideDraftMutationVariables => {
  const variables: UpdateGuideDraftMutationInGuideDraftMutationVariables = {
    input: {
      addresses: [],
      description: description ?? '',
      guideDraftId,
      guideTagIds: tagIds,
      name,
    },
  }

  if (!isEmpty(geocoder?.center))
    variables.input.addresses = [constructAddressInput(geocoder)]

  return variables
}

const updateGuideDraftMutation = gql(`
  mutation UpdateGuideDraftMutationInGuideDraftOld(
    $first: Int = 100,
    $input: UpdateGuideDraftInput!
  ) {
    updateGuideDraft(input: $input) {
      guideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        guide {
          id
          status
        }
        hasUnpublishedChanges
        images {
          id
          source
          type
          url
        }
        imageUrl
        name
        owner {
          id
        }
        ownerProfile {
          id
          accountHandle
          displayName
          guides {
            id
          }
          isUserOwner
          profileImageUrl
          profileImageSource
          userId
        }
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFieldsOld
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFieldsOld
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        status
        tags {
          id
          name
        }
        updatedAt
      }
    }
  }
`)

/** @todo hardcoding `hasUnpublishedChanges` for now until we have a better way to handle this */
const useUpdateGuideDraftMutationOld = (hasUnpublishedChanges = true) => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updateGuideDraftMutation, {
    onCompleted: data => {
      const {
        addresses,
        description,
        guide,
        id,
        name,
        owner,
        tags,
        updatedAt,
      } = data?.updateGuideDraft?.guideDraft ?? {}
      const address = constructAddress({
        addressFirstLine: addresses?.[0]?.addressLine1,
        city: addresses?.[0]?.city,
        state: addresses?.[0]?.state,
      })

      if (!id) return

      pushDataToDataLayer('guide_update', {
        guide_description: description,
        guide_draft_id: id,
        guide_id: guide?.id,
        guide_location: address,
        guide_name: name,
        travel_style: tags ?? [],
        trigger_url: window.location.href,
        updated_at: updatedAt,
        user_id: owner?.id,
      })
    },
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updateGuideDraft?.guideDraft,
        hasUnpublishedChanges
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}

export { getUpdateGuideDraftVariables, useUpdateGuideDraftMutationOld }
