import { Button, ModalNext } from '@travelpass/design-system'

interface PublishGuidesModalProps {
  onClose: () => void
  onClick: () => void
}

export const PublishGuidesModal = ({
  onClose,
  onClick,
}: PublishGuidesModalProps) => (
  <ModalNext
    actions={
      <div className='flex w-full justify-center'>
        <Button label='Publish A Guide' size='medium' onClick={onClick} />
      </div>
    }
    size='large'
    title='Publish A Guide'
    onClose={onClose}
  >
    <section className='space-y-5 px-5'>
      <div className='flex w-full items-center justify-center'>
        <img
          alt='Four young people smiling inside a car'
          className='md:max-w-80% of-hidden z-1 relative mx-auto block border-none max-md:h-full max-md:w-full'
          src='https://static.travelpass.com/assets/job-campaign-2024//apply-hero-image.webp'
        />
      </div>
      <p className='type-h6'>What counts as a high-quality Guide?</p>
      <p className='type-body-1'>
        To be eligible to win the $20,000 prize, you must create and share three
        high-quality Guides with the Travelpass community. High-quality Guides
        are detailed, engaging, and relatable. We want to hear your personal
        experiences, recommendations, and practical tips about a destination or
        topic!
      </p>
      <p className='type-h6'>
        To count towards contest eligibility, Guides must include:
      </p>
      <ul className='type-body-1 p0 list-none'>
        <li>- A description or overview for your travel Guide.</li>
        <li>
          - At least five recommendations (places to stay, things to do, places
          to eat, etc.), with notes. More are strongly encouraged!
        </li>
        <li>
          - Your own photos to personalize your Guide. While your Guides can use
          the photos suggested by Travelpass, at least one photo from your photo
          library must be uploaded to each Guide (more are encouraged!).
        </li>
      </ul>
    </section>
  </ModalNext>
)
