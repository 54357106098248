import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { BookingHotelDetailsQueryVariables } from 'src/__generated__/graphql'

export const HOTEL_DETAIL_BY_ID = gql(`
  query BookingHotelDetails($hotelId: String!) {
    lodging(id: $hotelId, idType: STANDARD) {
      address
      amenities
      chainData {
        brandName
      }
      checkin
      checkinInstructions
      checkinSpecialInstructions
      checkout
      city
      country
      fees {
        type
        text
      }
      id
      imageLinks
      name
      phoneNumbers
      policies {
        text
        title
      }
      postalCode
      rating
      state
    }
  }
`)

export const useBookingHotelDetailsQuery = ({
  hotelId,
}: BookingHotelDetailsQueryVariables) =>
  useQuery(HOTEL_DETAIL_BY_ID, {
    variables: { hotelId },
    skip: !hotelId,
  })
