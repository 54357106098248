import { useEffect, useRef, useState } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Dropdown,
  DropdownOption,
  IconButton,
  KeyCode,
} from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useSearchParams } from 'react-router-dom'
import type { SearchUserProfilesQueryQuery } from 'src/__generated__/graphql'
import { CompetitionSortEnum } from 'src/__generated__/graphql'
import { ProfileSearchBarOption } from '../profile/components/ProfileSearchBar/ProfileSearchBarOption'
import { useSearchProfiles } from '../profile/components/ProfileSearchBar/useSearchProfiles'
import { getProfilePageUrl } from '../profile/components/profileUtils'

const SORTS: { [key: string]: CompetitionSortEnum } = {
  Popular: CompetitionSortEnum.Popular,
  'Most Recent': CompetitionSortEnum.Recent,
}

export const SortFilterSearch = () => {
  const [, setSearchParams] = useSearchParams()
  const { searchProfiles } = useSearchProfiles()
  const autocompleteRef = useRef(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [profiles, setProfiles] = useState<
    SearchUserProfilesQueryQuery['searchUserProfiles']
  >([])

  /**@description copied from src\pages\profile\components\ProfileSearchBar\ProfileSearchBar.tsx */
  useEffect(() => {
    if (searchTerm.length > 2) debouncedSearchProfiles(searchTerm)
    else setProfiles([])

    return () => debouncedSearchProfiles.cancel()
  }, [searchTerm])

  const debouncedSearchProfiles = debounce((searchString: string) => {
    searchProfiles({
      variables: { competitionReady: true, searchString },
    }).then(({ data }) => setProfiles(data.searchUserProfiles))
  }, 300)
  /**@description end copied code */

  const onKeyDown = event => {
    if (event?.key === KeyCode.ENTER) {
      setSearchParams(
        sp => {
          searchTerm?.trim()
            ? sp.set('userProfile', searchTerm.trim())
            : sp.delete('userProfile')
          return sp
        },
        { replace: true }
      )
    }

    if (event?.key === KeyCode.ESC) {
      setSearchTerm('')
      /**@todo find a less hacky way to do this */
      autocompleteRef?.current?.blur()
      autocompleteRef?.current?.focus()
    }
  }

  const onOptionSelect = value => {
    const accountHandle = value?.accountHandle
    if (accountHandle) {
      const URL = getProfilePageUrl(accountHandle)
      window.open(URL, '_blank')
      return
    } else {
      setSearchParams(
        sp => {
          sp.set('userProfile', value)
          return sp
        },
        { replace: true }
      )
    }
  }

  const handleSearchButtonClick = () => {
    /**@description execute the given search term if it exists, otherwise focus the search bar */
    if (searchTerm.trim()) {
      setSearchParams(
        sp => {
          sp.set('userProfile', searchTerm.trim())
          return sp
        },
        { replace: true }
      )
    } else {
      setSearchParams(
        sp => {
          sp.delete('userProfile')
          return sp
        },
        { replace: true }
      )
      autocompleteRef?.current?.focus()
    }
  }

  return (
    <div className='mb-10 space-y-9'>
      <header className='space-y-4 text-center'>
        <h1 className='text-8 lg:text-9 c-forest'>Cast Your Vote!</h1>
        <p className='type-body-1'>
          Log into your free Travelpass account and vote for your favorite
          candidates every 24 hours.
        </p>
      </header>
      <div className='max-w-340 mx-auto flex flex-col gap-y-4 px-8 md:flex md:flex-row md:items-end md:justify-between'>
        <Dropdown
          label='Sort by'
          onChange={updatedSort => {
            setSearchParams(
              sp => {
                sp.set('sort', updatedSort)
                return sp
              },
              { replace: true }
            )
          }}
        >
          {Object.entries(SORTS).map(([sortValueLabel, sortValue]) => (
            <DropdownOption
              key={sortValue}
              label={sortValueLabel}
              value={sortValue}
            >
              {sortValueLabel}
            </DropdownOption>
          ))}
        </Dropdown>
        <section className='md:max-w-128 w-full max-md:order-first md:w-1/2'>
          <Autocomplete
            autoExpand
            aria-label='Search applicants'
            autoComplete='on'
            id='leaderboard-applicant-search'
            placeholder='Search applicants'
            ref={autocompleteRef}
            slotAfter={
              <IconButton
                color='valley'
                icon='search'
                onClick={handleSearchButtonClick}
              />
            }
            value={searchTerm}
            onChange={event => {
              setSearchTerm(event?.target?.value)
            }}
            onKeyDown={onKeyDown}
            onOptionSelect={onOptionSelect}
          >
            {!!searchTerm.trim() && (
              <>
                {searchTerm?.trim() && (
                  <AutocompleteOption
                    className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-2 bg-white px-4 py-1.5 text-left outline-none'
                    value={searchTerm}
                  >
                    Search for &quot;{searchTerm}&quot;
                  </AutocompleteOption>
                )}
                <div className='max-h-50vh overflow-y-auto'>
                  {profiles.map(profile => (
                    <ProfileSearchBarOption
                      key={profile.id}
                      profile={profile}
                    />
                  ))}
                </div>
              </>
            )}
          </Autocomplete>
        </section>
      </div>
    </div>
  )
}
