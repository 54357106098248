import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import type {
  GetGuideQueryInGuideDetailsQueryVariables,
  LatLong,
} from 'src/__generated__/graphql'
import { constructMapCenter } from 'src/common/components/Map'
import { useGetCurrentUserId } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  decodeGuideDetailsName,
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
} from 'src/utils'
import { GuideSearchParam } from './guideConstants'
import { getGuideMetaData } from './guideUtils'
import type { GuideData, GuideMetaData, GuideOwner } from './types'

const getGuideQuery = gql(`
  query GetGuideQueryInGuideDetails($id: ID!) {
    node(id: $id) {
      ... on Guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        collectedCount
        description
        eventCounts {
          numberEvents
        }
        galleryImages {
          id
          url
        }
        guideDraft {
          id
        }
        images {
          id
          source
          url
        }
        imageUrl # remove when images are added
        insertedAt
        isCollected
        isPublishingV2Ready
        name
        numberOfLikes
        ownerProfile {
          id
          accountHandle
          displayName
          isFollowed
          isUserOwner
          profileImageUrl
          userId
        }
        shareCount
        status
        tags {
          id
          name
        }
        updatedAt
        timeZone
        viewCount
      }
    }
  }
`)

type UseGetGuideQuery = {
  guideData: GuideData
  guideMetaData: GuideMetaData
  hasError: ApolloError
  isLoading: boolean
  location: LatLong
  owner: GuideOwner
}

export const useGetGuideQuery = (
  id: GetGuideQueryInGuideDetailsQueryVariables['id']
): UseGetGuideQuery => {
  const { data: currentUserData } = useGetCurrentUserId()
  const [searchParams] = useSearchParams()
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getGuideQuery, {
    onCompleted: data => {
      const { guideDraft, id, name } = (data?.node as GuideData) ?? {}
      const userId = currentUserData?.currentUser?.id

      if (!id || !userId) return

      pushDataToDataLayer('guide_view', {
        guide_draft_id: guideDraft?.id,
        guide_id: id,
        guide_name: name,
        trigger_url: window.location.href,
        user_id: currentUserData?.currentUser?.id,
      })
    },
    skip: !id,
    variables: {
      id,
    },
  })
  const guideData = data?.node as GuideData
  const { addresses, eventCounts, images, imageUrl, name, ownerProfile } =
    guideData ?? {}
  const [address] = addresses ?? []
  const { accountHandle, displayName, isUserOwner, profileImageUrl } =
    ownerProfile ?? {}
  const { lat, lng } = constructMapCenter(address?.lat, address?.long)
  const owner: GuideOwner = {
    image: getGuideOwnerImage(profileImageUrl),
    isUserOwner,
    name: getGuideOwnerName({
      accountHandle,
      displayName,
    }),
    url: getGuideOwnerUrl(accountHandle),
  }
  const guideNameFromSearchParam = searchParams.get(GuideSearchParam.name)
  const guideMetaData = getGuideMetaData({
    address: addresses?.[0],
    image: images?.[0]?.url ?? imageUrl,
    name: name ?? decodeGuideDetailsName(guideNameFromSearchParam),
    numberEvents: eventCounts?.numberEvents,
    ownerName: owner?.name,
  })

  return {
    guideData,
    guideMetaData,
    hasError,
    isLoading,
    location: {
      latitude: lat,
      longitude: lng,
    },
    owner,
  }
}
