import type { ContextType } from 'react'
import React, { useRef } from 'react'
import { Chip } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { useSearchParams } from 'react-router-dom'
import { Scroller } from 'src/common/components'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import { MILES_DEFAULT_VALUE } from '../constants'
import { getExploreFiltersFromURL } from '../utils/exploreUtils'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

export const ExploreFilterChips: React.FC = () => {
  const apiRef = useRef<ScrollVisibilityApiType>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const { accountHandles, tags, miles, locationTitle } =
    getExploreFiltersFromURL(searchParams)
  const { tags: initialTags } = useGetPredefinedGuideTagsQuery(
    true,
    tags.length == 0
  )

  const shouldHideSection =
    !accountHandles?.length && !tags?.length && !miles && !locationTitle

  if (shouldHideSection) return null

  return (
    <section>
      <div className='hidden flex-row flex-wrap gap-2 md:flex'>
        {locationTitle && (
          <Chip
            includeCloseIcon
            isSelected={true}
            label={locationTitle}
            onClick={() => {
              setSearchParams(sp => {
                sp.delete('locationTitle')
                sp.delete('lat')
                sp.delete('lng')
                return sp
              })
            }}
          />
        )}

        {miles > 0 && miles !== MILES_DEFAULT_VALUE && (
          <Chip
            includeCloseIcon
            isSelected={true}
            label={`${miles} Miles`}
            onClick={() => {
              setSearchParams(sp => {
                sp.delete('miles')
                return sp
              })
            }}
          />
        )}

        {accountHandles.map(traveler => (
          <Chip
            key={traveler}
            includeCloseIcon
            isSelected={true}
            label={`@${traveler}`}
            onClick={() => {
              const updatedAccountHandles = accountHandles
                .filter(accountHandle => accountHandle !== traveler)
                ?.join(',')

              if (updatedAccountHandles.length)
                setSearchParams(sp => {
                  sp.set('createdBy', updatedAccountHandles)
                  return sp
                })

              if (!updatedAccountHandles.length)
                setSearchParams(sp => {
                  sp.delete('createdBy')
                  return sp
                })
            }}
          />
        ))}

        {tags.map(tagId => {
          const selectedTag = initialTags?.find(({ id }) => id == tagId)
          return (
            <Chip
              key={tagId}
              includeCloseIcon
              icon={selectedTag?.icon}
              isSelected={true}
              label={selectedTag?.name}
              onClick={() => {
                const updatedTags = tags.filter(tag => tag !== tagId)?.join(',')

                if (updatedTags.length)
                  setSearchParams(sp => {
                    sp.set('tags', updatedTags)
                    return sp
                  })

                if (!updatedTags.length)
                  setSearchParams(sp => {
                    sp.delete('tags')
                    return sp
                  })
              }}
            />
          )
        })}
      </div>
      <div className='md:hidden'>
        <Scroller
          allowTouch={true}
          apiRef={apiRef}
          hideScrollBar={true}
          scrollContainerClassName='gap-1'
        >
          {[
            locationTitle && (
              <Chip
                includeCloseIcon
                isSelected={true}
                label={locationTitle}
                onClick={() => {
                  setSearchParams(sp => {
                    sp.delete('locationTitle')
                    sp.delete('lat')
                    sp.delete('lng')
                    return sp
                  })
                }}
              />
            ),
            miles > 0 && miles !== MILES_DEFAULT_VALUE && (
              <Chip
                includeCloseIcon
                isSelected={true}
                label={`${miles} Miles`}
                onClick={() => {
                  setSearchParams(sp => {
                    sp.delete('miles')
                    return sp
                  })
                }}
              />
            ),
            ...accountHandles.map(traveler => (
              <Chip
                key={traveler}
                includeCloseIcon
                isSelected={true}
                label={`@${traveler}`}
                onClick={() => {
                  const updatedAccountHandles = accountHandles
                    .filter(accountHandle => accountHandle !== traveler)
                    ?.join(',')

                  if (updatedAccountHandles.length)
                    setSearchParams(sp => {
                      sp.set('createdBy', updatedAccountHandles)
                      return sp
                    })

                  if (!updatedAccountHandles.length)
                    setSearchParams(sp => {
                      sp.delete('createdBy')
                      return sp
                    })
                }}
              />
            )),
            ...tags.map(tagId => {
              const selectedTag = initialTags?.find(({ id }) => id == tagId)
              return (
                <Chip
                  key={tagId}
                  includeCloseIcon
                  icon={selectedTag?.icon}
                  isSelected={true}
                  label={selectedTag?.name}
                  onClick={() => {
                    const updatedTags = tags
                      .filter(tag => tag !== tagId)
                      ?.join(',')

                    if (updatedTags.length)
                      setSearchParams(sp => {
                        sp.set('tags', updatedTags)
                        return sp
                      })

                    if (!updatedTags.length)
                      setSearchParams(sp => {
                        sp.delete('tags')
                        return sp
                      })
                  }}
                />
              )
            }),
          ]}
        </Scroller>
      </div>
    </section>
  )
}
