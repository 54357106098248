import { useState } from 'react'
import {
  Button,
  Chip,
  Divider,
  Icon,
  IconButton,
  Input,
} from '@travelpass/design-system'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import type { ExploreFilterUpdateFunction } from '../../exploreTypes'

interface TagsFilterProps {
  tags: string[]
  onSelection: ExploreFilterUpdateFunction
}

export const TagsFilter = ({ onSelection, tags }: TagsFilterProps) => {
  const [showAllTags, setShowAllTags] = useState(false)
  const { tags: initialTags } = useGetPredefinedGuideTagsQuery(true)
  const [searchTerm, setSearchTerm] = useState('')

  const onTagClick = (id: string) => {
    onSelection(
      'tags',
      tags.includes(id) ? tags.filter(tagId => tagId !== id) : [...tags, id]
    )
  }

  /** Filter tags based on searchTerm  */
  const filteredTags = initialTags?.filter(tag =>
    tag.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <>
      <h4 className='type-h5'>Tags</h4>
      <Input
        fullWidth
        placeholder='Search Tags'
        slotAfter={
          searchTerm?.length > 0 ? (
            <IconButton icon='clear' onClick={() => setSearchTerm('')} />
          ) : null
        }
        slotBefore={
          <span className='color-new-forest'>
            <Icon name='search' />
          </span>
        }
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target?.value)}
      />
      <div className='flex flex-wrap justify-center gap-x-3 gap-y-2'>
        {filteredTags?.length == 0 && (
          <p className='type-body-1-desktop'>
            No tag was found for &quot;{searchTerm}&quot;
          </p>
        )}
        {(showAllTags ? filteredTags : filteredTags?.slice(0, 9))?.map(
          ({ id, name, icon }) => (
            <Chip
              key={id}
              icon={icon}
              isSelected={tags?.includes(id)}
              label={name}
              onClick={() => onTagClick(id)}
            />
          )
        )}
      </div>
      {!showAllTags && searchTerm?.length == 0 && (
        <div className='flex justify-center'>
          <Button
            label='View More'
            size='small'
            variant='text'
            onClick={() => setShowAllTags(true)}
          />
        </div>
      )}
      <Divider />
    </>
  )
}
