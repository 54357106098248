import type { GetPredefinedGuideTagsQuery } from 'src/__generated__/graphql'

export const predefinedGuidesTagsMock: GetPredefinedGuideTagsQuery = {
  getPredefinedGuideTags: [
    {
      id: 'VXNlclByb2ZpbGU6dXNwcm9mXzAxSFQzNzVLMkhFUFA1UERRWEJKOFEzRkVW',
      name: 'Beach',
      __typename: 'Tag',
    },
    {
      id: 'VXN2clByb2Zpb1U6dXNwcm9mXzAxSFQzNzVLMkhFUFA1UERRWEJKOFEzRkVW',
      name: 'Sporting Events',
      __typename: 'Tag',
    },
    {
      id: 'VONlclByb2ZpbGU6dXNwcm9mXzAxSFQzNzVLMkhFUFA1UERRWEJKOFEzRkVW',
      name: 'Culinary',
      __typename: 'Tag',
    },
    {
      id: 'VXNlclByb2ZpbGOAdXNwcm9mXzAxSFQzNzVLMkhFUFA1UERRWEJKOFEzRkVW',
      name: 'Nightlife',
      __typename: 'Tag',
    },
    {
      id: 'VXNlclByb2ZpAGU6LXNwcm9mXzAxSFQzNzVLMkhFUFA1UERRWEJKOFEzRkVW',
      name: 'Hiking',
      __typename: 'Tag',
    },
  ],
}
