import { useState } from 'react'
import { Button, Input, ModalNext } from '@travelpass/design-system'
import { useForm } from 'react-hook-form'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { SignInModal } from 'src/pages/signin'
import { AddToCollectionsList } from './AddToCollectionsList'
import { dispatchToggleAddToCollectionsModal } from './dispatchToggleAddToCollectionsModal'
import { useGetAllCollections } from './hooks/useGetAllCollections'
import { useCreateCollection } from '../CollectionFormModal/useCreateCollection'

interface AddToCollectionsModalProps {
  onClose(): void
  /** ID and Name from a hotel, experience, guide or event. */
  item: AddToItem
  onCompleted?: (isCollected: boolean) => void
}

interface CollectionFormFields {
  name: string
}

export const AddToCollectionsModal = ({
  onClose,
  item,
}: AddToCollectionsModalProps) => {
  const { collections, loading, error, refetch } = useGetAllCollections(
    item?.id,
    item?.type,
    100
  )
  const [showNewCollectionForm, setShowNewCollectionForm] = useState(false)

  const [createCollection] = useCreateCollection()
  const defaultValues: CollectionFormFields = { name: '' }
  const methods = useForm({ defaultValues })
  const errorMessage = methods?.formState?.errors?.name?.message

  const onSubmit = async ({ name }: CollectionFormFields) => {
    try {
      await createCollection({
        variables: {
          input: { name },
        },
      })
      refetch()
      setShowNewCollectionForm(false)
      methods?.setValue('name', '')
      pushDataToDataLayer('create_collection', {
        collection_name: name,
        event: 'create_collection',
      })
      methods.setValue('name', '')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ModalNext
      actions={
        <div
          className={`mt-8 flex w-full flex-row-reverse gap-3 ${showNewCollectionForm ? 'md:[&_button]:w-38 [&_button]:w-25 justify-center md:gap-8' : '[&_button]:max-w-54 [&_button]:w-54 md:[&_button]:max-w-unset md:[&_button]:w-unset items-center justify-between max-md:flex-col-reverse'}`}
        >
          <Button
            form={showNewCollectionForm ? 'create-collection-form' : null}
            label={showNewCollectionForm ? 'Save' : 'Done'}
            type={showNewCollectionForm ? 'submit' : 'button'}
            onClick={() => {
              !showNewCollectionForm && onClose()
            }}
          />
          <Button
            label={showNewCollectionForm ? 'Cancel' : 'Create new Collection'}
            variant='outlined'
            onClick={() => setShowNewCollectionForm(!showNewCollectionForm)}
          />
        </div>
      }
      className='max-md:[&>div]:w-full'
      scroll={true}
      size='small'
      onClose={onClose}
    >
      <div className='px-2'>
        <h2 className='type-h4 mb-2'>Add to a Collection</h2>
        {!showNewCollectionForm && (
          <p className='type-body-2 c-gray-900 mb-6'>
            Select one or multiple Collections from the list to add to.
          </p>
        )}
        {showNewCollectionForm && (
          <form
            className='my-5 flex'
            id='create-collection-form'
            onSubmit={methods?.handleSubmit(onSubmit)}
          >
            <Input
              autoFocus
              fullWidth
              aria-label='Name collection'
              errorText={errorMessage}
              placeholder='Name collection'
              {...methods?.register('name', {
                maxLength: {
                  value: 50,
                  message: 'Collection name must be 50 or fewer characters',
                },
                required: 'Collection name is required',
                onBlur: () => methods.clearErrors(),
                validate: value => {
                  return value.length <= 50
                },
              })}
            />
          </form>
        )}
        {error ? (
          <p className='type-body-1 mt-10'>
            Error while loading Collections...
          </p>
        ) : (
          <AddToCollectionsList
            collections={collections}
            collectionsLoading={loading}
            item={item}
            refetch={refetch}
          />
        )}
      </div>
    </ModalNext>
  )
}
