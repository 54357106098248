import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftPublishedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQueryOld'

const archivePublishedEventMutation = gql(`
  mutation ArchivePublishedEventMutationInGuideDraftOld($archivePublishedEventInput: ArchivePublishedEventInput!, $first: Int = 100) {
    archivePublishedEvent(archivePublishedEventInput: $archivePublishedEventInput) {
      guideDraft {
        id
        guide {
          id
          status
        }
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFieldsOld
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        hasUnpublishedChanges
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFieldsOld
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

export const useArchiveGuideDraftEventMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(archivePublishedEventMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.archivePublishedEvent?.guideDraft
      )
      if (!updatedCache) return
      cache.modify(updatedCache)
    },
  })
}
