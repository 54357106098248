import { useEffect, useState } from 'react'
import { Icon, Illustration, KeyCode } from '@travelpass/design-system'
import type { AvailableIllustrations } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { Card } from 'src/common/components/Card'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  accountSettingsPath,
  bookingsPath,
  notificationsPath,
  tripsPath,
} from 'src/constants'
import { QuizBanner } from './components/QuizBanner'
import { SearchPreferencesQuiz } from '../account-settings/components/search-preferences-quiz/SearchPreferencesQuiz'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from '../profile/components/hooks/useGetCurrentUserProfileAccountHandleAndAvatar'
import { getProfilePageUrl } from '../profile/components/profileUtils'

type CardInfo = {
  title:
    | 'Bookings'
    | 'Notifications'
    | 'Account Settings'
    | 'Trips'
    | 'Public Profile'
  text: string
  illustration: AvailableIllustrations
  route?: string
}

const cards: CardInfo[] = [
  {
    title: 'Bookings',
    text: 'Find all your booked experiences and hotels here',
    illustration: 'bookings',
    route: bookingsPath,
  },
  {
    title: 'Trips',
    text: 'Find all your created upcoming trips for here',
    illustration: 'scooter',
    route: tripsPath,
  },
  {
    title: 'Notifications',
    text: 'Control how you would like to be contacted',
    illustration: 'notifications',
    route: notificationsPath,
  },
  {
    title: 'Public Profile',
    text: 'Share your travel guide, posts, and social links with friends and family!',
    illustration: 'publicProfile',
  },
  {
    title: 'Account Settings',
    text: 'Update your username and manage your account',
    illustration: 'accountSettings',
    route: accountSettingsPath,
  },
]

export const MyAccount = () => {
  const isTripsHideEnabled = useFlag('tripsHide')
  const isSearchPreferencesQuizEnabled = useFlag('searchPreferencesQuizEnabled')
  const [showQuiz, setShowQuiz] = useState(false)
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const accountHandle = data?.currentUser?.userProfile?.accountHandle
  const displayName = data?.currentUser?.userProfile?.displayName
  const navigate = useNavigate()
  const { isAnonymous } = useFirebaseUser()

  useEffect(() => {
    if (isAnonymous) navigate('/')
  }, [isAnonymous])

  const redirect = (title: CardInfo['title'], route: string) => {
    navigate(
      title == 'Public Profile' ? getProfilePageUrl(accountHandle) : route
    )
  }

  const maybeFilteredCards = cards.filter(card => {
    if (card.title === 'Trips') return !isTripsHideEnabled
    return true
  })

  return (
    <PageLayoutContainer includeTopPadding size='large'>
      <Helmet
        pageName={'My Account' + (displayName ? ` | ${displayName}` : '')}
      />
      <h1 className='type-h2-desktop mb-10'>My Account</h1>
      <div className='mb-10 flex flex-col items-center justify-center py-0 lg:flex-row'>
        {isSearchPreferencesQuizEnabled && (
          <QuizBanner showQuiz={() => setShowQuiz(true)} />
        )}
        <div className='justify-initial lg:w-80% flex w-full flex-wrap gap-4 px-6 md:gap-8 lg:px-0'>
          {maybeFilteredCards.map(({ title, text, illustration, route }) => (
            <Card
              key={title}
              className='shadow-1 border-rd-4 border-1 border-grey300 flex w-full cursor-pointer flex-col gap-6 border-solid bg-white px-3 md:w-[47%] lg:w-[48%] lg:p-6 xl:w-1/4'
              role='button'
              tabIndex='0'
              onClick={() => redirect(title, route)}
              onKeyDown={({ key }) =>
                key === KeyCode.ENTER && redirect(title, route)
              }
            >
              <div className='my-5 flex gap-x-5'>
                <div className='w-45% md:w-20% flex flex-col justify-center'>
                  <div className='rounded-1 bg-#F0F6F7 w-full p-1 py-2 md:py-1'>
                    <Illustration name={illustration} />
                  </div>
                </div>
                <div className='w-80% flex flex-col justify-center gap-2'>
                  <h5 className='type-h5-desktop'>{title}</h5>
                  <p className='type-body-1-desktop c-grey700'>{text}</p>
                </div>
                <div className='flex flex-col justify-center'>
                  <Icon name='arrowForwardIos' />
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
      {isSearchPreferencesQuizEnabled && showQuiz && (
        <SearchPreferencesQuiz onClose={() => setShowQuiz(false)} />
      )}
    </PageLayoutContainer>
  )
}
