import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { TravelpassLogo } from 'src/common/components'
import { dashboardPath } from 'src/constants'
import {
  GuideMetricLike,
  GuideMetricSave,
  GuideMetricShare,
} from '../../common'
import type { GuideData } from '../types'

interface GuideStickyTopNavProps {
  guideData: GuideData
  isVisible: boolean
  selectedId: string
}

export const GuideStickyTopNav = ({
  guideData,
  isVisible,
  selectedId,
}: GuideStickyTopNavProps) => {
  const { name } = guideData ?? {}
  return (
    <div
      className={classNames(
        'bg-mint b-0 b-solid b-b-1 b-grey-400 h-17 z-100 p-x-12 p-y-4.5 transition-transform-500 lg:max-w-194.75 fixed left-0 top-0 hidden flex-row items-center justify-between gap-2 ease-in-out lg:flex',
        {
          '-translate-y-full transform': !isVisible,
          'translate-y-0 transform': isVisible,
          'w-[calc(100%-521px)]': selectedId,
          'w-full': !selectedId,
        }
      )}
    >
      <div className='flex grow flex-row items-center gap-3.5'>
        <Link to={dashboardPath}>
          <TravelpassLogo
            className='h-7 w-7 lg:h-8 lg:w-8'
            variant='symbol-valley'
          />
        </Link>
        <span className='type-h4-desktop'>{name}</span>
      </div>
      <div className='flex flex-row items-center gap-4'>
        <GuideMetricSave guideData={guideData} isIconButton={true} />
        <GuideMetricShare guideData={guideData} isIconButton={true} />
        <GuideMetricLike guideData={guideData} isIconButton={true} />
      </div>
    </div>
  )
}
