import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftPublishedEventFragment,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const updatePublishedSortOrderMutation = gql(`
  mutation UpdatePublishedEventSortOrderMutationInGuideDraft($first: Int = 100, $input: UpdatePublishedEventSortOrderInput!) {  
    updatePublishedEventSortOrder(input: $input) {
      publishedEvent {
        id
        guideDraft {
          id
          guide {
            id
            status
          }
          hasUnpublishedChanges
          paginatedEventCategories(first: 100) {
            edges {
              node {
                id
                description
                name
                publishedEvents(first: $first) {
                  edges {
                    node {
                      ...GuideDraftPublishedEventFields
                    }
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                  }
                  totalCount
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
            totalCount
          }
          uncategorizedPublishedEvents(first: $first) {
            edges {
              node {
                ...GuideDraftPublishedEventFields
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
            totalCount
          }
        }
      }
    }
  }
`)

export const useUpdateGuideDraftEventSortOrderMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updatePublishedSortOrderMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updatePublishedEventSortOrder?.publishedEvent?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
