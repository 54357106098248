import { GuideItemType } from 'src/__generated__/graphql'
import {
  DashboardFeedCardCreatedGuide,
  DashboardFeedCardRepublishedGuideCreatedEvent,
  DashboardFeedCardRepublishedGuideGrouped,
  DashboardFeedCardRepublishedGuidePhotos,
} from './DashboardFeedCard'
import type { DashboardFeedItem } from '../../types'

interface DashboardFeedWrapperProps {
  dashboardFeedItem: DashboardFeedItem
  isDashboardV3FeedNewEventEnabled: boolean
  isDashboardV3FeedNewPhotosEnabled: boolean
  isDashboardV3FeedRepublishGuideGroupedEnabled: boolean
}

/** @todo flags will be removed as soon as we release the new feed */
export const DashboardFeedWrapper = ({
  dashboardFeedItem,
  isDashboardV3FeedNewEventEnabled,
  isDashboardV3FeedNewPhotosEnabled,
  isDashboardV3FeedRepublishGuideGroupedEnabled,
}: DashboardFeedWrapperProps) => {
  const { groupedUpdate, guide, type } = dashboardFeedItem ?? {}
  const { newEvents, newImages } = guide?.latestUpdates ?? {}

  if (isDashboardV3FeedRepublishGuideGroupedEnabled && groupedUpdate)
    return (
      <DashboardFeedCardRepublishedGuideGrouped
        dashboardFeedItem={dashboardFeedItem}
      />
    )

  if (
    isDashboardV3FeedNewPhotosEnabled &&
    type === GuideItemType.Updates &&
    !!newImages?.images?.length
  )
    return (
      <DashboardFeedCardRepublishedGuidePhotos
        dashboardFeedItem={dashboardFeedItem}
      />
    )

  if (
    isDashboardV3FeedNewEventEnabled &&
    type === GuideItemType.Updates &&
    newEvents?.events?.length === 1
  )
    return (
      <DashboardFeedCardRepublishedGuideCreatedEvent
        dashboardFeedItem={dashboardFeedItem}
      />
    )

  return <DashboardFeedCardCreatedGuide dashboardFeedItem={dashboardFeedItem} />
}
