import { Divider, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { getGuideAddress } from 'src/utils'
import { GuideHeaderActions } from './GuideHeaderActions'
import type { GuideHeaderData } from '../../types'

interface GuideHeaderSummaryProps {
  guideHeaderData: Pick<
    GuideHeaderData,
    | 'addresses'
    | 'collectedCount'
    | 'eventCounts'
    | 'id'
    | 'isCollected'
    | 'name'
    | 'numberOfLikes'
    | 'ownerProfile'
    | 'shareCount'
    | 'viewCount'
  >
  isEdit: boolean
}

export const GuideHeaderSummary = ({
  guideHeaderData,
  isEdit,
}: GuideHeaderSummaryProps) => {
  const { addresses, eventCounts, viewCount } = guideHeaderData ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const guideAddress = getGuideAddress({
    city,
    country,
    state,
  })

  if (isEmpty(guideAddress) && !eventCounts?.numberEvents && !viewCount) return

  return (
    <div>
      <Divider />
      <div className='md:p-l-4 p-y-1 flex flex-row flex-wrap gap-x-3 gap-y-1 md:items-center'>
        <div className="md:after:[&>div]:w-1px flex grow flex-row items-center gap-3 md:after:[&>div:last-child]:hidden md:after:[&>div]:h-3 md:after:[&>div]:bg-black md:after:[&>div]:content-['']">
          {guideAddress && (
            <div className='[&>div>i]:color-orange-accent flex flex-row items-center gap-3'>
              <Icon name='placeOutline' size='small' />
              <span className='type-body-2 c-black'>{guideAddress}</span>
            </div>
          )}
          {!!eventCounts?.numberEvents && (
            <div className='hidden flex-row items-center gap-3 md:flex'>
              <span className='type-body-2 c-black'>{`${eventCounts?.numberEvents} Recommendations`}</span>
            </div>
          )}
          {!!viewCount && (
            <div className='hidden flex-row items-center gap-3 md:flex'>
              <span className='type-body-2 c-black'>{`${viewCount} Views`}</span>
            </div>
          )}
        </div>
        <GuideHeaderActions
          guideHeaderData={{
            ...guideHeaderData,
          }}
          isEdit={isEdit}
        />
      </div>
      <Divider />
    </div>
  )
}
