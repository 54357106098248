import { useEffect, useRef, useState } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Divider,
  Icon,
  IconButton,
  KeyCode,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getGeocode, type Suggestion } from 'use-places-autocomplete'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import { explorePath } from 'src/constants'
import { BOUNDING_BOX_PARAMS } from '../bounding-box/constants/boundingBoxParams'
import { extractViewport } from '../bounding-box/utils/extractViewport'
import { COUNTRY_STATE_PARAMS } from '../country-state/constants/COUNTRY_STATE_PARAMS'
import type { ExploreLocation } from '../exploreTypes'
import { KEYWORD_SEARCH_PHRASE } from '../keyword-search/constants'

interface ExploreSearchBarProps {
  onSelection?: (selection: ExploreLocation) => void
  hideSlotBefore?: boolean
  hideSearchButton?: boolean
  clearOnSelection?: boolean
  placeholder?: string
  className?: string
}

export const ExploreSearchBar = ({
  hideSearchButton = false,
  placeholder = 'Search',
  hideSlotBefore = false,
  clearOnSelection = false,
  onSelection,
  className,
}: ExploreSearchBarProps) => {
  //Hooks
  const navigate = useNavigate()
  const [showSearchTerm, setShowSearchTerm] = useState(false)
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const autocompleteParentRef = useRef<HTMLDivElement>(null)
  const { isMobileScreen } = useScreenQuery()
  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch({
      requestOptions: { componentRestrictions: { country: [] } },
      cacheKey: CACHE_KEYS.withoutRestriction,
    })
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  //Others
  const title = searchParams.get('title')
  const options = suggestions?.data || []
  const showOptions = Boolean(options?.length)
  const searchTerm = value?.trim()

  useEffect(() => setValue(title), [title])

  const handleClearAndFocus = ({
    newValue,
    focusesInput,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput: boolean
    shouldFetchData?: boolean
  }) => {
    newValue !== null && setValue(newValue, shouldFetchData)
    clearSuggestions()
    setShowSearchTerm(false)
    // hacky way to force the functionality to work with ESC key
    autocompleteRef?.current?.blur()
    focusesInput && autocompleteRef?.current?.focus()
  }

  const handleOptionSelection = async (selection: Suggestion | string) => {
    if (typeof selection === 'string') {
      if (selection.trim().length) handleKeywordSearch(selection)
    } else if (await checkCountryStateSearch(selection))
      handleCountryStateSearch(selection)
    else handleLocationSearch(selection)

    if (clearOnSelection) onClear()
  }

  const handleCountryStateSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({ placeId: selection?.place_id })
    const country = result.address_components?.find(
      addressComponent => addressComponent.types[0] === 'country'
    )?.long_name

    const state = result.address_components?.find(
      addressComponent =>
        addressComponent.types[0] === 'administrative_area_level_1'
    )?.long_name

    setSearchParams(sp => {
      sp.set('title', result?.formatted_address)
      sp.set(
        'location',
        result?.address_components?.[0]?.long_name ||
          result?.address_components?.[0]?.short_name ||
          result?.formatted_address
      )
      country ? sp.set('country', country) : sp.delete('country')
      state ? sp.set('state', state) : sp.delete('state')

      sp.delete('miles')
      sp.delete('lat')
      sp.delete('lng')
      sp.delete('locationTitle')
      sp.delete(KEYWORD_SEARCH_PHRASE)
      sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
      sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
      sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
      sp.delete(BOUNDING_BOX_PARAMS.sw.lng)

      return sp
    })

    if (!pathname.includes('/country-state'))
      navigate({
        pathname: `${explorePath}/country-state`,
        search: searchParams.toString(),
      })

    handleClearAndFocus({
      newValue: result.formatted_address,
      focusesInput: !isMobileScreen,
    })
  }

  const checkCountryStateSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({ placeId: selection?.place_id })

    const country = result.address_components?.[0]?.types.find(
      type => type === 'country'
    )

    const state = result.address_components?.[0]?.types.find(
      type => type === 'administrative_area_level_1'
    )

    return !!country || !!state
  }

  const handleKeywordSearch = (selection: string) => {
    // do not set a new value for the input since we want the user's search term to remain
    handleClearAndFocus({ newValue: null, focusesInput: !isMobileScreen })
    if (onSelection) onSelection(selection)
    else {
      setSearchParams(sp => {
        sp.set('title', selection)
        sp.set(KEYWORD_SEARCH_PHRASE, selection)

        sp.delete(COUNTRY_STATE_PARAMS.country)
        sp.delete(COUNTRY_STATE_PARAMS.state)
        sp.delete('location')
        sp.delete(BOUNDING_BOX_PARAMS.ne.lat)
        sp.delete(BOUNDING_BOX_PARAMS.ne.lng)
        sp.delete(BOUNDING_BOX_PARAMS.sw.lat)
        sp.delete(BOUNDING_BOX_PARAMS.sw.lng)

        return sp
      })
      if (!pathname.includes('/keyword'))
        navigate({
          pathname: `${explorePath}/keyword`,
          search: searchParams.toString(),
        })
    }
  }

  const handleLocationSearch = async (selection: Suggestion) => {
    const [result] = await getGeocode({ placeId: selection?.place_id })
    const { address_components: addressComponents } = result
    const viewport = extractViewport(result?.geometry?.viewport)

    const usesAlternativeCityDesignation = !addressComponents?.some(
      ({ types }) => {
        // postal_town is used in the UK
        return types.includes('locality') || types.includes('postal_town')
      }
    )

    const spLocation = addressComponents?.find(({ types }) => {
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      if (usesAlternativeCityDesignation) {
        /* administrative_area_level_2 is used to designate cities in Brazil.
           Other specific edge cases may need to be added in the future. */
        return types.includes('administrative_area_level_2')
      } else {
        return types.includes('locality') || types.includes('postal_town')
      }
    })?.long_name

    if (onSelection) {
      onSelection({
        ...BOUNDING_BOX_PARAMS,
        title: result?.formatted_address,
        location:
          spLocation ||
          result?.address_components?.[0]?.short_name ||
          result?.formatted_address,
        lat: result?.geometry?.location?.lat(),
        lng: result?.geometry?.location?.lng(),
      })
      return
    }

    setSearchParams(sp => {
      sp.set(BOUNDING_BOX_PARAMS.ne.lat, String(viewport.ne.lat))
      sp.set(BOUNDING_BOX_PARAMS.ne.lng, String(viewport.ne.lng))
      sp.set(BOUNDING_BOX_PARAMS.sw.lat, String(viewport.sw.lat))
      sp.set(BOUNDING_BOX_PARAMS.sw.lng, String(viewport.sw.lng))
      sp.set('title', result?.formatted_address)
      sp.set(
        'location',
        spLocation ||
          result?.address_components?.[0]?.short_name ||
          result?.formatted_address
      )

      sp.delete('miles')
      sp.delete('lat')
      sp.delete('lng')
      sp.delete('locationTitle')
      sp.delete(KEYWORD_SEARCH_PHRASE)
      sp.delete(COUNTRY_STATE_PARAMS.country)
      sp.delete(COUNTRY_STATE_PARAMS.state)

      return sp
    })

    if (!pathname.includes('/bounding-box'))
      navigate({
        pathname: `${explorePath}/bounding-box`,
        search: searchParams.toString(),
      })

    handleClearAndFocus({
      newValue: result.formatted_address,
      focusesInput: !isMobileScreen,
    })
  }

  const handleSearchTermEnter = e => {
    if (e.key === KeyCode.ENTER) {
      if (showSearchTerm) handleOptionSelection(searchTerm)
      else if (showOptions) handleOptionSelection(options[0])
    }
    if (e.key === KeyCode.ESC) onClear()
  }

  const handleSearchButtonClick = () => {
    if (showSearchTerm) handleOptionSelection(searchTerm)
    else if (showOptions) handleOptionSelection(options[0])
    else document.getElementById('guides-autocomplete-input')?.focus()
  }

  const ClearButton = () => {
    if (!value) return null

    return (
      <span className='mr--3'>
        <IconButton icon='clear' onClick={onClear} />
      </span>
    )
  }

  const onClear = () =>
    handleClearAndFocus({ newValue: '', focusesInput: true })

  const SlotBefore = () => {
    if (hideSlotBefore) return null
    return (
      <span className='color-new-forest flex'>
        <Icon name='search' />
      </span>
    )
  }

  return (
    <div
      className={classNames(
        'mx-auto flex w-full items-center justify-center [&>div:first-of-type]:w-full',
        className
      )}
      ref={autocompleteParentRef}
    >
      <Autocomplete
        autoExpand
        fullWidth
        className='rounded-r-none! w-full'
        id='guides-autocomplete-input'
        isDisabled={!ready}
        placeholder={placeholder}
        ref={autocompleteRef}
        slotAfter={<ClearButton />}
        slotBefore={<SlotBefore />}
        value={value}
        onChange={e => {
          setShowSearchTerm(true)
          setValue(e.target.value)
        }}
        onFocus={
          isMobileScreen
            ? () => {
                autocompleteParentRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            : null
        }
        onKeyDown={handleSearchTermEnter}
        onOptionSelect={handleOptionSelection}
      >
        {showOptions &&
          options.map(option => (
            <GeocoderOption
              key={option.place_id}
              option={option}
              searchTerm={searchTerm}
            />
          ))}

        {showOptions && showSearchTerm && value?.trim() && <Divider />}

        {showSearchTerm && value?.trim() && (
          <AutocompleteOption
            className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey mt- mt-2 flex w-full cursor-pointer flex-row items-center gap-2 px-4 text-left outline-none'
            value={searchTerm}
          >
            <span className='c-valleyDark mt1'>
              <Icon name='search' />
            </span>
            Search for Keyword
            <span className='font500'>&quot;{searchTerm}&quot;</span>
          </AutocompleteOption>
        )}
      </Autocomplete>
      {!hideSearchButton && (
        <div className='-ml-2'>
          <IconButton
            color='valley'
            icon='search'
            size='large'
            onClick={handleSearchButtonClick}
          />
        </div>
      )}
    </div>
  )
}
