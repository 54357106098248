import { Button, EmptyState } from '@travelpass/design-system'
import { dispatch } from 'src/common/hooks/useListen'

interface SignInAndSignUpButtonsProps {
  helperText?: string
  onLogIn?: () => void
  onSignUp?: () => void
}

export const SignInAndSignUpButtons = ({
  helperText,
  onLogIn,
  onSignUp,
}: SignInAndSignUpButtonsProps) => {
  const onClick = (signUp = true) =>
    dispatch('openSignin', {
      isCreateAccount: signUp,
    })

  return (
    <div className={searchTripSignInContainercss}>
      <div className='text-h4'>You aren&apos;t logged in</div>
      <div className='w-200px'>
        <EmptyState name='locked' />
      </div>
      {helperText?.length > 0 && <p>{helperText}</p>}
      <div className={searchTripSigininLinkCss}>
        <Button
          label='Log In'
          variant='outlined'
          onClick={() => (onLogIn ? onLogIn() : onClick(false))}
        />
        <Button
          label='Sign Up'
          onClick={() => (onSignUp ? onSignUp() : onClick(true))}
        />
      </div>
    </div>
  )
}

const searchTripSignInContainercss =
  'flex flex-col gap-2 items-center text-center sm:w-full lg:w-50%'

const searchTripSigininLinkCss =
  'flex gap-2 w-full items-center justify-center capitalize'
