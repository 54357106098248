import {
  Carousel,
  Illustration,
  useScreenQuery,
} from '@travelpass/design-system'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { useGetPaginatedCuratedGuides } from '../hooks/usePaginatedCuratedGuides'

const NATIONAL_PARK_KEY = 'national_park'

export const Park = () => {
  const { data, loading, error } = useGetPaginatedCuratedGuides({
    variables: {
      category: NATIONAL_PARK_KEY,
      first: 3,
    },
  })
  const guides = data?.paginatedCuratedGuides?.edges || []

  const onClickOwnerProfile = useOnClickOwnerProfile()
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const showResults = !loading && !!guides.length

  if (!showResults) return null

  return (
    <div>
      <section className='max-w-300 mx-auto space-y-8 md:space-y-12'>
        <header className='space-y-3 text-balance text-center max-md:px-4 md:space-y-4'>
          <span className='type-overline text-grey-700 uppercase tracking-widest'>
            Adventure Awaits
          </span>
          <h2 className='type-h1 capitalize'>
            <span className='hidden md:block'>
              Uncover your favorite national park
            </span>
            <span className='block md:hidden'>
              Uncover top rated <br /> national parks
            </span>
          </h2>
          <div className='bg-valley w-1/8 mx-auto h-1.5 rounded-full max-md:hidden' />
        </header>
        {error && (
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        )}
        {loading && (
          <ul
            aria-busy={loading}
            className='ps-none mt-18! grid list-none gap-4 max-md:mx-auto max-md:w-fit md:grid-cols-2 lg:grid-cols-3' // `max-md:mx-auto max-md:w-fit` are necessay due to loading card behavior of not stretching
          >
            <GuideRecommendedCardLoading />
            <GuideRecommendedCardLoading className='max-md:hidden' />
            <GuideRecommendedCardLoading className='max-lg:hidden' />
            <span className='sr-only'>Loading...</span>
          </ul>
        )}
        {isMobileOrTablet && showResults && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {guides?.map(({ node: guide }) => (
              <div
                key={guide.id}
                className='mx-auto mb-8 max-w-[calc(100%-24px)] text-left'
              >
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && showResults && (
          <ul className='ps-none mt-18 grid list-none gap-4 overflow-y-auto text-left md:grid-cols-3'>
            {guides?.map(({ node: guide }) => (
              <li key={guide.id}>
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  )
}
