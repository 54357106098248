import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { useUpdateGuideDraftQueryCache } from './useGetGuideDraftQueryOld'

const deletePublishedEventCategoryMutation = gql(`
  mutation DeletePublishedEventCategoryMutationInGuideDraftOld($deleteCategoryInput: DeletePublishedEventCategoryInput!) {
    deletePublishedEventCategory(deleteCategoryInput: $deleteCategoryInput) {
      guideDraft {
        id
        guide {
          id
          status
        }
        hasUnpublishedChanges
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
`)

export const useDeleteGuideDraftEventCategoryMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(deletePublishedEventCategoryMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.deletePublishedEventCategory?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
