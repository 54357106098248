import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const incrementGuideEventShareCountMutation = gql(`
  mutation IncrementGuideEventShareCount($publishedEventId: ID!) {
    incrementPublishedEventShareCount(publishedEventId: $publishedEventId) {
      id
    }
  }
`)

export const useIncrementGuideEventShareCountMutation = () =>
  useMutation(incrementGuideEventShareCountMutation)
