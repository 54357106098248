import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import { GuideDraftStatus, type GuideDraft } from 'src/__generated__/graphql'
import { useGetCurrentUserId } from 'src/common/hooks'
import { getGuideDraftsForUserQuery } from 'src/pages/dashboard'
import { useArchiveGuideDraftMutation } from '../useArchiveGuideDraftMutation'

interface GuideDraftDeleteModalProps {
  guideDraftData: Pick<GuideDraft, 'id' | 'name'>
  onDelete?: VoidFunction
  onDismiss: VoidFunction
}

export const GuideDraftDeleteModal = ({
  guideDraftData,
  onDelete,
  onDismiss,
}: GuideDraftDeleteModalProps) => {
  const { id, name } = guideDraftData ?? {}
  const [archiveGuideDraft] = useArchiveGuideDraftMutation()
  const { data: currentUserData } = useGetCurrentUserId()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const userId = currentUserData?.currentUser?.id ?? ''

  const onConfirm = async () => {
    try {
      const { data } = await archiveGuideDraft({
        variables: {
          input: {
            guideDraftId: id,
          },
          userGuideDraftsArgs: {
            includeStatuses: [GuideDraftStatus.Active],
          },
        },
        refetchQueries: [
          {
            query: getGuideDraftsForUserQuery,
            variables: {
              userId,
            },
          },
        ],
      })

      if (data && data.archiveGuideDraft) {
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide has been deleted',
        })
        onDismiss()
        onDelete?.()
      }
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  return (
    <Modal size='small' title={`Delete ${name} Guide?`} onDismiss={onDismiss}>
      <ModalScrollContents>
        <h3 className='type-body-1-medium-desktop'>
          {`Are you sure you want to delete your ${name} Guide?`}
          <br />
          This action is not reversible.
        </h3>
      </ModalScrollContents>
      <ModalActions>
        <Button
          aria-label='Delete Guide'
          size='large'
          variant='error'
          onClick={onConfirm}
        >
          Delete
        </Button>
        <Button
          aria-label='Cancel Guide deletion'
          size='large'
          variant='outlined'
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
