import { Skeleton } from '@travelpass/design-system'
import { DashboardFeedCardHeaderGuideLoading } from './DashboardFeedCardHeaderGuideLoading'

export const DashboardFeedCardLoading = () => (
  <div className='of-hidden rounded-2 b-1 b-solid b-grey-200 relative h-full bg-white p-4 md:p-6'>
    <DashboardFeedCardHeaderGuideLoading />
    <div className='space-y-4'>
      <div className='h-27 lg:h-50 flex flex-row items-center gap-1'>
        <div className='h-full w-full'>
          <Skeleton />
        </div>
        <div className='h-full w-full'>
          <Skeleton />
        </div>
        <div className='h-full w-full'>
          <Skeleton />
        </div>
      </div>
      <div className='space-y-2'>
        <div className='w-70 h-9 lg:h-12'>
          <Skeleton />
        </div>
        <div className='lg:h-4.5 w-42.5 h-4'>
          <Skeleton />
        </div>
      </div>
      <div className='lg:h-13.5 h-12 w-full'>
        <Skeleton />
      </div>
      <div className='flex w-full flex-row justify-end gap-2'>
        <div className='w-17.5 h-6.5'>
          <Skeleton variant='rounded' />
        </div>
        <div className='w-17 h-6.5'>
          <Skeleton variant='rounded' />
        </div>
      </div>
    </div>
  </div>
)
