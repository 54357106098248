import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'

interface GuideDraftShareReviewButtonProps {
  icon: AvailableIcons
  onClick: VoidFunction
  subtitle: string
  title: string
}

export const GuideDraftShareReviewButton = ({
  icon,
  onClick,
  subtitle,
  title,
}: GuideDraftShareReviewButtonProps) => (
  <button
    className='p-x-4 p-y-3 b-1 b-grey-200 rounded-3 transition-shadow-100 hover:shadow-3 [&>i]:c-valley flex w-full flex-row items-center gap-3 border-solid bg-transparent text-left outline-none ease-linear hover:cursor-pointer'
    onClick={onClick}
  >
    <span className='[&>i>svg]:color-valley bg-valley/10 rounded-1/2 flex h-11 w-11 min-w-11 flex-col items-center justify-center'>
      <Icon name={icon} />
    </span>
    <span className='flex grow flex-col gap-1'>
      <span className='type-h6 color-black'>{title}</span>
      <span className='type-body-1 color-black'>{subtitle}</span>
    </span>
    <Icon name='arrowForward' />
  </button>
)
