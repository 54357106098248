import { useState } from 'react'
import {
  Button,
  InlineDateRangePicker,
  Modal,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { useLucencyNumber } from 'src/config/analytics/useLucencyNumber'
import type { DatesType } from 'src/constants/user'
import { TripDetailsDrawerFooter } from 'src/pages/trips/components'
import { useTripsDetailsCreateEvent } from 'src/pages/trips/hooks'
import { pushTripEventCreateToDataLayer } from 'src/pages/trips/utils'
import {
  formatDate,
  generateHotelDetailsUrl,
  getArrivalDate,
  getDateFromUTC,
  getDepartureDate,
} from 'src/utils'
import type { ExploreHotel } from '../../../constants'
import { dateTemplate, timeTemplate } from '../../../constants'
import { constructHotelEventArguments } from '../../../utils'

interface TripDetailsExploreDrawerHotelFooterProps {
  item: ExploreHotel
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripDetailsExploreDrawerHotelFooter = ({
  item,
  tripDetailsData,
}: TripDetailsExploreDrawerHotelFooterProps) => {
  const {
    endDate: initialEndDate,
    id: tripId,
    name: tripName,
    startDate: initialStartDate,
    timeZone,
  } = tripDetailsData ?? {}
  const endDate = dayjs(getDateFromUTC(initialEndDate, timeZone))
  const endDateTime = endDate.format(timeTemplate)
  const startDate = dayjs(getDateFromUTC(initialStartDate, timeZone))
  const startDateTime = startDate.format(timeTemplate)
  const formattedEndDate = dayjs(formatDate(endDate))
  const formattedStartDate = dayjs(formatDate(startDate))
  const [dates, setDates] = useState<DatesType>([
    formattedStartDate,
    formattedEndDate,
  ])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { addSuccessSnack } = useSnackbar()
  const [createEvent, { loading: isLoading }] = useTripsDetailsCreateEvent()
  const { hotelAddress, id, name, googlePlaceIds } = item ?? {}
  const { city, state, stateAbbreviation } = hotelAddress ?? {}
  const [searchParams, setSearchParams] = useSearchParams()
  const { updateLucency } = useLucencyNumber()

  const onDeleteParams = () => {
    searchParams.delete('activeExploreId')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onAddToTripClick = async () => {
    try {
      setIsModalOpen(false)
      const updatedEndDate = dayjs(
        `${formatDate(dates?.[1])}${endDateTime}`,
        `${dateTemplate}${timeTemplate}`
      )
        .utc()
        .format()
      const updatedStartDate = dayjs(
        `${formatDate(dates?.[0])}${startDateTime}`,
        `${dateTemplate}${timeTemplate}`
      )
        .utc()
        .format()

      const newEvent = await createEvent(
        constructHotelEventArguments({
          startDate: updatedStartDate,
          id: tripId,
          endDate: updatedEndDate,
          tripDetailsExploreHotelData: item,
          googlePlaceId: googlePlaceIds?.[0],
        })
      )
      const {
        endDate,
        insertedAt,
        name: itemName,
        productId: itemId,
        startDate,
        status,
        trip,
        type,
      } = newEvent?.data?.createEvent ?? {}
      const tripName = trip?.name ?? ''
      pushTripEventCreateToDataLayer({
        itemCategory: type,
        itemId,
        itemInsertedAt: insertedAt,
        itemEndDate: endDate,
        itemName,
        itemStartDate: startDate,
        itemStatus: status,
        triggerVariant: 'TripDetailsExploreDrawerHotelFooter AddToTripButton',
        tripId,
        tripName,
      })
      onDeleteParams()
      addSuccessSnack({ title: 'Event created' })
    } catch (error) {
      //TO-DO
    }
  }

  const onBookClick = () =>
    window.open(
      generateHotelDetailsUrl({
        arrival: getArrivalDate({ date: startDate, timezone: timeZone }),
        city,
        departure: getDepartureDate({ date: endDate, timezone: timeZone }),
        id,
        name,
        state,
        stateAbbreviation,
        tripId,
      })
    )

  const onSelect = (range: { from?: Dayjs; to?: Dayjs }) => {
    // track check-in and check-out dates to lucency
    updateLucency({
      checkin: range.from,
      checkout: range?.to,
    })
    const updatedDates: DatesType = [range?.from, range?.to]
    setDates(updatedDates)
  }

  const constructedAddToTripButton = () => {
    if (isLoading)
      return (
        <div className='flex h-12 flex-col items-center justify-center'>
          <SkeletonDots />
        </div>
      )

    return (
      <Button
        fullWidth
        aria-label='Add hotel to trip'
        size='large'
        variant='outlined'
        onClick={() => setIsModalOpen(true)}
      >
        Add to Trip
      </Button>
    )
  }

  return (
    <>
      {isModalOpen && (
        <Modal size='medium' onDismiss={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-8'>
            <InlineDateRangePicker
              fromDate={formattedStartDate.toDate()}
              label='Start Date – End Date'
              numberOfMonths={1}
              selected={{
                from: dates[0],
                to: dates[1],
              }}
              toDate={formattedEndDate.toDate()}
              onSelect={onSelect}
            />
            <Button
              aria-label='Add hotel to trip'
              isDisabled={!dates?.[1]}
              size='medium'
              onClick={onAddToTripClick}
            >
              Add to Trip
            </Button>
          </div>
        </Modal>
      )}
      <TripDetailsDrawerFooter>
        {constructedAddToTripButton()}
        <Button
          fullWidth
          aria-label='Book hotel now'
          size='large'
          onClick={onBookClick}
        >
          Book Now
        </Button>
      </TripDetailsDrawerFooter>
    </>
  )
}
