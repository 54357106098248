import type { ComponentPropsWithoutRef, SyntheticEvent } from 'react'
import { useState } from 'react'
import { Button, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { CollectionItemIdType, EventType } from 'src/__generated__/graphql'
import {
  ResultsCard,
  ResultsCardImage,
  ResultsCardReviews,
} from 'src/common/components'
import { AddToMenu } from 'src/common/components/Collections/AddToMenu'
import { constructAddress, getPrice } from 'src/utils'
import type { HotelResultsItemData } from '../../hotelResultsTypes'
import {
  HotelResultsCardAmenities,
  HotelResultsCardAmenityContainer,
} from '../HotelResultsCard'
import { HotelResultsCardFeaturedContentContainer } from '../HotelResultsCardFeatured'

interface HotelResultsCardFeaturedSingleProps
  extends ComponentPropsWithoutRef<'button'> {
  hotelResultsCardData: HotelResultsItemData
}

export const HotelResultsCardFeaturedSingle = ({
  hotelResultsCardData,
  onMouseOut = () => {},
  onMouseOver = () => {},
  ...props
}: HotelResultsCardFeaturedSingleProps) => {
  const {
    amenities,
    customerReviewScore,
    hotelAddress,
    images: initialImages,
    name,
    id,
    nightlyAverage: initialNightlyAverage,
    starRating,
    totalCustomerReviews,
  } = hotelResultsCardData ?? {}
  const [images, setImages] = useState(() => initialImages)
  const { city, postalCode, state, streetAddress } = hotelAddress ?? {}
  const nightlyAverage =
    initialNightlyAverage && getPrice(initialNightlyAverage)
  const showCard = !isEmpty(hotelResultsCardData) && !!name
  const showSecondaryImages = images?.length >= 4
  const address = constructAddress({
    addressFirstLine: streetAddress,
    city,
    state,
    zipcode: postalCode,
  })

  const onError = (event: SyntheticEvent<HTMLImageElement, Event>) =>
    setImages(updatedImages =>
      updatedImages?.filter(image => event.currentTarget?.src !== image)
    )

  return (
    showCard && (
      <>
        <ResultsCard onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
          {/* TODO: remove event handlers in `section`. */}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <section
            className='grid grid-cols-2'
            onBlur={() => {}}
            onFocus={() => {}}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
          >
            <div className='of-hidden rounded-3 relative'>
              <section>
                <ResultsCardImage
                  alt={`${name} featured`}
                  src={images?.[0]}
                  onError={onError}
                />
                <HotelResultsCardFeaturedContentContainer>
                  <p className='color-white type-h6'>{name}</p>
                  <div className='flex flex-row justify-between'>
                    <ResultsCardReviews
                      average={customerReviewScore}
                      total={totalCustomerReviews}
                      type={EventType.Stay}
                      variant='featured'
                    />
                    <div className='z-0'>
                      <AddToMenu
                        item={{ id, name, type: CollectionItemIdType.Hotel }}
                      />
                    </div>
                  </div>
                </HotelResultsCardFeaturedContentContainer>
              </section>
            </div>
            <section className='space-y-3 px-6 py-7'>
              {showSecondaryImages && (
                <div className='h-34 grid grid-cols-3 gap-1'>
                  {Array(3)
                    .fill(0)
                    .map((_, index) => (
                      <div key={index} className='of-hidden rounded-1 relative'>
                        <ResultsCardImage
                          alt={`${name} hotel #${index + 1}`}
                          src={images?.[index + 1]}
                          onError={onError}
                        />
                      </div>
                    ))}
                </div>
              )}
              <header className='space-y-1'>
                <h2 className='type-h6'>
                  {name} {state}
                </h2>
                <p className='color-grey-800 type-small-text-desktop'>
                  {address}
                </p>
              </header>
              <div className='flex flex-wrap gap-6'>
                <HotelResultsCardAmenityContainer>
                  <Icon name='starBorder' size='small' />
                  <span className='truncate'>{starRating} - star hotel</span>
                </HotelResultsCardAmenityContainer>
                {/* TODO: remove limit after design is updated */}
                <HotelResultsCardAmenities amenities={amenities} limit={1} />
              </div>
              <div className='flex items-end justify-between gap-4'>
                {!!nightlyAverage && (
                  <div>
                    <b className='type-subtext text-sm'>from</b>
                    <p className='type-subtitle-1-desktop'>{nightlyAverage}</p>
                    <p className='type-subtext'>Avg/Night</p>
                    <p className='type-subtext'>Tax excluded</p>
                  </div>
                )}
                <div className='relative w-fit'>
                  <Button size='small' {...props}>
                    Book Now
                  </Button>
                </div>
              </div>
            </section>
          </section>
        </ResultsCard>
      </>
    )
  )
}
