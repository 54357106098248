import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetDashboardAccountSettingsFormQueryInDashboardQuery } from 'src/__generated__/graphql'

/** @todo node query wasn't working. Replace when fixed on the BE. */
const getCommunicationPreferencesForUserQuery = gql(`
  query GetDashboardAccountSettingsFormQueryInDashboard($userId: ID!) {
    getCommunicationPreferencesForUser(userId: $userId) {
      id
      sendFeatureUpdatesEmail
      sendFeatureUpdatesSms
      sendInspirationEmail
      sendInspirationSms
      sendPromotionEmail
      sendPromotionSms
      sendRecentReviewsEmail
      sendRecentReviewsSms
      user {
        id
        addressFirstLine
        addressSecondLine
        city
        country
        email
        firstName
        isDeleted
        lastName
        phoneNumber
        state
        zip
      }
    }
  }
`)

type UseGetDashboardAccountSettingsFormQuery = {
  dashboardAccountSettingsFormData: GetDashboardAccountSettingsFormQueryInDashboardQuery['getCommunicationPreferencesForUser']
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardAccountSettingsFormQuery = (
  userId: string
): UseGetDashboardAccountSettingsFormQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getCommunicationPreferencesForUserQuery, {
    skip: !userId,
    variables: {
      userId,
    },
  })

  return {
    dashboardAccountSettingsFormData:
      data?.getCommunicationPreferencesForUser ?? null,
    hasError,
    isLoading,
  }
}

export { useGetDashboardAccountSettingsFormQuery }
