import { Carousel } from '@travelpass/design-system'
import { StarIcon } from './StarIcon'
import { reviewsData } from './reviewsData'

export const Reviews = () => {
  return (
    <div>
      <img
        alt='Animated reviews'
        aria-label='animated reviews'
        className='md:max-w-81 m-auto block max-w-full'
        src='https://static.travelpass.com/assets/reviews.gif'
      />
      <Carousel>
        {reviewsData.data.map(({ content, date, name, rating }) => (
          <div
            key={name}
            className='flex flex-col gap-10 px-12 py-0 text-left md:px-24'
          >
            <div className='text-subtitle-1'>&quot;{content}&quot;</div>
            <div className='flex flex-col gap-4 md:flex-row md:justify-between'>
              <p className='text-body-1'>
                {name}
                <br />
                {date}
              </p>
              <div>
                {Array(rating)
                  .fill(0)
                  .map((_item, index) => (
                    <StarIcon key={index} />
                  ))}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}
