import type {
  ButtonSize,
  ButtonVariant,
  IconButtonProps,
} from '@travelpass/design-system'
import { Button, IconButton, useSnackbar } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { getGuideMetricString } from './'
import type { GuideData } from '../../details/types'
import {
  getCurrentUserLikedGuidesQuery,
  useGetUserLikesGuideQuery,
} from '../../useGetUserLikesGuideQuery'
import { useLikeGuideMutation } from '../../useLikeGuideMutation'

interface GuideMetricLikeProps {
  color?: IconButtonProps['color']
  guideData: Pick<GuideData, 'id' | 'numberOfLikes'>
  hideLabel?: boolean
  isDisabled?: boolean
  isIconButton?: boolean
  size?: ButtonSize | IconButtonProps['size']
  variant?: ButtonVariant
}

/** @todo extract business logic into a custom hook, leave the styling up to the component using the hook */
export const GuideMetricLike = ({
  color = 'forestLight',
  guideData,
  hideLabel = false,
  isDisabled = false,
  isIconButton = false,
  size = 'medium',
  variant = 'text',
}: GuideMetricLikeProps) => {
  const { isAnonymous } = useFirebaseUser()
  const { id, numberOfLikes } = guideData ?? {}
  const { hasLikedGuide } = useGetUserLikesGuideQuery(id)
  const [likeGuide] = useLikeGuideMutation()
  const { addErrorSnack } = useSnackbar()

  const onLikeGuide = async () => {
    if (isAnonymous) {
      dispatch('openSignin', { isCreateAccount: false })
    } else {
      try {
        likeGuide({
          variables: {
            guideId: id,
          },
          refetchQueries: [getCurrentUserLikedGuidesQuery],
        })
      } catch (error) {
        addErrorSnack({ timeout: 1000, title: 'Already liked!' })
      }
    }
  }

  return (
    <>
      {isIconButton ? (
        <IconButton
          aria-label='Like this guide'
          aria-pressed={hasLikedGuide}
          color={color}
          data-testid='GuideMetricLike-icon-button'
          icon={!hasLikedGuide ? 'favoriteBlank' : 'favorite'}
          isDisabled={isDisabled}
          outline='round'
          size={size as IconButtonProps['size']}
          onClick={debounce(onLikeGuide, 100)}
        />
      ) : (
        <Button
          aria-label='Like this guide'
          aria-pressed={hasLikedGuide}
          data-testid='GuideMetricLike-button'
          isDisabled={isDisabled}
          size={size}
          startIcon={
            isDisabled || !hasLikedGuide ? 'favoriteBlank' : 'favorite'
          }
          variant={variant}
          onClick={debounce(onLikeGuide, 100)}
        >
          {getGuideMetricString(numberOfLikes, hideLabel ? '' : 'like')}
        </Button>
      )}
    </>
  )
}
