const SLACK_WEBHOOK_URL =
  'https://hooks.slack.com/services/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'

export const onFeedbackFormSubmit = (payload, callback) => {
  const {
    name,
    email,
    feedbackOption,
    url,
    feedbackDetails,
    canWeReachYou,
    receiveMarketingEmail,
  } = payload

  fetch(SLACK_WEBHOOK_URL, {
    method: 'POST',
    body: JSON.stringify({
      text: `${name} left a feedback`,
      attachments: [
        {
          color: feedbackOption === 'Report a bug.' ? '#B70000' : '#36a64f',
          fields: [
            {
              title: 'User',
              value: [name, email].filter(value => value).join(' - '),
            },
            {
              title: 'I Would Like to',
              value: feedbackOption,
            },
            {
              title: 'Feedback Details',
              value: feedbackDetails,
            },
            {
              title: 'URL',
              value: url,
            },
            {
              title: 'If we have further questions, can we reach out to you?',
              value: canWeReachYou,
            },
            {
              title:
                'Would you like to receive marketing email messages from Travelpass?',
              value: receiveMarketingEmail,
            },
          ],
        },
      ],
    }),
  }).then(() => callback())
}
