import { Button, Divider, useSnackbar } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'
import {
  firebaseFacebookSignIn,
  firebaseGoogleSignIn,
} from 'src/config/firebase/firebaseUtils'
import type { UpdateUserInfoProps } from './types'

export const LinkCredentials = ({
  onCloseModal,
  updateUserInfo,
  setAttemptingCreation,
}: {
  onCloseModal(): void
  updateUserInfo?(arg0: UpdateUserInfoProps): void
  setAttemptingCreation?(arg0: boolean): void
}): JSX.Element => {
  const { addSuccessSnack } = useSnackbar()
  const isNewSignUpEnabled = useFlag('newSignUpFlow')
  const showSuccessSnack = () => {
    addSuccessSnack({ title: 'Successfully signed in' })
  }

  const updateUser = user => {
    const { uid, displayName, email, phoneNumber } = user ?? {}
    const [firstName, lastName] = displayName.split(' ')

    updateUserInfo?.({
      externalId: uid,
      email,
      firstName,
      lastName,
      phoneNumber,
      ...(isNewSignUpEnabled && { displayName }),
    })
  }

  const onGoogleSignIn = async () => {
    isNewSignUpEnabled && setAttemptingCreation && setAttemptingCreation(true)
    const response = await firebaseGoogleSignIn()
    const { createdNewAccount, user, error } = response ?? {}

    if (error) {
      setAttemptingCreation(false)
      return
    } else {
      if (createdNewAccount) updateUser(user)
      else {
        onCloseModal()
        showSuccessSnack()
      }
    }
  }

  const onFacebookSignIn = async () => {
    const response = await firebaseFacebookSignIn()
    const { createdNewAccount, user, error } = response ?? {}

    if (error) return
    else {
      if (createdNewAccount) updateUser(user)
      else {
        onCloseModal()
        showSuccessSnack()
      }
    }
  }

  return (
    <div className='flex flex-col space-y-7'>
      <section className='space-y-3'>
        <Button
          fullWidth
          data-testid='sign-in-google'
          label='Continue with Google'
          size='large'
          startIcon='coloredGoogle'
          variant='outlined'
          onClick={onGoogleSignIn}
        />
        {/* <Button
          fullWidth
          data-testid='sign-in-facebook'
          label='Continue with Facebook'
          size='large'
          startIcon='coloredFacebook'
          variant='outlined'
          onClick={onFacebookSignIn}
        /> */}
      </section>
      <section className='flex flex-row items-center justify-evenly text-center'>
        <Divider />
        <h3 className='type-body-1 mx-4'>or</h3>
        <Divider />
      </section>
    </div>
  )
}
