import { Button, Link } from '@travelpass/design-system'
import { useLocation } from 'react-router-dom'
import { SortBy } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'
import {
  generateHotelResultsUrl,
  generateExperiencesResultsUrl,
} from 'src/utils'
import { LandingPageSearchHotelsResults } from '../../../common/LandingPageSearchHotelsResults'

export const UtahCounty = () => {
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  const date = () => new Date()
  const today = date().toISOString().split('T')[0]
  const dateTomorrow = () => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }
  const tomorrow = dateTomorrow().toISOString().split('T')[0]

  const onLehiHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Lehi, UT, USA',
      latitude: 40.3916172,
      longitude: -111.8507662,
      sort: SortBy.Recommended,
    })
    window.open(url)
  }
  const onLehiExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'Lehi, UT, USA',
      latitude: 40.3916172,
      longitude: -111.8507662,
    })
    window.open(url)
  }
  const onPleasantGroveHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Pleasant Grove, UT, USA',
      latitude: 40.354721301112285,
      longitude: -111.73196700721736,
      mapBounds: JSON.stringify({
        south: 40.30548874487332,
        west: -111.84283882554332,
        north: 40.40395385735125,
        east: -111.6210951888914,
      }),
      sort: SortBy.Recommended,
    })
    window.open(url)
  }
  const onProvoHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Provo, UT, USA',
      latitude: 40.2338438,
      longitude: -111.6585337,
      sort: SortBy.Recommended,
    })
    window.open(url)
  }
  const onProvoExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'Provo, UT, USA',
      latitude: 40.2338438,
      longitude: -111.6585337,
    })
    window.open(url)
  }

  const jumpToLehi = e => {
    e.preventDefault()
    const node = document.getElementById('lehi')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToPleasantGrove = e => {
    e.preventDefault()
    const node = document.getElementById('pleasant-grove')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToProvo = e => {
    e.preventDefault()
    const node = document.getElementById('provo')
    node?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />

      <header className='h-200px lg:h-300px bg-top-center lg:bg-bottom-center w-full bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/b8a60e76-e70d-11ee-a7f3-fa9284f9fbb1)] bg-cover'></header>

      <PageLayoutContainer size='medium'>
        <div className='flex w-full flex-col gap-4 py-10'>
          <h1 className='font-archivo font-700 tracking-5% m-0 flex flex-col text-center'>
            <span className='text-6 lg:leading-14 lg:text-10 font-900 leading-6'>
              Surprises In
            </span>
            <span className='text-14 lg:leading-20 lg:text-26 font-900'>
              Utah County
            </span>
          </h1>
          <p className='type-subtitle-2 font-300 lg:line-height-8 tracking-wide lg:mt-8'>
            Sometimes the bigger cities and national parks in Utah get all the
            attention, but some of the top-booked destinations on Travelpass are
            found in and around Utah County! Find historic downtowns nestled
            just 15 minutes from spectacular hikes in the canyons, along with a
            few favorite restaurants. Next time you&apos;re passing through, we
            hope you&apos;re convinced to stop by and explore a little!
          </p>
        </div>
        <div className='pb-15 flex flex-col items-center justify-center gap-4 pt-5 lg:flex'>
          <Button
            fullWidth
            label='Lehi'
            size='large'
            variant='filledDark'
            onClick={jumpToLehi}
          />
          <Button
            fullWidth
            label='Pleasant Grove'
            size='large'
            variant='filledDark'
            onClick={jumpToPleasantGrove}
          />
          <Button
            fullWidth
            label='Provo'
            size='large'
            variant='filledDark'
            onClick={jumpToProvo}
          />
        </div>
      </PageLayoutContainer>

      <div
        className='bg-warmGrey flex w-full flex-col items-stretch justify-center lg:flex-row'
        id='lehi'
      >
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/b957841e-ea2b-11ee-9510-56bf3be8c794)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <PageLayoutContainer size='medium'>
                <h2 className='type-h1 c-white mb-4 text-center'>Lehi</h2>
                <p className='type-body-1 c-white mb-4 text-center'>
                  A drive in the older sections of Lehi reveal historic homes
                  and sprawling parks hiding behind the modern townhomes, tech
                  company hubs, and chain restaurants that seem to dominate the
                  area.
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip</b>: A visit to Lehi is only complete when
                  you’ve had a square donut from the famed Lehi Bakery. Sinners
                  and Saints Coffee is the spot to grab a drink to pair with
                  your sweet treat.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Nearby Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[101938045, 103111793, 103113902]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onLehiHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Nearby Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href='https://thanksgivingpoint.org/'
                    label='Visit the museums (and springtime tulips!) at Thanksgiving Point'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='https://jordanrivercommission.com/thetrail/'
                    label='Bike the Jordan River Trail'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='https://www.thanksgivingpointgolfclub.com/'
                    label='Golf at Thanksgiving Point (designed by Johnny Miller)'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onLehiExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
      </div>

      <div
        className='flex w-full flex-col flex-col-reverse items-stretch justify-center bg-white lg:flex-row'
        id='pleasant-grove'
      >
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Nearby Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[103113384, 101938018]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onPleasantGroveHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Nearby Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href='https://www.fs.usda.gov/detail/uwcnf/passes-permits/recreation/?cid=STELPRDB5043532'
                    label='Take a Scenic Drive up AF Canyon'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='https://www.nps.gov/tica/index.htm'
                    label='Tour the Timpanogos Cave'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='https://thegrid.com/'
                    label='Race Indoor Go Karts at The Grid'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onLehiExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/69de08a0-ea2f-11ee-9a90-56bf3be8c794)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <h2 className='type-h1 c-white mb-4 text-center'>
                Pleasant Grove
              </h2>
              <PageLayoutContainer size='medium'>
                <p className='type-body-1 c-white mb-4 text-center'>
                  Pleasant Grove is affectionately nicknamed PG for short, and
                  is locally famous for the summertime festival, Strawberry
                  Days. Come for fresh berries and cream, a pop-up market,
                  rodeo, and parade during the festival.
                </p>
                <p className='type-body-1 c-white text-center'>
                  Visiting other times of the year? You can’t go wrong with
                  dinner at Sol Agave for incredible Mexican food, in nearby
                  American Fork. <b>Inside tip</b>: Order the Chile Colorado
                  (braised short ribs in a roasted chile and beer sauce).
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
      </div>

      <div
        className='bg-warmGrey flex w-full flex-col items-stretch justify-center lg:flex-row'
        id='provo'
      >
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/c8619e24-e70e-11ee-9ea9-ca13f8e0f9ae)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <PageLayoutContainer size='medium'>
                <h2 className='type-h1 c-white mb-4 text-center'>Provo</h2>
                <p className='type-body-1 c-white mb-4 text-center'>
                  Found near the mouth of the Provo Canyon, this small city has
                  a charming (and very walkable) downtown, filled with curated
                  shops, artsy boutiques, and creative eateries.
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip</b>: Stopping for ice cream at Rockwell’s is a
                  must. Order their award-winning ice cream flavor, “The Goat”.
                  Later, have dinner at Communal. Their seasonal and local menu
                  changes frequently, with evergreen staples like Utah trout and
                  short ribs served with different sides.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Nearby Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[102045379, 101860703, 101897523]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onProvoHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Nearby Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzI1MTg1/Private-Utah-High-Country-Rafting-Adventure-from-Provo?arrival=${today}&departure=${tomorrow}`}
                    label='Raft the Provo River'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href=''
                    label='Sundance Full Moon Ski Lift Rides'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MTE5MDM2/Provo-Scavenger-Hunt%3A-A-Provo-Peak?arrival=${today}&departure=${tomorrow}`}
                    label='Provo Scavenger Hunt'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onProvoExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
      </div>

      <div className='bg-forest lg:py-15 flex w-full flex-col items-center justify-center py-10'>
        <h3 className='type-h2 c-white mb-10'>Explore Your Utah</h3>

        <div className='w-94% max-w-1000px mx-a grid grid-cols-1 gap-4 md:grid-cols-2'>
          <div className='h-200px bg-forest relative col-span-2 row-span-5 overflow-hidden lg:col-span-1'>
            <a href='/inside-scoop/salt-lake-city'>
              <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4'>
                SALT LAKE CITY
              </p>
              <img
                alt='Salt Lake City, Utah'
                className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-60'
                src='https://static.travelpass.com/assets/destinations-page/salt-lake-city-utah/book-a-hotel.webp'
              />
            </a>
          </div>

          <div className='h-200px bg-forest relative col-span-2 overflow-hidden lg:col-span-1 lg:row-span-2'>
            <a href='/inside-scoop/southern-utah'>
              <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4'>
                SOUTHERN UTAH
              </p>
              <img
                alt='Zion National Park, Utah'
                className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-60'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/a96704a4-e79b-11ee-9ca9-8e1da778f97e'
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const metaDescription =
  'Uncover the hidden gems of Utah County with our detailed travel guide. Experience the beauty of the Wasatch Front, from breathtaking mountain hikes to serene lakeside retreats. Discover the charm of Provo, and delve into the vibrant local arts scene. With insider tips on the best places to eat, stay, and explore, plan your perfect Utah County adventure today.'
const pageName = 'Inside Scoop: Utah County, Utah'
