import type { ApolloError } from '@apollo/client'
import { Button, useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { VisibilityLevel } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  RecommendedHotels,
  useUpdateHotelRecommendation,
} from './tabs/RecommendedHotels'
import { DEFAULT_PROFILE_IMAGE_URL } from '../../profileConstants'
import type { HotelNote, ProfileFields, Tab } from '../../types'
import { SaveButtonFooter } from '../SaveButtonFooter'
import { useCheckEditingMode } from '../hooks/useCheckEditingMode'
import { useGetProfile } from '../hooks/useGetProfile'
import { useUpsertCurrentUserProfile } from '../hooks/useUpsertCurrentUserProfile'
import { identifySocialLink } from '../profileUtils'

export const EditingProfileOverview = () => {
  const { refetch, profile } = useGetProfile()
  const { handleCloseEditMode } = useCheckEditingMode()
  const { handleSubmit, setError } = useFormContext<ProfileFields>()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const navigate = useNavigate()
  //GraphQl mutations
  const [updateProfileInfo] = useUpsertCurrentUserProfile()
  const [addNotes] = useUpdateHotelRecommendation()

  const isLinkValid = (linkURL: string, linkTitle: string) => {
    if (isEmpty(linkURL) && !isEmpty(linkTitle)) {
      setError('linkURL', { type: 'manual', message: 'A URL is required' })
      return false
    } else if (!isEmpty(linkURL) && isEmpty(linkTitle)) {
      setError('linkTitle', { type: 'manual', message: 'A title is required' })
      return false
    } else return true
  }

  const getCombinedSocialLinks = (
    socialLinks: ProfileFields['socialLinks'],
    identifier: ProfileFields['socialLink']
  ) => [
    ...(socialLinks ? JSON.parse(socialLinks as string) : []),
    ...(!isEmpty(identifier)
      ? [{ network: identifySocialLink(identifier), identifier }]
      : []),
  ]

  const getCombinedLinks = (
    links: ProfileFields['links'],
    url: ProfileFields['linkURL'],
    title: ProfileFields['linkTitle']
  ) => [
    ...(links ? JSON.parse(links as string) : []),
    ...(!isEmpty(url) && !isEmpty(title) ? [{ url, title }] : []),
  ]

  const onSave = ({
    displayName,
    bio,
    socialLinks,
    socialLink,
    links,
    linkURL,
    linkTitle,
    accountHandle,
    profileImageUrl,
    profileImageSource,
    activeBannerImageId,
    bragStates,
    bragContinents,
    bragCountries,
    hotelNotes,
    introVideoEmbed,
  }: ProfileFields) => {
    if (!isLinkValid(linkURL, linkTitle)) return

    const combinedSocialLinks = getCombinedSocialLinks(socialLinks, socialLink)
    const combinedLinks = getCombinedLinks(links, linkURL, linkTitle)

    updateProfileInfo({
      variables: {
        input: {
          userProfileRequest: {
            accountHandle,
            displayName,
            bio,
            profileImageUrl: profileImageUrl || DEFAULT_PROFILE_IMAGE_URL,
            profileImageSource,
            bannerImageId: activeBannerImageId,
            bragContinents: parseInt(bragContinents.toString()),
            bragCountries: parseInt(bragCountries.toString()),
            bragStates: parseInt(bragStates.toString()),
            links: combinedLinks || [],
            visibilityLevel: VisibilityLevel.Public,
            socialLinks: combinedSocialLinks || [],
            introVideoEmbed: introVideoEmbed || '',
            profileTagIds: profile?.tags?.map(tag => tag?.id) || [],
          },
        },
      },
      onCompleted: async () => {
        handleDataLayer(
          combinedSocialLinks,
          combinedLinks,
          bio,
          bragContinents,
          bragCountries,
          bragStates,
          accountHandle
        )
        await updateHotelNotes(hotelNotes)
        refetch()
        handleCloseEditMode(accountHandle)
        addSuccessSnack({ title: 'Profile updated!' })
      },
      onError,
    })
  }

  /**
   * It's meant to track the changes user made on its profile
   * @param socialLinks
   * @param links
   * @param bio
   * @param bragContinents
   * @param bragCountries
   * @param bragStates
   */
  const handleDataLayer = (
    socialLinks,
    links,
    bio: string,
    bragContinents: number,
    bragCountries: number,
    bragStates: number,
    accountHandle: string
  ) => {
    // If profile is null, it means user is creating a new profile
    if (!profile) {
      pushDataToDataLayer('create_profile', { accountHandle })
      return
    }

    const orginalSocialLinks = profile.socialLinks.map(
      ({ identifier, network }) => ({ identifier, network })
    )
    const originalLinks = profile.links.map(({ title, url }) => ({
      title,
      url,
    }))

    if (!isEqual(orginalSocialLinks, socialLinks)) {
      pushDataToDataLayer('add_social_media', {
        social_medias: socialLinks,
      })
    }
    if (!isEqual(originalLinks, links)) {
      pushDataToDataLayer('add_links', { links })
    }

    if (profile.bio !== bio) pushDataToDataLayer('add_bio', { bio })
    if (
      profile.bragContinents !== bragContinents ||
      profile.bragCountries !== bragCountries ||
      profile.bragStates !== bragStates
    ) {
      pushDataToDataLayer('add_travel_tracker', {
        bragContinents,
        bragCountries,
        bragStates,
      })
    }
  }

  const onError = (error: ApolloError) => {
    let errorMessage = 'An error occurred, try again later.'
    if (error?.graphQLErrors?.length > 0) {
      const message = error.graphQLErrors[0].message
      const match = message.match(/%\{(.+?): \["(.*?)"\]\}/)
      if (match && match[2]) errorMessage = `${match[2]}`

      addErrorSnack({ title: errorMessage })
    }
  }

  const updateHotelNotes = async (hotelNotes: HotelNote[]) => {
    const promises = hotelNotes?.map(({ id, notes }) =>
      addNotes({ variables: { input: { id, notes } } })
    )

    try {
      await Promise.all(promises)
    } catch {
      console.error('An unknown error happened while trying to add notes')
    }

    refetch()
  }

  const onClose = () => {
    if (profile?.accountHandle) {
      refetch()
      handleCloseEditMode(profile?.accountHandle)
    } else navigate('/')
  }

  return (
    <>
      <div className='grid justify-end px-10 sm:hidden md:flex'>
        <Button
          label='Close'
          size='small'
          startIcon='clear'
          variant='text'
          onClick={onClose}
        />
      </div>
      <h6 className='type-h6 c-black my-4 md:px-10'>Top Hotels</h6>
      <form
        className='pb-30 grid gap-10 sm:pt-5 md:px-10'
        onSubmit={handleSubmit(onSave)}
      >
        <RecommendedHotels profile={profile} />
        <SaveButtonFooter
          onCancel={() => {
            refetch()
            handleCloseEditMode()
          }}
        />
      </form>
    </>
  )
}
