import type { HotelDetailsStandardHotelQuery } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { HotelOverviewDescription } from './HotelOverviewDescription'
import { HotelOverviewNearbyAttractions } from './HotelOverviewNearbyAttractions'
import { HotelOverviewTopAmenities } from './HotelOverviewTopAmenities'

interface HotelOverviewProps {
  hotelDetailsData: HotelDetailsStandardHotelQuery['lodging']
}

export const HotelOverview = ({ hotelDetailsData }: HotelOverviewProps) => {
  const { descriptions, amenities, id, name } = hotelDetailsData ?? {}
  const constructedDescriptions = descriptions?.map(({ text }) => text)

  return (
    <PageLayoutContainer>
      <section className='lg:gap-18 py-10px lg:py-60px grid-items-center grid w-full grid-cols-1 items-baseline gap-8 lg:grid-cols-2'>
        {hotelDetailsData && (
          <div className='flex flex-col items-center justify-center'>
            <HotelOverviewDescription descriptions={constructedDescriptions} />
            <HotelOverviewTopAmenities
              amenities={amenities}
              hotelId={id}
              name={name}
            />
          </div>
        )}
        <HotelOverviewNearbyAttractions
          hotelDetailsData={hotelDetailsData}
          showMap={true}
        />
      </section>
    </PageLayoutContainer>
  )
}
