import { useState } from 'react'
import type { AvailableIcons } from '@travelpass/design-system'
import {
  Carousel,
  Illustration,
  Icon,
  SkeletonDots,
  Tile,
  useScreenQuery,
} from '@travelpass/design-system'
import { GuideCategory } from 'src/__generated__/graphql'
import {
  ButtonLink,
  GuideRecommendedCard,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { Separator } from './Separator'
import { useGetCuratedGuides } from './useGetCuratedGuides'
import { ProfileModal } from '../guides/results/ProfileModal'
import { ProfileModalListener } from '../guides/results/ProfileModalListener'

const FILTERS: [Partial<AvailableIcons>, GuideCategory][] = [
  ['restaurant', GuideCategory.Food],
  ['autoFixHigh', GuideCategory.Quirky],
  ['airplaneModeActive', GuideCategory.International],
  ['downhillSkiing', GuideCategory.Sports],
  ['map', GuideCategory.Outdoors],
  ['pool', GuideCategory.Beaches],
]

export const HomeGetInspired = () => {
  const [activeFilter, setActiveFilter] = useState<GuideCategory>(
    /**@desc randomize initial category as per executive request */
    FILTERS[Math.floor(Math.random() * FILTERS.length)][1]
  )
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [accountHandle] = useState('')
  const onClickOwnerProfile = useOnClickOwnerProfile()

  const { data, loading, error } = useGetCuratedGuides(activeFilter)
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const curatedGuides = data?.curatedGuides

  if (error) {
    return (
      <>
        <header className='flex flex-col items-center space-y-5'>
          <h4 className='type-overline uppercase'>Get Inspired</h4>
          <Separator />
          <h3 className='type-h2'>Explore Guides To Discover New Places</h3>
        </header>
        <section className='lg:px-18 space-y-10 text-left'>
          <div className='of-x-scroll md:m-unset md:of-x-unset mx-4 flex grow flex-row gap-2 [scrollbar-width:none] md:justify-center md:gap-4 lg:px-24'>
            {FILTERS.map(([icon, filter]) => (
              <Tile
                key={filter}
                pressed={filter === activeFilter}
                size='small'
                onClick={() => setActiveFilter(filter)}
              >
                <div className='flex flex-col items-center justify-center gap-2 lg:flex-row'>
                  <Icon name={icon} />
                  {filter}
                </div>
              </Tile>
            ))}
          </div>
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        </section>
      </>
    )
  }

  return (
    <>
      {isProfileModalOpen && (
        <ProfileModal
          accountHandle={accountHandle}
          onClose={() => setIsProfileModalOpen(false)}
        />
      )}
      <header className='flex flex-col items-center space-y-5'>
        <h4 className='type-overline uppercase'>Get Inspired</h4>
        <Separator />
        <h3 className='type-h2'>Explore Guides To Discover New Places</h3>
      </header>
      <section className='lg:px-18 space-y-10 text-left'>
        <div className='of-x-scroll md:m-unset md:of-x-unset mx-4 flex grow flex-row gap-2 [scrollbar-width:none] md:justify-center md:gap-4 lg:px-24'>
          {FILTERS.map(([icon, filter]) => (
            <Tile
              key={filter}
              pressed={filter === activeFilter}
              size='small'
              onClick={() => setActiveFilter(filter)}
            >
              <div className='flex flex-col items-center justify-center gap-2 lg:flex-row'>
                <Icon name={icon} />
                {filter}
              </div>
            </Tile>
          ))}
        </div>
        {isMobileOrTablet &&
          (loading ? (
            <div className='h-66 py-32'>
              <SkeletonDots />
            </div>
          ) : (
            <div className='px-10'>
              <Carousel
                hideArrows
                keyBoardControl
                borderRadius={12}
                size='small'
                slidesPerViewOnDesktop={2}
                slidesPerViewOnMobile={1}
                slidesPerViewOnTablet={2}
              >
                {curatedGuides?.map(guide => (
                  <div
                    key={guide.id}
                    className='mx-auto mb-8 max-w-[calc(100%-24px)]'
                  >
                    <GuideRecommendedCard
                      hideAddTo
                      guideRecommendedCardData={guide}
                      onClickOwnerProfile={onClickOwnerProfile}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          ))}
        {isDesktopScreen &&
          (loading ? (
            <div className='h-100 py-48'>
              <SkeletonDots />
            </div>
          ) : (
            <div className='max-w-304 mx-auto grid grid-cols-3 gap-6'>
              {curatedGuides?.map(guide => (
                <GuideRecommendedCard
                  key={guide.id}
                  hideAddTo
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              ))}
            </div>
          ))}
      </section>
      <ButtonLink
        id='guides-homepage-see-more'
        to='/guides/all'
        variant='outline'
      >
        See more guides
      </ButtonLink>
      <ProfileModalListener />
      <AddToCollectionsModalListener />
    </>
  )
}
