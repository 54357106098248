import { Avatar } from '@travelpass/design-system'
import classNames from 'classnames'
import { TravelpassLogo } from 'src/common/components'
import type { UseGetDashboardFeedQuery } from 'src/pages/dashboard/useGetDashboardFeedQuery'
import { GuideImage } from 'src/pages/guides/details/common'
import {
  getGuideAddress,
  getGuideDetailsUrl,
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
} from 'src/utils'
import { DashboardFeedCard } from '../DashboardFeedCard'

interface DashboardFeedSuggestedGuideCardProps {
  dashboardFeedSuggestedGuideItem: UseGetDashboardFeedQuery['dashboardFeedSuggestedGuidesData'][0]
  isTravelpass?: boolean
}

export const DashboardFeedSuggestedGuideCard = ({
  dashboardFeedSuggestedGuideItem,
  isTravelpass = false,
}: DashboardFeedSuggestedGuideCardProps) => {
  const { addresses, id, images, imageUrl, name, ownerProfile } =
    dashboardFeedSuggestedGuideItem ?? {}
  const { accountHandle, displayName, profileImageUrl } = ownerProfile ?? {}
  const address = getGuideAddress({
    city: addresses?.[0]?.city,
    country: addresses?.[0]?.country,
    state: addresses?.[0]?.state,
  })
  const image = images?.[0]?.url ?? imageUrl
  const ownerImage = getGuideOwnerImage(profileImageUrl)
  const ownerName = getGuideOwnerName({
    accountHandle,
    displayName,
  })
  const ownerUrl = getGuideOwnerUrl(accountHandle)
  const url = `${window.location.origin}${getGuideDetailsUrl({
    id,
    name,
  })}`

  if (!name) return

  return (
    <DashboardFeedCard
      className={classNames(
        'h-90 w-62 rounded-2 of-hidden transition-opacity-200 relative flex flex-col justify-end gap-3 ease-linear hover:opacity-90 active:opacity-90',
        {
          'p-x-3 p-y-4': isTravelpass,
          'p-x-6 p-y-8': !isTravelpass,
        }
      )}
      href={url}
    >
      <GuideImage
        className='pointer-events-none absolute inset-0'
        draggable='false'
        src={image}
      />
      <div className='from-grey-900/50 via-black/31 via-49% pointer-events-none absolute inset-0 bg-gradient-to-t to-white/0' />
      <div
        className={classNames('pointer-events-none relative', {
          'space-y-2': isTravelpass,
          'space-y-3': !isTravelpass,
        })}
      >
        {isTravelpass && (
          <a
            aria-label={`View profile of ${ownerName}`}
            className='transition-opacity-200 c-white type-body-1-medium-mobile pointer-events-auto relative block flex max-w-24 cursor-pointer flex-row items-center gap-3 bg-transparent p-0 no-underline ease-linear hover:opacity-50 active:opacity-50'
            href={ownerUrl}
            rel='noreferrer'
            target='_BLANK'
          >
            <TravelpassLogo variant='beach-valley' />
          </a>
        )}
        <h3 className='type-h3-mobile c-white line-clamp-2'>{name}</h3>
        {!isTravelpass && (
          <>
            <p className='type-overline-desktop c-white line-clamp-1'>
              {address}
            </p>
            <a
              aria-label={`View profile of ${ownerName}`}
              className='transition-opacity-200 c-white type-body-1-medium-mobile pointer-events-auto relative block flex cursor-pointer flex-row items-center gap-3 bg-transparent p-0 no-underline ease-linear hover:opacity-50 active:opacity-50'
              href={ownerUrl}
              rel='noreferrer'
              target='_BLANK'
            >
              <Avatar border='none' size='sm' src={ownerImage} />
              <span className='line-clamp-1'>{ownerName}</span>
            </a>
          </>
        )}
      </div>
    </DashboardFeedCard>
  )
}
