import { useLocation } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'
import { awayGameSchedule } from './awayGameSchedule'
import { ScheduleCard } from './components/ScheduleCard'

export const Schedule = () => {
  const { pathname } = useLocation()
  const canonicalUrl = baseUrl + pathname

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />

      {awayGameSchedule.map(({ month, games }) => (
        <section key={month}>
          <div className='p-y-10px bg-forest-dark'>
            <PageLayoutContainer>
              <h3 className='p-x-20px m-y-0 text-5 md:text-6 lg:text-6 font-900 c-white text-balance text-center tracking-[-0.01em] antialiased'>
                {month}
              </h3>
            </PageLayoutContainer>
          </div>
          <PageLayoutContainer>
            <div className='p-y-6 md:p-y-10 p-x-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3'>
              {games.map(
                ({
                  game,
                  arrival,
                  city,
                  colors,
                  date,
                  departure,
                  fullDate,
                  latitude,
                  longitude,
                  state,
                  teamName,
                  time,
                }) => (
                  <ScheduleCard
                    key={game}
                    arrival={arrival}
                    city={city}
                    colors={colors}
                    date={date}
                    departure={departure}
                    fullDate={fullDate}
                    latitude={latitude}
                    longitude={longitude}
                    state={state}
                    teamName={teamName}
                    time={time}
                  />
                )
              )}
            </div>
          </PageLayoutContainer>
        </section>
      ))}
    </>
  )
}

const pageName = 'Utah Jazz Away Game Schedule | 2024-2025 Season'
const metaDescription =
  'Follow the Utah Jazz on the road during the 2024-2025 season.'
