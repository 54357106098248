import { useState } from 'react'
import classNames from 'classnames'
import type { LodgingPointOfInterest } from 'src/__generated__/graphql'
import { ClusterMarker } from 'src/common/components'
import {
  Map,
  constructMapCenter,
  MarkerWrapper,
} from 'src/common/components/Map'
import { MarkerColors } from 'src/component-library/theme/colors'
import { HotelMapMarkers } from './HotelMapMarkers'
import { constructedHotelMapPoints } from './hotelMapUtils'
import { HOTEL_PIN_ID } from '../../constants'
import { useHotelLocationStandardHotelQuery } from '../../hooks'

interface HotelMapProps {
  enableScrollWheel?: boolean
  points?: LodgingPointOfInterest[]
  height?: string
  hideZoom?: boolean
}

export const HotelMap = ({
  enableScrollWheel = true,
  points = [],
  height = 'h-90',
  hideZoom,
}: HotelMapProps) => {
  const { hotelLocationData } = useHotelLocationStandardHotelQuery()
  const { latitude, longitude } = hotelLocationData ?? {}
  const [selectedItem, setSelectedItem] = useState('')
  const constructedPoints = constructedHotelMapPoints(
    points,
    parseFloat(latitude),
    parseFloat(longitude)
  )

  return (
    <div className={hotelMapCss({ height })}>
      <Map
        data={constructedPoints || []}
        defaultCenter={constructMapCenter(latitude, longitude)}
        defaultZoom={13}
        enableScrollWheel={enableScrollWheel}
        enableZoomControl={!hideZoom}
        maxZoom={20}
        render={({ clusters, supercluster }) => {
          return clusters.map((clusterPoint, index) => {
            const { coordinates } = clusterPoint.geometry
            const { properties } = clusterPoint
            // conditionally render marker based on presence of event cards
            return properties.cluster ? (
              <MarkerWrapper
                key={index}
                position={{ lat: coordinates[1], lng: coordinates[0] }}
                onClick={map => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(properties.cluster_id),
                    19
                  )
                  map.setZoom(expansionZoom)
                  map.panTo({ lat: coordinates[1], lng: coordinates[0] })
                }}
              >
                <ClusterMarker
                  count={properties.point_count}
                  label='Places Nearby'
                />
              </MarkerWrapper>
            ) : (
              <MarkerWrapper
                key={`${index}${properties.id}`}
                position={{
                  lat: parseFloat(properties.lat),
                  lng: parseFloat(properties.lng),
                }}
                onClick={() => setSelectedItem(properties.id)}
              >
                <div
                  className='flex flex-col flex-wrap items-center'
                  onBlur={() => setSelectedItem('')}
                  onFocus={() => setSelectedItem(properties.id)}
                  onMouseOut={() => setSelectedItem('')}
                  onMouseOver={() => setSelectedItem(properties.id)}
                >
                  <div
                    className={classNames(
                      'max-h-auto rd-5 text-overline bg-warm-grey flex max-w-3xl p-2 shadow-[0px_0px_4px_0px_grey]',
                      { invisible: selectedItem !== properties.id }
                    )}
                  >
                    {properties.name}
                  </div>
                  <HotelMapMarkers
                    color={MarkerColors.BOOKED}
                    isSelected={selectedItem === properties.id}
                    type={properties.id === HOTEL_PIN_ID ? 'hotel' : 'generic'}
                  />
                </div>
              </MarkerWrapper>
            )
          })
        }}
        // Use this code only when more than one point is present so it will center the map using the pins
        /* onDataChanged={map => {
          if (constructedPoints.length > 0) {
            centerMap({
              map,
              points: constructedPoints.map(p => ({
                lat: p.lat,
                lng: p.lng,
              })),
            })
          }
        }} */
      ></Map>
    </div>
  )
}

const hotelMapCss = ({ height }: Partial<HotelMapProps>) =>
  classNames('relative shadow-[0px_0px_5px_1px_rgba(0,0,0,0.05)]', height)
