import {
  IconButton,
  Icon,
  Autocomplete,
  AutocompleteOption,
  KeyCode,
} from '@travelpass/design-system'
import {
  NavLink,
  useParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { type Suggestion, getGeocode, getLatLng } from 'use-places-autocomplete'
import { SortBy } from 'src/__generated__/graphql'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import {
  CACHE_KEYS,
  useGeocoderSearch,
} from 'src/common/components/Geocoder/useGeocoderSearch'
import { useFlag } from 'src/common/hooks'
import {
  generateExperiencesResultsUrl,
  generateHotelResultsUrl,
  getGeocoderPlaceCountryFromAddressComponents,
  getGeocoderPlaceTypeFromAddressComponents,
} from 'src/utils/'
import { COUNTRY_STATE_SEARCH_TYPES } from '../guides/guideConstants'

const NavlinkTab = props => {
  return (
    <NavLink
      {...props}
      className='text-body-1 c-grey-800 border-b-solid border-b-grey-200 [&.active]:border-b-valley inline-flex items-center gap-2 border-b-2 bg-transparent p-4 no-underline transition-all hover:bg-black/5 md:px-8 md:py-6 [&.active]:border-b-2'
    />
  )
}

type SearchType = 'all' | 'hotels' | 'experiences' | 'guides'

const TITLE_BY_TYPE: Record<SearchType, React.ReactNode> = {
  all: <>Where are you headed&nbsp;next?</>,
  hotels: <>Find a great place to&nbsp;stay</>,
  experiences: <>Elevate your&nbsp;adventure</>,
  guides: <>Discover your new favorite&nbsp;destination</>,
}

const BOOK_TITLE_BY_TYPE: Record<SearchType, React.ReactNode> = {
  all: <>Where are you headed&nbsp;next?</>,
  hotels: <>Find a great place to&nbsp;stay</>,
  experiences: <>Experience something new</>,
  guides: <>Discover your new favorite&nbsp;destination</>,
}

const PLACEHOLDER_BY_TYPE: Record<SearchType, string> = {
  all: 'Search by US destination',
  hotels: 'Search Hotels',
  experiences: 'Search Experiences',
  guides: 'Search Guides',
}

export const SearchHero = () => {
  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const productType: SearchType = (params?.type as SearchType) || 'all'

  const TITLE_DICTIONARY = enablePlanPage ? BOOK_TITLE_BY_TYPE : TITLE_BY_TYPE
  const title = TITLE_DICTIONARY[productType] || TITLE_DICTIONARY.all

  const placeholder =
    PLACEHOLDER_BY_TYPE[productType] || PLACEHOLDER_BY_TYPE.all
  const redirect = useNavigate()
  const geocoderOptions = enablePlanPage
    ? undefined
    : {
        requestOptions: { componentRestrictions: { country: [] } },
        cacheKey: CACHE_KEYS.withoutRestriction,
      }
  const { value, setValue, suggestions, ready } =
    useGeocoderSearch(geocoderOptions)

  const options = suggestions?.data || []
  const showOptions = Boolean(options?.length)
  const searchTerm = value?.trim()
  const showSearchTerm = productType === 'guides' && !!searchTerm

  const handleOptionSelection = async (selection: Suggestion | string) => {
    // get the selection info
    if (typeof selection === 'string') {
      const similarName = selection
      switch (productType) {
        case 'guides': {
          searchParams.set('displayName', similarName)
          searchParams.set('similarName', similarName)
          const url = `/guides/all/?${searchParams.toString()}`
          redirect(url)
          return
        }
      }
    } else {
      const [result] = await getGeocode({
        placeId: selection.place_id,
      })
      const { lat, lng } = getLatLng(result)

      switch (productType) {
        case 'all': {
          searchParams.set('displayName', result.formatted_address)
          searchParams.set('location', result.formatted_address)
          searchParams.set('latitude', lat.toString())
          searchParams.set('longitude', lng.toString())
          const url = `/destinations/results/?${searchParams.toString()}`
          redirect(url)
          return
        }
        case 'hotels': {
          const placeCountry =
            getGeocoderPlaceCountryFromAddressComponents(
              result.address_components
            ) ?? ''

          const placeFilteredType = getGeocoderPlaceTypeFromAddressComponents(
            result.address_components
          )

          // per logic in src/common/components/SearchHotels/searchHotelsUtils.ts#L47-L48
          // 'lodging' means hotel/"specific place". if hotel, omit place_id to trigger specificPlace search on Hotels page
          const placeId =
            (result.types?.includes('lodging') && result.place_id) || ''

          /** @todo how to preserve existing URL search params when using this util? eg. utm */
          const url = generateHotelResultsUrl({
            adults: 2,
            kids: 0,
            // arrival,
            // departure,
            location: selection.description,
            latitude: lat,
            longitude: lng,
            placeCountry,
            placeId,
            placeShortName: placeFilteredType?.shortName ?? '',
            placeType: placeFilteredType?.type ?? '',
            sort: !placeId ? SortBy.Recommended : SortBy.Distance,
            generateDefaultDates: false,
          })
          redirect(url)
          return
        }
        case 'experiences': {
          /** @todo how to preserve existing URL search params when using this util? eg. utm */
          const url = generateExperiencesResultsUrl({
            location: result.formatted_address,
            latitude: lat,
            longitude: lng,
            generateDefaultDates: false,
          })
          redirect(url)
          return
        }
        case 'guides': {
          const isLatLongSearch =
            !result.address_components ||
            !result.address_components?.length ||
            result.address_components?.some(component =>
              component.types.some(
                type => !COUNTRY_STATE_SEARCH_TYPES.has(type)
              )
            )

          if (!isLatLongSearch) {
            setSearchParams(sp => {
              const country = result.address_components?.find(
                addressComponent => addressComponent.types[0] === 'country'
              )?.long_name
              const state = result.address_components?.find(
                addressComponent =>
                  addressComponent.types[0] === 'administrative_area_level_1'
              )?.long_name

              country ? sp.set('country', country) : sp.delete('country')
              state ? sp.set('state', state) : sp.delete('state')

              sp.set('displayName', result.formatted_address)
              sp.set('location', result.formatted_address)
              sp.delete('latitude')
              sp.delete('longitude')
              sp.delete('similarName')
              return sp
            })
          }

          if (isLatLongSearch) {
            setSearchParams(sp => {
              sp.set('displayName', result.formatted_address)
              sp.set('location', result.formatted_address)
              sp.set('latitude', lat.toString())
              sp.set('longitude', lng.toString())
              sp.delete('similarName')
              sp.delete('country')
              sp.delete('state')
              return sp
            })
          }
          const url = `/guides/all/?${searchParams.toString()}`
          redirect(url)
          return
        }
      }
    }
  }

  const handleSearchTermEnter = e => {
    if (e.key === KeyCode.ENTER) {
      if (showSearchTerm) {
        handleOptionSelection(searchTerm)
      } else if (showOptions) {
        handleOptionSelection(options[0])
      }
    }
  }

  const handleSearchButtonClick = () => {
    if (showSearchTerm) {
      handleOptionSelection(searchTerm)
    } else if (showOptions) {
      handleOptionSelection(options[0])
    } else {
      document.getElementById('search-autocomplete-input')?.focus()
    }
  }

  return (
    <div className='bg-warm-grey lg:pt-25 pt-15'>
      <div className='mx-4 mx-auto flex flex-col items-center justify-center space-y-8'>
        <h1 className='type-h1 text-center capitalize' id='search-hero-title'>
          {title}
        </h1>
        <nav aria-label='Search by category' className='flex justify-center'>
          {enablePlanPage ? (
            <NavlinkTab end to={`/${pathSegment}`}>
              <Icon name='place' />
              Destinations
            </NavlinkTab>
          ) : (
            <NavlinkTab end to={`/${pathSegment}`}>
              All
            </NavlinkTab>
          )}
          <NavlinkTab to={`/${pathSegment}/hotels`}>
            <Icon name='bed' />
            Hotels
          </NavlinkTab>
          <NavlinkTab to={`/${pathSegment}/experiences`}>
            <Icon name='cameraAlt' />
            Experiences
          </NavlinkTab>
          {!enablePlanPage && (
            <NavlinkTab to={`/${pathSegment}/guides`}>
              <Icon name='mapOutlined' />
              Travel Guides
            </NavlinkTab>
          )}
        </nav>
        <div className='flex w-full max-w-xl items-center justify-stretch'>
          <div className='w-full'>
            <Autocomplete
              autoExpand
              fullWidth
              aria-describedby='search-hero-title'
              id='search-autocomplete-input'
              isDisabled={!ready}
              placeholder={placeholder}
              value={value}
              onChange={e => setValue(e.target.value)}
              onKeyDown={handleSearchTermEnter}
              onOptionSelect={handleOptionSelection}
            >
              {!enablePlanPage && showSearchTerm && (
                <AutocompleteOption
                  className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-2 bg-white px-4 py-1.5 text-left outline-none'
                  value={searchTerm}
                >
                  Search for &quot;{searchTerm}&quot;
                </AutocompleteOption>
              )}
              {showOptions &&
                options.map(option => (
                  <GeocoderOption key={option.place_id} option={option} />
                ))}
            </Autocomplete>
          </div>
          <span className='ml-2'>
            <IconButton
              color='valley'
              icon='search'
              onClick={handleSearchButtonClick}
            />
          </span>
        </div>
      </div>
    </div>
  )
}
