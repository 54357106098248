import { useEffect, useState } from 'react'
import {
  Button,
  Illustration,
  Input,
  Modal,
  ModalActions,
  ModalScrollContents,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import { Geocoder } from 'src/common/components'
import { useFlag, useGetCurrentUserId } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { getGuideDraftsForUserQuery } from 'src/pages/dashboard'
import { decodeGuideId, getGuideDraftDetailsUrl } from 'src/utils'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from '../guideDraftConstants'
import { getCreateGuideDraftVariables } from '../guideDraftUtils'
import { useCreateGuideDraftMutationOld } from '../useCreateGuideDraftMutationOld'

interface GuideDraftCreateModalProps {
  redirect?: boolean
  onDismiss?(): void
}

export const GuideDraftCreateModal = ({
  redirect,
  onDismiss,
}: GuideDraftCreateModalProps) => {
  const [createGuideDraft] = useCreateGuideDraftMutationOld()
  const { data: currentUserData } = useGetCurrentUserId()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)
  const [isNameInvalid, setIsNameInvalid] = useState(false)
  const [name, setName] = useState('')
  const [geocoder, setGeocoder] = useState(initialGeocoder)
  const [description, setDescription] = useState('')
  const userId = currentUserData?.currentUser?.id ?? ''

  useEffect(() => {
    pushDataToDataLayer('modalOpened', {
      modalType: 'published_guide',
    })
  }, [])

  const onGeocoderChange = (updatedGeocoder: GeocoderType) => {
    setGeocoder(updatedGeocoder)
  }

  const onSubmit = async () => {
    const validatedDescription = description?.trim() ?? null
    const validatedName = name?.trim()
    const updatedIsDescriptionInvalid =
      validatedDescription?.indexOf('http') !== -1
    const updatedIsNameInvalid = !validatedName
    setIsDescriptionInvalid(updatedIsDescriptionInvalid)
    setIsNameInvalid(updatedIsNameInvalid)

    if (!updatedIsDescriptionInvalid && !updatedIsNameInvalid) {
      try {
        const result = await createGuideDraft({
          variables: getCreateGuideDraftVariables({
            description: validatedDescription,
            geocoder,
            name: validatedName,
          }),
          refetchQueries: [
            {
              query: getGuideDraftsForUserQuery,
              variables: {
                userId,
              },
            },
          ],
        })
        addSuccessSnack({
          timeout: 1000,
          title: 'Guide created',
        })
        const { id, name: listGuideName } =
          result?.data?.createGuideDraft?.guideDraft ?? {}
        const guideIdDecoded = decodeGuideId(id)
        const guideUrl = getGuideDraftDetailsUrl({
          id: guideIdDecoded,
          name: listGuideName,
          isUserOwner: true,
        })
        if (redirect) {
          window.location.href = guideUrl
        } else {
          onDismiss()
          window.open(guideUrl, '_blank')
        }
      } catch (error) {
        console.error(error)
        addErrorSnack({
          timeout: 1000,
          title: 'Server error',
        })
      }
    }
  }

  return (
    <Modal size='medium' title='Create Your Guide' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='w-full space-y-4 p-8'>
          <Input
            aria-label='Add a name for your guide'
            errorText={isNameInvalid && 'Guide Name is required'}
            fullWidth={true}
            helperText={`${name.length}/${guideDraftNameMaxLength} characters`}
            isInvalid={isNameInvalid}
            label='Guide Name'
            maxLength={guideDraftNameMaxLength}
            placeholder='e.g., Best Restaurants in Seattle'
            required={true}
            onChange={event => setName(event.target.value)}
          />
          <Geocoder
            aria-label='Add a location for your Guide'
            config={{
              requestOptions: {
                componentRestrictions: {
                  country: null,
                },
              },
            }}
            focusOnInput={false}
            fullWidth={true}
            geocoder={geocoder}
            label='Location'
            placeholder='e.g., Salt Lake City, Utah'
            onResult={onGeocoderChange}
          />
          <p className='type-small-text-desktop'>
            Write a quick description about your recommendation guide and
            express your excitement to others. Let them know why it&apos;s got
            you excited!
          </p>
          <TextArea
            aria-label='Add a description for your Guide'
            errorText={
              isDescriptionInvalid && 'Guide Description can not have URLs'
            }
            helperText={`${description.length}/${guideDraftDescriptionMaxLength} characters`}
            maxLength={guideDraftDescriptionMaxLength}
            onInput={event => setDescription(event.currentTarget.value)}
          />
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button
          aria-label='Create Guide'
          autoFocus={true}
          size='large'
          onClick={onSubmit}
        >
          Create Guide
        </Button>
        <Button
          aria-label='Cancel guide creation'
          size='large'
          variant='outlined'
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
