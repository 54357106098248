import { Divider, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { Link } from 'react-router-dom'
import { PageLayoutContainer, TravelpassLogo } from 'src/common/components'
import { dashboardPath } from 'src/constants'
import { getGuideAddress } from 'src/utils'
import { GuideDraftTopNavMenu } from './GuideDraftTopNavMenu'
import type { GuideDraftData } from '../../types'

interface GuideDraftTopNavProps {
  guideDraftData: GuideDraftData
  isUserOwner: boolean
}

export const GuideDraftTopNavOld = ({
  guideDraftData,
  isUserOwner,
}: GuideDraftTopNavProps) => {
  const { name, addresses } = guideDraftData ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const location = getGuideAddress({
    city,
    country,
    state,
  })

  return (
    <div className='lg:z-4 bg-white lg:sticky lg:top-0'>
      <PageLayoutContainer size='none'>
        <div className='p-y-5 lg:p-y-3.5 flex flex-row items-center gap-8'>
          <div className='flex min-w-0 grow flex-row gap-4 lg:items-center lg:gap-3.5'>
            <Link to={dashboardPath}>
              <TravelpassLogo
                className='h-7 w-7 lg:h-8 lg:w-8'
                variant='symbol-valley'
              />
            </Link>
            <div className='flex grow flex-col gap-2 lg:flex-row lg:gap-6'>
              <span className='type-h3 line-clamp-1'>{name}</span>
              <div className='flex flex-row items-center gap-6'>
                {location && (
                  <p className='color-grey-800 type-body-1-desktop type-body-2-mobile flex flex-row items-center gap-1'>
                    <Icon name='placeOutline' size='small' />
                    <span className='text-nowrap'>{location}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
          {!isEmpty(guideDraftData) && (
            <GuideDraftTopNavMenu
              guideDraftData={guideDraftData}
              isUserOwner={isUserOwner}
            />
          )}
        </div>
      </PageLayoutContainer>
      <Divider className='bg-grey-200 h-1px w-full' />
    </div>
  )
}
