import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetFeedFromCurrentUserQueryInDashboardQuery } from 'src/__generated__/graphql'
import type { DashboardFeedItem } from './types'

/** @todo replace insertedAt/updatedAt with relevantDate when BE is ready */
export const getFeedFromCurrentUserQuery = gql(`
  query GetFeedFromCurrentUserQueryInDashboard {
    currentUser {
      id
      activityFeed(feedCount: 100) {
        guideItem {
          groupedUpdate
          guide {
            id
            addresses {
              id
              city
              country
              state
            }
            description
            eventCounts {
              numberEvents
            }
            insertedAt
            images {
              id
              source
              url
            }
            imageUrl
            latestUpdates {
              newEvents {
                events {
                  id
                  images {
                    id
                    source
                    url
                  }
                  imageUrl
                  name
                }
                eventsCount
              }
              newImages {
                images {
                  id
                  source
                  url
                }
                imagesCount
              }
            }
            name
            ownerProfile {
              id
              accountHandle
              displayName
              isFollowed
              profileImageUrl
              userId
            }
            updatedAt
            timeZone
          }
          relevantDatetime
          type
        }
        title
      }
      userProfile {
        id
        userFollowRecommendations {
          id
          accountHandle
          displayName
          followerFollowingCount {
            countOfFollowers
          }
          guideCount
          isFollowed
          profileImageUrl
          userId
        }
        guideRecommendations(numberOfGuides: 50) {
          id
          addresses {
            id
            city
            country
            state
          }
          images {
            id
            url
          }
          imageUrl
          name
          ownerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
      }
    }
    officialTravelpassGuides(first: 50) {
      edges {
        node {
          id
          addresses {
            id
            city
            country
            state
          }
          images {
            id
            url
          }
          imageUrl
          name
          ownerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`)

type UseGetDashboardFeedQuery = {
  dashboardFeedData: DashboardFeedItem[]
  dashboardFeedSuggestedFollowersData: GetFeedFromCurrentUserQueryInDashboardQuery['currentUser']['userProfile']['userFollowRecommendations']
  dashboardFeedSuggestedGuidesData: GetFeedFromCurrentUserQueryInDashboardQuery['currentUser']['userProfile']['guideRecommendations']
  dashboardFeedSuggestedTravelpassGuidesData: GetFeedFromCurrentUserQueryInDashboardQuery['officialTravelpassGuides']['edges']
  hasError: ApolloError
  isLoading: boolean
}

const useGetDashboardFeedQuery = (): UseGetDashboardFeedQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(getFeedFromCurrentUserQuery)
  const dashboardFeedData =
    data?.currentUser?.activityFeed?.reduce((total, current) => {
      if (current?.guideItem?.guide?.id && total?.length < 20)
        total.push(current?.guideItem)

      return total
    }, [] as DashboardFeedItem[]) ?? []
  const dashboardFeedSuggestedFollowersData =
    data?.currentUser?.userProfile?.userFollowRecommendations
  const dashboardFeedSuggestedGuidesData =
    data?.currentUser?.userProfile?.guideRecommendations
  const dashboardFeedSuggestedTravelpassGuidesData =
    data?.officialTravelpassGuides?.edges

  return {
    dashboardFeedData,
    dashboardFeedSuggestedFollowersData,
    dashboardFeedSuggestedGuidesData,
    dashboardFeedSuggestedTravelpassGuidesData,
    hasError,
    isLoading,
  }
}

export type { UseGetDashboardFeedQuery }
export { useGetDashboardFeedQuery }
