import { Icon, useScreenQuery } from '@travelpass/design-system'
import { Link } from 'react-router-dom'
import { ButtonLink } from 'src/common/components'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'
import { COMPETITION_IMAGE_SOURCE_PREFIX } from './competitionConstants'
import { useGetCurrentUserCompetitionInfo } from './hooks/useGetCurrentUserCompetitionInfo'
import { hasUserEnteredCompetition } from './utils'

const TimelineCard = props => {
  return (
    <div
      {...props}
      className={`grid-col-span-1 b-t-4 b-t-solid flex flex-col items-center gap-2 rounded bg-white px-3 py-10 text-center shadow max-md:space-y-6 md:px-6 ${props?.className || ''}`}
    >
      {props?.children}
    </div>
  )
}

export const About = () => {
  const { data } = useGetCurrentUserCompetitionInfo()
  const { isMobileScreen, isTabletScreen } = useScreenQuery()

  const info = data?.currentUser?.userProfile?.competitionInfo
  const hasEnteredCompetition = hasUserEnteredCompetition(info?.status)

  const getBannerImageSource = () => {
    if (isMobileScreen) return '/about-company-mobile.webp'
    if (isTabletScreen) return '/about-company-tablet.webp'
    return '/about-company-desktop.webp'
  }

  const getCoupleImageSource = () => {
    if (isMobileScreen) return '/about-couple-mobile.webp'
    if (isTabletScreen) return '/about-couple-tablet.webp'
    return '/about-couple-desktop.webp'
  }

  return (
    <div className='lg:px-30 space-y-12 px-6 lg:space-y-20'>
      <section
        className='lg:gap-25 flex flex-col items-center gap-8 md:flex-row md:gap-10'
        id='contest-summary'
      >
        <img
          alt=''
          className='max-w-465px max-h-574px max-md:hidden'
          loading='lazy'
          src={COMPETITION_IMAGE_SOURCE_PREFIX + getCoupleImageSource()}
        />
        <div className='[&_i]:c-forest-light space-y-6 lg:space-y-8'>
          <h1 className='c-forest text-8 lg:text-9'>
            Win $20,000 For Your Dream Vacation
          </h1>
          <section className='space-y-2 lg:space-y-4'>
            <p className='type-body-1'>
              Enter in just three easy steps and start dreaming about your epic
              adventure. To enter:
            </p>
            <p className='flex flex-row items-center gap-x-2'>
              <Icon name='personAltOutline' />
              <span className='type-body-1-medium'>
                Log In And Complete Your Travelpass Profile
              </span>
            </p>

            <p className='flex flex-row items-center gap-x-2'>
              <Icon name='luggage' />
              <span className='type-body-1-medium'>
                Create Three Travel Guides
              </span>
            </p>

            <p className='flex flex-row items-center gap-x-2'>
              <Icon name='thumbUpOffAlt' />
              <span className='type-body-1-medium'>
                Share Your Profile For Votes
              </span>
            </p>

            <p className='type-body-1'>
              Whether it&apos;s traveling around Europe, relaxing on the
              beautiful islands of Bora Bora, or another adventure, your dream
              vacation is waiting for you. Because votes are what ultimately
              determine the winner, be sure to enter early and share your
              profile for the best chance at winning this once-in-a-lifetime
              prize.
            </p>
          </section>
          <section className='mx-auto flex flex-col items-center space-y-6 md:items-start lg:space-y-8'>
            {hasEnteredCompetition ? (
              <ButtonLink
                to='/dashboard?tab=guides&collapsed=false'
                variant='forest'
              >
                Check My Progress
              </ButtonLink>
            ) : (
              <ButtonLink to='/competition/apply' variant='forest'>
                Enter to win
              </ButtonLink>
            )}
            <Link
              className='c-forest-light type-button inline-flex items-center gap-2 no-underline hover:underline'
              state={{ preventScroll: true }}
              to='../leaderboard?sort=POPULAR'
            >
              See Others In The Running
              <Icon name='arrowForward' />
            </Link>
          </section>
        </div>
      </section>

      <section className='mx-auto mt-16 md:mt-32' id='contest-timeline'>
        <div className='lg:space-y-19 md:space-y-9'>
          <h2 className='text-8 lg:text-9 c-forest text-center'>
            Contest Timeline
          </h2>
          <div className='grid grid-cols-1 gap-6 md:gap-9 lg:grid-cols-3'>
            <TimelineCard className='b-t-orangeMedium'>
              <header className='mb-4 space-y-2'>
                <h4 className='c-orangeMedium m-0 text-3xl font-bold'>
                  Round 1
                </h4>
                <p className='type-overline'>Oct. 16 - Nov. 8</p>
              </header>
              <section className='lg:justify-unset flex h-full w-full flex-col items-center space-y-6 md:flex-row lg:flex-col'>
                <div className='h-50 md:w-full'>
                  <img
                    alt='graphic of man completing tasks'
                    className='md:px-23% block h-full max-h-full w-full max-w-full object-contain px-2.5 py-6 lg:px-2.5'
                    loading='lazy'
                    src={COMPETITION_IMAGE_SOURCE_PREFIX + '/choose-topic.svg'}
                  />
                </div>
                <div className='md:min-w-350px lg:min-w-unset space-y-3 [align-content:_center]'>
                  <h3 className='type-h3'>Enter To Win</h3>
                  <ul className='c-grey-800 type-body-1 m-0 list-none space-y-2 p-0'>
                    <li>Complete your Travelpass profile</li>
                    <li>(Add a photo, bio, and link a travel video)</li>
                    <li>Create three high-quality Travelpass Guides</li>
                    <li>Enter before Nov. 1, collect votes until Nov. 8</li>
                  </ul>
                </div>
              </section>
            </TimelineCard>
            <TimelineCard className='b-t-forest-light'>
              <header className='mb-4 space-y-2'>
                <h4 className='c-forest-light m-0 text-3xl font-bold'>
                  Round 2
                </h4>
                <p className='type-overline'>Nov. 11 - Nov. 21</p>
              </header>
              <section className='lg:justify-unset flex h-full w-full flex-col items-center space-y-6 md:flex-row lg:flex-col'>
                <div className='h-50 md:w-full'>
                  <img
                    alt='graphic of people in a meeting'
                    className='md:px-23% block h-full max-h-full w-full max-w-full object-contain px-2.5 py-4 lg:px-2.5'
                    loading='lazy'
                    src={
                      COMPETITION_IMAGE_SOURCE_PREFIX + '/prove-yourself.svg'
                    }
                  />
                </div>
                <div className='md:min-w-350px lg:min-w-unset space-y-3 [align-content:_center]'>
                  <h3 className='type-h3'>The Top 20</h3>
                  <ul className='c-grey-800 type-body-1 m-0 list-none space-y-2 p-0'>
                    <li>
                      Based on total votes, 20 profiles advance from Round 1 to
                      Round 2
                    </li>
                    <li>Vote count resets at the start of the round</li>
                    <li>
                      Collect votes by sharing your profile between Nov. 11 -
                      Nov. 21
                    </li>
                  </ul>
                </div>
              </section>
            </TimelineCard>
            <TimelineCard className='b-t-valleyDark'>
              <header className='mb-4 space-y-2'>
                <h4 className='c-valleyDark m-0 text-3xl font-bold'>
                  The Final Step
                </h4>
                <p className='type-overline'>Nov. 22</p>
              </header>
              <section className='lg:justify-unset flex h-full w-full flex-col items-center space-y-6 md:flex-row lg:flex-col'>
                <div className='h-50 [align-content:_center] md:w-full'>
                  <img
                    alt='graphic of an airplane'
                    className='md:px-8% block h-full max-h-full w-full max-w-full object-contain px-2.5 py-8 lg:px-2.5'
                    loading='lazy'
                    src={COMPETITION_IMAGE_SOURCE_PREFIX + '/airplane.svg'}
                  />
                </div>
                <div className='md:min-w-350px lg:min-w-unset space-y-3 text-center [align-content:_center]'>
                  <h3 className='type-h3'>Winner Announced!</h3>
                  <ul className='c-grey-800 type-body-1 m-0 list-none space-y-2 p-0'>
                    <li>
                      The top profile with the most votes will win the grand
                      prize.
                    </li>
                  </ul>
                </div>
              </section>
            </TimelineCard>
          </div>
        </div>
      </section>

      <img
        alt='scrapbook-style travel polaroid photos'
        className='mx-auto block max-w-full'
        src={
          isMobileScreen
            ? COMPETITION_IMAGE_SOURCE_PREFIX + '/about-trip-mobile.webp'
            : COMPETITION_IMAGE_SOURCE_PREFIX + '/about-trip.webp'
        }
      />

      <section
        className='mb-3 space-y-10 py-6 md:space-y-8 md:px-12 lg:space-y-10 lg:px-0 lg:py-0'
        id='contest-rules'
      >
        <h2 className='text-8 lg:text-9 c-forest text-center'>Contest Rules</h2>
        <div className='type-body-1 flex flex-col gap-10 md:flex-row'>
          <section className='space-y-10 md:basis-1/2'>
            <div className='flex items-start'>
              <span className='c-orange font-archivo bg-orange/20 mr-6 h-10 rounded-full p-2'>
                <Icon name='personAltOutline' />
              </span>
              <div>
                <h4 className='type-h3 mb-4'>Eligibility</h4>
                <p>You must be 18 years old or older to enter.</p>
              </div>
            </div>
            <div className='flex items-start'>
              <span className='c-orange font-archivo bg-orange/20 mr-6 h-10 rounded-full p-2'>
                <Icon name='eventAvailable' />
              </span>
              <div>
                <h4 className='type-h3 mb-4'>Submission Deadline</h4>
                <p>
                  All Round 1 entries must be submitted by 11:59 p.m., MST, Nov.
                  1, 2024.<br></br> Round 1 voting concludes at 11:59 p.m., MST,
                  Nov. 8, 2024.<br></br>Round 2 voting concludes at 11:59 p.m.,
                  MST, Nov. 21, 2024.
                </p>
              </div>
            </div>
          </section>
          <section className='space-y-10 md:basis-1/2'>
            <div className='flex items-start'>
              <span className='c-orange font-archivo bg-orange/20 mr-6 h-10 rounded-full p-2'>
                <Icon name='balance' />
              </span>
              <div>
                <h4 className='type-h3 mb-4'>Judging Criteria</h4>
                <p>
                  After Round 2 voting concludes, the profile with the most
                  votes will win the $20,000 prize. Please see Terms and
                  Conditions for full details.
                </p>
              </div>
            </div>
            <div className='flex items-start'>
              <span className='c-orange font-archivo bg-orange/20 mr-6 h-10 rounded-full p-2'>
                <Icon name='thumbUpOffAlt' />
              </span>
              <div>
                <h4 className='type-h3 mb-4'>Winner Announcement</h4>
                <p>
                  The contest winner will be notified within three days of Nov.
                  22, 2024.
                </p>
              </div>
            </div>
          </section>
        </div>
        <section className='flex flex-col items-center justify-center gap-3 lg:pt-10'>
          {hasEnteredCompetition ? (
            <ButtonLink
              to='/dashboard?tab=guides&collapsed=false'
              variant='forest'
            >
              Check My Progress
            </ButtonLink>
          ) : (
            <ButtonLink to='/competition/apply' variant='forest'>
              Enter to win
            </ButtonLink>
          )}
          <TermsAndConditionsModal />
        </section>
      </section>

      <section className='lg:pb-13 w-full max-md:py-6 lg:pt-7' id='info-banner'>
        <img
          alt='travelers enjoying various activities'
          className='mx-auto block max-w-full'
          src={COMPETITION_IMAGE_SOURCE_PREFIX + getBannerImageSource()}
        />
      </section>
    </div>
  )
}
