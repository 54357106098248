import type { LatLong } from 'src/__generated__/graphql'
import { constructMapCenter } from 'src/common/components/Map'
import { guideMock } from './guideMock'

const { lat, lng } = constructMapCenter(
  guideMock.addresses[0].lat,
  guideMock.addresses[0].long
)

export const guideLocationMock: LatLong = {
  latitude: lat,
  longitude: lng,
}
