import { useState } from 'react'
import { Button, Divider, ModalNext } from '@travelpass/design-system'

interface TermsAndConditionsModalProps {
  controlledByParent?: boolean
  onClose?: VoidFunction
}

export const TermsAndConditionsModal = ({
  controlledByParent = false,
  onClose = null,
}: TermsAndConditionsModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {!controlledByParent && (
        <Button variant='text' onClick={() => setIsOpen(true)}>
          See Terms And Conditions
        </Button>
      )}
      {(controlledByParent || isOpen) && (
        <ModalNext
          size='large'
          title='Travelpass Dream Vacation Contest Terms and Conditions'
          onClose={() => (onClose ? onClose() : setIsOpen(false))}
        >
          <ul className='list-none space-y-4'>
            <h2>1. Eligibility</h2>
            <li>
              1.1. The contest is open to entrants who are at least 18 years old
              at the time of entry.
            </li>
            <li>
              1.2. Employees, officers, directors, agents, and representatives
              of Travelpass Group and its parent companies, subsidiaries,
              affiliates, and advertising agencies, and their immediate family
              members (spouse, parents, siblings, and children){' '}
              <span className='underline'>
                <strong>are</strong>
              </span>{' '}
              eligible to participate.
            </li>
            <li>1.3. The contest is void where prohibited by law.</li>
            <h2>2. How to Enter</h2>
            <li>2.1. No purchase is necessary to enter or win.</li>
            <li>
              2.2. To enter, visit Travelpass.com directly or through a referral
              link provided by a Travelpass Group employee. Complete the
              following requirements with all required information.
            </li>
            <ul className='list-none space-y-2'>
              <li>
                2.2.1. Create a profile on Travelpass.com, including name,
                profile picture and bio
              </li>
              <li>
                2.2.2. Create at least 1 guide for a destination of your
                choosing that meets the following minimum requirements
              </li>
              <ol className='space-y-2' type='i'>
                <li>Written guide description</li>
                <li>Minimum of 5 events included</li>
                <li>Minimum of 5 personalized notes included on events</li>
                <li>At least 1 personal photo added to the guide</li>
              </ol>
              <li>
                2.2.3. Link a video from YouTube to your Travelpass profile.
                Video content may include:
              </li>
              <ol className='space-y-2' type='i'>
                <li>Video of yourself describing your dream vacation</li>
                <li>Public video posted on YouTube related to travel</li>
              </ol>
            </ul>
            <li>
              2.3. Limit one entry per person. Multiple entries from the same
              person will be disqualified.
            </li>
            <li>
              2.4. All entries must be received by 11:59 PM MST on Friday,
              November 1, 2024 to be eligible to win
            </li>
            <li>
              2.5. Votes for a profile during round 1 will be allowed until
              11:59 PM MST on Friday, November 8, 2024
            </li>
            <li>
              2.6. The top 25 point earners from round 1 will move on to round 2
            </li>
            <ul className='list-none space-y-2'>
              <li>
                2.6.1. Round 2 will be a live event where the top 25 point
                earners will be interviewed by Travelpass Group employees
              </li>
              <li>
                2.6.2. The top 5 point earners from round 2 will move on to
                round 3
              </li>
              <li>
                2.6.3. Voters may vote for any profiles once in a 24 hour period
                (as measured by IP)
              </li>
              <li>
                2.6.4. Voting for round 2 ends at 11:59pm on Thursday, November
                21, 2024
              </li>
            </ul>
            <li>
              2.7. The top 10 vote earners will be moved to a final drawing
            </li>
            <ul className='list-none space-y-2'>
              <li>
                2.7.1. A random drawing will be conducted from the top 10
                candidates to determine the winner of the grand prize on Friday,
                November 22, 2024
              </li>
            </ul>
            <h2>3. Grand Prize</h2>
            <li>
              3.1. One (1) grand prize will be awarded: a trip valued at $20,000
              USD.
            </li>
            <li>
              3.2. The prize value may be used towards a single vacation package
              to include airfare, accommodations, transfers, activities or any
              other reasonable travel-related expenses.{' '}
            </li>
            <li>
              3.3. The prize must be accepted as awarded and cannot be
              transferred, substituted, or redeemed for cash.
            </li>
            <li>
              3.4. Travelpass Group reserves the right to act as the fulfilling
              seller of any travel bookings available through its platforms.
            </li>
            <li>
              3.5 Any expenses above the maximum prize value shall be the
              responsibility of the winner.
            </li>
            <h2>4. Other Prizes</h2>
            <li>
              4.1. One (1) $5,000 travel credit referral prize will be awarded
              to the Travelpass Group employee who refers the most entrants to
              this contest.
            </li>
            <ul className='list-none space-y-2'>
              <li>
                4.1.1. A referral is successful and counts towards the referral
                prize tracking only when the referred person completes all the
                steps to enter to win.
              </li>
              <li>
                4.1.2. Travelpass Group will provide tracking to inform
                employees how many successful referrals they have made as part
                of this contest.
              </li>
              <li>
                4.1.3. The prize value may be used towards a single vacation
                package to include airfare, accommodations, transfers and
                activities.
              </li>
              <li>
                4.1.4. The prize must be accepted as awarded and cannot be
                transferred, substituted, or redeemed for cash.
              </li>
              <li>
                4.1.5. Travelpass Group reserves the right to act as the
                fulfilling seller of any travel bookings available through its
                platforms
              </li>
              <li>
                4.1.6. Any expenses above the maximum prize value shall be the
                responsibility of the winner.
              </li>
            </ul>
            <li>
              4.2. Travelpass Group will provide additional smaller prizes as
              part of weekly drawings for employees of Travelpass Group.
            </li>
            <ul className='list-none space-y-2'>
              <li>
                4.2.1. Employees receive 1 entry into the weekly drawing for
                each successful referral of a contact that completes the
                requirements to enter to win.
              </li>
              <li>
                4.2.2. The prize must be accepted as awarded and cannot be
                transferred, substituted, or redeemed for cash.
              </li>
            </ul>
            <h2>5. Winner Selection</h2>
            <li>
              5.1. The grand prize winner will be selected in a random drawing
              from the top 10 vote earners from round 2
            </li>
            <li>
              5.2. The drawing will be conducted on November 22, 2024, and the
              winner will be notified via email or phone within 3 days of the
              drawing.
            </li>
            <li>
              5.3. If the selected winner does not respond within 3 days of
              notification, an alternate winner may be selected.
            </li>
            <h2>6. Winner Responsibilities</h2>
            <li>
              6.1. The winner is responsible for any applicable federal, state,
              and local taxes, as well as any other costs or expenses not
              specified as part of the prize.
            </li>
            <li>
              6.2. The winner must provide all necessary documentation,
              including identification and a signed affidavit of eligibility and
              release, before the prize is awarded.
            </li>
            <li>
              6.3. The winner agrees to participate in promotional activities as
              required by Travelpass Group, including but not limited to
              providing photos and a testimonial.
            </li>
            <h2>7. General Conditions</h2>
            <li>
              7.1. Travelpass Group reserves the right to cancel, suspend, or
              modify the contest if any fraud, technical failure, or other
              factor beyond its control impairs the integrity or proper
              functioning of the contest, as determined by Travelpass Group in
              its sole discretion.
            </li>
            <li>
              7.2. Travelpass Group is not responsible for any entry that is
              lost, late, misdirected, incomplete, or corrupted.
            </li>
            <li>
              7.3. By entering the contest, participants agree to release and
              hold harmless Travelpass Group, its affiliates, and their
              respective officers, directors, employees, and agents from any
              liability arising from participation in the contest or the
              acceptance or use of the prize.
            </li>
            <h2>8. Privacy</h2>
            <li>
              8.1. Information collected from participants is subject to
              Travelpass Group’s privacy policy, available at
              [Travelpass.com/privacy].
            </li>
            <h2>9. Governing Law</h2>
            <li>
              9.1. The contest is governed by the laws of Utah, USA, without
              regard to its conflict of laws principles.
            </li>
            <li>
              9.2. Any disputes arising from or related to the contest shall be
              resolved in the courts located in South Jordan, Utah.
            </li>
            <h2>10. Sponsor</h2>
            <li>
              10.1. The contest is sponsored by Travelpass Group 4700 W Daybreak
              Village Pkwy, Ste 100, South Jordan, UT 84009.
            </li>
            <li>
              10.2. For any questions or concerns regarding the contest, please
              contact cmeyerson@travelpassgroup.com
            </li>
            <Divider />
            <p>
              By entering this contest, participants agree to abide by these
              terms and conditions.
            </p>
          </ul>
        </ModalNext>
      )}
    </>
  )
}
