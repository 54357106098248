import isEmpty from 'lodash.isempty'
import type { ExperienceFilters } from 'src/__generated__/graphql'
import { ExperienceTimeRange } from 'src/__generated__/graphql'
import { ExperienceSearchParam } from 'src/pages/experiences/results'
import { initialMaxScore, initialMinScore } from './filtersAndSortConstants'

export const constructTimeOfDay = (filters: ExperienceFilters) => {
  const { timeOfDay } = filters ?? {}

  const isTimeInclude = (time: ExperienceTimeRange) => {
    if (isEmpty(timeOfDay)) {
      return time === ExperienceTimeRange.Anytime
    }
    return timeOfDay?.includes(time)
  }

  return [
    {
      id: ExperienceTimeRange.Anytime,
      isChecked: isTimeInclude(ExperienceTimeRange.Anytime),
      label: 'Anytime',
    },
    {
      id: ExperienceTimeRange.Morning,
      isChecked: isTimeInclude(ExperienceTimeRange.Morning),
      label: 'Morning (6am to 12pm)',
    },
    {
      id: ExperienceTimeRange.Afternoon,
      isChecked: isTimeInclude(ExperienceTimeRange.Afternoon),
      label: 'Afternoon (12pm to 5pm)',
    },
    {
      id: ExperienceTimeRange.Evening,
      isChecked: isTimeInclude(ExperienceTimeRange.Evening),
      label: 'Evening (5pm to 12am)',
    },
  ]
}

export const updatedTimeOfDay = ({ id, checked, timeOfDay }) => {
  timeOfDay = timeOfDay || []

  if (checked) {
    timeOfDay.push(id)
  } else {
    timeOfDay = timeOfDay.filter(time => time !== id)
  }

  return timeOfDay.length >= 3 ? [] : timeOfDay
}

enum ExperienceDuration {
  Anytime = 'Anytime',
  UpToOneHr = 'Up to 1 hr',
  OneToFourHrs = '1 to 4 hrs',
  FourToTenHrs = '4 to 10 hrs',
  TenHrsToOneDay = '10 hrs to 1 day',
}

export const constructDuration = (filters: ExperienceFilters) => {
  const { duration } = filters ?? {}
  const { minHours, maxHours } = duration ?? {}

  const isDurationInclude = ({ min, max }) => {
    if (isEmpty(duration) || (minHours === 0 && maxHours === 24)) {
      return min === 0 && max === 24
    }
    return minHours <= min && maxHours >= max
  }

  return [
    {
      id: ExperienceDuration.Anytime,
      isChecked: isDurationInclude({ min: 0, max: 24 }),
      label: 'Anytime',
    },
    {
      id: ExperienceDuration.UpToOneHr,
      isChecked: isDurationInclude({ min: 0, max: 1 }),
      label: 'Up to 1 hr',
    },
    {
      id: ExperienceDuration.OneToFourHrs,
      isChecked: isDurationInclude({ min: 1, max: 4 }),
      label: '1 to 4 hrs',
    },
    {
      id: ExperienceDuration.FourToTenHrs,
      isChecked: isDurationInclude({ min: 4, max: 10 }),
      label: '4 to 10 hrs',
    },
    {
      id: ExperienceDuration.TenHrsToOneDay,
      isChecked: isDurationInclude({ min: 10, max: 24 }),
      label: '10 hrs to 1 day',
    },
  ]
}

export const getDuration = ({ id, checked, minHours, maxHours }) => {
  const durationMap = {
    [ExperienceDuration.Anytime]: { minHours: 0, maxHours: 24 },
    [ExperienceDuration.UpToOneHr]: { minHours: 0, maxHours: 1 },
    [ExperienceDuration.OneToFourHrs]: { minHours: 1, maxHours: 4 },
    [ExperienceDuration.FourToTenHrs]: { minHours: 4, maxHours: 10 },
    [ExperienceDuration.TenHrsToOneDay]: { minHours: 10, maxHours: 24 },
  }

  const getValidMinHours = threshold => {
    return minHours < threshold ? minHours : threshold
  }

  const getValidMaxHours = threshold => {
    return maxHours > threshold ? maxHours : threshold
  }

  if (checked) {
    if (
      id !== ExperienceDuration.Anytime &&
      minHours === 0 &&
      maxHours === 24
    ) {
      return durationMap[id]
    }

    durationMap[id].minHours = getValidMinHours(durationMap[id].minHours)
    durationMap[id].maxHours = getValidMaxHours(durationMap[id].maxHours)

    return durationMap[id]
  } else {
    const result = Object.values(ExperienceDuration).reduce(
      (acc, durationId) => {
        if (
          durationId !== id &&
          durationMap[durationId].minHours >= minHours &&
          durationMap[durationId].maxHours <= maxHours
        ) {
          const duration = durationMap[durationId]
          return {
            durations: [...acc.durations, duration],
            minHours:
              acc.minHours === null
                ? duration.minHours
                : Math.min(acc.minHours, duration.minHours),
            maxHours:
              acc.maxHours === null
                ? duration.maxHours
                : Math.max(acc.maxHours, duration.maxHours),
          }
        }
        return acc
      },
      {
        durations: [],
        minHours: null,
        maxHours: null,
      }
    )

    return result.durations.length === 0
      ? durationMap[ExperienceDuration.Anytime]
      : { minHours: result.minHours, maxHours: result.maxHours }
  }
}

export const constructResultsFiltersArguments = (
  searchParams?: URLSearchParams
): ExperienceFilters => {
  const additionalArguments = {}

  const customerReviewScore = searchParams?.get(
    ExperienceSearchParam.customerReviewScore
  )
  const duration = searchParams?.get(ExperienceSearchParam.duration)
  const priceRange = searchParams?.get(ExperienceSearchParam.priceRange)
  const tags = searchParams?.get(ExperienceSearchParam.tags)
  const timeOfDay = searchParams?.get(ExperienceSearchParam.timeOfDay)

  const updatedCustomerReviewScore = customerReviewScore
    ? JSON.parse(customerReviewScore)
    : {}

  if (priceRange) additionalArguments['priceRange'] = JSON.parse(priceRange)
  if (duration) additionalArguments['duration'] = JSON.parse(duration)
  if (tags) additionalArguments['tags'] = JSON.parse(tags)
  if (timeOfDay) additionalArguments['timeOfDay'] = JSON.parse(timeOfDay)
  if (customerReviewScore) {
    additionalArguments['customerReviewScore'] = {
      maxScore: initialMaxScore,
      minScore: updatedCustomerReviewScore?.minScore ?? initialMinScore,
    }
  }

  return additionalArguments
}
