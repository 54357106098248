import { Button, useSnackbar } from '@travelpass/design-system'
import { UserRole } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { copyToClipboard } from 'src/utils'

interface CopyReferralLinkButtonProps {
  userRoles: UserRole[]
  id: string
}

export const CopyReferralLinkButton = ({
  userRoles,
  id,
}: CopyReferralLinkButtonProps) => {
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const { addSuccessSnack } = useSnackbar()

  const onCopyReferralLink = () => {
    copyToClipboard(`${window.location.origin}/competition/about?referer=${id}`)
    addSuccessSnack({ title: 'Referral link copied' })
  }

  if (!userRoles?.includes(UserRole.Employee) || !enableCompetition2024)
    return null

  return (
    <Button
      fullWidth
      label='Copy Referral Link'
      size='small'
      startIcon='linkDiagonal'
      onClick={onCopyReferralLink}
    />
  )
}
