import { useEffect } from 'react'
import {
  Button,
  Icon,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useParams, useSearchParams } from 'react-router-dom'
import type { GetUserCompetitionInfoQuery } from 'src/__generated__/graphql'
import { ParticipantStatus } from 'src/__generated__/graphql'
import { ButtonLink } from 'src/common/components'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { useUserProfileQuery } from 'src/common/hooks/useUserProfileQuery'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { VoteModalListener } from 'src/pages/competition/VoteModalListener'
import { dispatchToggleVoteModal } from 'src/pages/competition/dispatchToggleVoteModal'
import { useCreateVoteMutation } from 'src/pages/competition/hooks/useCreateVoteMutation'
import { getTracker } from 'src/utils'
import { useGetUserCompetitionInfo } from './useGetUserCompetitionInfo'

type UserProfileSubfields = GetUserCompetitionInfoQuery['viewUserProfile']

export const CompetitionVoteCta = () => {
  const { isAnonymous, email } = useFirebaseUser()
  const { accountHandle } = useParams()
  const { isDesktopScreen } = useScreenQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const { addErrorSnack } = useSnackbar()

  const { data: contestantData } = useGetUserCompetitionInfo({
    accountHandle,
  })
  const { data: currentUserData } = useUserProfileQuery()
  const [createVote] = useCreateVoteMutation()

  /*using search params to hold state because this component is getting its state reset when the parent component re-renders
    see https://github.com/travelpassgroup/travelpass.com/blob/ebd7d7314da2afc89ac3f35ae35e8c22ee6bed16/app/src/pages/profile/Profile.tsx#L57-L58 */
  const deferVote = searchParams.get('deferVote')
  const profile = contestantData?.viewUserProfile
  const { competitionInfo, displayName, isUserOwner } = profile ?? {}
  const { canVoteLeaderboard, status, voteCount } = competitionInfo ?? {}
  const isReady = status === ParticipantStatus.Ready
  const showCta =
    isReady || (status === ParticipantStatus.Entered && isUserOwner)

  /**@desc trigger the vote if the user had clicked "Vote" before signing in */
  useEffect(() => {
    if (deferVote === 'true' && !isAnonymous && profile?.id) {
      triggerVote(profile)
      setSearchParams(sp => {
        sp.delete('deferVote')
        return sp
      })
    }
  }, [deferVote, isAnonymous, profile?.id])

  const triggerVote = async (userProfile: UserProfileSubfields) => {
    try {
      const response = await createVote({
        variables: {
          input: {
            tracker: getTracker('tracker'),
            userProfileId: userProfile.id,
          },
        },
      })
      if (response?.data) {
        pushDataToDataLayer('vote_competition', {
          user_id: currentUserData?.currentUser?.id,
          voted_user: {
            user_id: userProfile?.userId,
            user_name: userProfile?.displayName,
            user_account_handle: userProfile?.accountHandle,
          },
          user_email: email,
          timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
        })
        dispatchToggleVoteModal(userProfile)
      }
      return response
    } catch {
      addErrorSnack({
        title: 'Error',
        subTitle:
          "Unable to capture your vote. If you're using a VPN, please try turning off your VPN and voting again.",
      })
    }
  }

  const onVoteClick = (userProfile: UserProfileSubfields) => {
    if (isAnonymous) {
      setSearchParams(sp => {
        sp.set('deferVote', 'true')
        return sp
      })
      dispatch('openSignin', {
        isCreateAccount: true,
      })
    } else {
      triggerVote(userProfile)
    }
  }

  if (!showCta) return null

  return (
    <>
      <div className='bg-warm-grey job-promotion max-h-190px b-1px b-solid b-beachDark bg-bottom-right relative flex flex-col gap-5 rounded-lg bg-[url(https://static.travelpass.com/assets/job-campaign-2024/cta-squiggle-bottom-mobile.webp)] bg-no-repeat md:flex-row md:justify-center md:bg-[url(https://static.travelpass.com/assets/job-campaign-2024/cta-squiggle-bottom.webp)] lg:ml-0 lg:items-center'>
        <img
          alt=''
          className='absolute left-0 top-0 hidden md:block'
          src='https://static.travelpass.com/assets/job-campaign-2024/cta-squiggle-top.webp'
        />
        <section className='of-y-hidden flex flex-col items-center justify-center space-y-3 bg-no-repeat px-6 pb-7 pt-12 max-md:bg-[url(https://static.travelpass.com/assets/job-campaign-2024/cta-squiggle-top-mobile.webp)] md:items-start md:pl-12 md:pr-0 lg:max-h-full [&_button]:bg-transparent'>
          <h3 className='type-h4 c-forestDark'>
            {isReady
              ? `Help ${displayName} win a dream vacation!`
              : "You're almost done!"}
          </h3>
          <p className='type-body-1-mobile c-grey-800'>
            {isReady
              ? 'Vote to help this traveler win a $20,000 dream vacation!'
              : 'Finish up the last few steps to enter for a chance to win.'}
          </p>
          <div className='flex flex-row items-center gap-4 md:gap-6'>
            {isReady ? (
              <Button
                isDisabled={!canVoteLeaderboard}
                label={`Vote (${voteCount})`}
                size={isDesktopScreen ? 'large' : 'medium'}
                startIcon='thumbUpOffAlt'
                variant='outlined'
                onClick={() => {
                  onVoteClick(profile)
                }}
              />
            ) : (
              <ButtonLink
                className='c-forestLight b-forestLight text-forestLight hover:text-forest hover:b-forest hover:c-forest capitalize hover:bg-transparent'
                to='/dashboard?tab=guides&collapsed=false'
                variant='outline'
              >
                See Next Steps
              </ButtonLink>
            )}
            {isReady && (
              <a
                className='decoration-none type-button c-newForest flex items-center'
                href='/competition/about'
                target='_blank'
              >
                Learn More
                <Icon name='arrowForward' />
              </a>
            )}
          </div>
        </section>
        <section className='hidden md:block'>
          <img
            alt='woman with hot air balloons and polaroids'
            className='lg:w-full'
            src='https://static.travelpass.com/assets/job-campaign-2024/cta-hero.webp'
          />
        </section>
      </div>
      <VoteModalListener />
    </>
  )
}
