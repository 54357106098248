import {
  Carousel,
  Illustration,
  useScreenQuery,
} from '@travelpass/design-system'
import { GuideCategory } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { useGetCuratedGuides } from 'src/pages/home/useGetCuratedGuides'

export const Outdoors = () => {
  const { data, loading, error } = useGetCuratedGuides(
    GuideCategory.PlanPageFeatured
  )
  const guides = data?.curatedGuides || []

  const onClickOwnerProfile = useOnClickOwnerProfile()
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const showResults = !loading && !!guides.length

  return (
    <div className=''>
      <section className='max-w-300 mx-auto space-y-8 md:grid md:grid-cols-3'>
        <header className='space-y-3 text-balance text-center max-md:px-4 md:space-y-4 md:place-self-center md:text-left'>
          <span className='type-overline text-grey-700 uppercase tracking-widest'>
            Featured Guides
          </span>
          <h2 className='type-h1 capitalize'>Plan Something Unforgettable</h2>
          <div className='bg-valley h-1 w-1/3 rounded-full max-md:mx-auto md:h-1.5' />
        </header>
        {error && (
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        )}
        {loading && (
          <ul
            aria-busy={loading}
            className='ps-none mt-18! grid list-none gap-4 max-md:mx-auto max-md:w-fit md:grid-cols-2 lg:grid-cols-3' // `max-md:mx-auto max-md:w-fit` are necessay due to loading card behavior of not stretching
          >
            <GuideRecommendedCardLoading />
            <GuideRecommendedCardLoading className='max-md:hidden' />
            <GuideRecommendedCardLoading className='max-lg:hidden' />
            <span className='sr-only'>Loading...</span>
          </ul>
        )}
        {isMobileOrTablet && showResults && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {guides?.map(guide => (
              <div
                key={guide.id}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && showResults && (
          <ul className='ps-none mt-18 col-span-2 grid list-none gap-4 overflow-y-auto md:grid-cols-2'>
            {guides?.slice(0, 2).map(guide => (
              <li key={guide.id}>
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  )
}
