import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetCompetitionLeaderboardQueryVariables } from 'src/__generated__/graphql'

const GET_COMPETITION_LEADERBOARD = gql(`
  query GetCompetitionLeaderboard($after: String, $first: Int, $before: String, $last: Int, $competitionLeaderboardRequest: CompetitionLeaderboardRequest!) {
    competitionLeaderboard(after: $after, first: $first, before: $before, last: $last, competitionLeaderboardRequest: $competitionLeaderboardRequest) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          canVoteLeaderboard
          categories
          flags {
            id
          }
          userProfile {
            id
            accountHandle
            displayName
            introVideoEmbed
            isFollowed
            isUserOwner
            profileImageSource
            profileImageUrl
            userId
          }
          votes {
            id
          }
          voteCount
        }
        cursor
      }
    }
  }
`)

export const useCompetitionLeaderboardQuery = ({
  after = null,
  first = 12,
  before = null,
  last = null,
  competitionLeaderboardRequest = {
    category: null,
    sortBy: null,
    userProfile: null,
  },
}: GetCompetitionLeaderboardQueryVariables) => {
  return useQuery(GET_COMPETITION_LEADERBOARD, {
    variables: {
      after,
      first,
      before,
      last,
      competitionLeaderboardRequest,
    },
    fetchPolicy: 'cache-and-network',
  })
}
