import type { ComponentPropsWithoutRef } from 'react'
import isEmpty from 'lodash.isempty'
import { CollectionItemIdType, EventType } from 'src/__generated__/graphql'
import type { ResultsCardBannerVariant } from 'src/common/components'
import {
  ResultsCard,
  ResultsCardCarousel,
  ResultsCardContent,
  ResultsCardBanner,
  ResultsCardReviews,
} from 'src/common/components'
import { AddToMenu } from 'src/common/components/Collections/AddToMenu'
import { constructImageLinks, getPrice } from 'src/utils'
import {
  HotelResultsCardAmenities,
  HotelResultsCardAmenitiesContainer,
} from './HotelResultsCardAmenities'
import { HotelResultsCardHeader } from './HotelResultsCardHeader'
import type { HotelResultsItemData } from '../../hotelResultsTypes'

interface HotelResultsCardProps extends ComponentPropsWithoutRef<'button'> {
  bannerVariant?: ResultsCardBannerVariant
  hideCollection?: boolean
  hotelResultsCardData: HotelResultsItemData
  href?: string
}

export const HotelResultsCard = ({
  bannerVariant,
  hideCollection = false,
  hotelResultsCardData,
  onMouseOut = () => {},
  onMouseOver = () => {},
  ...props
}: HotelResultsCardProps) => {
  const {
    amenities,
    customerReviewScore,
    id,
    images: initialImages,
    name,
    nightlyAverage: initialNightlyAverage,
    totalCustomerReviews,
  } = hotelResultsCardData ?? {}
  const images = constructImageLinks(initialImages, '500x240')
  const nightlyAverage =
    initialNightlyAverage && getPrice(initialNightlyAverage)
  const showCard = !isEmpty(hotelResultsCardData) && !!name

  const handleClick = e => {
    if (props?.onClick) props.onClick(e)
    else if (props?.href) window.open(props?.href)
  }

  return (
    showCard && (
      <>
        {/* TODO: remove event handlers in `section`.  */}
        <ResultsCard
          {...props}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <section
            className='space-y-1'
            data-testid='HotelResultsCard'
            onBlur={() => {}}
            onFocus={() => {}}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
          >
            <HotelResultsCardHeader>
              <ResultsCardCarousel
                images={images}
                title={name}
                onClick={handleClick}
              />
              {bannerVariant && (
                <div className='absolute left-3 top-3'>
                  <ResultsCardBanner variant={bannerVariant} />
                </div>
              )}
            </HotelResultsCardHeader>
            <ResultsCardContent>
              <div className='space-y-3'>
                <p className='type-h5 line-clamp-2'>{name}</p>
                <ResultsCardReviews
                  average={customerReviewScore}
                  total={totalCustomerReviews}
                  type={EventType.Stay}
                />
                <HotelResultsCardAmenitiesContainer>
                  {/* TODO: remove limit after design is updated */}
                  <HotelResultsCardAmenities amenities={amenities} limit={2} />
                </HotelResultsCardAmenitiesContainer>

                <div className='flex min-h-6 flex-row justify-between'>
                  <div className='z-0 flex w-full items-end'>
                    <AddToMenu
                      item={{ id, name, type: CollectionItemIdType.Hotel }}
                      variant='text'
                    />
                  </div>
                  {!!nightlyAverage && (
                    <p className='type-subtitle-1-desktop w-full text-right'>
                      <span className='color-zinc-500 mb-0.5 block text-xs font-medium'>
                        from
                      </span>
                      {nightlyAverage}
                      {!!nightlyAverage && (
                        <span className='color-grey-800 type-small-text flex grow justify-end self-start'>
                          per night
                        </span>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </ResultsCardContent>
          </section>
        </ResultsCard>
      </>
    )
  )
}
