import { initializeApp } from 'firebase/app'
import type { AuthCredential, AuthError } from 'firebase/auth'
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  updateProfile,
} from 'firebase/auth'
import { lucencyIdFromCookie } from 'src/utils'
import { firebaseConfig } from './firebaseConfig'
import { logError } from './logger'
import { pushDataToDataLayer } from '../analytics/googleTagManagerIntegration'

type Providers = GoogleAuthProvider | FacebookAuthProvider

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

const firebaseGoogleSignIn = () => {
  return firebaseGoogleOrFacebookSignIn(new GoogleAuthProvider())
}

const firebaseFacebookSignIn = () => {
  return firebaseGoogleOrFacebookSignIn(new FacebookAuthProvider())
}

const getProvider = (provider: Providers) => {
  if (provider instanceof GoogleAuthProvider) return GoogleAuthProvider
  if (provider instanceof FacebookAuthProvider) return FacebookAuthProvider
}

const firebaseGoogleOrFacebookSignIn = async (provider: Providers) => {
  try {
    provider.setCustomParameters({
      prompt: 'select_account',
    })
    const response = await signInWithPopup(auth, provider)
    const isNewUser = getAdditionalUserInfo(response).isNewUser

    pushDataToDataLayer(isNewUser ? 'account_created' : 'login', {
      // Update this logic when we have more than two different providers
      method: provider instanceof GoogleAuthProvider ? 'Google' : 'Facebook',
      user: response.user,
      sessionId: lucencyIdFromCookie,
    })

    await updateProfile(auth.currentUser, {
      displayName: auth.currentUser?.providerData[0].displayName,
      photoURL: auth.currentUser?.providerData[0].photoURL,
    })

    return { createdNewAccount: !!isNewUser, user: response?.user }
  } catch (error) {
    const newProvider = getProvider(provider)
    const credential = newProvider.credentialFromError(error)
    const response = await firebaseSigninWithExistingAccount(credential)

    if (!response) return { error }

    return { createdNewAccount: false }
  }
}

const firebaseSigninWithExistingAccount = async (
  credential: AuthCredential
) => {
  try {
    const response = await signInWithCredential(auth, credential)
    pushDataToDataLayer('login', {
      method: credential.providerId,
      user: response.user,
      sessionId: lucencyIdFromCookie,
    })

    return response
  } catch (err) {
    logError(err)
  }
}

const firebasePasswordSignIn = async (email: string, password: string) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password)

    pushDataToDataLayer('login', {
      method: 'Email',
      user: response.user,
      sessionId: lucencyIdFromCookie,
    })

    return { status: 'success' }
  } catch (err) {
    return { status: 'error', err }
  }
}

const firebaseSignOut = async () => {
  const response = await signOut(auth)
  return response
}

const firebaseCreateAccountWithEmailAndPassword = async (
  email: string,
  password: string,
  displayName?: string
) => {
  try {
    const response = await createUserWithEmailAndPassword(auth, email, password)

    pushDataToDataLayer('account_created', {
      method: 'Email',
      user: response.user,
      sessionId: lucencyIdFromCookie,
      ...(displayName && { displayName }),
    })

    sendEmailVerification(response.user)

    return { status: 'success', user: response?.user }
  } catch (err) {
    return { status: 'error', err: err as AuthError }
  }
}

const firebaseResetPassword = (email: string) =>
  sendPasswordResetEmail(auth, email).catch(error => logError(error))

const firebaseUpdatePassword = (newPassword: string) => {
  try {
    return updatePassword(auth.currentUser, newPassword)
  } catch (error) {
    logError(error)
  }
}

const firebaseVerifiedCurrentPassword = async (
  providedEmail: string,
  providedPassword: string
) => {
  try {
    const credential = EmailAuthProvider.credential(
      providedEmail,
      providedPassword
    )
    await reauthenticateWithCredential(auth.currentUser, credential)
    return 'success'
  } catch (error) {
    return error.code
  }
}

const firebaseCheckConnectedProviders = () => {
  const { providerData } = auth.currentUser ?? {}

  const connectedProviders = {
    isConnectedToGoogle: providerData?.some(
      ({ providerId }) => providerId === 'google.com'
    ),
    isConnectedToFacebook: providerData?.some(
      ({ providerId }) => providerId === 'facebook.com'
    ),
    isConnectedToEmail: providerData?.some(
      ({ providerId }) => providerId === 'password'
    ),
  }

  return connectedProviders
}

export {
  auth,
  firebaseCheckConnectedProviders,
  firebaseCreateAccountWithEmailAndPassword,
  firebaseFacebookSignIn,
  firebaseGoogleSignIn,
  firebasePasswordSignIn,
  firebaseSignOut,
  firebaseResetPassword,
  firebaseUpdatePassword,
  firebaseVerifiedCurrentPassword,
}
