import { Button, Divider, IconButton } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { ReadMoreContentSection } from 'src/common/components/ReadMoreContentSection'
import { guidesPath } from 'src/constants'
import { FollowButton } from 'src/pages/profile/components/FollowButton/FollowButton'
import { getGuideDraftDetailsUrl } from 'src/utils'
import { GuideHeaderContentMetrics } from './GuideHeaderContentMetrics'
import type { GuideData, GuideOwner } from '../../../types'
import { GuideProfile } from '../../GuideProfile'
import { GuideHeaderTags } from '../GuideHeaderTags'

interface GuideHeaderContentProps {
  guideData: GuideData
  owner: GuideOwner
}

export const GuideHeaderContent = ({
  guideData,
  owner,
}: GuideHeaderContentProps) => {
  const navigate = useNavigate()
  const {
    name: title,
    guideDraft,
    description,
    ownerProfile,
    tags,
  } = guideData ?? {}
  const { displayName, isFollowed, userId } = ownerProfile ?? {}
  const isUserOwner = ownerProfile?.isUserOwner
  const guideDraftUrl = getGuideDraftDetailsUrl({
    id: guideDraft?.id,
    isUserOwner,
    name: title,
  })

  return (
    <PageLayoutContainer size='none'>
      <div className='lg:p-y-10 relative space-y-4 lg:space-y-6'>
        <div>
          {/** @todo replace this 'All Guides' Button with <Breadcrumbs /> from Design-System when the DS matches Figma */}
          <div className='m-b-6 flex flex-row justify-between gap-3 lg:hidden'>
            <div className='m-l--3.25'>
              <Button
                startIcon='chevronLeft'
                variant='text'
                onClick={() => navigate(guidesPath)}
              >
                All Guides
              </Button>
            </div>
            {isUserOwner && (
              <IconButton
                color='forestLight'
                icon='modeEdit'
                outline='round'
                size='small'
                onClick={() => window.open(guideDraftUrl, '_blank')}
              />
            )}
          </div>
          <div className='space-y-2'>
            <h2 className='text-color-primary-forest type-h1-mobile type-h2-desktop lg:text-color-white'>
              {title}
            </h2>
            <Divider className='bg-valley w-17.5 block h-1 lg:hidden' />
            <div className='flex flex-row items-center gap-3'>
              <GuideProfile guideData={guideData} owner={owner} />
              {!isUserOwner && (
                <>
                  <div className='hidden lg:block'>
                    <FollowButton
                      displayName={displayName}
                      fullWidth={false}
                      isFollowed={isFollowed}
                      label={isFollowed ? 'Following' : 'Follow'}
                      userId={userId}
                      variant={isFollowed ? 'transparent' : 'filled'}
                    />
                  </div>
                  <div className='lg:hidden'>
                    <FollowButton
                      displayName={displayName}
                      fullWidth={false}
                      isFollowed={isFollowed}
                      label={isFollowed ? 'Following' : 'Follow'}
                      userId={userId}
                      variant={isFollowed ? 'outlined' : 'filled'}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {description && (
          <ReadMoreContentSection maxHeightClass='max-h-18'>
            <div className='block lg:hidden'>
              <p className='text-color-primary-forest type-body-1-mobile'>
                {description}
              </p>
            </div>
          </ReadMoreContentSection>
        )}
        {description && (
          <div className='hidden lg:block'>
            <p className='text-color-white type-body-1 line-clamp-4'>
              {description}
            </p>
          </div>
        )}
        <GuideHeaderContentMetrics guideData={guideData} />
        {tags && <GuideHeaderTags tags={tags} />}
      </div>
    </PageLayoutContainer>
  )
}
