import { Chip } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useGetPredefinedGuideTagsQuery } from 'src/common/hooks'
import type {
  GuideDraftPublishFormValues,
  GuideDraftPublishInvalidFormValues,
  UseGuideDraftPublishForm,
} from './types'
import { GuideDraftGroup, GuideDraftGroupHeader } from '../GuideDraftGroup'

interface GuideDraftPublishTagsProps {
  formValues: GuideDraftPublishFormValues
  invalidFormValues: GuideDraftPublishInvalidFormValues
  onChange: UseGuideDraftPublishForm['onFormValuesChange']
}

export const GuideDraftPublishTags = ({
  formValues,
  invalidFormValues,
  onChange,
}: GuideDraftPublishTagsProps) => {
  const { tags, loading } = useGetPredefinedGuideTagsQuery()
  const { tagIds } = formValues ?? {}
  const predefinedGuidesTagsData = tags ?? []
  const showTags = !loading && !isEmpty(predefinedGuidesTagsData)

  const onTagChange = (tagId: string) => {
    let updatedTagIds = []

    if (tagIds.includes(tagId)) {
      updatedTagIds = tagIds.filter(id => id !== tagId)
    } else {
      updatedTagIds = [...tagIds, tagId]
    }

    if (updatedTagIds.length > 5) return

    onChange({
      ...formValues,
      tagIds: updatedTagIds,
    })
  }

  if (!showTags) return

  return (
    <GuideDraftGroup>
      <GuideDraftGroupHeader
        subtitle='Select up to 5 tags to best describe your experience and help others discover your Guide'
        title='Tag Your Guide'
      />
      <div>
        <div
          className={classNames(
            'b-1 b-solid rounded-2 flex flex-row flex-wrap gap-x-1 gap-y-2 bg-white p-1 md:gap-3 md:p-6',
            {
              'b-error': invalidFormValues.tagIds,
              'b-grey-300': !invalidFormValues.tagIds,
            }
          )}
        >
          {predefinedGuidesTagsData?.map(({ id, name }) => (
            <div key={id}>
              <Chip
                isSelected={tagIds.includes(id)}
                label={name}
                onClick={() => onTagChange(id)}
              />
            </div>
          ))}
        </div>
        {invalidFormValues.tagIds && (
          <p className='c-red font-2.5 type-subtext pl-3.5'>
            At least one tag is required.
          </p>
        )}
      </div>
      {!!tagIds.length && (
        <p className='type-body-1-medium text-right'>
          {tagIds.length} of 5 Tags Selected*
        </p>
      )}
    </GuideDraftGroup>
  )
}
