import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

export const FOLLOWERS_QUERY = gql(`
  query Followers($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      isFollowed
      isUserOwner
      userId
      followingUsers {
        ...FollowUserProfileFields
      }
      followedUsers {
        ...FollowUserProfileFields
      }
      followCount {
        countOfFollowing
        countOfFollowed
      }
    }
  }
`)

const useFollowersQuery = (accountHandle: string) =>
  useQuery(FOLLOWERS_QUERY, { variables: { accountHandle } })
