import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { GuideSorts } from 'src/__generated__/graphql'
import { getTracker } from 'src/utils'

const RESULTS_LIMIT = 3

const GUIDE_SEARCH_QUERY = gql(`
  query getTrendingGuides($guideSearchArgs: GuideSearchArgs!, $first: Int, $after: String) {
    guideSearch(guideSearchArgs: $guideSearchArgs, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          addresses {
            id
            city
            country
            state
          }
          isCollected
          endDate
          guideType
          images {
            id
            source
            type
            url
          }
          imageUrl
          insertedAt
          name
          ownerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
          startDate
          updatedAt
          timeZone
        }
      }
    }
  }
`)

type UseGetTrendingGuides = {
  sortBy?:
    | GuideSorts.TrendingPastDay
    | GuideSorts.TrendingPastWeek
    | GuideSorts.TrendingPastMonth
  queryCount?: number
  skip?: boolean
}

export const useGetTrendingGuides = ({
  queryCount,
  sortBy = GuideSorts.TrendingPastWeek,
  skip,
}: UseGetTrendingGuides) =>
  useQuery(GUIDE_SEARCH_QUERY, {
    variables: {
      first: queryCount || RESULTS_LIMIT,
      guideSearchArgs: {
        sortBy: sortBy as GuideSorts,
        tracker: getTracker(sortBy),
      },
    },
    notifyOnNetworkStatusChange: true,
    skip,
  })
