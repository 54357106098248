import { useEffect } from 'react'
import { InfoBox } from '@react-google-maps/api'
import isEmpty from 'lodash.isempty'
import type { GetPlaceDetailsInTripsQuery } from 'src/__generated__/graphql'
import { getGeocoderLatitude, getGeocoderLongitude } from 'src/utils'
import { GuideDraftMapPlaceMarkerPopup } from './GuideDraftMapPlaceMarkerPopup'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'

interface GuideDraftMapInfoWindowProps {
  infoWindowPosition: google.maps.LatLng
  isLoading: boolean
  onAddEvent: (placeDetailsData: GetPlaceDetailsInTripsQuery) => void
  onDismiss: VoidFunction
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  placeDetailsData: GetPlaceDetailsInTripsQuery
}

export const GuideDraftMapInfoWindow = ({
  infoWindowPosition,
  isLoading,
  onAddEvent,
  onDismiss,
  onMapMarkerCenterChange,
  placeDetailsData,
}: GuideDraftMapInfoWindowProps) => {
  useEffect(() => {
    if (placeDetailsData?.getPlaceDetails?.googlePlaceId)
      onMapMarkerCenterChange({
        lat: getGeocoderLatitude(placeDetailsData?.getPlaceDetails?.latitude),
        lng: getGeocoderLongitude(placeDetailsData?.getPlaceDetails?.longitude),
      })
  }, [placeDetailsData])

  if (isLoading || isEmpty(placeDetailsData)) return

  return (
    <>
      <InfoBox
        options={{
          closeBoxURL: '',
          disableAutoPan: true,
          enableEventPropagation: true,
          boxClass: 'm-l--37 md:m-l--64 w-75 md:w-136',
        }}
        position={infoWindowPosition}
        onCloseClick={onDismiss}
      >
        <div className='relative left--4 top--4 hidden p-4 md:block'>
          <GuideDraftMapPlaceMarkerPopup
            placeDetailsData={placeDetailsData}
            onAddEvent={() => onAddEvent(placeDetailsData)}
          />
        </div>
      </InfoBox>
      <div className='z-2 absolute bottom-0 left-0 right-0 md:hidden'>
        <GuideDraftMapPlaceMarkerPopup
          placeDetailsData={placeDetailsData}
          onAddEvent={() => onAddEvent(placeDetailsData)}
        />
      </div>
    </>
  )
}
