import type { KeywordSearchArgs, KeywordSorts } from 'src/__generated__/graphql'
import { KEYWORD_SEARCH_PHRASE } from '../keyword-search/constants'

export const buildKeywordSearchArgs = (sp: URLSearchParams) => {
  let args: KeywordSearchArgs = {
    keywordPhrase: sp.get(KEYWORD_SEARCH_PHRASE),
  }

  if (sp.get('sort')) args.sort = sp.get('sort') as KeywordSorts

  if (sp.get('lat') && sp.get('lng')) {
    args.latLong = {
      latitude: Number(sp.get('lat')),
      longitude: Number(sp.get('lng')),
    }
  }

  if (sp.get('tags')) args.tagIds = sp.get('tags').split(',')
  if (sp.get('createdBy')) args.accountHandles = sp.get('createdBy').split(',')
  if (sp.get('miles')) args.radius = Number(sp.get('miles'))

  return args
}

export const getExploreFiltersFromURL = (sp: URLSearchParams) => {
  const miles = Number(sp.get('miles'))
  const lat = Number(sp.get('lat'))
  const lng = Number(sp.get('lng'))
  const locationTitle = sp.get('locationTitle')
  const keyword = sp.get(KEYWORD_SEARCH_PHRASE)
  const accountHandles = sp.get('createdBy')?.split(',') || []
  const tags = sp.get('tags')?.split(',') || []
  return {
    miles,
    lat,
    lng,
    locationTitle,
    keyword,
    accountHandles,
    tags,
  }
}
