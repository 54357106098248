import { Button, Icon, ModalNext } from '@travelpass/design-system'

interface UploadProfilePictureModalProps {
  onClose: () => void
  onClick: () => void
}

export const UploadProfilePictureModal = ({
  onClose,
  onClick,
}: UploadProfilePictureModalProps) => (
  <ModalNext
    actions={
      <div className='flex w-full justify-center'>
        <Button
          label='Upload A Profile Picture'
          size='medium'
          onClick={onClick}
        />
      </div>
    }
    size='large'
    title='Upload A Profile Picture'
    onClose={onClose}
  >
    <section className='space-y-5'>
      <p className='type-h6'>What makes a great profile photo?</p>
      <p className='type-body-1'>
        Your profile picture gives you the chance to show your personality,
        photography skills, and travel style. Now&apos;s a great time to feature
        yourself in a favorite vacation spot.
      </p>
      <p className='type-body-1'>
        When choosing a profile picture keep these guidelines in mind:
      </p>
      <p className='mt5 type-body-1'>
        <span>
          Lighting: Photos should be clear and well-lit, not dark or blurry.
        </span>
        <br />
        <span>
          Subject: This is your profile! Avoid group photos or images where
          it&apos;s difficult to tell that you&apos;re in the picture.{' '}
        </span>
        <br />
        <span>Attire: Keep things polished and appropriate.</span>
      </p>
      <div className='grid grid-cols-1 gap-x-8 gap-y-10 px-5 md:grid-cols-2'>
        <div>
          <img
            alt='Woman taking a selfie with her phone'
            className='w-full'
            src='https://static.travelpass.com/assets/dashboard/selfie.webp'
          />
          <p className='type-body-2 flex flex-row items-center'>
            <span className='c-valley'>
              <Icon name='check' />
            </span>
            Good lighting and front facing
          </p>
        </div>
        <div>
          <img
            alt='Group of people looking at the camera and standing their hands'
            className='w-full'
            src='https://static.travelpass.com/assets/dashboard/group.webp'
          />
          <p className='type-body-2 flex flex-row items-center'>
            <span className='c-red'>
              <Icon name='clear' />
            </span>
            Avoid group photos
          </p>
        </div>
        <div>
          <img
            alt='Traveler at the top of a mountain looking at the horizon'
            className='w-full'
            src='https://static.travelpass.com/assets/dashboard/horizon.webp'
          />
          <p className='type-body-2 flex flex-row items-center'>
            <span className='c-red'>
              <Icon name='clear' />
            </span>
            Avoid far away shots with your back to the camera
          </p>
        </div>
        <div>
          <img
            alt='Man smiling at camera in a dark room with lights and background'
            className='w-full'
            src='https://static.travelpass.com/assets/dashboard/man-in-nightclub.webp'
          />
          <p className='type-body-2 flex flex-row items-center'>
            <span className='c-red'>
              <Icon name='clear' />
            </span>
            Avoid dark or blurry pictures
          </p>
        </div>
      </div>
    </section>
  </ModalNext>
)
