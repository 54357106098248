const guideDraftDescriptionMaxLength = 600
const guideDraftEventImagesLimit = 10
const guideDraftEventNoteMaxLength = 3000
const guideDraftImageAcceptedTypes = 'image/jpeg, image/png'
const guideDraftImageMaxSize = 5242880 // 5mb
const guideDraftImagesLimit = 3
const guideDraftNameMaxLength = 50
const guideDraftSectionDescriptionMaxLength = 500
const guideDraftSectionNameMaxLength = 40

export {
  guideDraftDescriptionMaxLength,
  guideDraftEventImagesLimit,
  guideDraftEventNoteMaxLength,
  guideDraftImageAcceptedTypes,
  guideDraftImageMaxSize,
  guideDraftImagesLimit,
  guideDraftNameMaxLength,
  guideDraftSectionDescriptionMaxLength,
  guideDraftSectionNameMaxLength,
}
