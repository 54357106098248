import { useLazyQuery, useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const nodeQuery = gql(`
  query StayTPGData($standardHotelId: String!, $idType: LodgingIdType!, $listHotelReviewsArgs: ListHotelReviewsArgs!) {
    listHotelReviews(listHotelReviewsArgs: $listHotelReviewsArgs) {
      averageOverall
      totalReviews
    }
    lodging(id: $standardHotelId, idType: $idType) {
      id
      address
      amenities
      checkin
      checkout
      city
      country
      descriptions {
        text
      }
      imageUrls
      googlePlaceId
      latitude
      longitude
      name
      phoneNumbers
      postalCode
      rating
      state
      stateCode
      webAddress
    }
  }
`)

export const useStayProductData = () => {
  const [getStayProductData, { data: stayProductData, loading, error }] =
    useLazyQuery(nodeQuery)

  const data = stayProductData ?? null

  return {
    getStayProductData,
    data,
    loading,
    error,
  }
}
