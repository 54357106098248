import { Helmet, PageLayoutContainer } from 'src/common/components'
import { PrivacyContent } from 'src/common/components/PrivacyModal/PrivacyContent'
import { baseUrl, privacyPath } from 'src/constants'
import { LegalLayout } from '../common/components/LegalLayout/LegalLayout'

export const Privacy = () => {
  return (
    <PageLayoutContainer includeTopPadding>
      <Helmet canonicalUrl={`${baseUrl}${privacyPath}`} pageName='Privacy' />
      <LegalLayout
        caption='OUR POLICIES'
        subtitle="Travelpass' Privacy Policy was updated on October 4, 2024."
        title='Privacy Policy'
      >
        <PrivacyContent />
      </LegalLayout>
    </PageLayoutContainer>
  )
}
