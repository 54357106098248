import type { ReactNode } from 'react'
import classNames from 'classnames'

interface DashboardFeedCardProps {
  children: ReactNode
  className?: string
  onClick?: VoidFunction
  label?: string
  href?: string
}

export const DashboardFeedCard = ({
  children,
  className = 'b-1 b-solid b-grey-200 transition-shadow-200 hover:shadow-3 p-4 ease-linear md:p-6',
  label,
  onClick,
  href,
}: DashboardFeedCardProps) => {
  return (
    <div
      className={classNames(
        'of-hidden rounded-2 relative h-full bg-white',
        className
      )}
    >
      {href && (
        <a
          className='b-none m-none p-none absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent p-0 outline-none'
          data-testid='DashboardFeedCard-link'
          draggable='false'
          href={href}
          rel='noreferrer'
          target='_blank'
        >
          <DashboardFeedCardScreenReaderText label={label} />
        </a>
      )}
      {!href && (
        <button
          className='b-none m-none p-none absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent p-0 outline-none'
          data-testid='DashboardFeedCard-button'
          draggable='false'
          tabIndex={-1}
          type='button'
          onClick={() => onClick?.()}
        >
          <DashboardFeedCardScreenReaderText label={label} />
        </button>
      )}
      {children}
    </div>
  )
}

const DashboardFeedCardScreenReaderText = ({
  label,
}: {
  label?: DashboardFeedCardProps['label']
}) => (
  <span className='sr-only'>
    {label || 'More information about this card, opens in a new tab'}
  </span>
)
