import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updateUserMutation = gql(`
  mutation UpdateDashboardAccountSettingsFormInfoMutationInDashboard($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        addressFirstLine
        addressSecondLine
        city
        country
        email
        firstName
        isDeleted
        lastName
        phoneNumber
        state
        zip
      }
    }
  }
`)

export const useUpdateDashboardAccountSettingsFormInfoMutation = () =>
  useMutation(updateUserMutation)
