import { useEffect, useState } from 'react'
import { useQuery, type ApolloError } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  GetGuideDrawerAppearsInGuidesQueryInGuideQuery,
  GetGuideDrawerQueryInGuideQuery,
  PublishedEvent,
} from 'src/__generated__/graphql'
import {
  checkGuideEventIsHotelSearch,
  generateHotelDetailsUrl,
} from 'src/utils'

const getGuideDrawerQuery = gql(`
  query GetGuideDrawerQueryInGuide($placeDetailsRequest: PlaceDetailsRequest!){
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      address
      city
      country
      countryCode
      description
      googlePlaceId
      imageLinks
      latitude
      longitude
      name
      phoneNumber
      openingHours {
        weekdayText
      }
      phoneNumber
      postalCode
      rating
      standardHotelId
      state
      type
      website
    }
  }
`)

const getGuideDrawerLodgingQuery = gql(`
  query GetGuideDrawerLodgingQueryInGuide($hotelId: String!) {
    lodging(id: $hotelId, idType: STANDARD) {
      id
      city
      name
      state
      stateCode
    }
  }
`)

const getGuideDrawerAppearsInGuidesQuery = gql(`
  query GetGuideDrawerAppearsInGuidesQueryInGuide($placeId: ID!) {
    node (id: $placeId) {
      ... on PublishedEvent {
        id
        appearsInGuides(first: 1) {
          totalCount
        }
      }
    }
  }
`)

type UseGetGuideDrawerQuery = {
  guideDrawerData: GetGuideDrawerQueryInGuideQuery['getPlaceDetails']
  guideDrawerNumberOfGuidesCount: number
  guideDrawerWebsite: GetGuideDrawerQueryInGuideQuery['getPlaceDetails']['website']
  hasError: ApolloError
  isLoading: boolean
}

export const useGetGuideDrawerQuery = (
  selectedEvent: PublishedEvent
): UseGetGuideDrawerQuery => {
  const placeDetailsRequest = {
    isHotelSearch: checkGuideEventIsHotelSearch(selectedEvent),
    placeId: selectedEvent?.addresses?.[0]?.googlePlaceId,
  }
  const {
    data,
    error: hasError,
    loading: isGetGuideDrawerQueryLoading,
  } = useQuery(getGuideDrawerQuery, {
    variables: {
      placeDetailsRequest,
    },
    skip: !placeDetailsRequest?.placeId,
  })
  const guideDrawerData = data?.getPlaceDetails ?? {}
  const { data: lodgingData, loading: isLodgingDataLoading } = useQuery(
    getGuideDrawerLodgingQuery,
    {
      variables: { hotelId: guideDrawerData?.standardHotelId?.toString() },
      skip: !guideDrawerData?.standardHotelId,
    }
  )
  const { data: appearsInGuidesNodeData, loading: isNearbyPlacesLoading } =
    useQuery(getGuideDrawerAppearsInGuidesQuery, {
      variables: { placeId: selectedEvent?.id },
      skip: !placeDetailsRequest?.placeId,
    })

  const appearsInGuidesData = appearsInGuidesNodeData?.node as Extract<
    GetGuideDrawerAppearsInGuidesQueryInGuideQuery['node'],
    { __typename?: 'PublishedEvent' }
  >
  const guideDrawerNumberOfGuidesCount =
    (appearsInGuidesData?.appearsInGuides?.totalCount ?? 0) - 1

  const [guideDrawerWebsite, setGuideDrawerWebsite] =
    useState<UseGetGuideDrawerQuery['guideDrawerWebsite']>(null)

  useEffect(() => {
    if (lodgingData?.lodging?.id) {
      const { id, city, name, state, stateCode } = lodgingData?.lodging ?? {}
      const website = generateHotelDetailsUrl({
        city,
        id,
        name,
        state,
        stateAbbreviation: stateCode,
      })
      setGuideDrawerWebsite(website)
    } else if (guideDrawerData?.website) {
      setGuideDrawerWebsite(guideDrawerData?.website)
    }
  }, [guideDrawerData, lodgingData])

  const isLoading =
    isGetGuideDrawerQueryLoading &&
    isLodgingDataLoading &&
    isNearbyPlacesLoading

  return {
    guideDrawerData,
    guideDrawerNumberOfGuidesCount,
    guideDrawerWebsite,
    hasError,
    isLoading,
  }
}
