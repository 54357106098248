interface GuideDraftGroupHeaderProps {
  subtitle?: string
  title: string
}

export const GuideDraftGroupHeader = ({
  subtitle,
  title,
}: GuideDraftGroupHeaderProps) => (
  <header className='md:p-x-3.5 space-y-3'>
    <h4 className='c-grey-900 type-h5-mobile lg:type-h6-desktop'>{title}</h4>
    {subtitle && (
      <p className='c-grey-900 type-body-2-mobile lg:type-body-1-desktop'>
        {subtitle}
      </p>
    )}
  </header>
)
