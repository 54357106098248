interface AwayGameCardProps {
  teamName: string
  colors: {
    primary: string
    secondary: string
  }
  city: string
  state: string
  date: string
  fullDate: string
  time: string
  latitude: string
  longitude: string
  arrival: string
  departure: string
}

export const ScheduleCard = ({
  teamName,
  colors,
  city,
  state,
  date,
  fullDate,
  time,
  latitude,
  longitude,
  arrival,
  departure,
}: AwayGameCardProps) => {
  const awayGameLink = (teamName: string) =>
    teamName?.toLowerCase().replaceAll(' ', '-')
  return (
    <a
      href={`/utah-jazz/vs/${awayGameLink(teamName)}?latitude=${latitude}&longitude=${longitude}&arrival=${arrival}&departure=${departure}&date=${encodeURIComponent(fullDate)}`}
      style={{ textDecoration: 'none' }}
    >
      <div className='rounded-1 of-hidden ring-grey-300 grid grid-cols-3 gap-0 ring-1 ring-inset transition-shadow hover:cursor-pointer hover:shadow-lg'>
        <div
          className='p-y-4px col-span-3'
          style={{
            background: `linear-gradient(to right, ${colors.primary} 20%, ${colors.secondary} 80%)`,
          }}
        ></div>
        <div className='p-y-4 border-b-1 border-r-1 border-grey-300 col-span-1 place-content-center border-0 border-solid text-center'>
          <p className='c-grey-900 text-3.5 font-700 m-0'>{date}</p>
          <p className='c-grey-900 text-3 font-400 m-0'>{time}</p>
        </div>
        <div className='p-y-4 border-b-1 border-grey-300 col-span-2 place-content-center border-0 border-solid text-center'>
          <h2 className='text-5 font-900 c-black m-0 tracking-[-0.01em]'>
            {teamName}
          </h2>
          <p className='text-3 font-400 m-t-1 c-black m-0'>
            {city}, {state}
          </p>
        </div>
        <div className='p-y-2.25 m-r-1px m-b-1px m-l-1px rounded-bl-1 rounded-br-1 col-span-3 bg-white'>
          <p className='text-3.25 c-grey-800 font-700 m-0 text-center'>
            View {city} Away Game Page
          </p>
        </div>
      </div>
    </a>
  )
}
