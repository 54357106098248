import {
  Carousel,
  Illustration,
  useScreenQuery,
} from '@travelpass/design-system'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { useGetTrendingGuides } from '../hooks/useGetTrendingGuides'

interface TrendingProps {
  title?: string
  onlyMainTitle?: boolean
}

export const Trending = ({
  title = 'Discover Trending Guides',
  onlyMainTitle = false,
}: TrendingProps) => {
  const { data, loading, error } = useGetTrendingGuides({})
  const guides = data?.guideSearch?.edges || []
  const onClickOwnerProfile = useOnClickOwnerProfile()
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const showResults = !loading && !!guides.length
  const empty = !loading && !guides.length

  if (empty) return null

  return (
    <div className=''>
      <section className='max-w-300 mx-auto space-y-8 md:space-y-12'>
        <header className='space-y-3 text-balance text-center max-md:px-4 md:space-y-4'>
          {!onlyMainTitle && (
            <span className='type-overline text-grey-700 uppercase tracking-widest'>
              Popular Picks
            </span>
          )}
          <div className='space-y-3'>
            <h1 className='type-h1 capitalize'>{title}</h1>
            {!onlyMainTitle && (
              <p className='type-body-1'>
                Explore new and noteworthy destinations with tips from fellow
                travelers.
              </p>
            )}
          </div>
        </header>
        {error && (
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        )}
        {loading && (
          <ul
            aria-busy={loading}
            className='ps-none mt-18! grid list-none gap-4 max-md:mx-auto max-md:w-fit md:grid-cols-2 lg:grid-cols-3' // `max-md:mx-auto max-md:w-fit` are necessay due to loading card behavior of not stretching
          >
            <GuideRecommendedCardLoading />
            <GuideRecommendedCardLoading className='max-md:hidden' />
            <GuideRecommendedCardLoading className='max-lg:hidden' />
            <span className='sr-only'>Loading...</span>
          </ul>
        )}
        {isMobileOrTablet && showResults && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {guides?.map(guide => (
              <div
                key={guide.node.id}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide.node}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && showResults && (
          <ul className='ps-none mt-18 grid list-none gap-4 overflow-y-auto md:grid-cols-3'>
            {guides?.map(guide => (
              <li key={guide.node.id}>
                <GuideRecommendedCard
                  asLink
                  guideRecommendedCardData={guide.node}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  )
}
