import { Icon } from '@travelpass/design-system'
import {
  GuideMetricLike,
  GuideMetricSave,
  GuideMetricShare,
  GuideMetricView,
  getGuideMetricString,
} from 'src/pages/guides/common'
import { useGetUserLikesGuideQuery } from 'src/pages/guides/useGetUserLikesGuideQuery'
import type { GuideData } from '../../../types'

interface GuideHeaderContentMetricsProps {
  guideData: GuideData
}

export const GuideHeaderContentMetrics = ({
  guideData,
}: GuideHeaderContentMetricsProps) => {
  const {
    id,
    collectedCount,
    isCollected,
    numberOfLikes,
    shareCount,
    viewCount,
  } = guideData
  const { hasLikedGuide } = useGetUserLikesGuideQuery(id)

  return (
    <>
      <div className='flex flex-row items-center gap-8 lg:hidden'>
        <div className='color-grey-800 flex flex-row items-center gap-1'>
          <Icon name={hasLikedGuide ? 'favorite' : 'favoriteBlank'} />
          <span className='text-grey-800 type-small-text-desktop'>
            {getGuideMetricString(numberOfLikes, 'like')}
          </span>
        </div>
        <div className='color-grey-800 flex flex-row items-center gap-1'>
          <Icon name='visibility' />
          <span className='text-grey-800 type-small-text-desktop'>
            {getGuideMetricString(viewCount, 'view')}
          </span>
        </div>
        <div className='color-grey-800 flex flex-row items-center gap-1'>
          <Icon name={isCollected ? 'bookmark' : 'bookmarkBorder'} />
          <span className='text-grey-800 type-small-text-desktop'>
            {getGuideMetricString(collectedCount, 'save')}
          </span>
        </div>
        <div className='color-grey-800 flex flex-row items-center gap-1'>
          <Icon name='iosShare' />
          <span className='text-grey-800 type-small-text-desktop'>
            {getGuideMetricString(shareCount, 'share')}
          </span>
        </div>
      </div>
      <div className='[&>button]:p-x-0 hidden flex-row items-center gap-3.5 lg:flex'>
        <GuideMetricLike guideData={guideData} variant='white' />
        <div className='[&>button>div]:text-color-white [&>button]:p-x-0 [&>button>i]:color-white'>
          <GuideMetricView
            guideData={guideData}
            isDisabled={true}
            variant='white'
          />
        </div>
        <GuideMetricSave guideData={guideData} variant='white' />
        <GuideMetricShare guideData={guideData} variant='white' />
      </div>
    </>
  )
}
