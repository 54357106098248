import { useEffect, useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import { isbot } from 'isbot'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GuideDraftStatus } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import {
  decodeGuideId,
  encodeGuideId,
  getGuideDraftDetailsUrl,
  stringToBoolean,
} from 'src/utils'
import {
  GuideDraftEmpty,
  GuideDraftGeocoder,
  GuideDraftHeroEmpty,
  GuideDraftLoading,
  GuideDraftMap,
  GuideDraftMapEvents,
  GuideDraftMapMarkers,
  GuideDraftMapSearch,
  GuideDraftOnboarding,
  GuideDraftQuickPublishBanner,
  GuideDraftSections,
  GuideDraftStickyActionButtons,
  GuideDraftTopNav,
} from './common'
import { getGuideDraftEventMarkers } from './guideDraftUtils'
import { useGetGuideDraftQuery } from './useGetGuideDraftQuery'
import { GuideSearchParam, useGuideSessionStorageIds } from '../details'
import {
  GuideDrawer,
  GuideHeader,
  GuideHero,
  GuideMapWrapper,
  GuideSectionsWrapper,
} from '../details/common'

export const GuideDraft = () => {
  const { selectedId } = useGuideSessionStorageIds()
  const navigate = useNavigate()
  const { guideDraftId } = useParams()
  const guideDraftIdDecoded = decodeGuideId(guideDraftId)
  const guideDraftIdEncoded = encodeGuideId({
    guideId: guideDraftIdDecoded,
    isGuideDraft: true,
  })
  const {
    data,
    guideDraftData,
    guideDraftMetaData,
    hasError,
    isLoading,
    mapMarkerCenter,
    onMapMarkerCenterChange,
    owner,
  } = useGetGuideDraftQuery(guideDraftIdEncoded)
  const [placePoints, setPlacePoints] = useState<
    google.maps.places.PlaceResult[]
  >([])
  const [searchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView) ?? 'false'
  )
  const {
    addresses,
    description,
    eventCounts,
    guide,
    hasUnpublishedChanges,
    id,
    images,
    insertedAt,
    name,
    ownerProfile,
    paginatedEventCategories,
    tags,
    timeZone,
    uncategorizedPublishedEvents,
    updatedAt,
  } = guideDraftData ?? {}
  const { collectedCount, isCollected, numberOfLikes, shareCount, viewCount } =
    guide ?? {}
  const points = getGuideDraftEventMarkers({
    paginatedEventCategories,
    uncategorizedPublishedEvents,
  })
  const { isUserOwner } = owner ?? {}

  useEffect(() => {
    if (guideDraftId !== guideDraftIdDecoded) {
      navigate(
        getGuideDraftDetailsUrl({
          id: guideDraftIdDecoded,
          isUserOwner: true,
          name,
        }),
        {
          replace: true,
        }
      )
    }
  }, [guideDraftIdDecoded])

  const onPlacePointsChange = (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => setPlacePoints(updatedPlacePoints)

  const onSearchValueChange = (updatedSearchValue: string) =>
    setSearchValue(updatedSearchValue)

  if (isLoading) return <GuideDraftLoading />

  if (
    hasError ||
    guideDraftData?.status === GuideDraftStatus.Archived ||
    isEmpty(guideDraftData) ||
    !isUserOwner
  )
    return <GuideDraftEmpty />

  /** @todo use only 1 PageLayoutContainer instead of 2 if possible (bg color discrepancy caused this initially) */
  return (
    <>
      {!isEmpty(guideDraftData) && <Helmet {...guideDraftMetaData} />}
      <GuideDraftTopNav guideDraftData={guideDraftData} />
      {!!images && <GuideHero guideData={guideDraftData} isEdit={true} />}
      <div className='lg:flex lg:flex-row'>
        <GuideSectionsWrapper mapExpanded={mapFullView} selectedId={selectedId}>
          <div className='bg-white'>
            {hasUnpublishedChanges && (
              <GuideDraftQuickPublishBanner guideDraftData={guideDraftData} />
            )}
            <PageLayoutContainer size='none'>
              {isEmpty(images) && (
                <div className='p-t-6'>
                  <GuideDraftHeroEmpty guideDraftData={guideDraftData} />
                </div>
              )}
              <GuideHeader
                guideHeaderData={{
                  addresses,
                  collectedCount,
                  description,
                  eventCounts,
                  guideDraftId: id,
                  id: guide?.id,
                  insertedAt,
                  isCollected,
                  name,
                  numberOfLikes,
                  ownerProfile,
                  shareCount,
                  tags,
                  timeZone,
                  updatedAt,
                  viewCount,
                }}
                isEdit={true}
              />
            </PageLayoutContainer>
          </div>
          <PageLayoutContainer size='none'>
            <div className='p-t-6 p-b-5 lg:p-b-30 space-y-8 lg:space-y-10'>
              <div className='space-y-4'>
                <h3 className='color-forest-dark type-body-1-medium-desktop'>
                  Add places, experiences, and more to your Guide
                </h3>
                <GuideDraftGeocoder
                  searchValue={searchValue}
                  slotAfter={
                    <div className='pointer-events-none absolute right-0'>
                      <IconButton
                        color='valley'
                        icon='search'
                        onClick={() => {}}
                      />
                    </div>
                  }
                  onMapMarkerCenterChange={onMapMarkerCenterChange}
                  onPlacePointsChange={onPlacePointsChange}
                  onSearchValueChange={onSearchValueChange}
                />
              </div>
              <GuideDraftSections
                data={data}
                eventCount={points?.length}
                owner={owner}
                paginatedEventCategories={paginatedEventCategories}
                selectedId={selectedId}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
              />
            </div>
          </PageLayoutContainer>
          <GuideDraftOnboarding currentUser={data?.currentUser} />
          <GuideDraftStickyActionButtons
            guideDraftId={id}
            selectedId={selectedId}
          />
        </GuideSectionsWrapper>
        <GuideDrawer
          className='lg:top-19.5 h-[calc(100dvh-78px)]'
          guideDraftId={id}
          isEdit={true}
          owner={owner}
          points={points}
          selectedId={selectedId}
        />
        {!isbot(navigator.userAgent) && (
          <GuideMapWrapper
            className='lg:top-19.5'
            mapExpanded={mapFullView}
            selectedId={selectedId}
          >
            <GuideDraftMap data={data}>
              <GuideDraftMapEvents
                data={data}
                mapMarkerCenter={mapMarkerCenter}
              />
              <GuideDraftMapMarkers
                owner={owner}
                placePoints={placePoints}
                points={points}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
              />
              <GuideDraftMapSearch
                searchValue={searchValue}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
                onPlacePointsChange={onPlacePointsChange}
                onSearchValueChange={onSearchValueChange}
              />
            </GuideDraftMap>
          </GuideMapWrapper>
        )}
      </div>
    </>
  )
}
