import { Button, Illustration } from '@travelpass/design-system'
import type { TripStatus } from 'src/__generated__/graphql'

export const UserTripsEmpty = ({ tripStatus }: { tripStatus: TripStatus }) => {
  const title = `You have no ${tripStatus.toLocaleLowerCase()} trips`

  const onClick = () => {
    const element = document.getElementById('my-trips-create')
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className='flex w-full flex-col items-center justify-center gap-12 py-20 pb-20 md:flex-row md:pt-32'>
      <div className='flex h-full w-full flex-row items-center justify-center md:justify-end'>
        <div className='w-73.75'>
          <Illustration name='scooter' />
        </div>
      </div>
      <div className='md:pl-18 flex w-full flex-col items-center justify-center gap-6 md:items-start'>
        <h2 className='text-h5 c-black m-0'>{title}</h2>
        <p className='text-body-1 c-grey700 m-0'>Start planning one today!</p>
        <div className='my-4'>
          <Button
            aria-label='Create a Trip'
            size='large'
            startIcon='add'
            onClick={onClick}
          >
            Create Trip
          </Button>
        </div>
      </div>
    </div>
  )
}
