import { type ComponentPropsWithoutRef } from 'react'
import { Button, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import {
  type GetPlaceDetailsInTripsQuery,
  EventType,
} from 'src/__generated__/graphql'
import {
  ResultsCard,
  ResultsCardContent,
  ResultsCardImage,
  ResultsCardReviews,
} from 'src/common/components'
import { getEventImage } from 'src/pages/trips/utils'
import { stringToNumber } from 'src/utils'

interface GuideDraftMapPlaceMarkerPopupProps
  extends ComponentPropsWithoutRef<'button'> {
  onAddEvent: () => void
  placeDetailsData: GetPlaceDetailsInTripsQuery
}

export const GuideDraftMapPlaceMarkerPopup = ({
  onAddEvent,
  placeDetailsData,
  ...props
}: GuideDraftMapPlaceMarkerPopupProps) => {
  const { address, description, imageLinks, name, website, rating } =
    placeDetailsData?.getPlaceDetails ?? {}
  const ratingInt = stringToNumber(rating) * 2
  const [image] = imageLinks ?? []

  return (
    !isEmpty(placeDetailsData) &&
    !!name && (
      <ResultsCard {...props} className='shadow-1 b-1 b-solid b-grey-400'>
        <div className='w-75 md:w-128 grid grid-cols-3'>
          <div className='rounded-3 h-46 w-25 md:w-41 relative col-span-1 overflow-hidden object-cover'>
            <ResultsCardImage
              alt={`${name} featured`}
              imageDimensions=''
              src={getEventImage(image)}
            />
          </div>
          <div className='col-span-2'>
            <ResultsCardContent>
              <section className='space-y-4'>
                <header>
                  <div className='flex flex-row items-center justify-between'>
                    <h4 className='c-grey-900 type-body-1-medium'>{name}</h4>
                  </div>
                </header>
                <div className='space-y-3'>
                  <ResultsCardReviews
                    average={ratingInt}
                    type={EventType.Stay}
                  />
                  {description && (
                    <p className='c-grey-800 type-small-text-desktop line-clamp-1 md:line-clamp-2'>
                      {description}
                    </p>
                  )}
                  {address && (
                    <div className='c-grey-800 m-l--.5 flex flex-row items-center gap-1'>
                      <Icon name='placeOutline' size='small' />
                      <span className='type-small-text line-clamp-1'>
                        {address}
                      </span>
                    </div>
                  )}
                  {website && (
                    <div className='c-grey-800 m-l--.5 flex flex-row items-center gap-1'>
                      <Icon name='language' size='small' />
                      <span className='type-small-text line-clamp-1'>
                        {website}
                      </span>
                    </div>
                  )}
                </div>
                <div className='m-l--1.75 relative'>
                  <Button
                    size='small'
                    startIcon='addCircleOutline'
                    variant='text'
                    onClick={onAddEvent}
                  >
                    Add To Guide
                  </Button>
                </div>
              </section>
            </ResultsCardContent>
          </div>
        </div>
      </ResultsCard>
    )
  )
}
