import { Skeleton } from '@travelpass/design-system'

export const GuideProfileLoading = () => (
  <div className='flex flex-row gap-3'>
    <div className='of-hidden rounded-1/2 h-10 w-10'>
      <Skeleton />
    </div>
    <div className='space-y-2'>
      <div className='w-30 h-4'>
        <Skeleton />
      </div>
      <div className='w-25 h-3.5'>
        <Skeleton />
      </div>
    </div>
  </div>
)
