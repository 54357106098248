import type { ComponentPropsWithoutRef, ReactNode } from 'react'

interface DashboardFeedCardActionProps
  extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode
}

export const DashboardFeedCardAction = ({
  children,
  ...props
}: DashboardFeedCardActionProps) => (
  <button
    className='b-none relative cursor-pointer bg-transparent p-0 outline-none'
    {...props}
  >
    {children}
  </button>
)
