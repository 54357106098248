import { useState } from 'react'
import { Breadcrumbs, Button, useSnackbar } from '@travelpass/design-system'
import { useNavigate, useParams } from 'react-router-dom'
import {
  type CollectedEvent,
  type CollectedGuide,
  type CollectedHotel,
  type CollectedExperience,
  type PublishedEvent,
  type Collection,
  CollectionItemIdType,
} from 'src/__generated__/graphql'
import { GuideRecommendedCard } from 'src/common/components'
import { CollectionFormModal } from 'src/common/components/CollectionFormModal'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { useRemoveFromCollection } from 'src/common/components/Collections/hooks/useRemoveFromCollection'
import { DeleteCollectionModal } from 'src/common/components/DeleteCollectionModal'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { dashboardPath } from 'src/constants'
import { getEventImage } from 'src/pages/trips/utils'
import { CollectionEventCard } from './components/CollectionEventCard'
import { CollectionExperienceCard } from './components/CollectionExperienceCard'
import { CollectionHotelCard } from './components/CollectionHotelCard'
import { EventDetailModal } from './components/EventDetailModal'
import { useGetCollectionById } from './hooks/useGetCollectionById'
import { DashboardTab } from '../dashboard/dashboardConstants'

export const CollectionDetails = () => {
  // Routing Hooks
  const navigate = useNavigate()
  const { collectionId } = useParams()

  // GraphQl hooks
  const { data, loading, error, refetch, fetchMore } =
    useGetCollectionById(collectionId)
  const { removeItem } = useRemoveFromCollection()

  const collection = data?.node?.__typename === 'Collection' ? data.node : null
  const pageInfo = collection?.collectedItems?.pageInfo
  const isDefaultCollection = collection?.isDefault
  const collectedItems = collection?.collectedItems?.edges.map(
    edge => edge.node
  )
  const cursor = pageInfo?.hasNextPage && pageInfo?.endCursor

  // State hooks
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEventDetailModalOpen, setIsEventDetailModalOpen] = useState(false)
  const [eventTarget, setEventTarget] = useState(null)

  //Others
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const breadcrumbs = [
    {
      label: 'Dashboard',
      onClick: () => navigate(dashboardPath),
    },
    {
      label: 'Collections',
      onClick: () =>
        navigate(`${dashboardPath}?tab=${DashboardTab.collections}`),
    },
  ]

  const handleRemoveClick = async ({ item, type }) => {
    try {
      await removeItem({
        collectionId,
        itemId: item.id,
        type,
        onCompleted: refetch,
      })
      addSuccessSnack({ title: 'Successfully removed item' })
    } catch (_error) {
      addErrorSnack({ title: 'Unable to remove item' })
    }
  }

  const openEventDetailModal = ({ event }) => {
    const eventWithFormattedImage = {
      ...event,
      imageUrl: getEventImage(event?.imageUrl),
    }
    setEventTarget({ ...eventWithFormattedImage })
    setIsEventDetailModalOpen(true)
  }

  const closeEventDetailModal = () => {
    setIsEventDetailModalOpen(false)
  }

  const renderGuide = ({ guide }: CollectedGuide) => (
    <GuideRecommendedCard
      hideAddTo
      showTip
      addToItem={{
        id: guide.id,
        name: guide.name,
        type: CollectionItemIdType.Guide,
      }}
      guideRecommendedCardData={guide}
      onRemove={() =>
        handleRemoveClick({
          item: guide,
          type: CollectionItemIdType.Guide,
        })
      }
    />
  )

  const renderHotel = ({ hotel, hotelId }: CollectedHotel) => (
    <CollectionHotelCard
      hotel={hotel}
      hotelId={hotelId}
      onRemove={handleRemoveClick}
    />
  )

  const renderExperience = ({ product }: CollectedExperience) => (
    <CollectionExperienceCard
      experience={product}
      onRemove={handleRemoveClick}
    />
  )

  const renderEvent = ({ event }: CollectedEvent) => (
    <CollectionEventCard
      event={event as PublishedEvent}
      onClick={() =>
        openEventDetailModal({
          event,
        })
      }
      onRemove={() =>
        handleRemoveClick({
          item: event,
          type: CollectionItemIdType.Event,
        })
      }
    />
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  return (
    <div className='md:px-15 max-w-300 container mx-auto px-5 py-8'>
      <AddToGuideModalListener />
      <AddToCollectionsModalListener />
      <div className='px-5 md:flex md:flex-col md:px-0'>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className='flex items-center justify-between pt-2'>
          <h1 className='text-2xl font-bold'>{collection?.name}</h1>
          {!isDefaultCollection && (
            <Button
              startIcon='modeEdit'
              onClick={() => setIsAddEditModalOpen(true)}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      {collectedItems?.length > 0 ? (
        <>
          <div className='columns-1 gap-5 py-8 md:columns-3'>
            {collectedItems.map((item, index) => {
              const key =
                item.collectedGuide?.guide?.id ||
                item.collectedHotel?.hotel?.id ||
                item.collectedEvent?.event?.id ||
                item.collectedExperience?.product?.id ||
                index // This is a fallback key in case the item doesn't have an id, which, in famous last words, "should never happen"

              return (
                <div key={key} className='mb-4 w-full break-inside-avoid'>
                  {item.collectedGuide ? (
                    <>{renderGuide(item?.collectedGuide)}</>
                  ) : item.collectedHotel ? (
                    <>{renderHotel(item?.collectedHotel)}</>
                  ) : item.collectedExperience ? (
                    <>{renderExperience(item?.collectedExperience)}</>
                  ) : item?.collectedEvent ? (
                    <>{renderEvent(item?.collectedEvent as CollectedEvent)}</>
                  ) : null}
                </div>
              )
            })}
          </div>
          {(data?.node as Collection)?.collectedItems?.pageInfo
            ?.hasNextPage && (
            <div className='flex justify-center'>
              <Button
                onClick={async () =>
                  await fetchMore({ variables: { after: cursor, first: 10 } })
                }
              >
                Load More
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className='mt-20 flex flex-col content-center'>
          <div className='type-h6 mb-8 text-center'>
            Looks like nothing has been saved yet!
          </div>
          <img
            alt=''
            className='drag-none h-65 bg-contain'
            loading='lazy'
            src='https://static.travelpass.com/assets/collections/empty-state.svg'
          />
          <p className='c-grey-800 type-h6 max-w-220 my-10 self-center text-center'>
            Start curating your dream destinations and experiences to inspire
            your next adventure. Use the &#39;Add To&#39; option found on cards
            throughout the site to get started.
          </p>
        </div>
      )}
      {isAddEditModalOpen && (
        <CollectionFormModal
          collection={data?.node as Collection}
          onClose={() => setIsAddEditModalOpen(false)}
          onConfirm={() => setIsAddEditModalOpen(false)}
          onDelete={() => {
            setIsAddEditModalOpen(false)
            setIsDeleteModalOpen(true)
          }}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteCollectionModal
          collection={data?.node as Collection}
          onCancel={() => {
            setIsDeleteModalOpen(false)
            setIsAddEditModalOpen(true)
          }}
          onClose={() => {
            setIsDeleteModalOpen(false)
            setIsAddEditModalOpen(true)
          }}
          onConfirm={() => {
            setIsDeleteModalOpen(false)
            setIsAddEditModalOpen(false)
            navigate(dashboardPath)
          }}
        />
      )}
      {isEventDetailModalOpen && (
        <EventDetailModal event={eventTarget} onClose={closeEventDetailModal} />
      )}
    </div>
  )
}
