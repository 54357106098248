export enum SEARCH_BAR_STATES {
  open = 'OPEN',
  closed = 'CLOSED',
}

export enum SEARCH_BAR_OPTIONS {
  guides = 'GUIDES',
  hotels = 'HOTELS',
  experiences = 'EXPERIENCES',
  destinations = 'DESTINATIONS',
}
