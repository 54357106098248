import { Button, useSnackbar } from '@travelpass/design-system'
import type { AvailableIcons, ButtonVariant } from '@travelpass/design-system'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { getFeedFromCurrentUserQuery } from 'src/pages/dashboard/useGetDashboardFeedQuery'
import { FOLLOWERS_QUERY } from '../FollowersModal/useFollowersQuery'
import { useCheckEditingMode } from '../hooks/useCheckEditingMode'
import { useFollowMutation } from '../hooks/useFollowMutation'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from '../hooks/useGetCurrentUserProfileAccountHandleAndAvatar'
import { useUnfollowMutation } from '../hooks/useUnfollowMutation'

interface FollowButtonProps {
  displayName: string
  fullWidth?: boolean
  isFollowed: boolean
  label: string
  startIcon?: AvailableIcons
  userId: string
  variant: ButtonVariant
}

export const FollowButton = ({
  displayName,
  fullWidth,
  isFollowed,
  label,
  startIcon,
  userId,
  variant,
}: FollowButtonProps) => {
  const { isAnonymous } = useFirebaseUser()
  const { addErrorSnack } = useSnackbar()
  const { isEditingMode } = useCheckEditingMode()
  //GraphQl
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const mutationOptions = {
    refetchQueries: [
      {
        query: FOLLOWERS_QUERY,
        variables: {
          accountHandle: data?.currentUser?.userProfile?.accountHandle,
          userId,
        },
      },
      {
        query: getFeedFromCurrentUserQuery,
      },
    ],
  }
  const [follow] = useFollowMutation(mutationOptions)
  const [unfollow] = useUnfollowMutation(mutationOptions)

  const onClick = async () => {
    if (isAnonymous) {
      dispatch('openSignin', {
        isCreateAccount: false,
      })
      return
    }

    let response = null
    const variables = { input: { userId } }

    if (isFollowed) response = await unfollow({ variables })
    else response = await follow({ variables })

    if (response?.errors) {
      addErrorSnack({
        title: `An unknown error happened while trying to ${isFollowed ? 'unfollow' : 'follow'} ${displayName}.`,
      })
    }
  }

  return (
    <Button
      aria-label={`${isFollowed ? 'Unfollow' : 'Follow'} ${displayName}`}
      fullWidth={fullWidth}
      label={label}
      size='small'
      startIcon={startIcon}
      type='button'
      variant={variant}
      onClick={!isEditingMode ? onClick : null}
    />
  )
}
