import { useEffect } from 'react'
import { Icon } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { ButtonLink, Helmet } from 'src/common/components'
import { MobileBottomNav } from 'src/common/components/MobileBottomNav'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dashboardPath } from 'src/constants'
import { HomeCompetitionBanner } from './HomeCompetitionBanner'
import { HomeContainer } from './HomeContainer'
import { HomeFindYourNextDestination } from './HomeFindYourNextDestination'
import { HomeGetInspired } from './HomeGetInspired'
import { HomePopularProfiles } from './HomePopularProfiles'
import { Separator } from './Separator'

export const Home = () => {
  const { isAnonymous } = useFirebaseUser()
  const enableCompetition2024 = useFlag('enableCompetition2024')
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAnonymous) {
      navigate(dashboardPath)
    }
  }, [isAnonymous])

  return (
    <>
      <Helmet canonicalUrl='https://www.travelpass.com' />
      <div className='bg-forest c-white bg-cover bg-center' id='guides-hero'>
        <div className='from-forest/80 md-via-transparent md:w-50% w-full to-transparent md:bg-gradient-to-r md:via-50% lg:w-full'>
          <div className='md:mx-none lg:max-w-340 mx-auto px-8 pb-[70vh] pt-36 text-center md:pb-[20vh] md:text-left lg:mx-auto lg:pb-[25vh] lg:pt-[15vh]'>
            <div className='lg:w-50% space-y-8'>
              <div className='space-y-2'>
                <h1 className='type-h1 c-white text-9 md:leading-12 md:text-12 lg:leading-16 lg:text-16 text-balance leading-9'>
                  Discover, Plan & Share With Travelpass
                </h1>
              </div>
              <ButtonLink id='guide-cta-hero' to='/guides/all' variant='valley'>
                Explore Guides
                <Icon name='arrowForward' size='medium' />
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
      <HomeContainer className='bg-[radial-gradient(61.11%_100%_at_50.03%_100%,_#125E5C_26%,_#042E30_100%)] px-2'>
        <div className='max-w-340 relative mx-auto pt-36'>
          <img
            alt=''
            className='drag-none left-50% md:translate-x-none translate-x--50% max-w-90 md:max-w-40vw lg:max-w-25vw translate-y--40% absolute top-0 md:left-0'
            loading='eager'
            src='https://static.travelpass.com/assets/home-page/guides/guides-1-l.png'
          />
          <img
            alt=''
            className='drag-none right--50% md:translate-x-none translate-x--50% max-w-90 md:max-w-40vw lg:max-w-25vw translate-y--33% absolute top-0 hidden md:right-0 md:block'
            loading='eager'
            src='https://static.travelpass.com/assets/home-page/guides/guides-1-r.png'
          />
          <div className='space-y-6 text-center'>
            <h4 className='type-overline c-white uppercase'>
              Personalized travel guides
            </h4>
            <Separator />
            <h3 className='type-h2 c-white'>
              Effortlessly Create And Share Your Top Spots
            </h3>
            <p className='c-white type-body-1 md:hidden'>
              No more spreadsheets, no more scattered&nbsp;notes.<br></br>Easily
              share advice and experiences with<br></br>friends, family, or
              other travelers.
            </p>
            <p className='c-white type-body-1 hidden md:block'>
              No more spreadsheets, no more scattered notes. Easily share advice
              <br></br>
              and experiences with friends, family, or other travelers.
            </p>
            <div>
              <ButtonLink
                id='guide-cta-top-spots'
                to='/list/create'
                variant='white-outline'
              >
                Create your guide
              </ButtonLink>
            </div>
          </div>
          <div className='hidden md:block'>
            <div className='of-hidden'>
              <img
                alt=''
                className='px-5% drag-none translate-y-10% max-w-full'
                loading='lazy'
                src='https://static.travelpass.com/assets/home-page/guides/product-demo-2-desktop.webp'
              />
            </div>
          </div>
          <img
            alt=''
            className='drag-none block max-w-full md:hidden'
            loading='lazy'
            src='https://static.travelpass.com/assets/home-page/guides/product-demo-mobile.webp'
          />
        </div>
      </HomeContainer>
      {enableCompetition2024 && <HomeCompetitionBanner />}
      <HomeContainer className='space-y-6 py-24 text-center'>
        <HomeGetInspired />
      </HomeContainer>
      <HomeContainer className='bg-warm-grey space-y-12 px-4 pb-12 pt-24 text-center'>
        <div className='space-y-4'>
          <h4 className='type-overline uppercase'>How guides work</h4>
          <Separator />
          <h3 className='type-h2'>Showcase And Share Your Favorite Places</h3>
        </div>
        <div className='max-w-300 mx-auto grid gap-8 md:grid-cols-3'>
          <div className='space-y-4'>
            <img
              alt=''
              className='drag-none h-50 bg-contain'
              loading='lazy'
              src='https://static.travelpass.com/assets/home-page/guides/choose-focus.svg'
            />
            <h6 className='type-h4'>Decide What To Share</h6>
            <p className='type-body-1'>
              Whether you want to build a list of hidden gems in&nbsp;a specific
              city, or showcase your favorite Spanish beaches... We&apos;ve got
              you.
            </p>
          </div>
          <div className='space-y-4'>
            <img
              alt=''
              className='drag-none h-50 bg-contain'
              loading='lazy'
              src='https://static.travelpass.com/assets/home-page/guides/add-recommendations.svg'
            />
            <h6 className='type-h4'>Add Recommendations</h6>
            <p className='type-body-1'>
              Curate your list of top spots, personalize it with notes, and
              watch it come to life on the map.
            </p>
          </div>
          <div className='space-y-4'>
            <img
              alt=''
              className='drag-none h-50 bg-contain'
              loading='lazy'
              src='https://static.travelpass.com/assets/home-page/guides/share-or-publish.svg'
            />
            <h6 className='type-h4'>Share Your Guide</h6>
            <p className='type-body-1'>
              Send it to your friends and family to inspire their travels, or
              publish it for the world to explore.
            </p>
          </div>
        </div>
        <div>
          <ButtonLink
            id='guide-cta-how-it-works'
            to='/list/create'
            variant='forest'
          >
            Create your&nbsp;guide
          </ButtonLink>
        </div>
        <section className='lg:py-18 flex flex-col items-center justify-center gap-16 lg:flex-row'>
          <div className='flex flex-wrap items-center justify-center gap-8 md:flex-row'>
            <p className='w-full md:w-fit'>Proud Partners&nbsp;of</p>
            <a href='/utah-jazz' target='_blank'>
              <img
                alt='Utah Jazz NBA team logo'
                className='drag-none max-h-50px block w-auto object-contain invert'
                src='https://static.travelpass.com/assets/landing-pages/utah-jazz/jazz-note-white.webp'
              />
            </a>
            <a href='/salt-lake-bees' target='_blank'>
              <img
                alt='Salt Lake Bees triple-A baseball team logo'
                className='drag-none max-h-50px block w-auto object-contain'
                src='https://static.travelpass.com/assets/brands/salt-lake-bees/logo-2.png'
              />
            </a>
          </div>
          <div className='flex flex-wrap items-center justify-center gap-8 md:flex-row'>
            <p className='w-full md:w-fit'>
              Featured&nbsp;in
              <span className='sr-only'>
                Travel+Leisure magazine & Hospitalitynet
              </span>
            </p>
            <a href='https://www.travelandleisure.com/travelpass-hotel-discount-booking-platform-launch-7487906'>
              <img
                alt='Travel+Leisure magazine logo'
                className='drag-none max-h-35px block w-auto object-contain'
                src='https://static.travelpass.com/assets/brands/travel+leisure/logo.webp'
              />
            </a>
            <a href='https://www.hospitalitynet.org/opinion/4118329.html'>
              <img
                alt='Hospitalitynet logo'
                className='drag-none max-h-35px block w-auto object-contain'
                src='https://static.travelpass.com/assets/brands/hospitalitynet/logo.png'
              />
            </a>
          </div>
        </section>
      </HomeContainer>
      <HomeContainer className='bg-top-center pb-39 space-y-10 bg-[url(https://static.travelpass.com/assets/homev2-page/travelers-bg-mobile.webp)] bg-cover bg-no-repeat text-center md:bg-[url(https://static.travelpass.com/assets/homev2-page/travelers-bg-desktop.webp)]'>
        <HomePopularProfiles />
        <HomeFindYourNextDestination />
      </HomeContainer>
      <MobileBottomNav />
    </>
  )
}
