import { useRef, useState } from 'react'
import { Button, DateRangePicker, Icon } from '@travelpass/design-system'
import dayjs from 'dayjs'
import type { DateInterval } from 'react-day-picker'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { EventType } from 'src/__generated__/graphql'
import { GeocoderWithTextSearch } from 'src/common/components/GeocoderWithTextSearch/GeocoderWithTextSearch'
import { GuestConfigurationPopover } from 'src/common/components/Guests/GuestConfigurationPopover'
import { useLucencyNumber } from 'src/config/analytics/useLucencyNumber'
import { destinationsPath, guidesResultsPath } from 'src/constants'
import type { DatesType, GuestsType, GeocoderType } from 'src/constants/user'
import {
  initialDates,
  initialGeocoder,
  initialGuests,
} from 'src/constants/user'
import { getHomeHeroContentsArgumentsAsString } from './homeHeroContentsUtils'

interface HomeHeroContentsProps {
  type?: string
}

const PlaceOutlineIcon = () => (
  <span className='c-new-forest'>
    <Icon name='placeOutline' />
  </span>
)

const MAX_DATE = dayjs().add(1, 'year').toDate()

export const HomeHeroContents = ({ type }: HomeHeroContentsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const autocompleteRef = useRef<HTMLInputElement>(null)

  const [dates, setDates] = useState<DatesType>(initialDates)
  const [guests, setGuests] = useState<GuestsType>(initialGuests)
  const { updateLucency } = useLucencyNumber()

  const displayName = searchParams.get('displayName')
  const loc = searchParams.get('location')
  const lat = searchParams.get('latitude')
  const lng = searchParams.get('longitude')
  const [geocoder, setGeocoder] = useState(() => {
    return loc
      ? {
          center: [parseFloat(lat), parseFloat(lng)],
          placeName: loc,
        }
      : initialGeocoder
  })

  const [error, setError] = useState('')
  const isExperience = type === EventType.Experience
  const isGuide = type === 'GUIDE'
  const isStay = type === EventType.Stay
  const isUniversal = type === 'ALL'

  const onClick = () => {
    if (isGuide) {
      if (autocompleteRef?.current?.value?.trim() === '') {
        return setError('Please select a destination to search')
      }
      // logic to allow executing search without first clicking the 'TEXT STRING' autocomplete option
      // since onOptionSelect is not being called in this case, the logic needs to be handled here rather than in the GeocoderWithTextSearch component
      if (!loc) {
        setSearchParams(sp => {
          sp.set('displayName', autocompleteRef?.current?.value)
          return sp
        })
      }

      setError('')
      return navigate(`${guidesResultsPath}/?${searchParams.toString()}`)
    }

    // useGuideSearchQuery requires either A) all three of lat, long, loc
    if (!lat || !lng || !loc)
      return setError('Please select a destination to search')

    if (isExperience || isStay) {
      return navigate(
        getHomeHeroContentsArgumentsAsString({
          dates,
          geocoder,
          guests,
          isExperience,
        })
      )
    } else {
      return navigate(`${destinationsPath}/?${searchParams.toString()}`)
    }
  }

  const onResult = ({
    updatedGeocoder,
    textString,
  }: {
    updatedGeocoder?: GeocoderType
    textString?: string
  }) => {
    if (!updatedGeocoder) {
      setSearchParams(sp => {
        sp.delete('latitude')
        sp.delete('longitude')
        sp.set('displayName', textString)
        return sp
      })
    } else {
      setSearchParams(sp => {
        if (!updatedGeocoder.placeName) {
          sp.delete('latitude')
          sp.delete('longitude')
        } else {
          sp.set(
            'displayName',
            updatedGeocoder?.addressComponents?.find(component =>
              component.types.includes('locality')
            )?.long_name ||
              updatedGeocoder?.addressComponents?.[0]?.long_name ||
              displayName ||
              ''
          )
          sp.set('location', updatedGeocoder.placeName)
          sp.set('latitude', updatedGeocoder.center?.[0]?.toString())
          sp.set('longitude', updatedGeocoder.center?.[1]?.toString())
        }
        return sp
      })
    }
  }

  const onDateSelect = range => {
    updateLucency({
      checkin: range.from,
      checkout: range?.to,
    })
    setDates([range.from, range?.to])
  }

  const oneMonthIntervalMatcher: DateInterval = {
    after: dates[0].add(30, 'day').toDate(),
    before: dates[0].subtract(30, 'day').toDate(),
  }

  return (
    <div className='rounded-3 grid w-full grid-cols-12 items-end gap-x-2 gap-y-2 bg-white px-3 py-5 md:gap-x-6 md:px-8 md:py-4'>
      <div
        className={`${isGuide || isUniversal ? 'md:col-span-9' : 'md:col-span-4'} col-span-full`}
      >
        <GeocoderWithTextSearch
          fullWidth
          allowTextSearch={isGuide}
          checkTypes={!isExperience}
          errorText={error}
          focusOnInput={false}
          geocoder={geocoder}
          isInvalid={!!error}
          label='Destination'
          placeholder='Where are you going?'
          ref={autocompleteRef}
          slotBefore={<PlaceOutlineIcon />}
          onClear={() => {
            setSearchParams(sp => {
              sp.delete('displayName')
              sp.delete('location')
              sp.delete('latitude')
              sp.delete('longitude')
              return sp
            })
          }}
          onClick={event =>
            event.currentTarget.querySelector('input')?.select()
          }
          onFocus={() => setError('')}
          onResult={(textString, updatedGeocoder) => {
            if (updatedGeocoder) setGeocoder(updatedGeocoder)
            onResult({ updatedGeocoder, textString })
          }}
        />
      </div>
      {isStay && (
        <div className='col-span-5 md:col-span-2'>
          <GuestConfigurationPopover
            guests={guests}
            onChange={updatedGuests => setGuests(updatedGuests)}
          />
        </div>
      )}
      {(isExperience || isStay) && (
        <div
          className={
            isExperience
              ? 'col-span-full md:col-span-5'
              : 'col-span-7 md:col-span-3'
          }
        >
          <DateRangePicker
            canSelectSameDay={isExperience}
            disabled={!dates[1] && oneMonthIntervalMatcher}
            label={isExperience ? 'Travel Dates' : 'Check In - Check Out'}
            numberOfMonths={1}
            selected={{
              from: dates[0],
              to: dates[1],
            }}
            selectedValueFormat='M/DD'
            toDate={MAX_DATE}
            onSelect={onDateSelect}
          />
        </div>
      )}
      <span className='col-span-full mb-1 md:col-span-3'>
        <Button
          fullWidth
          label='Search'
          size='large'
          startIcon='search'
          onClick={onClick}
        />
      </span>
    </div>
  )
}
