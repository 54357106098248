import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Illustration,
  ModalNext,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import dayjs from 'dayjs'
import { CompetitionSortEnum } from 'src/__generated__/graphql'
import type { UserProfile } from 'src/__generated__/graphql'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { dispatch } from 'src/common/hooks/useListen'
import { useUserProfileQuery } from 'src/common/hooks/useUserProfileQuery'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getTracker } from 'src/utils'
import { ContestantCard } from './ContestantCard'
import { ContestantCardLoading } from './ContestantCardLoading'
import { SortFilterSearch } from './SortFilterSearch'
import { VoteModalListener } from './VoteModalListener'
import { dispatchToggleVoteModal } from './dispatchToggleVoteModal'
import { useCompetitionLeaderboardQuery } from './hooks/useCompetitionLeaderboardQuery'
import { useCreateVoteMutation } from './hooks/useCreateVoteMutation'
import { useLeaderboardSearchParams } from './hooks/useLeaderboardSearchParams'
import { VideoPreview } from '../profile/content-section/guides/VideoPreview'

const DEFAULT_VARIABLES = {
  first: 12,
  competitionLeaderboardRequest: {
    category: null,
    sortBy: CompetitionSortEnum.Popular,
    userProfile: null,
  },
}

export const Leaderboard = () => {
  // Custom hooks
  const { addErrorSnack } = useSnackbar()
  const { isAnonymous, email } = useFirebaseUser()
  const { isMobileScreen } = useScreenQuery()
  const { sortBy: spSortBy, userProfile: spUserProfile } =
    useLeaderboardSearchParams()

  // Queries
  const { data: userData } = useUserProfileQuery()
  const { data, loading, error, fetchMore, refetch } =
    useCompetitionLeaderboardQuery(DEFAULT_VARIABLES)
  const [createVote] = useCreateVoteMutation()

  // State
  const [videoUrl, setVideoUrl] = useState('')
  /**@description votedContestant is used to store the user profile that the user wants to vote for until they have signed in */
  const [votedContestant, setVotedContestant] = useState<UserProfile>(null)
  const [pageCount, setPageCount] = useState(1)
  const votedContestantRef = useRef<HTMLDivElement>(null)

  // Data
  const contestants = data?.competitionLeaderboard?.edges?.map(
    edge => edge.node
  )
  const cursor =
    data?.competitionLeaderboard?.pageInfo?.hasNextPage &&
    data?.competitionLeaderboard?.pageInfo.endCursor
  const isEmpty = !contestants || contestants.length === 0
  const isVideoModalOpen = !!videoUrl
  const noResults =
    (!contestants || contestants.length === 0) && !loading && !error
  const isVotedUserInFirstPage = async () => {
    return contestants?.some(
      contestant => contestant?.userProfile?.id === votedContestant?.id
    )
  }

  const handleVoteError = () => {
    addErrorSnack({
      title: 'Error',
      subTitle:
        "Unable to capture your vote. If you're using a VPN, please try turning off your VPN and voting again.",
    })
  }

  /**@desc update the leaderboard request when the URL search params change */
  useEffect(() => {
    if (spSortBy || spUserProfile) {
      refetch({
        competitionLeaderboardRequest: {
          category: null,
          sortBy: spSortBy,
          userProfile: spUserProfile,
        },
      })
    }
  }, [spSortBy, spUserProfile])

  /**@desc Trigger the user's selected vote after they have finished signing in */
  useEffect(() => {
    if (!isAnonymous && votedContestant) {
      try {
        triggerVote(votedContestant).then(() => {
          isVotedUserInFirstPage().then(isInFirstPage => {
            if (isInFirstPage) {
              votedContestantRef.current.scrollIntoView({
                behavior: 'smooth',
              })
              setVotedContestant(null)
            } else {
              refetch({ first: pageCount * 12 }).then(() => {
                votedContestantRef.current.scrollIntoView({
                  behavior: 'smooth',
                })
                setVotedContestant(null)
              })
            }
          })
        })
      } catch {
        handleVoteError()
      }
    }
  }, [isAnonymous])

  const triggerVote = async (userProfile: UserProfile) => {
    try {
      const response = await createVote({
        variables: {
          input: {
            tracker: getTracker('tracker'),
            userProfileId: userProfile.id,
          },
        },
      })
      if (response?.data) {
        pushDataToDataLayer('vote_competition', {
          user_id: userData?.currentUser?.id,
          voted_user: {
            user_id: userProfile?.userId,
            user_name: userProfile?.displayName,
            user_account_handle: userProfile?.accountHandle,
          },
          user_email: email,
          timeStamp: `${dayjs().tz('America/Denver').format('YYYY-MM-DD HH:mm')} MST`,
        })
        dispatchToggleVoteModal(userProfile)
      }
    } catch {
      handleVoteError()
    }
  }

  const onLoadMoreClick = async () => {
    try {
      await fetchMore({
        variables: {
          after: cursor,
        },
      })
      setPageCount(pageCount + 1)
    } catch {
      addErrorSnack({
        title: 'Error displaying more results',
      })
    }
  }

  const onVoteClick = (userProfile: UserProfile) => {
    if (isAnonymous) {
      setVotedContestant(userProfile)
      dispatch('openSignin', {
        isCreateAccount: true,
      })
    } else triggerVote(userProfile)
  }

  if (error) return <></>

  return (
    <div className='job-promotion'>
      <SortFilterSearch />
      {noResults && (
        <section className='lg:pb-31 mx-auto w-fit space-y-10 px-6 pb-12 text-center'>
          <div className='max-w-186px max-h-137px mx-auto'>
            <Illustration name='resting' />
          </div>
          <p className='type-body-1 c-grey-800'>
            Sorry, it looks like we weren&apos;t able to find any contestants
            matching your search.{' '}
          </p>
        </section>
      )}
      {loading ? (
        <section className='max-w-340 mx-auto grid grid-cols-1 items-center gap-6 px-8 md:grid-cols-3 md:gap-y-12 lg:grid-cols-4'>
          <ContestantCardLoading />
          <ContestantCardLoading />
          <ContestantCardLoading />
          <ContestantCardLoading className='hidden lg:block' />
        </section>
      ) : (
        contestants &&
        !isEmpty && (
          <section className='max-w-340 mx-auto grid grid-cols-1 items-center gap-6 px-8 md:grid-cols-3 md:gap-y-12 lg:grid-cols-4'>
            {contestants.map(
              ({ canVoteLeaderboard, userProfile, voteCount }) => (
                <div
                  key={userProfile?.id}
                  className='h-full w-full'
                  ref={
                    userProfile?.id === votedContestant?.id
                      ? votedContestantRef
                      : null
                  }
                >
                  <ContestantCard
                    canVoteLeaderboard={canVoteLeaderboard}
                    userProfile={userProfile}
                    voteCount={voteCount}
                    onVoteClick={() => onVoteClick(userProfile)}
                  />
                </div>
              )
            )}
          </section>
        )
      )}
      {cursor && (
        <section className='mt-12 flex justify-center'>
          <Button
            aria-label='Load next page of contestants'
            isDisabled={loading}
            size='large'
            variant='outlined'
            onClick={onLoadMoreClick}
          >
            View more
          </Button>
        </section>
      )}
      {isVideoModalOpen && (
        <ModalNext
          aria-label='Video Modal'
          size='medium'
          onClose={() => setVideoUrl('')}
        >
          <VideoPreview
            embedVideoLink={videoUrl}
            fullWidth={true}
            height={isMobileScreen ? '180' : '360'}
          />
        </ModalNext>
      )}
      <VoteModalListener />
    </div>
  )
}
