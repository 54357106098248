import { Button } from '@travelpass/design-system'
import { type UserProfile } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { RecommendedHotelsZeroState } from './RecommendedHotelsZeroState'
import { useCheckEditingMode } from '../../components/hooks/useCheckEditingMode'
import { HotelCard } from '../new-hotels/HotelCard'

interface RecommendedHotelsProps {
  profile: UserProfile
}

export const RecommendedHotels = ({ profile }: RecommendedHotelsProps) => {
  const enableProfileFavoriteHotels = useFlag('enableProfileFavoriteHotels')
  const { hotelRecommendations, isUserOwner } = { ...profile }
  const { handleOpenEditMode } = useCheckEditingMode()

  if (
    !enableProfileFavoriteHotels ||
    (hotelRecommendations?.length == 0 && !isUserOwner)
  )
    return null

  return (
    <div className='flex flex-col gap-y-5'>
      {!enableProfileFavoriteHotels && (
        <div className='flex justify-center lg:justify-between'>
          <p className='type-h3-desktop hidden lg:flex'>My Top Hotels</p>
          {isUserOwner && !!hotelRecommendations?.length && (
            <Button
              label='Manage Hotels'
              variant='outlined'
              onClick={() => handleOpenEditMode()}
            />
          )}
        </div>
      )}
      {hotelRecommendations?.length == 0 ? (
        <RecommendedHotelsZeroState />
      ) : (
        <div className='flex flex-col gap-y-4'>
          {hotelRecommendations?.map(hotel => (
            <HotelCard key={hotel.id} hotel={hotel} />
          ))}
        </div>
      )}
    </div>
  )
}
