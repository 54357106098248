import {
  Avatar,
  Button,
  IconButton,
  useSnackbar,
} from '@travelpass/design-system'
import { CopyReferralLinkButton } from 'src/common/components/CopyReferralLinkButton/CopyReferralLinkButton'
import { GuideImage } from 'src/pages/guides/details/common'
import { getProfilePageUrl } from 'src/pages/profile/components/profileUtils'
import type { FollowersModalType } from 'src/pages/profile/types'
import { copyToClipboard } from 'src/utils'
import { DashboardProfileCompletion } from './DashboardProfileCompletion'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardProfileProps {
  dashboardData: UseGetDashboardQuery['dashboardData']
  onModalInfoOpen: () => void
  onScrollIdChange: (scrollId: string) => void
  toggleFollowersModal: (modalType: FollowersModalType) => void
}

export const DashboardProfile = ({
  dashboardData,
  onModalInfoOpen,
  onScrollIdChange,
  toggleFollowersModal,
}: DashboardProfileProps) => {
  const { userProfile, userRoles, id } = dashboardData?.currentUser ?? {}
  /** @todo When we remove profile queries entirely, */
  /** consider combining this 'profile' object with userProfile that comes from dashboardData */
  const { addSuccessSnack } = useSnackbar()
  const { profileCompletion } = userProfile ?? {}
  const ownerUrl = `${window.location.origin}${getProfilePageUrl(userProfile?.accountHandle)}`

  const onShare = () => {
    copyToClipboard(ownerUrl)
    addSuccessSnack({ title: 'Profile URL copied' })
  }

  const onCopyReferralLink = () => {
    copyToClipboard(`${window.location.origin}/competition/about?referer=${id}`)
    addSuccessSnack({ title: 'Referral link copied' })
  }

  return (
    <section className='shadow-3 rounded-3 p-y-3.5 p-x-3 space-y-6 bg-white'>
      <div className='h-37.5 relative flex flex-col items-center'>
        <GuideImage
          className='rounded-t-3 absolute inset-0'
          src={userProfile?.activeBannerImage?.smallUrl}
        />
        <a
          className='bottom--10.5 [&>img]:shadow-1 absolute'
          href={ownerUrl}
          rel='noreferrer'
          target='_blank'
        >
          <Avatar border='sm' size='md' src={userProfile?.profileImageUrl} />
        </a>
      </div>
      <div className='p-t-10 space-y-8 p-3'>
        <header className='space-y-4'>
          <div className='flex flex-row items-center justify-between gap-4'>
            <h4 className='type-h4-desktop'>{userProfile?.displayName}</h4>
            <div className='flex flex-row items-center justify-between gap-2'>
              <IconButton
                icon='modeEdit'
                size='small'
                onClick={() => {
                  onScrollIdChange('')
                  onModalInfoOpen()
                }}
              />
              <IconButton icon='iosShare' size='small' onClick={onShare} />
            </div>
          </div>
          <div className='flex flex-row items-center gap-4'>
            <div className='[&>button]:p-x-0 [&>button>div]:color-black flex flex-row items-center gap-1.5'>
              <p className='type-body-1-medium-desktop color-black'>
                {userProfile?.followCount?.countOfFollowing ?? 0}
              </p>
              <Button
                size='small'
                variant='text'
                onClick={() => toggleFollowersModal('followers')}
              >
                Followers
              </Button>
            </div>
            <div className='[&>button]:p-x-0 [&>button>div]:color-black flex flex-row items-center gap-1.5'>
              <p className='type-body-1-medium-desktop color-black'>
                {userProfile?.followCount?.countOfFollowed ?? 0}
              </p>
              <Button
                size='small'
                variant='text'
                onClick={() => toggleFollowersModal('following')}
              >
                Following
              </Button>
            </div>
          </div>
          <CopyReferralLinkButton id={id} userRoles={userRoles} />
        </header>
        {profileCompletion?.completionPercentage < 1 && (
          <DashboardProfileCompletion
            profileCompletion={profileCompletion}
            onModalInfoOpen={onModalInfoOpen}
            onScrollIdChange={onScrollIdChange}
          />
        )}
      </div>
    </section>
  )
}
