import { useEffect } from 'react'
import { Illustration } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { PartyTypeEnum } from 'src/__generated__/graphql'
import { CheckedTile } from '../../common/CheckedTile'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useCurrentUserSearchPreferences } from '../hooks/useCurrentUserSearchPreferences'

// Field to update: partyType
export const QuestionPartyType = () => {
  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()
  const { data } = useCurrentUserSearchPreferences()
  const initialPartyType = data?.currentUser?.searchPreferences?.partyType

  const partyType = watch('partyType') || initialPartyType

  useEffect(() => {
    setValue('partyType', initialPartyType, {
      shouldTouch: true,
    })
  }, [initialPartyType])

  return (
    <div className='flex flex-col'>
      <div className='type-h2 mb-10 self-start'>Usually I travel...</div>
      <div className='flex justify-center gap-10 sm:flex-col md:flex-row'>
        <div className='flex flex-col items-center justify-center'>
          <CheckedTile
            pressed={partyType === PartyTypeEnum.Single}
            size='small'
            onClick={() =>
              setValue('partyType', PartyTypeEnum.Single, { shouldDirty: true })
            }
          >
            <Illustration name='suitcase' />
          </CheckedTile>
          <span className='type-h4 mt-2 cursor-default select-none'>Solo</span>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <CheckedTile
            pressed={partyType === PartyTypeEnum.Group}
            size='small'
            onClick={() =>
              setValue('partyType', PartyTypeEnum.Group, { shouldDirty: true })
            }
          >
            <img
              alt='party-group'
              className='max-h-50 max-w-50 object-cover'
              src='https://static.travelpass.com/assets/profile-quiz/party-group.webp'
            />
          </CheckedTile>
          <span className='type-h4 mt-2 cursor-default select-none'>
            In a group
          </span>
        </div>
      </div>
    </div>
  )
}
