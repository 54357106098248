import { useMutation, useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_PREDEFINED_PROFILE_TAGS = gql(`
  query GetPredefinedProfileTags {
    getPredefinedProfileTags {
      id
      name
      category
    }
  }
`)

const useGetPredefinedProfileTags = () => useQuery(GET_PREDEFINED_PROFILE_TAGS)

const ADD_TAG_TO_PROFILE = gql(`
  mutation AddTagToProfile($input: AddTagToProfileInput!) {
    addTagToProfile(input: $input) {
      userProfile {
        id
        tags {
          id
          name
          category
        }
      }
    }
  }
`)

const useAddTagToProfile = () => useMutation(ADD_TAG_TO_PROFILE)

const REMOVE_TAG_FROM_PROFILE = gql(`
  mutation RemoveTagFromProfile($input: RemoveTagFromProfileInput!) {
    removeTagFromProfile(input: $input) {
      userProfile {
        id
        tags {
          id
          name
          category
        }
      }
    }
  }
`)

const useRemoveTagFromProfile = () => useMutation(REMOVE_TAG_FROM_PROFILE)

export const useTags = () => {
  const { data: availableTags } = useGetPredefinedProfileTags()
  const [addTagToProfile] = useAddTagToProfile()
  const [removeTagFromProfile] = useRemoveTagFromProfile()

  return {
    availableTags: availableTags?.getPredefinedProfileTags,
    addTagToProfile,
    removeTagFromProfile,
  }
}
