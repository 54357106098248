import { useLocation } from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'

export const FavoritePlacesUtah = () => {
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />

      <div className='bg-white'>
        <div className='h-400px lg:h-700px bg-bottom-center after:bg-top-center after:w-100% after:h-120px md:after:h-150px lg:after:h-330px relative w-full bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/847d5350-e24b-11ee-8ade-e218a6003cd5)] bg-cover after:absolute after:bottom-0 after:left-0 after:z-0 after:bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/aef8b6ee-e6f4-11ee-af45-fa9284f9fbb1)] after:bg-cover after:content-[""]'>
          <div className='flex h-full flex-col justify-center'>
            <h1 className='color-white font-archivo font-not-oblique font-700 lg:mt--50 tracking-5% text-shadow-lg m-0 mt--20 flex flex-col text-center'>
              <span className='leading-34px mb--17px text-21px lg:mb--30px lg:text-40px'>
                An Insider&apos;s Guide To
              </span>
              <span className='leading-28 text-22 lg:leading-64 lg:text-50 font-900 tracking-widest'>
                Utah
              </span>
            </h1>
          </div>
        </div>

        <PageLayoutContainer>
          <div className='max-w-1000px mx-a z-2 relative mt--10 flex w-full flex-col lg:mt--20'>
            <h2 className='type-h1 c-forest mb-4 text-center'>
              Welcome To Our Backyard!
            </h2>
            <p className='type-body-1 font-300 line-height-22px my-2 text-center tracking-wide'>
              As a Utah company obsessed with exploring our own backyard, we’re
              passionate about sharing the things that make the Beehive State so
              great. All things Utah. With so much to see and do here, we feel
              like anyone can find their own version of Utah to fall in love
              with — whether that’s for the world-famous snow, the slot canyon
              hiking, or worth-the-hype brunch spots and music venues.
            </p>
            <p className='type-body-1 font-300 line-height-22px my-2 text-center tracking-wide'>
              It’s time to truly explore what this state has to offer. Come
              discover favorite restaurants, places to explore, our local tips,
              and more.
            </p>
          </div>
        </PageLayoutContainer>

        <PageLayoutContainer>
          <div className='lg:h-600px my-10 grid h-auto grid-flow-row grid-cols-1 grid-rows-5 gap-4 lg:grid-flow-col lg:grid-cols-2 lg:gap-6'>
            <div className='sm:h-200px bg-forestDark relative col-span-2 row-span-5 overflow-hidden lg:col-span-1 lg:h-auto'>
              <a href='/inside-scoop/salt-lake-city'>
                <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4 lg:left-8 lg:top-8'>
                  SALT LAKE CITY
                </p>
                <img
                  alt='Salt Lake City, Utah'
                  className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-40'
                  src='https://static.travelpass.com/assets/destinations-page/salt-lake-city-utah/book-a-hotel.webp'
                />
              </a>
            </div>

            <div className='sm:h-200px bg-forestDark relative col-span-2 row-span-3 overflow-hidden lg:col-span-1 lg:h-auto'>
              <a href='/inside-scoop/southern-utah'>
                <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4 lg:left-8 lg:top-8'>
                  SOUTHERN UTAH
                </p>
                <img
                  alt='Zion National Park, Utah'
                  className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-40'
                  src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/028343ca-d4c5-11ee-93f3-a65382347988'
                />
              </a>
            </div>

            <div className='sm:h-200px bg-forestDark relative col-span-2 overflow-hidden lg:col-span-1 lg:row-span-2 lg:h-auto'>
              <a href='/inside-scoop/utah-county'>
                <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4 lg:left-8 lg:top-8'>
                  UTAH COUNTY
                </p>
                <img
                  alt='Provo, Utah'
                  className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-40'
                  src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/c8619e24-e70e-11ee-9ea9-ca13f8e0f9ae'
                />
              </a>
            </div>
          </div>
        </PageLayoutContainer>
      </div>
      <div className='h-200px md:h-300px lg:h-600px relative w-full'>
        <div className='h-50px lg:h-100px absolute left-0 top-0 w-full bg-gradient-to-b from-white'></div>
        <img
          alt='Hiker hiking down a mountainside'
          className='object-center-bottom z-0 h-full w-full object-cover'
          src='https://static.travelpass.com/assets/homev2-page/about.webp'
        />
      </div>
    </>
  )
}

const metaDescription =
  'As a Utah company obsessed with exploring our own backyard, we’re on a mission to share all things Utah. With so much to see and do here, we feel like anyone can find their own version of Utah to fall in love with.'
const pageName = 'An Insider’s Guide To Utah: Welcome To Our Backyard!'
