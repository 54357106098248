import type { RefObject } from 'react'
import { useState, useEffect, useRef } from 'react'

interface Dimensions {
  width: number
  height: number
}

export const useGetDivDimensions = (): [
  RefObject<HTMLDivElement>,
  Dimensions,
] => {
  const ref = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        setDimensions({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        })
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  return [ref, dimensions]
}
