import { useEffect, useState } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import { initialGeocoder, type GeocoderType } from 'src/constants/user'
import { constructGeocoderFromAddress } from 'src/pages/trips/utils'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from './guideDraftConstants'
import type { GuideDraftData } from './types'
import {
  getUpdateGuideDraftVariables,
  useUpdateGuideDraftMutation,
} from './useUpdateGuideDraftMutation'

type GuideDraftEditFormErrors = {
  name: string
  description: string
  geocoder: string
  tagIds: string
}

type GuideDraftEditFormFields = Pick<GuideDraftData, 'description' | 'name'> & {
  geocoder: GeocoderType
  tagIds: string[]
}

type GuideDraftEditFormHelperText = Pick<
  GuideDraftData,
  'description' | 'name'
> & {
  tagIds: string
}

type UseUpdateGuideDraftForm = {
  formErrors: GuideDraftEditFormErrors
  formFields: GuideDraftEditFormFields
  formHelperText: GuideDraftEditFormHelperText
  onFieldChange: <FieldKey extends keyof GuideDraftEditFormFields>(
    key: FieldKey,
    value: GuideDraftEditFormFields[FieldKey]
  ) => void
  onSubmit: VoidFunction
  onTagIdsChange: (updatedTagId: string) => void
}

const initialFormErrors: GuideDraftEditFormErrors = {
  name: '',
  description: '',
  geocoder: '',
  tagIds: '',
}

const initialFormFields: GuideDraftEditFormFields = {
  name: '',
  description: '',
  geocoder: initialGeocoder,
  tagIds: [],
}

const getInitialFormFields = (
  guideDraftData: GuideDraftData
): GuideDraftEditFormFields => {
  const { name, description, addresses, tags } = guideDraftData ?? {}

  return {
    name: name || '',
    description: description || '',
    geocoder: constructGeocoderFromAddress(addresses?.[0]) ?? initialGeocoder,
    tagIds: tags?.map(({ id }) => id) ?? [],
  }
}

const getTagHelperText = (tagIdsLength: number) => {
  if (!tagIdsLength) return

  if (tagIdsLength === 1) return '1 Tag Selected*'

  return `${tagIdsLength} Tags Selected*`
}

const maxTags = 5

const useUpdateGuideDraftForm = ({
  guideDraftData,
  onDismiss,
}: {
  guideDraftData: GuideDraftData
  onDismiss: VoidFunction
}): UseUpdateGuideDraftForm => {
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [formErrors, setFormErrors] =
    useState<GuideDraftEditFormErrors>(initialFormErrors)
  const [formFields, setFormFields] =
    useState<GuideDraftEditFormFields>(initialFormFields)
  const [updateGuideDraft, { loading: isLoading }] =
    useUpdateGuideDraftMutation()
  const formHelperText: GuideDraftEditFormHelperText = {
    description: `${formFields?.description?.length}/${guideDraftDescriptionMaxLength} characters`,
    name: `${formFields?.name?.length}/${guideDraftNameMaxLength} characters`,
    tagIds: getTagHelperText(formFields?.tagIds?.length),
  }
  useEffect(() => {
    validateFormFields(false)
  }, [formFields])

  useEffect(() => {
    setFormErrors(initialFormErrors)
    setFormFields(getInitialFormFields(guideDraftData))
  }, [guideDraftData])

  const onFieldChange = <FieldKey extends keyof GuideDraftEditFormFields>(
    key: FieldKey,
    value: GuideDraftEditFormFields[FieldKey]
  ) => {
    setFormFields(prevFields => ({
      ...prevFields,
      [key]: value,
    }))
  }

  const onTagIdsChange = (updatedTagId: string) => {
    let updatedTagIds: GuideDraftEditFormFields['tagIds'] = []

    if (formFields?.tagIds.includes(updatedTagId)) {
      updatedTagIds = formFields?.tagIds.filter(id => id !== updatedTagId)
    } else {
      updatedTagIds = [...formFields?.tagIds, updatedTagId]
    }

    if (updatedTagIds.length > maxTags) return

    onFieldChange('tagIds', updatedTagIds)
  }

  const onSubmit = async () => {
    if (isLoading || !validateFormFields()) return

    try {
      await updateGuideDraft({
        variables: getUpdateGuideDraftVariables({
          description: formFields.description,
          geocoder: formFields.geocoder,
          guideDraftId: guideDraftData?.id,
          name: formFields.name,
          tagIds: formFields.tagIds,
        }),
      })
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide has been updated',
      })
      onDismiss()
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  const validateFormFields = (validateAllFields = true) => {
    const errors = {
      ...initialFormErrors,
    }
    const fieldsToValidate = validateAllFields
      ? Object.keys(formFields)
      : Object.keys(formErrors).filter(key => formErrors[key])

    fieldsToValidate.forEach(field => {
      if (field === 'name' && !formFields.name) {
        errors.name = 'Guide Name is required.'
      }

      if (field === 'description') {
        if (!formFields.description) {
          errors.description = 'Guide Description is required.'
        } else if (formFields.description.indexOf('http') !== -1) {
          errors.description = 'Guide Description cannot contain URLs.'
        }
      }

      if (field === 'geocoder' && !formFields.geocoder?.center?.length) {
        errors.geocoder = 'Guide Location is required.'
      }

      if (field === 'tagIds' && !formFields.tagIds?.length) {
        errors.tagIds = 'At least one tag is required.'
      }
    })

    setFormErrors(errors)

    return !Object.values(errors).some(Boolean)
  }

  return {
    formErrors,
    formFields,
    formHelperText,
    onFieldChange,
    onSubmit,
    onTagIdsChange,
  }
}

export type { GuideDraftEditFormFields }
export { initialFormErrors, useUpdateGuideDraftForm }
