import { Button, Divider, Icon, useSnackbar } from '@travelpass/design-system'
import classNames from 'classnames'
import { CollectionItemIdType, type Guide } from 'src/__generated__/graphql'
import { ResultsCard } from 'src/common/components'
import { AddToMenu } from 'src/common/components/Collections/AddToMenu'
import { useFlag } from 'src/common/hooks'
import { useShareGuide } from 'src/pages/guides'
import { getEventImage } from 'src/pages/trips/utils'
import { getGuideAddress } from 'src/utils'
import { GuideEventCardDescription } from './GuideEventCardDescription'
import { GuideEventMetricLike, GuideImage } from '..'
import type { GuideEvent, GuideOwner } from '../../types'
import {
  onGuideSessionStorageHoverIdChange,
  onGuideSessionStorageSelectedIdChange,
} from '../../useGuideSessionStorageIds'
import { useIncrementGuideEventShareCountMutation } from '../../useIncrementGuideEventShareCountMutation'

interface GuideEventCardProps {
  guideEventCardData: GuideEvent
  isHovered: boolean
  isSelected: boolean
  owner: GuideOwner
}

export const GuideEventCard = ({
  guideEventCardData,
  isHovered,
  isSelected,
  owner,
}: GuideEventCardProps) => {
  const shareGuide = useShareGuide()
  const [incrementGuideEventShareCount] =
    useIncrementGuideEventShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const { addresses, id, imageUrl, isCollected, name, notes } =
    guideEventCardData ?? {}
  const { addressLine1, city, country, state } = addresses?.[0] ?? {}
  const { isUserOwner } = owner ?? {}
  const addressFormatted = getGuideAddress({
    addressLine1,
    city,
    country,
    state,
  })
  const src = getEventImage(imageUrl)

  const onMouseEnter = () => onGuideSessionStorageHoverIdChange(id)

  const onMouseLeave = () => onGuideSessionStorageHoverIdChange('')

  /** @todo account for Pagination/SelectedId on this share so that the drawer opens when the receiver opens the Url */
  const onShareGuideEvent = async () => {
    await shareGuide({
      guideDraftTitle: name,
      isUserOwner,
      shareUrl: window.location.href,
      onSuccessfulShare: () => {
        addSuccessSnack({ timeout: 1000, title: 'Guide URL copied' })
      },
    })
    await incrementGuideEventShareCount({
      variables: {
        publishedEventId: id,
      },
    })
  }

  return (
    <>
      {name && (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <ResultsCard
            className={classNames({
              'shadow-1': isSelected || isHovered,
            })}
            isCollected={isCollected}
            onClick={() =>
              onGuideSessionStorageSelectedIdChange(isSelected ? '' : id)
            }
          >
            <div
              className={classNames(
                'b-2px b-solid rounded-4 flex w-full flex-col gap-2 md:flex-row',
                {
                  'b-forestLight bg-mintLight': isSelected,
                  'b-grey-300': !isSelected,
                }
              )}
            >
              <div className='min-h-35 md:min-w-45.5 md:w-45.5 pointer-events-none relative w-full md:min-h-0'>
                <GuideImage
                  alt=''
                  className='rounded-3 absolute inset-0 h-full w-full object-cover'
                  loading='lazy'
                  src={src}
                />
              </div>
              <div className='p-x-4 p-y-3 pointer-events-none relative grow space-y-3 md:max-w-[calc(100%-198px)]'>
                <div className='space-y-1'>
                  <div className='flex flex-row items-center justify-between'>
                    <h3 className='type-body-1-medium-desktop type-h5-mobile color-forest-dark line-clamp-1'>
                      {name}
                    </h3>
                    <div className='pointer-events-auto'>
                      <GuideEventMetricLike
                        guideEventData={guideEventCardData}
                      />
                    </div>
                  </div>
                  <p className='c-orange flex flex-row items-center gap-1'>
                    <Icon name='placeOutline' size='small' />
                    <span className='c-black type-subtext line-clamp-1 max-h-3.5 grow lg:max-h-3.5'>
                      {addressFormatted}
                    </span>
                  </p>
                </div>
                <div className='md:min-h-18 flex min-h-16 flex-col justify-between'>
                  <GuideEventCardDescription notes={notes} owner={owner} />
                </div>
                <div className='md:hidden'>
                  <Divider />
                </div>
                <div className='z-4 pointer-events-auto flex gap-2 self-start md:gap-3'>
                  <AddToMenu
                    item={{ id, name, type: CollectionItemIdType.Event }}
                    variant='text'
                  />
                  {/** @todo consider abstracting this into a GuideEventMetricShare component (may be unecessary since we don't render the metric count) */}
                  <Button
                    size='small'
                    startIcon='iosShare'
                    variant='text'
                    onClick={onShareGuideEvent}
                  >
                    Share
                  </Button>
                </div>
              </div>
            </div>
          </ResultsCard>
        </div>
      )}
    </>
  )
}
