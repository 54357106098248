import { Button, Link } from '@travelpass/design-system'
import { useLocation } from 'react-router-dom'
import { SortBy } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'
import {
  generateHotelResultsUrl,
  generateExperiencesResultsUrl,
} from 'src/utils'
import { LandingPageSearchHotelsResults } from '../../../common/LandingPageSearchHotelsResults'

export const SaltLakeCity = () => {
  const { pathname } = useLocation()

  const canonicalUrl = baseUrl + pathname

  const date = () => new Date()
  const today = date().toISOString().split('T')[0]
  const dateTomorrow = () => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }
  const tomorrow = dateTomorrow().toISOString().split('T')[0]

  const onUtahJazzHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Salt Lake City, UT, USA',
      latitude: 40.7607793,
      longitude: -111.8910474,
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onSaltLakeBeesHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Salt Lake City, UT, USA',
      latitude: 40.751761575078774,
      longitude: -111.893515958367,
      mapBounds: JSON.stringify({
        south: 40.73552409895977,
        west: -111.9303002518429,
        north: 40.767999051197776,
        east: -111.85673166489113,
      }),
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onBestOfSlcHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Salt Lake City, UT, USA',
      latitude: 40.763951626373,
      longitude: -111.88714893907755,
      mapBounds: JSON.stringify({
        south: 40.74769400506764,
        west: -111.92398562361296,
        north: 40.780209247678364,
        east: -111.85031225454212,
      }),
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onBestOfSlcExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'Salt Lake City, UT, USA',
      latitude: 40.763951626373,
      longitude: -111.88714893907755,
    })
    window.open(url)
  }

  const onParkCityHotelsClick = () => {
    const url = generateHotelResultsUrl({
      location: 'Park City, UT, USA',
      latitude: 40.6460622,
      longitude: -111.4979729,
      sort: SortBy.Recommended,
    })
    window.open(url)
  }

  const onParkCityExperiencesClick = () => {
    const url = generateExperiencesResultsUrl({
      location: 'Park City, UT, USA',
      latitude: 40.6460622,
      longitude: -111.4979729,
    })
    window.open(url)
  }

  const jumpToUtahJazz = e => {
    e.preventDefault()
    const node = document.getElementById('utah-jazz')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToSaltLakeBees = e => {
    e.preventDefault()
    const node = document.getElementById('salt-lake-bees')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToBestOfSlc = e => {
    e.preventDefault()
    const node = document.getElementById('best-of-slc')
    node?.scrollIntoView({ behavior: 'smooth' })
  }
  const jumpToParkCity = e => {
    e.preventDefault()
    const node = document.getElementById('park-city')
    node?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />

      <header className='h-200px lg:h-300px bg-top-center w-full bg-[url(https://static.travelpass.com/assets/destinations-page/salt-lake-city-utah/book-a-hotel.webp)] bg-cover lg:bg-center'></header>

      <PageLayoutContainer size='medium'>
        <div className='flex w-full flex-col gap-4 py-10'>
          <h1 className='font-archivo font-700 tracking-5% m-0 flex flex-col text-center'>
            <span className='text-6 lg:leading-14 lg:text-10 font-900 leading-6'>
              Get To Know
            </span>
            <span className='text-14 lg:leading-20 lg:text-26 font-900'>
              Salt Lake City
            </span>
          </h1>
          <p className='type-subtitle-2 font-300 lg:line-height-8 tracking-wide lg:mt-8'>
            Framed by picturesque mountains, the state&apos;s capital is the
            center of so many things to do. It&apos;s both a starting point for
            day trips to chic ski towns and a destination itself, thanks to top
            restaurants, convnention centers galore, museums aplenty, quite a
            few music venues, and a couple of major sports teams. There&apos;s a
            lot to love in SLC.
          </p>
        </div>
      </PageLayoutContainer>
      <PageLayoutContainer>
        <div className='pb-15 flex flex-col items-center justify-center gap-4 pt-5 lg:flex'>
          <Button
            fullWidth
            label='Utah Jazz'
            size='large'
            variant='filledDark'
            onClick={jumpToUtahJazz}
          />
          <Button
            fullWidth
            label='Salt Lake Bees'
            size='large'
            variant='filledDark'
            onClick={jumpToSaltLakeBees}
          />
          <Button
            fullWidth
            label='The Best of SLC'
            size='large'
            variant='filledDark'
            onClick={jumpToBestOfSlc}
          />
          <Button
            fullWidth
            label='Park City'
            size='large'
            variant='filledDark'
            onClick={jumpToParkCity}
          />
        </div>
      </PageLayoutContainer>

      <div
        className='bg-warmGrey flex w-full flex-col items-stretch justify-center lg:flex-row'
        id='utah-jazz'
      >
        <div className='h-300px bg-[url(https://static.travelpass.com/assets/landing-pages/utah-jazz/jazz-travelpass-partnership.webp)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 relative flex h-full flex-col justify-center'>
            <div className='bg-forest absolute bottom-0 left-0 flex w-full flex-row items-center justify-center gap-4 py-2'>
              <p className='type-body-2 c-white'>
                Proud Partner Of The Utah Jazz
              </p>
              <img
                alt='Jazz/Travelpass Logo Lockup'
                className='w-150px lg:w-200px block'
                src='https://static.travelpass.com/assets/landing-pages/utah-jazz/jazz-travelpass-logo-lockup.png'
              />
            </div>
            <PageLayoutContainer>
              <PageLayoutContainer size='medium'>
                <h2 className='type-h1 c-white mb-4 text-center'>
                  The Utah Jazz
                </h2>
                <p className='type-body-1 c-white mb-4 text-center'>
                  In town to cheer on the Utah Jazz? Head to Delta Center for
                  the game and get a little help arranging the pre-game and
                  post-game details.
                </p>
                <p className='type-body-1 c-white text-center'>
                  Our Travelpass team pulled together a handful of recommended
                  hotels that are within walking distance of the stadium (5
                  minutes or less!), and some nearby restaurants, too.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Stay Near Delta Center:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[101932115, 110257633, 101934960]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onUtahJazzHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Dinner Spots Nearby:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href='https://www.prettybirdchicken.com/'
                    label='Pretty Bird Hot Chicken (order the special sauce)'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='http://www.crown-burgers.com/locations.php'
                    label='Crown Burgers for pastrami-topped classics'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='https://www.chile-tepin.com/home'
                    label='Chile Tepin (split the Parrillada!)'
                    target='_blank'
                  />
                </li>
              </ul>
            </div>
          </PageLayoutContainer>
        </div>
      </div>

      <div
        className='flex w-full flex-col flex-col-reverse items-stretch justify-center bg-white lg:flex-row'
        id='salt-lake-bees'
      >
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>
                Stay Near Smith’s Ballpark:
              </h3>
              <LandingPageSearchHotelsResults
                hotelIds={[100779177, 100779073, 101854943]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onSaltLakeBeesHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Dinner Spots Nearby:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href='https://www.lucky13slc.com/'
                    label='Lucky 13 (try the Nut Butter burger) '
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='https://www.toshsramenslc.com/'
                    label='Tosh’s Ramen for classic Tonkatsu'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href='https://www.slackwaterpizzeria.com/'
                    label='Slackwater SLC (creatively delicious pizza, calzones, and craft beer) '
                    target='_blank'
                  />
                </li>
              </ul>
            </div>
          </PageLayoutContainer>
        </div>
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/12564176-e898-11ee-9b3b-c2d7923f35dd)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <h2 className='type-h1 c-white mb-4 text-center'>
                The Salt Lake Bees
              </h2>
              <PageLayoutContainer size='medium'>
                <p className='type-body-1 c-white mb-4 text-center'>
                  A Bees Baseball game is a spring and summer staple, especially
                  when you have a little help planning the pre-game and
                  post-game details!
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip:</b> There is a Trax train station right by the
                  ballpark, which makes parking elsewhere and riding public
                  transit a breeze. And, &ldquo;luck&rdquo; would have it that
                  our favorite pre-game burger spot (Lucky 13) is within walking
                  distance to the ballpark!
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
      </div>

      <div
        className='bg-warmGrey flex w-full flex-col items-stretch justify-center lg:flex-row'
        id='best-of-slc'
      >
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/e39abd3a-e89c-11ee-a748-e650c0355901)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/60 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <PageLayoutContainer size='medium'>
                <h2 className='type-h1 c-white mb-4 text-center'>
                  The Best of SLC
                </h2>
                <p className='type-body-1 c-white mb-4 text-center'>
                  There’s a lot to tackle for this capital city. Are you here
                  for history? A conference, concert, or convention? No matter
                  what brings you to Salt Lake City, we’ve got you covered with
                  our recommendations for some of the top restaurants,
                  experiences and accommodations.
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>The Best Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[101855436, 100830289, 100779237]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onBestOfSlcHotelsClick}
                />
              </div>
              <div className='flex w-full flex-col gap-4 lg:flex lg:items-start lg:justify-around'>
                <div>
                  <h3 className='type-h3 self-start'>The Best Dinner Spots:</h3>
                  <ul className='self-start'>
                    <li>
                      <Link
                        href='https://ruthsdiner.com/'
                        label='Ruth’s Diner (we love the biscuits and gravy)'
                        target='_blank'
                      />
                    </li>
                    <li>
                      <Link
                        href='https://caputos.com/menu/#cat=all&loc=5583&map=0'
                        label='Caputo’s Italian Deli (the Muffaleta sandwich is a must)'
                        target='_blank'
                      />
                    </li>
                    <li>
                      <Link
                        href='https://www.instagram.com/normal.club/?hl=en'
                        label='Normal Ice Cream (flavors change monthly)'
                        target='_blank'
                      />
                    </li>
                  </ul>
                </div>
                <div className='pb-4 lg:pb-0'>
                  <h3 className='type-h3 self-start'>The Best Experiences:</h3>
                  <ul className='self-start'>
                    <li>
                      <Link
                        href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjYwOTM1/Private-Half-Day-tour-to-Bonneville-Salt-Flats?arrival=${today}&departure=${tomorrow}`}
                        label='Visit the Bonneville Salt Flats'
                        target='_blank'
                      />
                    </li>
                    <li>
                      <Link
                        href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzIwNjA3/45-Minute-Great-Salt-Lake-Boat-Tour?arrival=${today}&departure=${tomorrow}`}
                        label='Boat Tour of the Great Salt Lake'
                        target='_blank'
                      />
                    </li>
                    <li>
                      <Link
                        href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MzE1MzI0/Salt-Lake-Ghosts-and-Hauntings-Tour-By-US-Ghost-Adventures?arrival=${today}&departure=${tomorrow}`}
                        label='SLC Ghost Tour'
                        target='_blank'
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onBestOfSlcExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
      </div>

      <div
        className='flex w-full flex-col flex-col-reverse items-stretch justify-center bg-white lg:flex-row'
        id='park-city'
      >
        <div className='py-10'>
          <PageLayoutContainer>
            <div className='flex h-full flex-col items-center gap-4'>
              <h3 className='type-h3 self-start'>Nearby Hotels:</h3>
              <LandingPageSearchHotelsResults
                hotelIds={[101215842, 102790416, 100650854]}
              />
              <div className='py-5'>
                <Button
                  label='See More Hotels'
                  size='large'
                  onClick={onParkCityHotelsClick}
                />
              </div>
              <h3 className='type-h3 self-start'>Nearby Experiences:</h3>
              <ul className='self-start'>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjU0MDk3/Ultimate-Park-City-Tour%3A-Olympic-Park%2C-Hike%2C-Historic-Main-Street?arrival=${today}&departure=${tomorrow}`}
                    label='Olympic Park Tour & More'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6MjY2NzA3/Park-City-Prowl-Scavenger-Hunt?arrival=${today}&departure=${tomorrow}`}
                    label='Park City Scavenger Hunt'
                    target='_blank'
                  />
                </li>
                <li>
                  <Link
                    href={`https://www.travelpass.com/destinations/state/city/experiences/RXhwZXJpZW5jZVByb2R1Y3Q6NTIxNTY=/2-Day-Rental-of-Park-City-Premium-Ski-Package?arrival=${today}&departure=${tomorrow}`}
                    label='Ski Rental Package (Winter Only)'
                    target='_blank'
                  />
                </li>
              </ul>
              <Button
                label='Explore More Experiences'
                size='large'
                onClick={onParkCityExperiencesClick}
              />
            </div>
          </PageLayoutContainer>
        </div>
        <div className='h-300px bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/e389a538-eac5-11ee-8c15-0e92b60508d9)] bg-cover bg-center lg:h-auto'>
          <div className='bg-forestDark/50 flex h-full flex-col justify-center'>
            <PageLayoutContainer>
              <h2 className='type-h1 c-white mb-4 text-center'>
                Day Trip to Park City
              </h2>
              <PageLayoutContainer size='medium'>
                <p className='type-body-1 c-white mb-4 text-center'>
                  We can’t resist a trip to nearby Park City. Known for
                  world-class skiing and charming mountain vibes year round, a
                  trip here is all about soaking in the views, fine dining,
                  snowy experiences, and summer evenings under the stars.
                </p>
                <p className='type-body-1 c-white text-center'>
                  <b>Inside tip:</b> We have a packed Park City itinerary for
                  your visit, but if you need more to do, Deer Valley and Midway
                  are local favorites (both are close by).
                </p>
              </PageLayoutContainer>
            </PageLayoutContainer>
          </div>
        </div>
      </div>

      <div className='bg-forest lg:py-15 flex w-full flex-col items-center justify-center py-10'>
        <h3 className='type-h2 c-white mb-10'>Explore Your Utah</h3>

        <div className='w-94% max-w-1000px mx-a grid grid-cols-1 gap-4 md:grid-cols-2'>
          <div className='h-200px bg-forest relative col-span-2 row-span-5 overflow-hidden lg:col-span-1'>
            <a href='/inside-scoop/southern-utah'>
              <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4'>
                SOUTHERN UTAH
              </p>
              <img
                alt='Zion National Park, Utah'
                className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-60'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/a96704a4-e79b-11ee-9ca9-8e1da778f97e'
              />
            </a>
          </div>

          <div className='h-200px bg-forest relative col-span-2 overflow-hidden lg:col-span-1 lg:row-span-2'>
            <a href='/inside-scoop/utah-county'>
              <p className='type-h3 c-white z-2 text-shadow-lg absolute left-4 top-4'>
                UTAH COUNTY
              </p>
              <img
                alt='Provo, Utah'
                className='hover:scale-115 h-full w-full object-cover transition-all duration-300 hover:opacity-60'
                src='https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/c8619e24-e70e-11ee-9ea9-ca13f8e0f9ae'
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const metaDescription =
  'Explore Salt Lake City, Utah’s vibrant capital, with our ultimate travel guide. Dive into the rich history, stunning mountain views, and dynamic culture. From world-class skiing and hiking to exploring Temple Square and the Great Salt Lake, find everything you need for an unforgettable trip. Get insider tips on the best hotels, restaurants, and attractions to make your Salt Lake City visit truly remarkable.'
const pageName = 'Inside Scoop: Salt Lake City, Utah'
