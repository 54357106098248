import { Modal, ModalScrollContents } from '@travelpass/design-system'
import { EditingProfileOverview } from './EditingProfileOverview'
import { useCheckEditingMode } from '../hooks/useCheckEditingMode'
import { useGetProfile } from '../hooks/useGetProfile'

export const EditingProfileModal = () => {
  const { refetch } = useGetProfile()
  const { handleCloseEditMode } = useCheckEditingMode()

  return (
    <Modal
      onDismiss={() => {
        refetch()
        handleCloseEditMode()
      }}
    >
      <ModalScrollContents>
        <EditingProfileOverview />
      </ModalScrollContents>
    </Modal>
  )
}
