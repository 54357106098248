import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'

export const CustomContentProgressbar = props => {
  const { children, ...otherProps } = props

  return (
    <div className='w-100% h-100% relative'>
      <div className='absolute'>
        <CircularProgressbar
          {...otherProps}
          styles={buildStyles({
            pathColor: '#66D210',
            trailColor: '#D9D9D980',
          })}
        />
      </div>
      <div className='h-100% w-100% flex flex-col items-center justify-center'>
        {props.children}
      </div>
    </div>
  )
}
