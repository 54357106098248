import { Button, ModalNext } from '@travelpass/design-system'

interface LinkAVideoModalProps {
  onClose: () => void
  onClick: () => void
}

export const LinkAVideoModal = ({ onClose, onClick }: LinkAVideoModalProps) => (
  <ModalNext
    actions={
      <div className='flex w-full justify-center'>
        <Button label='Link A Video' size='medium' onClick={onClick} />
      </div>
    }
    size='medium'
    title='Link A Video'
    onClose={onClose}
  >
    <section className='space-y-5'>
      <p className='type-h6'>What type of video (and why)?</p>
      <p className='type-body-1'>
        - Travelpass is partially using this $20,000 dream vacation contest to
        test different elements of the platform which is why we&apos;re asking
        each participant to link a video.
      </p>
      <p className='type-body-1'>
        To complete your profile, please provide a link to your favorite
        travel-related YouTube video to share with the travel community. While
        high-quality content is appreciated, your video choice won&apos;t affect
        your chances of winning as long as it&apos;s appropriate.
      </p>
    </section>
  </ModalNext>
)
