import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const USER_COMPETITION_STATUS = gql(`
    query GetUserCompetitionStatus {
      currentUser {
        id
        firstName
        lastName
        userProfile {
          id
          accountHandle
          displayName
          isUserOwner
          profileCompletion {
            completionPercentage
            hasBio
            hasProfileImage
            hasSocialLinks
            hasTravelStats
            hasTravelStyle
          }
          competitionInfo {
            status
            competitionCheck{
              hasProfileImage
              hasBio
              hasVideoLink
              highQualityGuideCount
              isCompetitionReady
            }
          }
          userId
        }
      }
    }
  `)

export const useLazyUserCompetitionStatus = () =>
  useLazyQuery(USER_COMPETITION_STATUS)
