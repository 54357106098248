import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import type { HotelHeroHeaderAndImagesQuery } from 'src/__generated__/graphql'

const HOTEL_HERO = gql(`
  query HotelHeroHeaderAndImages($hotelId: String!) {
    lodging(id: $hotelId, idType: STANDARD) {
      active
      id
      address
      amenities
      chainData {
        brandName
      }
      city
      country
      descriptions {
        text
      }
      googlePlaceId
      imageLinks
      latitude
      longitude
      name
      postalCode
      rating
      state
      stateCode
    }
  }
`)

export const useHotelHeroStandardHotelQuery = (
  onCompleted?: (
    standardHotel: HotelHeroHeaderAndImagesQuery['lodging']
  ) => void
) => {
  const { hotelId } = useParams()

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(HOTEL_HERO, {
    skip: !hotelId,
    variables: { hotelId },
    onCompleted: data => {
      onCompleted?.(data.lodging)
    },
  })

  const { lodging } = data ?? {}

  return {
    hasError,
    standardHotel: lodging,
    isLoading,
  }
}
