import { useState } from 'react'
import { IconButton, Icon } from '@travelpass/design-system'
import type { HotelDetailsStandardHotelQuery } from 'src/__generated__/graphql'
import { HotelMap } from 'src/pages/hotels/details/components/HotelMap'
import {
  kilometersToMiles,
  milesToMinutesWalking,
  stringToNumber,
} from 'src/utils'
import { useHotelLocationStandardHotelQuery } from '../../hooks'
import { HotelLoading } from '../HotelLoading'
import { filteredPointsOfInterest } from '../HotelMap/hotelMapUtils'
import { HotelModal } from '../HotelModal'

interface HotelOverviewNearbyAttractionsProps {
  hotelDetailsData: HotelDetailsStandardHotelQuery['lodging']
  showMap: boolean
}

export const HotelOverviewNearbyAttractions = ({
  hotelDetailsData,
  showMap,
}: HotelOverviewNearbyAttractionsProps) => {
  const [isMapModalOpen, setIsMapModalOpen] = useState(false)
  const { hasError, hotelLocationData, isLoading } =
    useHotelLocationStandardHotelQuery()
  const { staticMap } = hotelDetailsData ?? {}
  const { pointsOfInterest } = hotelLocationData ?? {}
  const filteredPoints = filteredPointsOfInterest(pointsOfInterest)

  if (isLoading || !showMap) return <HotelLoading />
  if (hasError) return // TODO: Error component

  const constructedPointsItems = filteredPoints.map(
    ({ distance, name, unit }) => {
      const distanceToNumber = stringToNumber(distance)
      const updatedDistance =
        unit === 'Km' ? kilometersToMiles(distanceToNumber) : distanceToNumber
      const minutesWalking = milesToMinutesWalking(updatedDistance)
      const maximumMinutesWalking = 100

      return (
        minutesWalking < maximumMinutesWalking && (
          <div
            key={name}
            className='color-grey-900 type-body-1 flex justify-between gap-x-2 py-1'
          >
            <div className='flex gap-4'>
              <Icon name='place' size='small' /> {name}
            </div>
            <div>{minutesWalking && <> {minutesWalking} min walk</>}</div>
          </div>
        )
      )
    }
  )

  return (
    <>
      {isMapModalOpen && (
        <HotelModal
          initialTab='MAP'
          onCloseModal={() => setIsMapModalOpen(false)}
        />
      )}
      <div id='hotel-location'>
        <div className='sm:pb-8'>
          <div className='pb-6'>
            <h4 className='type-h4'>Nearby Attractions</h4>
          </div>
          <div className='h-90 rounded-5 of-hidden relative'>
            {staticMap ? (
              <img
                alt='Google Static Map'
                className='h-full w-full object-cover'
                loading='lazy'
                src={staticMap}
              />
            ) : (
              <HotelMap
                enableScrollWheel={false}
                height='h-90'
                hideZoom={true}
                points={filteredPoints}
              />
            )}
            <div className='shadow-2 rounded-10 absolute bottom-7 right-5 flex flex-col bg-white'>
              <IconButton
                color='transparent'
                icon='mapExpand'
                onClick={() => setIsMapModalOpen(true)}
              />
            </div>
          </div>
          <div className='px-4 pt-8 lg:px-12'>{constructedPointsItems}</div>
        </div>
      </div>
    </>
  )
}
