export const BOUNDING_BOX_PARAMS = {
  sw: {
    lat: 'sw-lat',
    lng: 'sw-lng',
  },
  ne: {
    lat: 'ne-lat',
    lng: 'ne-lng',
  },
}
