import {
  Button,
  Divider,
  IconButton,
  Input,
  Modal,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideStatus } from 'src/__generated__/graphql'
import {
  constructAddress,
  copyToClipboard,
  getGuideDetailsUrl,
} from 'src/utils'
import { GuideDraftShareSettings } from './GuideDraftShareSettings'
import { GuideImage } from '../../../details/common'
import type { GuideDraftData } from '../../types'

interface GuideDraftShareModalProps {
  guideDraftData: GuideDraftData
  onDismiss: VoidFunction
}

export const GuideDraftShareModal = ({
  guideDraftData,
  onDismiss,
}: GuideDraftShareModalProps) => {
  const { addSuccessSnack } = useSnackbar()

  const { addresses, guide } = guideDraftData ?? {}
  const { id, name, status } = guide ?? {}
  const address = constructAddress({
    addressFirstLine: addresses?.[0]?.addressLine1,
    city: addresses?.[0]?.city,
    state: addresses?.[0]?.state,
  })
  const guideUrl = `${window.location.origin}${getGuideDetailsUrl({
    id,
    name,
  })}`
  const image = guideDraftData?.images?.[0]?.url ?? guideDraftData?.imageUrl
  const isPublished = status === GuideStatus.Published

  const onCopy = () => {
    copyToClipboard(guideUrl)
    addSuccessSnack({
      title: 'Copied to clipboard!',
    })
  }

  if (!id) return

  return (
    <Modal size='large' title='Share your Guide' onDismiss={onDismiss}>
      <ModalScrollContents>
        <section className='p-x-2 md:p-x-0 space-y-4'>
          <header
            className={classNames({
              'min-h-50 p-x-6 p-y-4 of-hidden rounded-2 relative flex flex-col justify-end gap-1':
                image,
            })}
          >
            {image && (
              <>
                <GuideImage className='absolute inset-0' src={image} />
                <div className='from-black/58 absolute inset-0 bg-gradient-to-l via-black/30 via-40% to-black/65 to-100%' />
              </>
            )}
            <h3
              className={classNames('type-h3 relative', {
                'c-white': image,
              })}
            >
              {guideDraftData?.name}
            </h3>
            {address && (
              <p
                className={classNames('type-body-1 relative', {
                  'c-white': image,
                })}
              >
                {address}
              </p>
            )}
          </header>
          <GuideDraftShareSettings
            guideDraftData={guideDraftData}
            isPublished={isPublished}
          />
          <Divider />
          <section className='space-y-6'>
            <header className='space-y-3'>
              <h4 className='c-black type-h4'>
                Share your {isPublished ? 'private' : 'public'} Guide
              </h4>
              <p className='type-body-2 c-grey-900'>
                This link provides viewing access to your private guide,
                allowing others to see the information and details you have
                shared within it.
              </p>
            </header>
            <div className='flex flex-col items-center gap-2 md:flex-row'>
              <Input
                fullWidth={true}
                readOnly={true}
                slotBefore={
                  <IconButton
                    aria-label='Copy guide URL'
                    autoFocus={true}
                    color='transparent'
                    icon='contentCopy'
                    onClick={onCopy}
                  />
                }
                value={guideUrl}
              />
              <div className='min-w-fit'>
                <Button
                  size='large'
                  startIcon='link'
                  variant='outlined'
                  onClick={onCopy}
                >
                  copy link
                </Button>
              </div>
            </div>
          </section>
        </section>
      </ModalScrollContents>
    </Modal>
  )
}
