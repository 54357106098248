import type { ReactNode } from 'react'
import { Button } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useSearchParams } from 'react-router-dom'
import { Map } from 'src/common/components/Map'
import { getDefaultZoom } from 'src/common/components/Map/utils'
import { googleMapIdsGuideDraftConfig } from 'src/config/maps'
import { initialCountry } from 'src/constants/user'
import { GuideSearchParam } from 'src/pages/guides/details'
import { onGuideSessionStorageSelectedIdChange } from 'src/pages/guides/details/useGuideSessionStorageIds'
import { getGeocoderLatitude, getGeocoderLongitude } from 'src/utils'
import { onGuideSessionStorageHoverIdChange } from '../../../details'
import type { GuideDraftData } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQueryOld'

interface GuideDraftMapProps {
  children: ReactNode
  data: UseGetGuideDraftQuery['data']
}

export const GuideDraftMap = ({ children, data }: GuideDraftMapProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { addresses } = (data?.node as GuideDraftData) ?? {}
  const { city, country, lat, long, state } = addresses?.[0] ?? {}
  const mapZoom = city
    ? getDefaultZoom({
        city,
        state,
        country: country ?? initialCountry,
      })
    : 5

  const onMapClick = debounce(() => {
    onGuideSessionStorageHoverIdChange('')
    onGuideSessionStorageSelectedIdChange('')
  }, 100)

  const onMapClose = () => {
    searchParams.set(GuideSearchParam.mapFullView, 'false')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <>
      <Map
        defaultCenter={{
          lat: getGeocoderLatitude(lat),
          lng: getGeocoderLongitude(long),
        }}
        defaultZoom={mapZoom}
        gestureHandling='greedy'
        googleMapConfigId={googleMapIdsGuideDraftConfig}
        maxZoom={20}
        minZoom={3}
        onClick={onMapClick}
      >
        {children}
      </Map>
      <div className='m-a fixed bottom-3 left-0 right-0 w-fit lg:hidden'>
        <Button
          aria-label='Back to guide'
          size='small'
          startIcon='arrowBackIos'
          onClick={onMapClose}
        >
          Back to Guide
        </Button>
      </div>
    </>
  )
}
