import { Icon, IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { ResultsCard } from 'src/common/components'
import { GuideImage } from 'src/pages/guides/details/common'
import { TripSearchParams } from 'src/pages/trips/constants'
import type { TripDailyEvent } from 'src/pages/trips/types'
import { constructAddress } from 'src/utils'

interface TripExploreCardProps {
  tripEventData: TripDailyEvent
}

export const TripExploreCard = ({ tripEventData }: TripExploreCardProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { id, name: title, addresses, imageUrl: image } = tripEventData ?? {}
  const { addressLine1: addressFirstLine, city, state } = addresses[0] ?? {}
  const address = constructAddress({
    addressFirstLine,
    city,
    state,
  })

  const onClick = () => {
    searchParams.set(TripSearchParams.selectedId, id)
    searchParams.set(TripSearchParams.mapExpanded, 'true')
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onMouseEnter = () => {
    searchParams.set(TripSearchParams.hoverId, id)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  const onMouseLeave = () => {
    searchParams.delete(TripSearchParams.hoverId)
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    title && (
      <ResultsCard>
        <section className='b-grey-300 b-solid b-1px p-l-33 of-hidden rounded-3 md:p-l-31 pointer-events-none relative'>
          <div className='of-hidden rounded-br-3 rounded-tr-3 w-33 md:w-31 absolute left-0 top-0 h-full'>
            <GuideImage alt='Event image' src={image} />
          </div>
          <div className='min-h-23.5 flex flex-row justify-between p-3'>
            <div className='space-y-1'>
              <h2 className='type-body-1-medium line-clamp-1'>{title}</h2>
              {address && (
                <div
                  className='color-grey-800 flex flex-row items-center gap-1'
                  data-test-id='address'
                >
                  <Icon name='placeOutline' size='small' />
                  <p className='type-small-text line-clamp-1'>{address}</p>
                </div>
              )}
            </div>
            <div className='m-y-auto'>
              {/** @todo add the onClick for the iconButton */}
              <IconButton
                color='white'
                icon='add'
                outline='round'
                size='small'
              />
            </div>
          </div>
        </section>
      </ResultsCard>
    )
  )
}
