import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_USER_ONBOARDING = gql(`
  query UserOnboardingInfo {
    currentUser {
      id
      userProfile {
        id
        accountHandle
      }
      ...ProfileCurrentUser
    }
  } 
`)

export const useGetUserOnboardingQuery = () => useQuery(GET_USER_ONBOARDING)
